import Stepper from 'components/stepper';
import { Button } from 'components/styled-mobile-register-header/styles';
import CopyRight from 'images/copyright.svg';
import { Logo } from 'images/custom-organization';
import CustomLogo from 'images/custom-organization/logo.svg';
import Mail from 'images/mail.svg';
import { WhiteHamburguerSvg } from 'images/white-hamburguer';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Container, Footer, FooterContent, FooterLabel, LogoContainer } from './styles';

interface IStyledMobileSideMenuProps {}

const StyledMobileSideMenu: React.FC<IStyledMobileSideMenuProps> = props => {
  const { t } = useTranslation();
  const { showMobileStepsModal, setShowMobileStepsModal } = useRegisterForm();
  return (
    <Container>
      <LogoContainer>
        <Logo isWhite />
        <Button onClick={() => setShowMobileStepsModal(!showMobileStepsModal)}>
          <WhiteHamburguerSvg />
        </Button>
      </LogoContainer>
      <Stepper activeStep={0} />
      <Footer>
        <FooterContent>
          <img width="14" src={Mail} />
          <FooterLabel>{t('footer.email')}</FooterLabel>
        </FooterContent>
        <FooterContent>
          <img width="14" src={CopyRight} />
          <FooterLabel>{t('footer.companyName')}</FooterLabel>
        </FooterContent>
      </Footer>
    </Container>
  );
};

export default StyledMobileSideMenu;
