import { Attachment } from './attachment';
import { Contract } from './dashboard';

export enum InsuranceStatus {
  WAITING = 'WAITING',
  TICKET_CREATED = 'TICKET_CREATED',
  TICKET_CANCELLED = 'TICKET_CANCELLED',
}

export interface Insurance {
  id: number;
  idNo?: string;
  policyNo?: string;
  statusId?: number;
  status?: string;
  ticketNo?: string;
  internalStatus?: InsuranceStatus;
  attachments?: Attachment[];
  insuranceSimulations?: InsuranceSimulation[];
  contract?: Contract;
}

export interface InsuranceSimulation {
  id?: number;
  idNo?: string;
  statusId?: number;
  status?: string;
  grossPremium?: number;
  sumInsured?: number;
  createdDate?: Date;
  financialAmount?: number;
}
