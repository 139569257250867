import { useTheme } from 'styled-components';
import { ReactComponent as minus } from '../general-images/minus.svg';
import { ReactComponent as plus } from '../general-images/plus.svg';
import { ReactComponent as minus2 } from '../general-images/minus2.svg';
import { ReactComponent as plus2 } from '../general-images/plus2.svg';

export const SimulatorImages = {
  plus,
  plus2,
  minus,
  minus2,
};

export const OutOfTimeSVG = () => {
  const theme = useTheme();
  return (
    <svg width="202" height="201" viewBox="0 0 202 201" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.1019 139.122C55.9957 139.122 65.6374 129.48 65.6374 117.586C65.6374 105.692 55.9957 96.0505 44.1019 96.0505C32.2082 96.0505 22.5664 105.692 22.5664 117.586C22.5664 129.48 32.2082 139.122 44.1019 139.122Z"
        fill={theme.color.primaryColor}
      />
      <path
        d="M44.1019 117.585C42.8937 117.585 41.7126 117.227 40.708 116.556C39.7035 115.885 38.9205 114.931 38.4582 113.814C37.9958 112.698 37.8748 111.47 38.1105 110.285C38.3463 109.1 38.928 108.011 39.7824 107.157C40.6367 106.303 41.7251 105.721 42.9101 105.485C44.0951 105.25 45.3233 105.371 46.4396 105.833C47.5558 106.295 48.5098 107.078 49.1811 108.083C49.8523 109.087 50.2106 110.268 50.2106 111.477"
        stroke="#EEEEEE"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.1018 117.587C45.31 117.587 46.4911 117.945 47.4957 118.616C48.5002 119.287 49.2832 120.241 49.7455 121.358C50.2079 122.474 50.3289 123.702 50.0932 124.887C49.8575 126.072 49.2757 127.161 48.4214 128.015C47.5671 128.869 46.4786 129.451 45.2936 129.687C44.1086 129.922 42.8804 129.801 41.7642 129.339C40.6479 128.877 39.6939 128.094 39.0227 127.089C38.3514 126.085 37.9932 124.904 37.9932 123.695"
        stroke="#EEEEEE"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M44.1016 101.86V133.311" stroke="#EEEEEE" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M196.445 0.557373H148.505V87.1081H196.445V0.557373Z" fill="#EEEEEE" />
      <path d="M75.8031 0.557617H27.8633V87.1083H75.8031V0.557617Z" fill="#EEEEEE" />
      <path d="M99.6181 147.796V193.05L96.6318 199.481H117.108L123.738 136.081L99.6181 147.796Z" fill="#CCCCCC" />
      <path d="M104.167 81.8699L135.954 80.0273V114.488V199.482H114.466L117.107 194.748L104.167 81.8699Z" fill="#EEEEEE" />
      <path
        d="M105.726 25.3802V29.6257C105.726 31.2209 106.26 32.77 107.245 34.0254C108.229 35.2808 109.606 36.17 111.155 36.5507L117.911 38.2113V21.6121L112.293 20.2314C111.512 20.0395 110.698 20.0276 109.912 20.1968C109.126 20.3659 108.389 20.7115 107.756 21.2076C107.124 21.7036 106.612 22.337 106.26 23.0599C105.908 23.7828 105.726 24.5762 105.726 25.3802Z"
        fill="#EEEEEE"
      />
      <path
        d="M112.609 28.7834C112.789 28.7834 112.935 28.6376 112.935 28.4576C112.935 28.2777 112.789 28.1318 112.609 28.1318C112.429 28.1318 112.283 28.2777 112.283 28.4576C112.283 28.6376 112.429 28.7834 112.609 28.7834Z"
        fill="#4C4D4D"
      />
      <path
        d="M107.604 28.7834C107.784 28.7834 107.93 28.6376 107.93 28.4576C107.93 28.2777 107.784 28.1318 107.604 28.1318C107.424 28.1318 107.278 28.2777 107.278 28.4576C107.278 28.6376 107.424 28.7834 107.604 28.7834Z"
        fill="#4C4D4D"
      />
      <path
        d="M117.107 27.9528V29.6964C117.331 29.6964 117.553 29.6523 117.759 29.5668C117.966 29.4812 118.153 29.3557 118.312 29.1976C118.47 29.0395 118.595 28.8517 118.681 28.6451C118.766 28.4385 118.81 28.217 118.81 27.9934C118.81 27.9411 118.808 27.8895 118.803 27.8384C118.708 26.7809 117.107 26.891 117.107 27.9528Z"
        fill="#EEEEEE"
      />
      <path
        d="M135.955 55.8254V82.4337L98.2607 91.6982V62.0639C98.2607 56.9579 99.9727 51.9993 103.123 47.981C106.273 43.9626 110.68 41.1166 115.638 39.8979H115.639C118.054 39.3043 120.572 39.2677 123.003 39.7908C125.435 40.314 127.715 41.3832 129.673 42.9177C131.63 44.4521 133.213 46.4115 134.301 48.6477C135.389 50.884 135.955 53.3384 135.955 55.8254L135.955 55.8254Z"
        fill="#EEEEEE"
      />
      <path d="M135.955 82.4329L98.2607 91.6974V149.979L155.548 140.957L135.955 82.4329Z" fill={theme.color.primaryColor} />
      <path
        d="M103.376 82.0178C103.376 82.0178 117.897 100.853 139.367 92.6246L135.885 82.3917C128.894 92.7939 114.742 88.4288 103.376 82.0178Z"
        fill="#4C4D4D"
      />
      <path
        d="M178.219 105.523C175.075 100.746 170.2 97.3753 164.621 96.12L164.287 96.0449C164.287 96.0449 166.294 110.115 180.969 111.921L181.492 150.004V116.446C181.492 112.563 180.354 108.766 178.219 105.523Z"
        fill="#EEEEEE"
      />
      <path
        d="M198.696 100.561L198.362 100.636C192.783 101.891 187.908 105.262 184.764 110.038V110.038C182.629 113.281 181.491 117.079 181.491 120.962V154.52L182.326 115.264C197.001 113.457 198.696 100.561 198.696 100.561Z"
        fill="#EEEEEE"
      />
      <path d="M192.782 145.506H170.201V200H192.782V145.506Z" fill={theme.color.primaryColor} />
      <path d="M172.475 0V87.6657" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M148.505 87.1086V0.557617H196.445" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M201.068 29.1895H143.881" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M201.068 58.4766H143.881" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M51.833 0V87.6657" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M27.8633 87.1086V0.557617H75.8031" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M80.4268 29.1895H23.2393" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M80.4268 58.4766H23.2393" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M105.726 29.626C105.726 31.2212 106.26 32.7703 107.245 34.0257C108.229 35.2811 109.606 36.1703 111.155 36.551L117.911 38.2115"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M109.072 30.4018V25.3809H106.557" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M113.222 25.3809H110.707" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M117.107 29.6961C117.331 29.6961 117.553 29.6521 117.759 29.5665C117.966 29.4809 118.153 29.3555 118.312 29.1974C118.47 29.0392 118.595 28.8515 118.681 28.6449C118.766 28.4383 118.81 28.2168 118.81 27.9932"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M110.448 30.9873C110.66 31.1988 110.947 31.3176 111.246 31.3176C111.545 31.3176 111.832 31.1988 112.043 30.9873"
        stroke={theme.color.primaryColor}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M109.072 30.4043L110.172 30.6747" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M105.969 23.7853C105.969 23.7853 106.156 20.9955 108.683 21.1678C111.21 21.3401 114.828 21.1104 115.115 23.4075C115.402 25.7046 116.286 27.5423 117.108 27.5423"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M105.726 27.0985C105.726 27.0985 96.7791 10.8883 112.609 9.73977C128.439 8.59121 135.888 29.5716 117.96 32.7876"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M118.336 30.925C118.336 31.168 118.264 31.4056 118.129 31.6076C117.994 31.8097 117.802 31.9672 117.578 32.0602C117.353 32.1532 117.106 32.1775 116.868 32.1301C116.63 32.0827 116.411 31.9657 116.239 31.7938C116.067 31.622 115.95 31.4031 115.903 31.1647C115.855 30.9264 115.879 30.6793 115.972 30.4548C116.065 30.2303 116.223 30.0384 116.425 29.9034C116.627 29.7683 116.865 29.6963 117.108 29.6963"
        stroke={theme.color.primaryColor}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.725 31.8674C105.574 31.9941 105.394 32.0825 105.201 32.125C105.008 32.1675 104.808 32.1629 104.617 32.1115C104.427 32.0602 104.251 31.9637 104.106 31.8302C103.96 31.6967 103.849 31.5301 103.782 31.3445C103.714 31.1589 103.693 30.9598 103.719 30.764C103.744 30.5682 103.817 30.3816 103.93 30.2199C104.044 30.0582 104.194 29.9262 104.37 29.835C104.545 29.7439 104.739 29.6963 104.937 29.6963"
        stroke={theme.color.primaryColor}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M114.467 199.481L117.108 194.747L109.828 131.245" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M130.549 43.6562C130.549 43.6562 148.547 59.2042 136.755 80.9503C124.963 102.696 86.9841 71.6087 86.5247 69.1584C86.0652 66.7082 90.0469 66.0956 93.416 67.9333C93.416 67.9333 92.9566 65.4256 94.7943 66.1435C96.632 66.8613 97.5508 69.0053 97.5508 69.0053L123.432 70.0773L120.061 55.8351"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M106.166 69.3627L94.7949 39.416L68.7607 46.494L84.8406 82.4336L99.2272 79.5183"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M105.969 44.9609L99.209 51.0413" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M74.9267 60.2764C74.9267 60.2764 70.6749 76.2423 80.7822 83.0189C90.8895 89.7954 98.2612 84.7417 98.2612 84.7417"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.492 150.004V116.446C181.492 112.563 180.354 108.766 178.219 105.523V105.523C175.075 100.746 170.2 97.3754 164.621 96.1201L164.287 96.0449C164.287 96.0449 163.384 109.591 178.059 111.397"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.491 154.52V120.962C181.491 117.079 182.629 113.281 184.764 110.038V110.038C187.908 105.262 192.783 101.891 198.362 100.636L198.696 100.561C198.696 100.561 199.599 114.107 184.924 115.913"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.492 147.67V128.178C181.492 125.923 180.831 123.717 179.591 121.833V121.833C177.764 119.059 174.933 117.101 171.693 116.372L171.498 116.328C171.498 116.328 170.974 124.196 179.498 125.245"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M29.3682 200H142.931" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M11.9365 116.424C11.9365 107.87 15.3344 99.6673 21.3827 93.619C27.4309 87.5707 35.6341 84.1729 44.1877 84.1729C52.7412 84.1729 60.9444 87.5707 66.9927 93.619C73.041 99.6673 76.4388 107.87 76.4388 116.424"
        stroke="#4C4D4D"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M44.1875 71.7119V79.419" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M32.6152 73.2363L34.61 80.6808" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M21.832 77.7031L25.6856 84.3776" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M12.5723 84.8086L18.022 90.2583" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M5.4668 94.0684L12.1413 97.9219" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M1 104.853L8.44444 106.847" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M79.9307 106.847L87.3751 104.853" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M76.2344 97.9219L82.9089 94.0684" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M70.3535 90.2583L75.8032 84.8086" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M62.6895 84.3776L66.543 77.7031" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M53.7646 80.6808L55.7594 73.2363" stroke="#4C4D4D" strokeWidth="0.5" strokeMiterlimit="10" />
    </svg>
  );
};
