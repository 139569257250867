import { HeaderVariants } from 'model/enums/header-variants';
import {
  StyledButton,
  StyledButtonsContainer,
  StyledContainer,
  StyledInnerContainer,
  StyledSvgContainer,
  StyledText,
  StyledTitle,
} from './styles';
import Header from 'components/general-components/header';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as AppDownloadSvg } from 'images/app-download.svg';
import { ReactComponent as AppStoreSvg } from 'images/app-store.svg';
import { ReactComponent as PlayStoreSvg } from 'images/play-store.svg';

enum StoreLink {
  PLAY_STORE = 'https://play.google.com/store/search?q=hcred&c=apps&hl=pt_BR&gl=US',
  APP_STORE = 'https://apps.apple.com/br/app/hcred-app/id6446378450',
}

const AppDownloadScreen = ({ t }: WithTranslation) => {
  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />

      <StyledInnerContainer>
        <div>
          <StyledTitle>{t('appDownloadScreen.appNotFound')}</StyledTitle>
          <StyledText>{t('appDownloadScreen.downloadAndReadQrCodeAgain')}</StyledText>
        </div>
        <StyledSvgContainer>
          <AppDownloadSvg />
        </StyledSvgContainer>
        <StyledButtonsContainer>
          <StyledButton href={StoreLink.PLAY_STORE} target="_blank">
            <PlayStoreSvg />
            {t('appDownloadScreen.downloadPlayStore')}
          </StyledButton>
          <StyledButton href={StoreLink.APP_STORE} target="_blank">
            <AppStoreSvg />
            {t('appDownloadScreen.downloadAppStore')}
          </StyledButton>
        </StyledButtonsContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(AppDownloadScreen);
