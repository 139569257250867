import { ORGANIZATION_NAME } from 'config/constants';
import { useConfirmSimulationContext } from 'context/confirm-simulation';
import { useSimulatorContext } from 'context/simulator';
import { useAuthorities } from 'hooks/use-authorities';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { OrganizationTypes } from 'model/enums/organization';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientDataService from 'services/client-data-service';
import clientService from 'services/client-service';
import landingPageService from 'services/landing-page-service';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface StateType {
  isNewCredit?: boolean;
}

interface LocationType {
  state: StateType;
}

interface ConfirmSimulationProps {
  location: LocationType;
}

const ConfirmSimulation: React.FC<ConfirmSimulationProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;

  const isNewCredit = props?.location?.state?.isNewCredit ?? false;

  const history = useHistory();
  const { simulatorScreenData, simulatorLimits } = useSimulatorContext();
  const { setLimitMonthError } = useConfirmSimulationContext();

  const { isPhysicalPerson } = useSystemParameters();

  const { clientId } = useParams<{ clientId: string }>();

  const [percentageOverClientIncome, setPercentageOverClientIncome] = useState<number>(0);
  const [income, setIncome] = useState<number>(0);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const isAuth = useSelector((state: IRootState) => state.authentication);

  const { isDashboardAdmin } = useAuthorities();

  const redirect = nextRoute => {
    setTimeout(() => {
      history.push(nextRoute, {
        isNewCredit: isNewCredit,
      });
    }, 1000);
  };

  const verifyUser = () => {
    if (isAuth.isAuthenticated) {
      return isDashboardAdmin ? redirect(RoutePath.ADMIN_HOME) : redirect(RoutePath.CREDITS);
    } else {
      return redirect(RoutePath.HOME);
    }
  };

  const getInformationForClient = async () => {
    let income: number | undefined;

    const simulatorParameters = await landingPageService.getSimulatorParameters();

    if (!simulatorParameters.percentageOverClientIncome) return;

    setPercentageOverClientIncome(simulatorParameters.percentageOverClientIncome);
    const foundClientId = (await Number(clientId)) || account?.client?.id;
    const registrationKey = localStorage.getItem(LocalStorageKeys.REGISTRATION_KEY) ?? undefined;

    if (!foundClientId) {
      if (!registrationKey) return verifyUser();
      const clientRegistration = await clientService.getClientRegistration(JSON.parse(registrationKey));
      income = isPhysicalPerson ? clientRegistration.physicalPerson?.income : clientRegistration.legalPerson?.revenue;
    } else {
      const clientIncome = await clientDataService.getClientSummary(foundClientId);
      income = isPhysicalPerson ? clientIncome.physicalPerson?.income : clientIncome.legalPerson?.revenue;
    }

    if (!income) return verifyUser();
    setIncome(income);
  };

  const unLoggedStatus = [
    RegistrationStatus.REJECTED,
    RegistrationStatus.NEW,
    RegistrationStatus.PRE_REJECTED,
    RegistrationStatus.VALIDATION,
  ];

  const whichRoute = unLoggedStatus.some((status: RegistrationStatus) => status.includes(account?.client?.registrationStatus ?? ''));

  useEffect(() => {
    switch (ORGANIZATION_NAME) {
      case OrganizationTypes.azure:
        getInformationForClient();
        return;
      case OrganizationTypes.monjua:
        getInformationForClient();
        return;
      default:
        getInformationForClient();
        return;
    }
  }, []);

  useEffect(() => {
    if (income === 0 || percentageOverClientIncome === 0) return;
    if (!simulatorLimits.newMonthLimit) return;
    const firstInstallment = simulatorScreenData?.simulate?.firstInstallmentValue ?? 0;

    const aprovedMonthLimit = simulatorLimits.newMonthLimit + (10 / 100) * simulatorLimits.newMonthLimit;
    const aprovedPerIncome = (income * percentageOverClientIncome) / 100;

    let minimumValueForInstallment;

    if (!isNaN(aprovedMonthLimit)) {
      minimumValueForInstallment = aprovedMonthLimit > aprovedPerIncome ? aprovedPerIncome : aprovedMonthLimit;
    } else {
      minimumValueForInstallment = aprovedPerIncome;
    }

    const aprovedMensalValue = minimumValueForInstallment > firstInstallment;

    if (!aprovedMensalValue) {
      return setLimitMonthError(true);
    } else {
      return setLimitMonthError(false);
    }
  }, [simulatorScreenData, percentageOverClientIncome, income]);

  return !isMobile ? <Desktop whichRoute={whichRoute} /> : <Mobile whichRoute={whichRoute} />;
};

export default ConfirmSimulation;
