import { LocalStorageKeys } from 'model/enums/local-storage-keys';

interface ClientSimulatorInfo {
  [key: string]: unknown;
}

const useSimulatorStorage = () => {
  const setClientSimulatorInfoToStorage = (clientId: string | undefined, sendSimulatorData: unknown) => {
    const simulatorData = localStorage.getItem(LocalStorageKeys.SEND_SIMULATOR);
    const clientKey = clientId ? clientId : 'client';

    let clientSimulatorInfo: ClientSimulatorInfo;
    if (simulatorData) {
      clientSimulatorInfo = JSON.parse(simulatorData);
    } else {
      clientSimulatorInfo = {};
    }

    clientSimulatorInfo[clientKey] = sendSimulatorData;
    localStorage.setItem(LocalStorageKeys.SEND_SIMULATOR, JSON.stringify(clientSimulatorInfo));
  };

  const getClientSimulatorInfoFromStorage = (clientId: string | undefined): unknown | undefined => {
    const simulatorData = localStorage.getItem(LocalStorageKeys.SEND_SIMULATOR);
    const clientKey = clientId ? clientId : 'client';

    let clientSimulatorInfo: ClientSimulatorInfo;
    if (simulatorData) {
      clientSimulatorInfo = JSON.parse(simulatorData);
    } else {
      clientSimulatorInfo = {};
    }

    return clientSimulatorInfo[clientKey];
  };

  const clearClientSimulatorInfoFromStorage = (clientId: string) => {
    const simulatorData = localStorage.getItem(LocalStorageKeys.SEND_SIMULATOR);
    const clientKey = clientId ? clientId : 'client';

    let clientSimulatorInfo: ClientSimulatorInfo;
    if (simulatorData) {
      clientSimulatorInfo = JSON.parse(simulatorData);
    } else {
      clientSimulatorInfo = {};
    }

    delete clientSimulatorInfo[clientKey];
    localStorage.setItem(LocalStorageKeys.SEND_SIMULATOR, JSON.stringify(clientSimulatorInfo));
  };

  return {
    setClientSimulatorInfoToStorage,
    getClientSimulatorInfoFromStorage,
    clearClientSimulatorInfoFromStorage,
  };
};

export default useSimulatorStorage;
