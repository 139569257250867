import evolveGuaranteeApi from 'api/evolve-guarantee-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { EvolveGuaranteeToSimple } from 'model/evolve-guarantee';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { createEvolveGuaranteeError, createEvolveGuaranteeRequest, createEvolveGuaranteeSuccess } from './actions';
import { CreateEvolveGuaranteeTypes } from './types';

function* handleCreateEvolveGuarantee(action: ReturnType<typeof createEvolveGuaranteeRequest>) {
  try {
    const result: AxiosResponse<EvolveGuaranteeToSimple> = yield call(evolveGuaranteeApi.createEvolveGuarantee, action.payload);
    if (result.status !== HttpStatus.CREATED) {
      const errorMessage: string = result.status === 401 ? 'Not permitted' : 'An unknown error occured.';
      yield put(createEvolveGuaranteeError(errorMessage));
      return;
    }
    yield put(createEvolveGuaranteeSuccess(result.data));
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(createEvolveGuaranteeError(error.stack));
    } else {
      yield put(createEvolveGuaranteeError('An unknown error occured.'));
    }
  }
}

function* watchCreateEvolveGuarantee() {
  yield takeLeading(CreateEvolveGuaranteeTypes.CREATE_EVOLVE_GUARANTEE_REQUEST, handleCreateEvolveGuarantee);
}

function* createEvolveGuaranteeSaga() {
  yield all([fork(watchCreateEvolveGuarantee)]);
}

export default createEvolveGuaranteeSaga;
