import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Content, Label, PageContainer, TextContainer, TextField, Title, TitleHeader, Text } from './styles';
import { FiChevronLeft } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import financingService from 'services/financing-service';
import { BankAccount } from 'model/bank-account';
import { fillNumbers } from 'shared/util/string-utils';
import { BankAccountType } from 'model/enums/bank-account-type';

export const BankAccountScreen = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { id } = useParams<{ id: string }>();
  const [bankAccount, setBankAccount] = useState<BankAccount | null>(null);

  useEffect(() => {
    getBankAccount();
  }, []);

  const getBankAccount = async () => {
    if (id) {
      const res = await financingService.getFinancingBankAccount(Number(id));
      setBankAccount(res.data.bankAccount);
    }
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.bankAccountScreen.pageTitle')}</span>
        </Title>
      </TitleHeader>

      <Content>
        <TextContainer>
          <TextField>
            <Label>{t('admin.bankAccountScreen.labels.bank')}</Label>
            <Text>{`${bankAccount?.bank?.number ?? ''} ${bankAccount?.bank?.name ? ` - ${bankAccount.bank.name}` : ''}`}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.bankAccountScreen.labels.agency')}</Label>
            <Text>{`${bankAccount?.agencyNumber != null ? fillNumbers(bankAccount.agencyNumber) : ''}${
              bankAccount?.agencyDigit ? `-${bankAccount?.agencyDigit}` : ''
            }`}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.bankAccountScreen.labels.account')}</Label>
            <Text>
              {bankAccount?.bankAccountNumber != null ? `${bankAccount?.bankAccountNumber} - ${bankAccount?.bankAccountDigit}}` : ''}
            </Text>
          </TextField>
          <TextField>
            <Label>{t('admin.bankAccountScreen.labels.type')}</Label>
            <Text>{bankAccount?.type ? BankAccountType[bankAccount?.type] : ''}</Text>
          </TextField>
          {bankAccount?.pixType && (
            <TextField>
              <Label>{t('admin.bankAccountScreen.labels.pixType')}</Label>
              <Text>{t(`enums.pixType.${bankAccount?.pixType}`)}</Text>
            </TextField>
          )}
          {bankAccount?.pixKey && (
            <TextField>
              <Label>{t('admin.bankAccountScreen.labels.pixKey')}</Label>
              <Text>{bankAccount?.pixKey}</Text>
            </TextField>
          )}
          <TextField>
            <Label>{t('admin.bankAccountScreen.labels.jointAccount')}</Label>
            <Text>{bankAccount?.jointAccount ? t('global.button.yes') : t('global.button.no')}</Text>
          </TextField>
        </TextContainer>
      </Content>
    </PageContainer>
  );
};
