import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Client, EmploymentBond } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { unMaskedCurrency } from 'shared/util/register-utils';
import { employmentBondOptions } from 'shared/util/select-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsIncomeTopOne } from './inputs-income';

export const FormIncomeTopOne = () => {
  const { onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const { occupations, getOccupations } = useSelectLists();
  const [employmentBondValue, setEmploymentBondValue] = useState('');
  const { initialClientData } = useRegisterForm();

  const checkIfIsRequired = () => {
    switch (employmentBondValue) {
      case EmploymentBond.AUTONOMO:
        return true;
      case EmploymentBond.PROFISSIONAL_LIBERAL:
        return true;
      case EmploymentBond.APOSENTADO:
        return true;
      case EmploymentBond.RURAL:
        return true;
      case EmploymentBond.EMPRESARIO:
        return true;
      case EmploymentBond.OUTRAS:
        return true;
      default:
        return false;
    }
  };

  const schema = yup.object({
    employmentBond: yup.string().required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    admissionDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
  });

  useEffect(() => {
    getOccupations({ size: 7 });
  }, []);

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const newIncome = unMaskedCurrency(res.income) / 100;
          const occupation = occupations.find(occ => occ.name === res.occupation);
          const employmentBondName = employmentBondOptions.find(occ => occ.label === res.employmentBond)?.value;

          const newPhysicalPerson = {
            ...initialClientData,
            employmentBond: employmentBondName,
            physicalPerson: {
              ...initialClientData?.physicalPerson,
              admissionDate: res.admissionDate ? format(res.admissionDate, 'yyyy-MM-dd') : '',
              income: newIncome,
              occupation,
            },
          };

          onClickNext(newPhysicalPerson as Client);
        }}
      >
        <ContainerInputsIncomeTopOne setEmploymentBondValue={setEmploymentBondValue} />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
