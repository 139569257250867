import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { unMaskedCurrency } from 'shared/util/register-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsPatrimonialSituation } from './inputs-patrimonial-situation';

export const FormPatrimonialSituation = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();

  const schema = yup.object({
    baseDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    netWorth: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), netWorth => {
        return Boolean(netWorth?.match(/\d/g));
      }),
    revenue: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), revenue => {
        return Boolean(revenue?.match(/\d/g));
      }),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const baseDate = format(res.baseDate, 'yyyy-MM-dd');
          const netWorth = unMaskedCurrency(res.netWorth) / 100;
          const revenue = unMaskedCurrency(res.revenue) / 100;
          const newRes = {
            ...initialClientData,
            legalPerson: {
              ...initialClientData?.legalPerson,
              baseDate,
              netWorth,
              revenue,
            },
          };
          onClickNext(newRes as Client);
        }}
      >
        <ContainerInputsPatrimonialSituation />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
