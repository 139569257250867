import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ReactComponent as ErrorSvg } from 'images/sadface.svg';
import CustomButton from 'components/custom-button';
import { useHistory } from 'react-router-dom';

const ErrorPage = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <S.ErrorContainer>
        <div style={{ margin: '16px 0px 16px 0px' }}>
          <ErrorSvg />
        </div>
        <S.Title>{t('errorPage.title')}</S.Title>
        <S.Description>{t('errorPage.description')}</S.Description>

        <CustomButton
          height={48}
          width="160px"
          onClick={() => {
            goBack();
          }}
          style={{ justifyContent: 'center' }}
        >
          {t('global.button.backStep')}
        </CustomButton>
      </S.ErrorContainer>
    </>
  );
};

export default ErrorPage;
