import landingPageApi from 'api/landing-page-api';
import { AxiosResponse } from 'axios';
import { OrganizationData } from 'model/organization-types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { landingPageTemplateError, landingPageTemplateSuccess } from './actions';
import { LandingPageTemplateActionTypes } from './types';

function* handleLandingPageTemplate() {
  try {
    const result: AxiosResponse<OrganizationData> = yield call(landingPageApi.getData);

    if (result.status !== 200 || result.data == null) {
      const errorMessage: string = result.status === 401 ? 'Cannot register this user' : 'An unknown error occured.';
      yield put(landingPageTemplateError(errorMessage));
      return;
    } else {
      yield put(landingPageTemplateSuccess(result.data));
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(landingPageTemplateError(err.stack));
    } else {
      yield put(landingPageTemplateError('An unknown error occured.'));
    }
  }
}

function* watchLandingPageTemplateRequest() {
  yield takeEvery(LandingPageTemplateActionTypes.LANDING_PAGE_TEMPLATE_REQUEST, handleLandingPageTemplate);
}

function* landingPageTemplateSaga() {
  yield all([fork(watchLandingPageTemplateRequest)]);
}

export default landingPageTemplateSaga;
