import Header from 'components/general-components/header';
import { TableList, TableValues } from 'features/admin/dashboard/components/table-list';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiEdit2, FiTrash } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ButtonsContainer, EditContainer, PageContainer, Title, TitleHeader } from './styles';

export const GuarantorsList = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: 'Nome',
        values: ['Carlos Ferreira Vasconcelos', 'Carlos Ferreira Vasconcelos'],
      },
      { header: 'E- mail', values: ['carlosfvasconcelos@email.com', 'carlosfvasconcelos@email.com'] },
      { header: 'Telefone', values: ['(00) 00000-0000', '(00) 00000-0000'] },
      {
        header: 'CPF',
        values: [
          <EditContainer key="1">
            <span>000.000.000-00</span>
            <ButtonsContainer>
              <button className="edit" onClick={() => {}}>
                <FiEdit2 color={color.gray24} size={18} />
              </button>
              <button className="trash">
                <FiTrash color={color.gray24} size={18} onClick={() => {}} />
              </button>
            </ButtonsContainer>
          </EditContainer>,
          <EditContainer key="2">
            <span>000.000.000-00</span>
            <ButtonsContainer>
              <button className="edit" onClick={() => {}}>
                <FiEdit2 color={color.gray24} size={18} />
              </button>
              <button className="trash">
                <FiTrash color={color.gray24} size={18} onClick={() => {}} />
              </button>
            </ButtonsContainer>
          </EditContainer>,
        ],
      },
    ];

    return tableValues;
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.guarantorsList.pageTitle')}</span>
        </Title>
      </TitleHeader>
      <div className="tableContainer">
        <TableList tableValues={setTableValues()} noPagination />
      </div>
    </PageContainer>
  );
};
