import { SVGProps } from 'react';
import theme from '../themes/default';

export const EditSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3497_14510)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.56622 3.23223C2.03506 2.76339 2.67094 2.5 3.33398 2.5H9.16732C9.62755 2.5 10.0007 2.8731 10.0007 3.33333C10.0007 3.79357 9.62755 4.16667 9.16732 4.16667H3.33398C3.11297 4.16667 2.90101 4.25446 2.74473 4.41074C2.58845 4.56702 2.50065 4.77899 2.50065 5V16.6667C2.50065 16.8877 2.58845 17.0996 2.74473 17.2559C2.90101 17.4122 3.11297 17.5 3.33398 17.5H15.0007C15.2217 17.5 15.4336 17.4122 15.5899 17.2559C15.7462 17.0996 15.834 16.8877 15.834 16.6667V10.8333C15.834 10.3731 16.2071 10 16.6673 10C17.1276 10 17.5007 10.3731 17.5007 10.8333V16.6667C17.5007 17.3297 17.2373 17.9656 16.7684 18.4344C16.2996 18.9033 15.6637 19.1667 15.0007 19.1667H3.33398C2.67094 19.1667 2.03506 18.9033 1.56622 18.4344C1.09738 17.9656 0.833984 17.3297 0.833984 16.6667V5C0.833984 4.33696 1.09738 3.70107 1.56622 3.23223Z"
        fill={theme.color.primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6673 2.39884C16.4195 2.39884 16.1818 2.49729 16.0066 2.67253L8.25314 10.426L7.81264 12.188L9.57463 11.7475L17.3281 3.99402C17.5033 3.81878 17.6018 3.5811 17.6018 3.33328C17.6018 3.08545 17.5033 2.84777 17.3281 2.67253C17.1528 2.49729 16.9152 2.39884 16.6673 2.39884ZM14.8281 1.49402C15.3159 1.00622 15.9775 0.732178 16.6673 0.732178C17.3572 0.732178 18.0188 1.00622 18.5066 1.49402C18.9944 1.98182 19.2684 2.64342 19.2684 3.33328C19.2684 4.02313 18.9944 4.68473 18.5066 5.17253L10.5899 13.0892C10.4831 13.196 10.3493 13.2718 10.2028 13.3084L6.86945 14.1417C6.58547 14.2127 6.28506 14.1295 6.07808 13.9225C5.8711 13.7156 5.78789 13.4151 5.85888 13.1312L6.69222 9.79783C6.72885 9.6513 6.80461 9.51749 6.91141 9.41069L14.8281 1.49402Z"
        fill={theme.color.primaryColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_3497_14510">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
