import { AxiosResponse } from 'axios';
import { Authentication, Authorities } from 'model/authentication';
import { IRecoveryPassword } from 'model/recovery-password';
import { User } from 'model/user';
import { UserParameters } from 'model/user-parameters';
import { api } from './api';

export const authenticate = () => {
  const login = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate/client', authentication);
  };

  const adminLogin = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate', authentication);
  };

  const getSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/account');
  };

  const getAccountParameters = (): Promise<AxiosResponse<UserParameters>> => {
    return api.get<UserParameters>('/account/parameters');
  };

  const createUser = (user: User): Promise<AxiosResponse<User>> => {
    return api.post<User>('/users/register', user);
  };

  const activateAccountToken = (activationKey: string): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/activate/${activationKey}`);
  };

  const requestRecoveryPassword = (email: IRecoveryPassword): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/reset-password/init`, email);
  };

  const finishPasswordReset = (key: string, newPassword: string): Promise<AxiosResponse<Authorities>> => {
    return api.post<Authorities>('/account/reset-password/finish', { key, newPassword });
  };

  const checkIfEmailExists = (email: string, checkIfIsAvailable: boolean): Promise<AxiosResponse<void>> => {
    return api.get<void>(`users/check-email/${email}/${checkIfIsAvailable}`);
  };

  const checkIfResetKeyIsValid = (key: string): Promise<AxiosResponse<number>> => {
    return api.get<number>(`users/check-reset-key/${key}`);
  };

  const checkIfActivationKeyIsValid = (key: string): Promise<AxiosResponse<string>> => {
    return api.get<string>(`users/check-activation-key/${key}`);
  };

  const updateAdminProfile = (profile: User): Promise<AxiosResponse<User>> => {
    return api.put<User>('/admin/users/edit-profiles', profile);
  };

  return {
    login,
    adminLogin,
    getSession,
    createUser,
    checkIfEmailExists,
    finishPasswordReset,
    requestRecoveryPassword,
    activateAccountToken,
    checkIfResetKeyIsValid,
    checkIfActivationKeyIsValid,
    getAccountParameters,
    updateAdminProfile,
  };
};

export default authenticate();
