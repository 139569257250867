import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  font-size: 24px;
  font-weight: 700;
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;
