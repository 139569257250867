export enum FinancingStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  VALIDATION = 'VALIDATION',
  ADJUST = 'ADJUST',
  ADJUSTED = 'ADJUSTED',
  APPROVED = 'APPROVED',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SIGN = 'PENDING_SIGN',
}
