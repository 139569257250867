import Header from 'components/general-components/header';
import { RegistrationNotActivatedSvg } from 'images/registration-not-activated';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import AuthUtils from 'shared/util/auth-utils';
import { CommentContainer, StyledContainer, StyledInnerContainer, StyledMainTitle, StyledSvgContainer, StyledText } from './styles';

const NotActivateYourRegistration = ({ t }: WithTranslation) => {
  const [comments, setComments] = useState<string>();
  const history = useHistory();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  // tslint:disable-next-line:no-shadowed-variable
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);

  useEffect(() => {
    if (isAuthenticated && account?.id != null && accountParameters != null) {
      AuthUtils.redirectToPath(account?.client?.registrationStatus ?? '', history, accountParameters);
    }
  }, [account, loginStatus, accountParameters]);

  useEffect(() => {
    clientService.getClientData().then(data => {
      setComments(data.internalComment ?? '');
    });
  }, []);

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledContainer>
        <StyledInnerContainer>
          <StyledMainTitle>{t('registrationAnalysis.notActivateRegistration')}</StyledMainTitle>
          <StyledSvgContainer>
            <RegistrationNotActivatedSvg />
          </StyledSvgContainer>
          <StyledText>{t('registrationAnalysis.registrationNotApproved')}</StyledText>
          {comments && (
            <CommentContainer>
              <span>{comments}</span>
            </CommentContainer>
          )}
        </StyledInnerContainer>
      </StyledContainer>
    </>
  );
};

export default withTranslation()(NotActivateYourRegistration);
