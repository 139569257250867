import { Logo } from 'images/custom-organization';
import { useTranslation } from 'react-i18next';
import { Container, LogoContainer } from './styles';
import { LogoBox } from 'components/general-components/header/styles';

interface IStyledDesktopHeaderProps {}

const StyledDesktopHeader: React.FC<IStyledDesktopHeaderProps> = props => {
  const { t } = useTranslation();
  return (
    <Container>
      <LogoContainer>
        <LogoBox>
          <Logo isWhite />
        </LogoBox>
      </LogoContainer>
    </Container>
  );
};

export default StyledDesktopHeader;
