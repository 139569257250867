import styled from 'styled-components/macro';

export const FilterDateContainer = styled.div`
  width: 380px;
  height: 647px;
  background-color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 16px;

  z-index: 1;
  @media (max-width: 575.98px) {
    overflow-y: auto;
    width: 80%;
  }
`;

export const ModalContainer = styled.div`
  ${(props: { expanded: boolean }) => null}
  display: flex;
  pointer-events: ${(props: { expanded: boolean }) => (props.expanded ? '' : 'none')};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: ${(props: { expanded: boolean }) => (props.expanded ? '1' : '0')};
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: 500ms;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const ButtonFilter = styled.button`
  font-size: 14px;
  display: flex;
  width: 96px;
  height: 19px;
  padding: 6px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f2f8ff;
  border: none;

  :hover {
    background: #8089ab;
    color: #fff;
    width: 96px;
    height: 32px;
  }
`;

export const FilterDateHeader = styled.div`
  width: 303px;
  height: 48px;
  display: flex;
  padding: 8px 7px 8px 8px;
  justify-content: center;
  align-items: center;
  background-color: #f2f8ff;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const TitleContainer = styled.div`
  width: 303px;
  display: flex;
  margin-bottom: 10px;

  span {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const FilterDateFooter = styled.div`
  width: 303px;
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 39px;

  button {
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
`;

export const Separator = styled.div`
  width: 367px;
  height: 1px;
  background: #dde1e6;
  margin-bottom: 16px;
`;

export const ClosingArea = styled.div`
  width: 380px;
  height: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;

  section {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 20px;
    height: 20px;

    &:hover {
      border-radius: 50%;
      background-color: var(--Cool-gray-20, #dde1e6);
    }
  }
`;
