import styled from 'styled-components/macro';

interface ThinRequirementItemProps {
  isValid?: boolean;
}

export const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RequirementTitle = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7f7f7f;
`;

export const RequirementItem = styled.li<ThinRequirementItemProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${props => (props.isValid ? '#0DB052' : '#7f7f7f')};
`;
