import crefizApi from 'api/crefiz-api';
import { AxiosResponse } from 'axios';
import { CrefizCreditEngine, CrefizCreditEngineId } from 'model/crefiz-credit-engine';
import HttpStatus from 'model/enums/http-status';
import { VaduCreditEngine } from 'model/vadu-credit-engine';

export const crefizService = () => {
  const createAnalysis = async (financingId: number): Promise<CrefizCreditEngineId> => {
    try {
      const result: AxiosResponse<CrefizCreditEngineId> = await crefizApi.createAnalysis(financingId);
      if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getAnalysisInfo = async (clientId: number): Promise<VaduCreditEngine> => {
    try {
      const result: AxiosResponse<CrefizCreditEngine> = await crefizApi.getAnalysisInfo(clientId);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    createAnalysis,
    getAnalysisInfo,
  };
};

export default crefizService();
