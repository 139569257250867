import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unMaskedCurrency } from 'shared/util/register-utils';
import * as yup from 'yup';

export const useFormIncomeEvolve = () => {
  const { onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const { occupations, getOccupations } = useSelectLists();
  const { initialClientData } = useRegisterForm();

  const schema = yup.object({
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
  });

  useEffect(() => {
    getOccupations({ size: 7 });
  }, []);

  const handleSubmit = res => {
    const newIncome = unMaskedCurrency(res.income) / 100;
    const occupation = occupations.find(occ => occ.name === res.occupation);

    const newPhysicalPerson = {
      physicalPerson: {
        ...initialClientData?.physicalPerson,
        income: newIncome,
        occupation,
      },
    };
    onClickNext(newPhysicalPerson as Client);
  };

  return {
    schema,
    handleSubmit,
  };
};
