import StyledDesktopFormContainer from 'components/styled-desktop-form-container';
import StyledDesktopHeader from 'components/styled-desktop-header';
import StyledDesktopSideMenu from 'components/styled-desktop-side-menu';
import { Container, StyledScreenContainer } from '../style';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  return (
    <StyledScreenContainer>
      <StyledDesktopHeader />
      <Container>
        <StyledDesktopSideMenu />
        <StyledDesktopFormContainer />
      </Container>
    </StyledScreenContainer>
  );
};

export default Desktop;
