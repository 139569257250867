import { StyledContainer } from './styles';
import { Logo } from 'images/custom-organization';

const MobileHeader = () => {
  return (
    <StyledContainer>
      <Logo isWhite={true} />
    </StyledContainer>
  );
};

export default MobileHeader;
