import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerDocument from 'components/container-document';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import EnterCustomButton from 'components/enter-custom-button';
import { Button } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { Partner } from 'model/dashboard';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, DocumentContainer, IdentityContainer, Title, ButtonsContainer } from './styles';

interface IResumeScreenProps {
  attachmentResume?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  onClickForward: (partner: Partner) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { initialPartnerData, setInitialPartnerData, setInitialClientData, onClickNext, files, isLastForm, isLoading } = useRegisterForm();
  const schema = yup.object({
    attachmentIdentification: yup.mixed(),
    attachmentProofIncome: yup.mixed(),
    attachmentProofResidence: yup.mixed(),
  });
  const [requiredError, setRequiredError] = useState<string[]>([]);

  const handleSubmit = () => {
    if (
      !props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD) ||
      !props.handleVerifyEmptyFields(AttachmentType.PROOF_INCOME) ||
      !props.handleVerifyEmptyFields(AttachmentType.PROOF_RESIDENCE)
    ) {
      const newPartner = {
        ...initialPartnerData,
        attachments: files,
      } as Partner;
      setInitialPartnerData(newPartner);
      props.onClickForward(newPartner);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD)) {
      setRequiredError(old => [...old, AttachmentType.IDENTIFICATION_CARD]);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.PROOF_INCOME)) {
      setRequiredError(old => [...old, AttachmentType.PROOF_INCOME]);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.PROOF_RESIDENCE)) {
      setRequiredError(old => [...old, AttachmentType.PROOF_RESIDENCE]);
    }
  };

  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => handleSubmit()}>
      <Container>
        <DocumentContainer>
          <Title>{t('documents.identity.title')}</Title>
          <IdentityContainer>
            <ContainerDocument
              requiredError={requiredError.includes(AttachmentType.IDENTIFICATION_CARD)}
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
            <ContainerUploadedDocument editDocument attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          </IdentityContainer>
        </DocumentContainer>
        <>
          <DocumentContainer>
            <Title>{t('documents.proofs.incomeProof.title')}</Title>
            <IdentityContainer>
              <ContainerDocument
                requiredError={requiredError.includes(AttachmentType.PROOF_INCOME)}
                titleDocument="documents.identification"
                editDocument
                nameInput="doc2"
                name="attachmentIncome"
                type={AttachmentType.PROOF_INCOME}
                title={files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
                docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
              />
              <ContainerUploadedDocument editDocument attachmentType={AttachmentType.PROOF_INCOME} />
            </IdentityContainer>
          </DocumentContainer>
          <DocumentContainer>
            <Title>{t('documents.proofs.residenceProof.title')}</Title>
            <IdentityContainer>
              <ContainerDocument
                requiredError={requiredError.includes(AttachmentType.PROOF_RESIDENCE)}
                titleDocument="documents.identification"
                editDocument
                nameInput="doc3"
                name="attachmentResidence"
                type={AttachmentType.PROOF_RESIDENCE}
                title={files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
                docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
              />
              <ContainerUploadedDocument editDocument attachmentType={AttachmentType.PROOF_RESIDENCE} />
            </IdentityContainer>
          </DocumentContainer>
        </>
      </Container>
      <ButtonsContainer>
        {props.actualStep !== 0 ? (
          <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </ReactHookFormProvider>
  );
};
