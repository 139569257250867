import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import registerApi from 'api/register-api';
import { Authentication } from 'model/authentication';
import { registerError, registerRequest, registerSuccess } from './actions';
import { RegisterActionTypes } from './types';

function* handleRegister(action: ReturnType<typeof registerRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(registerApi.registerFree, action.payload);

    if (result.status !== 201) {
      const errorMessage: string = result.status === 401 ? 'Cannot register this user' : 'An unknown error occured.';
      yield put(registerError(errorMessage));
      return;
    } else {
      yield put(registerSuccess());
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(registerError(err.stack));
    } else {
      yield put(registerError('An unknown error occured.'));
    }
  }
}

function* watchRegisterRequest() {
  yield takeEvery(RegisterActionTypes.REGISTER_REQUEST, handleRegister);
}

function* registerSaga() {
  yield all([fork(watchRegisterRequest)]);
}

export default registerSaga;
