import { HttpRequestStatus } from 'model/enums/http-request-status';
import { EvolveGuarantee, EvolveGuaranteeToSimple } from 'model/evolve-guarantee';

export type ApiResponse = Record<string, any>;

export enum UpdateEvolveGuaranteeTypes {
  UPDATE_EVOLVE_GUARANTEE_REQUEST = '@@evolve-guarantee-update/UPDATE_EVOLVE_GUARANTEE_REQUEST',
  UPDATE_EVOLVE_GUARANTEE_SUCCESS = '@@evolve-guarantee-update/UPDATE_EVOLVE_GUARANTEE_SUCCESS',
  UPDATE_EVOLVE_GUARANTEE_ERROR = '@@evolve-guarantee-update/UPDATE_EVOLVE_GUARANTEE_ERROR',
  UPDATE_EVOLVE_GUARANTEE_RESET = '@@evolve-guarantee-update/UPDATE_EVOLVE_GUARANTEE_RESET',
}

export interface UpdateEvolveGuaranteeState {
  readonly guarantee?: EvolveGuaranteeToSimple;
  readonly status: HttpRequestStatus;
  readonly error?: string;
}
