import { ORGANIZATION_NAME, ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext, { Filters } from 'context/admin-filters';
import _ from 'lodash';
import { ContractDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { ContractStatus } from 'model/enums/contract-status';
import { OrganizationName } from 'model/enums/organization-name';
import { OrganizationType } from 'model/enums/organization-type';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { getStorageFilter } from 'shared/util/filter-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import TableListFilters from '../../components/table-list-filters';

interface GetContractProps {
  name?: string;
  size?: number;
  page?: number;
  contractStatus?: string[];
  createdDates?: CreatedDates;
  filters?: Filters;

  subsidiaryName?: string;
}

interface StatusTypeFilter {
  label: string;
  value: string;
}

export const ContractsList = () => {
  const pageSizeDefault = 8;
  const [contracts, setContracts] = useState<ContractDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState('');
  const [containsNextPage, setContainsNextPage] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [filterContractByClientName, setFilterContractByClientName] = useState<ContractDashboard[]>([]);
  const [isFilterName, setIsFilterName] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [endDate, setEndDate] = useState('');
  const [contractStatus, setContractStatus] = useState('');
  const { filters } = useContext(AdminFiltersContext);
  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;

  const statusValues: StatusTypeFilter[] = [
    { label: t(`enum.contractStatus.${ContractStatus.NEW}`), value: ContractStatus.NEW },
    { label: t(`enum.contractStatus.${ContractStatus.IN_PROGRESS}`), value: ContractStatus.IN_PROGRESS },
    { label: t(`enum.contractStatus.${ContractStatus.FINISHED}`), value: ContractStatus.FINISHED },
    { label: t(`enum.contractStatus.${ContractStatus.CANCELLED}`), value: ContractStatus.CANCELLED },
  ];

  useEffect(() => {
    verifyStorageAndGetContract();
  }, [viewMore, contractStatus, searchName, page, orderBy, searchSubsidiaryName, filters, sortColumn, pageSize, initialDate]);

  useEffect(() => {
    if (!isFilterName) {
      setFilterContractByClientName([]);
    }
  }, [isFilterName]);

  const verifyStorageAndGetContract = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    if (isHcred) {
      getDashboardContracts({
        name: searchName,
        page,
        contractStatus: filters.statusFilters,
        createdDates: filters.dateRange,
        subsidiaryName: searchSubsidiaryName,
      });
    } else {
      getDashboardContracts({ name: searchName, page, contractStatus: defaultFilters, createdDates, subsidiaryName: searchSubsidiaryName });
    }
  };

  const getDashboardContracts = async (props?: GetContractProps) => {
    if (!viewMore) setLoading(true);
    const res = await DashboardService().getContractsList(
      props?.name,
      pageSize,
      props?.page,
      contractStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName,
      sortColumn,
      initialDate,
      endDate
    );

    if (res && !viewMore) setLoading(false);
    isFilterName ? setFilterContractByClientName(res.content) : setContracts(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
    setContainsNextPage(res.size < res.totalElements);
  };

  const resetFilters = () => {
    setSearchName('');
    setContractStatus('');
    setInitialDate('');
    setEndDate('');
    setIsFilterName(false);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? contracts.map(contract => contract.financing.client.physicalPerson?.name ?? '')
          : contracts.map(contract => contract.financing.client.legalPerson?.corporateName ?? ''),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
        paddingLeft: '26px',
        sortColumn: 'financing.client.physicalPerson.name',
      },
      {
        header: t('admin.dashboard.tableHeaders.contract'),
        values: contracts.map(contract => contract.attachment?.fileName ?? ''),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
        sortColumn: 'attachment.fileName',
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: contracts.map(contract => (
          <Status key={contract.id} text={t(`enum.contractStatus.${contract.status}`)} color={contract.status} />
        )),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
        sortColumn: 'status',
        showMore: 'Ver mais',
      },
    ];

    return tableValues;
  };

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const handlePagination = () => {
    setPageSize(pageSize + pageSizeDefault);
  };

  const handleSearchName = () => {
    return isPhysicalPerson
      ? filterContractByClientName.map(contract => contract.financing.client.physicalPerson?.name ?? '')
      : filterContractByClientName.map(contract => contract.financing.client.legalPerson?.corporateName ?? '');
  };

  const searchClientName = _.debounce(() => {
    setIsFilterName(false);
    verifyStorageAndGetContract();
  }, 500);

  return (
    <>
      <TableListFilters
        values={handleSearchName()}
        onClickFilterDate={verifyStorageAndGetContract}
        setEndDate={setEndDate}
        setInitialDate={setInitialDate}
        searchName={searchName}
        setSearchName={setSearchName}
        setIsFilterName={setIsFilterName}
        onClick={searchClientName}
        setFilterStatus={setContractStatus}
        initialDate={initialDate}
        endDate={endDate}
        filterStatus={contractStatus}
        resetFilters={resetFilters}
        statusOptions={statusValues}
      />

      <TableList
        tableValues={setTableValues()}
        page={page}
        totalPages={totalPages}
        setNewPage={setNewPage}
        setSortColumn={setSortColumn}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        containsNextPage={containsNextPage}
        handlePagination={handlePagination}
        loading={loading}
        setViewMore={setViewMore}
        viewMore={viewMore}
      />
    </>
  );
};
