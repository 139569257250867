import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer, FormInputsContainer, Column } from 'components/register-forms-mobile/styles';
import InputText from 'components/styled-input';
import { format, parse } from 'date-fns';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPatrimonialSituation = () => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();

  return (
    <FormInputsContainer>
      <Column>
        <InputText
          isRequired
          name="baseDate"
          label={t('register.inputs.baseDate')}
          placeholder={t('register.placeholder.baseDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.legalPerson?.baseDate
              ? format(parse(initialClientData?.legalPerson?.baseDate, 'yyyy-MM-dd', new Date()), 'MM/yyyy')
              : ''
          }
        />
        <InputText
          name="netWorth"
          label={t('register.inputs.netWorth')}
          placeholder={t('register.placeholder.netWorth')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.legalPerson?.netWorth ? initialClientData?.legalPerson?.netWorth.toFixed(2) : ''}
          maxLength={14}
        />
        <InputText
          name="revenue"
          label={t('register.inputs.revenue')}
          placeholder={t('register.placeholder.revenue')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.legalPerson?.revenue ? initialClientData?.legalPerson?.revenue.toFixed(2) : ''}
          maxLength={14}
        />
      </Column>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
