import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { FormContainer } from '../styles';
import { ContainerInputsAddress } from './inputs-address';
import { useFormAddressEvolve } from './hoooks';

export const FormAddressEvolve = () => {
  const { schema, handleSubmit } = useFormAddressEvolve();

  return (
    <FormContainer>
      <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
        <ContainerInputsAddress />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
