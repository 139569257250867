import { SVGProps } from 'react';
import theme from '../themes/default';

export const UploadingSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="221" height="252" viewBox="0 0 221 252" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0042 228.138L32.8792 234.977L53.8795 208.123L63.3299 216.032L113.205 150.45L157.306 228.138L169.381 214.795L191.432 251H6.62891L25.0042 228.138Z"
      fill="white"
    />
    <path d="M198.056 251H0.00585938" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path
      d="M50.4429 114.455C50.4429 114.455 -28.6787 214.896 112.514 211.297L118.114 216.096V182.394L69.7362 171.699L75.4172 109.702"
      fill={theme.color.primaryColor}
    />
    <path
      d="M71.4284 153.229C56.8206 153.995 45.123 173.477 45.123 197.4C45.123 197.925 45.1307 198.446 45.1418 198.966C55.9124 205.894 72.7605 210.562 98.1484 211.29C99.0687 206.718 99.5283 202.064 99.5203 197.4C99.5509 190.711 98.5863 184.055 96.658 177.65L69.7359 171.698L71.4284 153.229Z"
      fill="#4C4D4D"
    />
    <path
      d="M57.5599 116.205C57.5599 116.205 52.1767 204.067 67.381 245.667L64.2051 250.334H91.2477C91.2477 250.334 94.202 138.577 94.202 114.456L57.5599 116.205Z"
      fill={theme.color.primaryColor}
    />
    <path d="M78.1063 48.2233V59.642L63.9609 63.1186V43.849L78.1063 40.3723V48.2233Z" fill="#EEEEEE" />
    <path
      d="M70.1145 52.1744C70.3234 52.1744 70.4928 52.0051 70.4928 51.7962C70.4928 51.5873 70.3234 51.418 70.1145 51.418C69.9057 51.418 69.7363 51.5873 69.7363 51.7962C69.7363 52.0051 69.9057 52.1744 70.1145 52.1744Z"
      fill="#4C4D4D"
    />
    <path
      d="M75.9251 52.1744C76.134 52.1744 76.3033 52.0051 76.3033 51.7962C76.3033 51.5873 76.134 51.418 75.9251 51.418C75.7162 51.418 75.5469 51.5873 75.5469 51.7962C75.5469 52.0051 75.7162 52.1744 75.9251 52.1744Z"
      fill="#4C4D4D"
    />
    <path d="M74.2207 54.0525V48.2239H77.1407" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path d="M69.4023 48.2239H72.3223" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path
      d="M64.891 51.2103V53.2344C64.3667 53.2344 63.8639 53.0261 63.4931 52.6554C63.1224 52.2846 62.9141 51.7818 62.9141 51.2575C62.9141 51.1968 62.9168 51.1368 62.9221 51.0775C63.0329 49.8499 64.891 49.9777 64.891 51.2103Z"
      fill="#EEEEEE"
    />
    <path
      d="M64.891 53.2333C64.3667 53.2333 63.8639 53.025 63.4931 52.6543C63.1224 52.2835 62.9141 51.7807 62.9141 51.2563"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path
      d="M72.9423 55.4892C72.6795 55.4892 72.4194 55.4374 72.1766 55.3369C71.9338 55.2363 71.7133 55.0889 71.5275 54.9031C71.3417 54.7173 71.1943 54.4968 71.0937 54.254C70.9932 54.0112 70.9414 53.751 70.9414 53.4883"
      stroke={theme.color.primaryColor}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M78.1063 59.6418L63.9609 63.1185" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path d="M74.2205 54.0571L72.9434 54.371" stroke="#4C4D4D" stroke-miterlimit="10" stroke-linecap="round" />
    <path
      d="M50.4434 83.566V114.455L94.2014 125.21V90.8081C94.2014 84.8807 92.2139 79.1244 88.5568 74.4596C84.8997 69.7948 79.7842 66.4909 74.028 65.0762H74.028C71.2243 64.3871 68.3007 64.3446 65.4782 64.9519C62.6557 65.5593 60.0083 66.8005 57.7362 68.5818C55.4641 70.3631 53.6268 72.6377 52.3634 75.2337C51.0999 77.8296 50.4434 80.6789 50.4434 83.566L50.4434 83.566Z"
      fill="#EEEEEE"
    />
    <path d="M57.5599 116.204C57.5599 116.204 52.1767 204.067 67.381 245.667" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path
      d="M63.4887 50.2902C63.4887 50.2902 61.5873 43.9915 64.5873 41.3915C67.5873 38.7915 80.8539 39.4582 80.8539 39.4582C80.8539 39.4582 81.7873 48.6582 68.1206 46.4582C68.1206 46.4582 67.922 49.0076 65.654 49.4582"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path
      d="M82.3455 68.7212C82.3455 68.7212 102.299 81.527 98.5654 116.204C94.8321 150.88 43.8099 106.969 42.3877 103.769C40.9655 100.569 46.2988 97.9023 52.8766 101.636C52.8766 101.636 50.2099 99.3968 51.4544 99.094C52.6988 98.7912 55.237 99.6801 56.6346 102.525L87.5432 108.213"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path d="M51.3324 99.7246H20.5208L7.00977 68.4883" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path d="M8.90039 71.8588L56.6337 45.9922" stroke={theme.color.primaryColor} stroke-linecap="round" stroke-linejoin="round" />
    <path d="M56.3284 69.802C56.3284 69.802 45.3915 80.1241 42.1562 99.7241" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path d="M26.9199 99.7251C26.9199 99.7251 34.7494 121.903 43.8347 124.08" stroke="#4C4D4D" stroke-miterlimit="10" />
    <path
      d="M39.1874 99.7241C39.1874 99.7241 35.3256 94.3464 32.7676 93.1019C30.2096 91.8575 22.4763 94.7019 23.4541 96.0353C24.4319 97.3686 26.2096 96.6575 26.2096 96.6575C26.2096 96.6575 25.7652 97.9908 26.5652 98.3464"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path
      d="M197.089 26.9266C197.091 26.8243 197.093 26.7218 197.093 26.619C197.092 24.2086 196.477 21.8381 195.305 19.7316C194.133 17.6251 192.444 15.8523 190.396 14.5805C188.348 13.3088 186.01 12.5802 183.603 12.4637C181.195 12.3471 178.797 12.8464 176.636 13.9144C173.177 8.87224 168.248 5.01901 162.52 2.87959C156.792 0.740177 150.543 0.418585 144.626 1.95862C138.708 3.49866 133.409 6.82546 129.451 11.4858C125.492 16.1462 123.067 21.9136 122.505 28.0023C117.133 29.7976 112.463 33.2362 109.153 37.8318C105.844 42.4273 104.063 47.9471 104.062 53.6103V53.6104C104.063 60.769 106.906 67.6343 111.968 72.6962C117.03 77.7581 123.895 80.6018 131.054 80.6018H193.002C200.161 80.6018 207.026 77.7581 212.088 72.6962C217.15 67.6343 219.994 60.769 219.994 53.6104V53.6103C219.993 47.1597 217.683 40.9225 213.482 36.0278C209.28 31.1332 203.465 27.9046 197.089 26.9266Z"
      fill="#EEEEEE"
    />
    <path
      d="M176.635 13.9144C173.906 9.93044 170.246 6.67214 165.973 4.42179C161.7 2.17145 156.943 0.996929 152.113 1.00001"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path
      d="M197.092 26.6185C197.092 24.7574 196.725 22.9145 196.013 21.1951C195.301 19.4757 194.257 17.9134 192.941 16.5974C191.625 15.2814 190.063 14.2375 188.343 13.5253C186.624 12.8131 184.781 12.4465 182.92 12.4465"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path
      d="M131.053 80.6008H193.001C200.16 80.6008 207.025 77.757 212.087 72.6952C217.149 67.6333 219.992 60.7679 219.992 53.6094"
      stroke="#4C4D4D"
      stroke-miterlimit="10"
    />
    <path d="M147.684 111.603H176.373V82.9135H147.684V111.603Z" fill="#4C4D4D" />
    <path d="M184.238 85.0751L162.027 62.8645L139.816 85.0751H184.238Z" fill="#4C4D4D" />
    <path d="M147.684 168.015H176.373V139.326H147.684V168.015Z" fill="#CCCCCC" />
    <path d="M184.238 141.489L162.027 119.279L139.816 141.489H184.238Z" fill="#CCCCCC" />
    <path d="M147.868 224.537L176.557 224.32L176.34 195.632L147.651 195.848L147.868 224.537Z" fill="#CCCCCC" />
    <path d="M184.221 197.735L161.843 175.693L139.801 198.071L184.221 197.735Z" fill="#CCCCCC" />
  </svg>
);
