import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import * as S from './styles';
import { FilterItem } from '../custom-filter-calendar/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIconSvg } from 'images/chevron-down.svg';
import { ReactComponent as EllipseIconSvg } from 'images/red-ellipse.svg';
import SearchInput from 'features/admin/dashboard/components/search-input';
import { CheckBox } from '../checkbox';

interface FilterButtonProps extends WithTranslation {
  filterData: Object[];
  placeholder: string;
  icon: JSX.Element;
  keyDisplay: string;
  isActive?: boolean;
  onlySelectOneElement?: boolean;
  listSelectedItems: (value: any[] | null) => void;
  onCleanFilter: () => void;
  setSearch?: (value: string) => void;
}

const CustomSelectionFilter = ({
  filterData,
  placeholder,
  icon,
  keyDisplay,
  isActive,
  onlySelectOneElement,
  listSelectedItems,
  onCleanFilter,
  setSearch,
  t,
}: FilterButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<Object[]>([]);

  const ref: MutableRefObject<PopupActions | null> = useRef(null);
  const closeTooltip = () => ref.current?.close();

  useEffect(() => {
    if (!isActive) {
      setItems([]);
    }
  }, [isActive]);

  const closeModal = () => setIsOpen(false);

  const handleSelectionData = (data: Object) => {
    const isItemAlreadyList = items.map(value => value[keyDisplay]).includes(data[keyDisplay]);

    if (isItemAlreadyList) {
      removeItemToList(data);
    } else {
      addItemToList(data);
    }
  };

  const removeItemToList = (data: Object) => {
    setItems(items.filter(item => item[keyDisplay] !== data[keyDisplay]));
  };

  const addItemToList = (data: Object) => {
    if (onlySelectOneElement) {
      setItems([data]);
    } else {
      setItems([...items, data]);
    }
  };

  return (
    <Popup
      ref={ref}
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      trigger={
        <FilterItem onClick={() => setIsOpen(!isOpen)}>
          <S.PaintSvg>{icon}</S.PaintSvg>
          <span>{placeholder}</span>
          {isActive && <EllipseIconSvg />}
          <ChevronDownIconSvg style={{ marginBottom: '-2px' }} />
        </FilterItem>
      }
      position="bottom center"
      on="click"
    >
      <S.DropDownFilter>
        <S.FilterBox>
          <S.SectionTitle className={setSearch == null ? 'customStyle' : ''}>{t('admin.dashboard.selectOne')}</S.SectionTitle>
          {setSearch != null && (
            <S.SearchContainer>
              <SearchInput title={t('admin.dashboard.search')} setSearch={setSearch} />
            </S.SearchContainer>
          )}

          {filterData.map((data, index) => {
            return (
              <CheckBox
                key={`${placeholder} ${index}`}
                text={data[keyDisplay]}
                checked={items.map(item => item[keyDisplay]).includes(data[keyDisplay])}
                handleClick={() => handleSelectionData(data)}
              />
            );
          })}
        </S.FilterBox>
        <S.ButtonsContainer>
          <button
            className="clean"
            onClick={() => {
              setItems([]);
              listSelectedItems(null);
              onCleanFilter();
              closeModal();
              closeTooltip();
            }}
          >
            {t('global.button.clean')}
          </button>
          <button
            className="apply"
            onClick={() => {
              closeModal();
              closeTooltip();
              listSelectedItems(items.length > 0 ? items : null);
            }}
          >
            {t('global.button.apply')}
          </button>
        </S.ButtonsContainer>
      </S.DropDownFilter>
    </Popup>
  );
};

export default withTranslation()(CustomSelectionFilter);
