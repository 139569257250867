import { withTranslation, WithTranslation } from 'react-i18next';
import StyledLoadingContainer from './styles';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';

const LoadingDocument = ({ t }: WithTranslation) => {
  const { color } = useTheme();

  return (
    <StyledLoadingContainer>
      <span>{t('global.fields.loadingDocument')}</span>
      <ReactLoading type="spinningBubbles" color={color.primaryColor} width="48px" height="48px" className="spinner" />
    </StyledLoadingContainer>
  );
};

export default withTranslation()(LoadingDocument);
