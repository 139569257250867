import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { genderType, maritalStatus, nationalityType } from 'shared/util/select-utils';
import { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer, ImageContainer } from '../styles';
import { ContainerInputsPersonalDataMulttiplo } from './inputs-personal-data';

export const FormPersonalDataMulttiplo = () => {
  const { validateMajority, onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();
  const history = useHistory();
  const generateMinDate = () => {
    const actualDate = new Date();
    actualDate.setFullYear(actualDate.getFullYear() - 18);
    return actualDate;
  };

  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const clientBirth = format(res.birth, 'yyyy-MM-dd');

          const newRes = {
            phone: res.phone,
            email: res.email,
            physicalPerson: {
              ...initialClientData?.physicalPerson,
              name: res.name,
              cpf: initialClientData?.physicalPerson?.cpf ?? res.cpf,
              rg: res.rg,
              birth: clientBirth,
            },
          };
          if (validateMajority(res.birth)) {
            onClickNext(newRes as Client);
          }
        }}
      >
        <ContainerInputsPersonalDataMulttiplo />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
