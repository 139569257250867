import { EditFormProvider } from 'provider/edit-form';
import { SelectListsProvider } from 'provider/select-list';
import Desktop from './layout/desktop';

interface IRegistrationAdjustmentsProps {}

const RegistrationAdjustments: React.FC<IRegistrationAdjustmentsProps> = props => {
  return (
    <SelectListsProvider>
      <EditFormProvider>
        <Desktop />
      </EditFormProvider>
    </SelectListsProvider>
  );
};

export default RegistrationAdjustments;
