import { CustomCheckBox } from 'components/custom-checkbox';
import EnterCustomButton from 'components/enter-custom-button';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { AuthorizationProps } from '..';
import { Container, ContentContainer, DetailsContainer, ResumeContainer, Subtitle, Text, Title } from '../styles';

export const Desktop = ({ isChecked, handleCheck, onHandleClick, isLoading }: AuthorizationProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header variant={HeaderVariants.SECONDARY} />
      <ContentContainer>
        <Title>{t('authorization.title')}</Title>
        <Subtitle>{t('authorization.subTitle')}</Subtitle>
        <ResumeContainer>
          <Title>{t('authorization.resume.title')}</Title>
          <Text>
            Lorem ipsum dolor sit amet consectetur. Aliquet quam sed pellentesque turpis purus egestas eget dignissim elit. Dui nullam
            egestas posuere hac. Augue varius et in consectetur ac non. At nulla viverra turpis mauris mollis nisl vulputate. Vitae mattis
            ac eu molestie amet condimentum quis in. Sed nisl leo iaculis sit euismod. Semper commodo maecenas venenatis tortor a ultrices
            scelerisque quis egestas. Lectus lorem congue pulvinar pulvinar sed cursus quis egestas elit. Vel ornare porta enim vitae tellus
            amet ut sit convallis. Vel sit consectetur at ornare proin lorem viverra orci ac. Pellentesque in fermentum ultricies aliquet
            eu. Non cras eget felis nulla semper. Auctor gravida augue suspendisse lobortis et ultrices. Arcu est mauris ut facilisi. Eu
            maecenas semper tellus ultrices mauris sapien cras massa. Scelerisque ipsum elementum imperdiet amet aliquet mi tortor. Augue
            convallis fringilla enim bibendum turpis amet. Ac consequat amet molestie pharetra ut integer tellus. Aenean at rhoncus volutpat
            ut mattis mattis imperdiet elementum. A sollicitudin vitae cras auctor in quis in. Sed leo lacinia proin lorem dignissim
            fermentum lacinia. Id velit et malesuada sit. Ac amet in odio sed eu. Placerat libero eros arcu duis aenean cursus vitae
            dignissim.
          </Text>
          <DetailsContainer>
            <CustomCheckBox text={t('authorization.resume.acceptTerms')} value="accept" checked={isChecked} onHandleClick={handleCheck} />
          </DetailsContainer>
        </ResumeContainer>
        <EnterCustomButton onClick={onHandleClick} disabled={!isChecked} fullSize isLoading={isLoading} text={t('global.button.accept')} />
      </ContentContainer>
    </Container>
  );
};
