import { useSendDataToSimulator } from 'components/simulator-data/hooks/use-send-data-to-simulator';
import SimulatorContext, { SimulatorLimit } from 'context/simulator';
import { CreateFinancingResponse } from 'model/create-financing-response';
import { CreditEngineType, CreditEngineRequest } from 'model/credit-engine';
import { CreditEngineStatus } from 'model/enums/credit-engine';
import { FinancingSimpleSimulation } from 'model/financing';
import { SimulatorParameters } from 'model/landing-page';
import { useContext } from 'react';
import financingService from 'services/financing-service';
import landingPageService from 'services/landing-page-service';
import { useRedirect } from 'hooks/use-redirect/use-redirect';
import { ProcessingSimulatorData, ProcessingSimulatorHandler } from '..';

export const useAzureSendFinancingDataHandler = (): ProcessingSimulatorHandler => {
  const { setSimulatorLimits } = useContext(SimulatorContext);
  const { sendDataToSimulator } = useSendDataToSimulator();

  const verifyLimitsAndSendSimulation = async (
    currentFinancingResponse: CreateFinancingResponse,
    creditResult: CreditEngineType,
    simulatorData: FinancingSimpleSimulation,
    isNewCredit: boolean,
    isAdmin: boolean
  ) => {
    const organizationData = await landingPageService.getSimulatorParameters();

    const choosedValue = simulatorData.choosedValue ?? 0;

    const totalLimit = creditResult.totalLimit ?? 0;
    const monthLimit = creditResult.monthLimit ?? 0;

    const installmentMin = Number.isNaN(Math.floor(totalLimit / monthLimit)) ? 1 : Math.floor(totalLimit / monthLimit);

    const maxFoundValue = totalLimit > organizationData.maxValue ? organizationData.maxValue : totalLimit;
    const exceptionMaxValue = installmentMin > 15 ? monthLimit * 15 : undefined;

    const verifiedMaxValue = exceptionMaxValue ? exceptionMaxValue : maxFoundValue;
    const verifiedChoosedValue = choosedValue > verifiedMaxValue ? verifiedMaxValue : choosedValue;

    const installmentsTotal = simulatorData.installmentsTotal ?? 0;
    const verifiedInstallment = installmentsTotal > installmentMin ? simulatorData.installmentsTotal : installmentMin;

    const newSimulate: FinancingSimpleSimulation = {
      choosedValue: verifiedMaxValue,
      installmentsTotal: verifiedInstallment,
      client: { id: simulatorData.client?.id },
      id: currentFinancingResponse.financingId,
    };

    const simulatorLimits: SimulatorLimit = {
      newMaxValue: verifiedMaxValue,
      newMinInstallment: installmentMin,
      newMonthLimit: monthLimit,
    };

    setSimulatorLimits(simulatorLimits);

    const simulatorValuesChanged = choosedValue !== verifiedChoosedValue || installmentsTotal !== verifiedInstallment;

    if (simulatorValuesChanged) {
      return await sendDataToSimulator(newSimulate, isNewCredit, isAdmin);
    }
  };

  const execute = async (data: ProcessingSimulatorData): Promise<ProcessingSimulatorData> => {
    const { simulatorData } = data;
    const { isAdmin, isNewCredit } = data.parameters;

    if (!simulatorData) return Promise.reject('Simulator data is undefined');

    const simulatorSavedData: CreateFinancingResponse = await sendDataToSimulator(simulatorData, isNewCredit, isAdmin);

    const clientHasMontlhyLimit = await landingPageService.checkClientMonthlyLimit(simulatorData.client?.id);
    if (!clientHasMontlhyLimit) {
      data.redirect = {
        withoutLimit: true,
      };
      return Promise.resolve(data);
    }

    const sendCreditEngine: CreditEngineRequest = {
      clientId: simulatorData?.client?.id,
      financingId: simulatorData?.financingId ?? simulatorSavedData?.financingId,
      firstDueDate: simulatorSavedData?.simulate?.firstDueDate,
      lastDueDate: simulatorSavedData?.simulate?.lastDueDate,
    };

    const creditResult = await financingService.postCreditEngine(sendCreditEngine);
    const creditEngineStatus = creditResult?.status;

    switch (creditEngineStatus) {
      case CreditEngineStatus.APPROVED:
      case CreditEngineStatus.CONDITIONED:
        await verifyLimitsAndSendSimulation(simulatorSavedData, creditResult, simulatorData, isNewCredit, isAdmin);
        data.redirect = {
          confirmSimulation: true,
        };
        break;
      case CreditEngineStatus.REJECTED:
        data.redirect = {
          approved: false,
        };
        break;
    }
    return Promise.resolve(data);
  };

  return {
    execute,
  };
};
