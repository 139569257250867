import { InactiveAccountSVG } from 'images/inative-account';
import { useTranslation } from 'react-i18next';
import { BoxSvg, SimulatorContainer, SimulatorText, SimulatorTitle } from '../style';

interface OutOfZoneProps {}

const OutOfZone: React.FC<OutOfZoneProps> = () => {
  const { t } = useTranslation();
  return (
    <SimulatorContainer>
      <SimulatorTitle>{t('register.outOfZone.title')}</SimulatorTitle>
      <SimulatorText>{t('register.outOfZone.text')}</SimulatorText>
      <BoxSvg>
        <InactiveAccountSVG />
      </BoxSvg>
    </SimulatorContainer>
  );
};

export default OutOfZone;
