import { AxiosResponse } from 'axios';
import { CreatedDates } from 'model/date';
import { IMyContractsRequest, IMyPaymentsRequest } from 'model/simulator-result';
import { api } from './api';

export const simulatorResultApi = () => {
  const getContracts = (
    name?: string,
    size?: number,
    page?: number,
    contractStatus?: string[],
    createdDates?: CreatedDates
  ): Promise<AxiosResponse<IMyContractsRequest>> => {
    return api.get<IMyContractsRequest>(
      `/contracts?size=${size ?? 5}&page=${page ?? 0}&financing.identification=${name ?? ''}${
        contractStatus && contractStatus.length > 0 ? contractStatus.map(status => `&status=contains(${status})`).join('') : ''
      }&sort=createdDate,asc${createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''}${
        createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''
      }`
    );
  };
  const getInstallments = (
    name?: string,
    size?: number,
    page?: number,
    paymentStatus?: string[],
    createdDates?: CreatedDates
  ): Promise<AxiosResponse<IMyPaymentsRequest>> => {
    return api.get<IMyPaymentsRequest>(
      `/financing?size=${size ?? 5}&page=${page ?? 0}&identification=${name ?? ''}${
        paymentStatus && paymentStatus.length > 0 ? paymentStatus.map(status => `&payment.status=contains(${status})`).join('') : ''
      }&sort=createdDate,asc${createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''}${
        createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''
      }`
    );
  };

  return {
    getContracts,
    getInstallments,
  };
};

export default simulatorResultApi();
