import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 70%;
  margin-bottom: 24px;

  padding: 24px;
  border: 1px solid ${({ theme }) => theme.color.rejected};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.lightRed};

  & > button {
    background-color: ${({ theme }) => theme.color.rejected};
    border-color: ${({ theme }) => theme.color.rejected};
  }

  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
  }
`;
