import Header from 'components/general-components/header';
import LoadingJustProcessing from 'components/loading-just-processing';
import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import { HeaderVariants } from 'model/enums/header-variants';
import { OrganizationName } from 'model/enums/organization-name';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Timeout } from 'react-number-format/types/types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { ClientService } from 'services/client-service';
import AuthUtils from 'shared/util/auth-utils';
import { StyledContainer, StyledInnerContainer, StyledMainTitle, StyledText, SvgContainer } from './styles';
import { ORGANIZATION_NAME } from 'config/constants';

const WaitRegistrationValidation = ({ t }: WithTranslation) => {
  const history = useHistory();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);

  useEffect(() => {
    if (isAuthenticated && accountParameters != null) {
      const keepCheckingAccountStatus = setInterval(() => getBasicInfo(keepCheckingAccountStatus), 15000);
      getBasicInfo(keepCheckingAccountStatus);
    }
  }, [accountParameters]);

  const getBasicInfo = (keepCheckingAccountStatus: Timeout) => {
    if (account?.client?.physicalPerson?.cpf != null) {
      ClientService()
        .getBasicInfo(account?.client?.physicalPerson?.cpf)
        .then(resp => {
          if (isAuthenticated && !accountParameters) {
            AuthUtils.redirectToPath(resp.registrationStatus ?? '', history, accountParameters);
          }

          if (resp.registrationStatus !== RegistrationStatus.VALIDATION) {
            clearInterval(keepCheckingAccountStatus);
          }
          AuthUtils.redirectToPath(resp.registrationStatus ?? '', history, accountParameters);
        });
    }
  };

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledContainer>
        <StyledInnerContainer>
          <StyledMainTitle>{t('registrationAnalysis.waitRegistrationValidated')}</StyledMainTitle>
          <SvgContainer>
            <WaitRegistrationValidationSvg />
          </SvgContainer>
          {ORGANIZATION_NAME === OrganizationName.AZURE && (
            <LoadingJustProcessing hideProgressMargin text={t('registrationAnalysis.thisMayTakeMinutes')} />
          )}
          <StyledText>{t('registrationAnalysis.waitWhileValidatesRegistration')}</StyledText>
        </StyledInnerContainer>
      </StyledContainer>
    </>
  );
};

export default withTranslation()(WaitRegistrationValidation);
