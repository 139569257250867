import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1.5px solid ${({ theme }) => theme.color.lightGray2};
  border-radius: 8px;

  max-width: 700px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;

  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};

  > span.title {
    font-weight: 700;
    color: ${({ theme }) => theme.color.gray21};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > button {
    border: none;
    background-color: transparent;
  }

  button + button {
    margin-left: 8px;
  }
`;
