import styled from 'styled-components/macro';

interface Props {
  containerHeight?: string;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const Container = styled.div<Props>`
  height: ${({ containerHeight }) => (containerHeight ? `${containerHeight}vh` : '100vh')};
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = styled.div`
  width: 100vw;
  height: 104px;
  background: red;
  margin-bottom: 32px;
`;

export const Header = styled.div`
  width: 100%;
  width: 1148px;
  height: 104px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1148px;
  margin: 0 auto;
`;

export const TitleHeader = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span.title {
    color: ${({ theme }) => theme.color.gray21};
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
  }
`;
