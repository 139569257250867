import { HttpRequestStatus } from 'model/enums/http-request-status';
import { EvolveGuarantee } from 'model/evolve-guarantee';

export type ApiResponse = Record<string, any>;

export enum DetailEvolveGuaranteeTypes {
  DETAIL_EVOLVE_GUARANTEE_REQUEST = '@@evolve-guarantee-detail/DETAIL_EVOLVE_GUARANTEE_REQUEST',
  DETAIL_EVOLVE_GUARANTEE_SUCCESS = '@@evolve-guarantee-detail/DETAIL_EVOLVE_GUARANTEE_SUCCESS',
  DETAIL_EVOLVE_GUARANTEE_ERROR = '@@evolve-guarantee-detail/DETAIL_EVOLVE_GUARANTEE_ERROR',
  DETAIL_EVOLVE_GUARANTEE_RESET = '@@evolve-guarantee-detail/DETAIL_EVOLVE_GUARANTEE_RESET',
}

export interface DetailEvolveGuaranteeState {
  readonly guarantee?: EvolveGuarantee;
  readonly status: HttpRequestStatus;
  readonly error?: string;
}
