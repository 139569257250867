import { GeneralImages } from 'images/general-images';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

export const EmptyList = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src={GeneralImages.noResults} />
      <span className="title">{t('admin.dashboard.emptyList.title')}</span>
      <span className="message">{t('admin.dashboard.emptyList.message')}</span>
    </Container>
  );
};
