import { ButtonsContainer, Container, Titles } from './styles';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import StringUtils from 'shared/util/string-utils';

interface ReferenceCardProps {
  title: string;
  name: string;
  phone?: string;
}

export const ReferenceCard = ({ title, name, phone }: ReferenceCardProps) => {
  const { color } = useTheme();
  return (
    <Container>
      <Titles>
        <span className="title">{title}</span>
        <span className="name">{name}</span>
        {phone && <span className="phone">{StringUtils.phoneMask(phone)}</span>}
      </Titles>
      <ButtonsContainer></ButtonsContainer>
    </Container>
  );
};
