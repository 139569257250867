import UseResponsiveContext from 'context/use-responsive-context';
import FinancingStatusType from 'model/enums/financing-status-type';
import { IFilter, IMyPayments } from 'model/simulator-result';
import { useContext, useEffect, useState } from 'react';
import simulatorResultService from 'services/simulator-result';
import { useDebounce } from 'usehooks-ts';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface MyPaymentsContainerProps {}

const MyPaymentsContainer: React.FC<MyPaymentsContainerProps> = props => {
  const { isTablet } = useContext(UseResponsiveContext);

  const [payments, setPayments] = useState<IMyPayments[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchByIdentification, setSearchByIdentification] = useState('');
  const debounceName = useDebounce(searchByIdentification, 500);

  const [page, setPage] = useState<string>('1');
  const debouncePage = useDebounce(page, 500);

  const [totalPages, setTotalPages] = useState<number>(1);

  const filterTable = payments?.filter(payment => payment.payment) ?? [];
  const numberedPage = Number(page);
  const isEmptySearch = searchByIdentification !== '';
  const verifyPageMax = numberedPage > totalPages;
  const verifyPageMin = numberedPage < 1;

  useEffect(() => {
    if (!payments || payments.length === 0) {
      getDashboardPayments();
      return;
    }

    if (verifyPageMax) {
      setPage(totalPages.toString());
      return;
    } else if (verifyPageMin) {
      setPage('1');
      return;
    }
    if (isEmptySearch || (!verifyPageMax && !verifyPageMin)) {
      getDashboardPayments({ name: searchByIdentification, numberedPage });
    }
  }, [debouncePage, debounceName]);

  const getDashboardPayments = async (props?: IFilter) => {
    setIsLoading(true);
    simulatorResultService
      .getInstallments(props?.name, props?.size, numberedPage - 1, [FinancingStatusType.FINISHED, FinancingStatusType.IN_PROGRESS])
      .then(res => {
        setPayments(res.content);
        setPage((res.pageable.pageNumber + 1).toString());
        setTotalPages(res?.totalPages);
      })
      .finally(() => setIsLoading(false));
  };

  return !isTablet ? (
    <Desktop
      filterTable={filterTable}
      setSearchByIdentification={setSearchByIdentification}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      numberedPage={numberedPage}
      isLoading={isLoading}
    />
  ) : (
    <Mobile
      filterTable={filterTable}
      setSearchByIdentification={setSearchByIdentification}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      numberedPage={numberedPage}
      isLoading={isLoading}
      searchByIdentification={searchByIdentification}
    />
  );
};

export default MyPaymentsContainer;
