import { AxiosResponse } from 'axios';
import FilterParameters from 'model/filter-parameters';
import { buildUrl } from 'shared/util/api-utils';
import { api } from './api';

const analysisReportApi = () => {
  const generateReport = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = buildUrl('/analysis-report/', filterParameters);
    return api.get<Blob>(url);
  };

  const generateReportCcb = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = `/report-ccb?startDate=${filterParameters.startDate}T00:00:00&endDate=${filterParameters.endDate}T23:59:59`;
    return api.get<Blob>(url);
  };

  return {
    generateReport,
    generateReportCcb,
  };
};

export default analysisReportApi();
