import React, { useState, useEffect, useRef } from 'react';

export interface TextRotatorProps {
  texts: string[];
  delay: number;
  circular?: boolean;
}

export const TextRotator = ({ texts, delay, circular }: TextRotatorProps) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const currentTextIndexRef = useRef(currentTextIndex);

  useEffect(() => {
    currentTextIndexRef.current = currentTextIndex;
  }, [currentTextIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (circular) {
        const nextTextIndex = currentTextIndexRef.current + 1 === texts.length ? 0 : currentTextIndexRef.current + 1;
        setCurrentTextIndex(nextTextIndex);
      } else if (currentTextIndexRef.current < texts.length - 1) {
        const nextTextIndex = currentTextIndexRef.current + 1;
        setCurrentTextIndex(nextTextIndex);
      }
    }, delay * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [delay, circular, texts]);

  return (
    <>
      <span>{texts[currentTextIndex]}</span>
    </>
  );
};
