import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {
  QontoConnector,
  QontoStepIconRoot,
  StyledContainer,
  StyledFixSizeMobile,
  StyledStepperContainer,
  StyledSubtitle,
  StyledText,
  StyledTitle,
} from './styles';

interface IProps {
  title: string;
  subtitle?: string;
  text?: string | JSX.Element;
  lastStep: number;
  activeStep?: number;
  steps: string[];
  children: JSX.Element;
  footer?: JSX.Element;
}

const QontoStepIcon = (props: StepIconProps, lastStep: number, activeStep?: number) => {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {activeStep != null && activeStep !== lastStep && icon === activeStep + 1 ? (
        <div className="QontoStepIcon-active" />
      ) : completed ? (
        <div className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

const StepView = ({ title, subtitle, text, activeStep, lastStep, steps, children, footer }: IProps) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>

      <Stack sx={{ width: '100%' }}>
        <StyledStepperContainer>
          <Stepper activeStep={lastStep} connector={<QontoConnector />}>
            {steps.map(step => (
              <Step key={`STEP_${step}`}>
                <StepLabel StepIconComponent={stepData => QontoStepIcon(stepData, lastStep, activeStep)} />
              </Step>
            ))}
          </Stepper>
        </StyledStepperContainer>
      </Stack>

      <StyledFixSizeMobile>{children}</StyledFixSizeMobile>

      {subtitle != null && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {text != null && <StyledText>{text}</StyledText>}
      {footer != null && footer}
    </StyledContainer>
  );
};

export default StepView;
