import EnterCustomButton from 'components/enter-custom-button';
import i18n from 'config/i18n';
import ReportFilterType from 'model/filter-parameters';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import analysisReportService from 'services/analysis-report-service';
import toastUtils from 'shared/util/toast-utils';
import CustomCalendar from '../../components/calendar';
import { ButtonContainer, Mainn, TitleContainer } from './styles';

const ReportCcb = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [resetCalendar, setResetCalendar] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setResetCalendar(false);
  }, [startDate, endDate]);

  const clearDates = () => {
    setStartDate('');
    setEndDate('');
    setResetCalendar(true);
  };

  const handleSearch = () => {
    const filters: ReportFilterType = {};

    if (startDate && endDate) {
      filters['startDate'] = startDate;
      filters['endDate'] = endDate;

      analysisReportService.generateReportCcb(filters).then(response => {
        const file = new Blob([response.data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(file);
        a.download = `relatorio_ccbs_${startDate}_${endDate}.csv`;
        document.body.appendChild(a);
        a.click();
      });
    } else {
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.reportCcb'));
    }
  };

  return (
    <Mainn>
      <TitleContainer>
        <span>{t('global.button.selectDate')}</span>
      </TitleContainer>
      <CustomCalendar resetCalendar={resetCalendar} setInitialDate={setStartDate} setFinishDate={setEndDate} />
      <ButtonContainer>
        <EnterCustomButton onClick={() => clearDates()} isInvertColor isLoading={false} text={t('global.button.clean')} />
        <EnterCustomButton onClick={() => handleSearch()} isLoading={false} text={t(`global.button.searchReport`)} />
      </ButtonContainer>
    </Mainn>
  );
};

export default ReportCcb;
