import { HttpRequestStatus } from 'model/enums/http-request-status';
import { LandingPageType, OrganizationSubsidiaries } from 'model/landing-page';

export type ApiResponse = Record<string, any>;

export enum GetSubsidiariesActionTypes {
  GET_SUBSIDIARIES_REQUEST = '@@subsidiaries/GET_SUBSIDIARIES_REQUEST',
  GET_SUBSIDIARIES_SUCCESS = '@@subsidiaries/GET_SUBSIDIARIES_SUCCESS',
  GET_SUBSIDIARIES_ERROR = '@@subsidiaries/GET_SUBSIDIARIES_ERROR',
}

export interface SubsidiaryState {
  readonly GetSubsidiariesStatus: HttpRequestStatus;
  readonly organizationSubsidiaries: OrganizationSubsidiaries[];
  readonly error?: string;
}
