import { Container } from './styles';
import { RiErrorWarningFill } from 'react-icons/ri';
import CustomButton from 'components/custom-button';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import clientService from 'services/client-service';

interface ReactivateAccountAlert {
  clientId: number;
}

export const ReactivateAccountAlert = ({ clientId }: ReactivateAccountAlert) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const reactivateAccount = async () => {
    if (clientId) {
      setLoading(true);
      const res = await clientService.reactivateAccountAdmin(clientId);

      if (res.isVerified) {
        setOpenModal(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container>
        <RiErrorWarningFill size={32} color={color.rejected} />
        <span>{t('clientData.reactivateWarning')}</span>
        <CustomButton onClick={() => setOpenModal(true)}>
          <span>{t('clientData.reactivateButton')}</span>
        </CustomButton>
      </Container>

      {openModal && (
        <CustomModalTwoButtons
          isShowModal={openModal}
          onCloseModal={() => setOpenModal(false)}
          title={t('clientData.reactivateModalText')}
          handleClickFirstButton={() => setOpenModal(false)}
          handleClickSecondButton={reactivateAccount}
          firstButtonText={t('global.button.cancel')}
          secondButtonText={t('global.button.confirm')}
          large
          loading={loading}
        />
      )}
    </>
  );
};
