import { AxiosResponse } from 'axios';
import { City, State } from 'model/address';
import { Client, Occupation } from 'model/client';
import { CustomerRegister } from 'model/customer-register';
import { MainActivity } from 'model/dashboard';
import { PageableResponse } from 'model/pageable';
import { IRecoveryPassword } from 'model/recovery-password';
import { ICheckIfClientAlreadyRegistered } from 'services/authentication-service';
import { api } from './api';

export const register = () => {
  const registerFree = (customer: CustomerRegister): Promise<AxiosResponse<CustomerRegister>> => {
    return api.post<CustomerRegister>('/account/register', customer);
  };

  const registerPhysicalPerson = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.post<Client>('/clients/physical-person', client);
  };

  const registerPhysicalPersonAdmin = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.post<Client>('/clients/physical-person/admin', client);
  };

  const registerLegalPerson = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.post<Client>('/clients/legal-person', client);
  };

  const registerLegalPersonAdmin = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.post<Client>('/clients/legal-person/admin', client);
  };

  const updatePhysicalPerson = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>('/clients/physical-person', client);
  };

  const updateLegalPerson = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>('/clients/legal-person', client);
  };

  const adminUpdatePhysicalPerson = (id: number, client: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>(`/clients/${id}/physical-person/admin`, client);
  };

  const adminUpdateLegalPerson = (id: number, client: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>(`/clients/${id}/legal-person/admin`, client);
  };

  const checkIfClientAlreadyRegistered = (documentNumber: string): Promise<AxiosResponse<ICheckIfClientAlreadyRegistered>> => {
    return api.get<ICheckIfClientAlreadyRegistered>(`/clients/is-registered/${documentNumber}`);
  };

  const confirmEmail = (key: string): Promise<AxiosResponse> => {
    return api.get(`/activate?key=${key}`);
  };

  const resendValidationEmail = (documentNumber: string): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/clients/${documentNumber}/activate-account`);
  };

  const requestRecoveryPassword = (email: IRecoveryPassword): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/reset-password/init`, email);
  };

  const getStates = (size?: number, page?: number, name?: string, ac?: string): Promise<AxiosResponse<PageableResponse<State>>> => {
    return api.get<PageableResponse<State>>(`/states?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}&acronym=${ac ?? ''}`);
  };

  const getCities = (stateID: number, size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<City>>> => {
    return api.get<PageableResponse<City>>(`cities/states/${stateID}?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const getOccupations = (size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<Occupation>>> => {
    return api.get<PageableResponse<Occupation>>(`/occupations?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const getMainActivities = (size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<MainActivity>>> => {
    return api.get<PageableResponse<MainActivity>>(
      `/main-activities?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}&sort=name,asc`
    );
  };

  return {
    registerFree,
    getStates,
    confirmEmail,
    getCities,
    getOccupations,
    checkIfClientAlreadyRegistered,
    requestRecoveryPassword,
    registerPhysicalPerson,
    resendValidationEmail,
    updatePhysicalPerson,
    adminUpdatePhysicalPerson,
    adminUpdateLegalPerson,
    registerPhysicalPersonAdmin,
    registerLegalPersonAdmin,
    registerLegalPerson,
    updateLegalPerson,
    getMainActivities,
  };
};

export default register();
