import GeneralFooter from 'components/footer';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import LoadingContainerScreen from './components/loading-container-screen/loading-container-screen';
import './i18n/yup-translate';
import { HttpRequestStatus } from './model/enums/http-request-status';
import { IRootState } from './reducer';
import { getAccountParametersRequest, getSessionRequest, logoutRequest } from './reducer/authentication/actions';
import AppRoutes from './shared/routes/routes';
import AuthUtils from './shared/util/auth-utils';

const baseHref = '/';

interface IAppState {}

const App: React.FC<IAppState> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const account = useSelector((state: IRootState) => state.authentication.account);
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const getSessionStatus = useSelector((state: IRootState) => state.authentication.getSessionStatus);
  const notAllowedPathname: string[] = ['/register', '/'];
  const checkIfIsAuthenticated = isAuthenticated && !notAllowedPathname.includes(location.pathname);

  useEffect(() => {
    if (AuthUtils.isAuthenticated()) {
      dispatch(getAccountParametersRequest());
      dispatch(getSessionRequest());
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (getSessionStatus === HttpRequestStatus.SUCCESS) {
      setIsLoading(false);
    }

    if (getSessionStatus === HttpRequestStatus.ERROR) {
      dispatch(logoutRequest());
      setIsLoading(false);
    }
  }, [getSessionStatus]);

  const renderApp = (appProps: RouteComponentProps<any>) => {
    return (
      <LoadingContainerScreen isLoading={isLoading}>
        <div style={{ height: '31px', paddingBottom: '10px', position: 'relative' }}>
          <AppRoutes {...appProps} />
        </div>

        <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
          <GeneralFooter />
        </div>
      </LoadingContainerScreen>
    );
  };

  return (
    <BrowserRouter basename={baseHref}>
      <Route render={renderApp} />
    </BrowserRouter>
  );
};

export default compose(connect(null, null))(App) as React.ComponentType<any>;
