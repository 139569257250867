import { Authority } from 'model/enums/authority';
import { RoutePath } from 'model/enums/route-path';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface SimulatorNotApprovedProps {}

const SimulatorNotApproved: React.FC<SimulatorNotApprovedProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const { t } = useTranslation();
  const history = useHistory();

  const renderTitle = () => {
    if (authorities?.includes(Authority.ROLE_ADMIN)) {
      return t('simulatorFLow.notApprovedAdmin.title');
    } else {
      return t('simulatorFLow.notApproved.title');
    }
  };

  const handleRedirect = () => {
    if (authorities?.includes(Authority.ROLE_ADMIN)) {
      return history.push(RoutePath.ADMIN_HOME);
    } else {
      return history.push(RoutePath.HOME);
    }
  };

  return !isMobile ? (
    <Desktop title={renderTitle} handleRedirect={handleRedirect} />
  ) : (
    <Mobile title={renderTitle} handleRedirect={handleRedirect} />
  );
};

export default SimulatorNotApproved;
