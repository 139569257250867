import { StyledContestationFile, StyledContestationInnerContainer } from './styles';
import { ReactComponent as SmallPaperClipIconSvg } from 'images/gray-small-paper-clips-icon.svg';
import { ReactComponent as CloseIconSvg } from 'images/close-gray.svg';
import { TypeAttachment } from 'model/credit-card';
import axios from 'axios';

interface Props {
  attachment?: TypeAttachment;
  disabled?: boolean;
  handleClick: () => void;
}

const ContestationFile = ({ attachment, disabled, handleClick }: Props) => {
  const handleFileDownload = async () => {
    if (attachment?.presignedUrl != null) {
      await axios
        .get<ArrayBuffer>(attachment.presignedUrl, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          if (attachment?.fileName != null && response.data != null) {
            const arrayBuffer = response.data;
            const byteArray = new Uint8Array(arrayBuffer);
            const blob = new Blob([byteArray], { type: attachment.contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = attachment.fileName;
            link.click();

            URL.revokeObjectURL(url);
          }
        });
    }
  };

  return (
    <StyledContestationFile onClick={() => handleFileDownload()}>
      <StyledContestationInnerContainer>
        <SmallPaperClipIconSvg />
        {attachment?.fileName}
      </StyledContestationInnerContainer>
      {!disabled && (
        <CloseIconSvg
          onClick={e => {
            handleClick();
            e.stopPropagation();
          }}
        />
      )}
    </StyledContestationFile>
  );
};

export default ContestationFile;
