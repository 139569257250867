import evolveGuaranteeApi from 'api/evolve-guarantee-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { EvolveGuarantee } from 'model/evolve-guarantee';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { detailEvolveGuaranteeError, detailEvolveGuaranteeRequest, detailEvolveGuaranteeSuccess } from './actions';
import { DetailEvolveGuaranteeTypes } from './types';

function* handleDetailEvolveGuarantee(action: ReturnType<typeof detailEvolveGuaranteeRequest>) {
  try {
    const result: AxiosResponse<EvolveGuarantee> = yield call(evolveGuaranteeApi.getEvolveGuarantee, action.payload);
    if (result.status !== HttpStatus.OK) {
      const errorMessage: string = result.status === 401 ? 'Not permitted' : 'An unknown error occured.';
      yield put(detailEvolveGuaranteeError(errorMessage));
      return;
    }
    yield put(detailEvolveGuaranteeSuccess(result.data));
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(detailEvolveGuaranteeError(error.stack));
    } else {
      yield put(detailEvolveGuaranteeError('An unknown error occured.'));
    }
  }
}

function* watchDetailEvolveGuarantee() {
  yield takeEvery(DetailEvolveGuaranteeTypes.DETAIL_EVOLVE_GUARANTEE_REQUEST, handleDetailEvolveGuarantee);
}

function* detailEvolveGuaranteeSaga() {
  yield all([fork(watchDetailEvolveGuarantee)]);
}

export default detailEvolveGuaranteeSaga;
