import { FinancingSimpleSimulation } from 'model/financing';
import { useLocation } from 'react-router-dom';

export enum SimulatorProcessingMoment {
  BEFORE_SIMULATION = 'BEFORE_SIMULATION',
  AFTER_SIMULATION = 'AFTER_SIMULATION',
}

export interface SimulatorState {
  isNewCredit: boolean;
  sendSimulator: FinancingSimpleSimulation;
}

export const useSimulatorState = () => {
  const state = useLocation<SimulatorState>().state;

  const isNewCredit = state?.isNewCredit ?? false;
  const sendSimulator = state?.sendSimulator ?? {};
  return { isNewCredit, sendSimulator };
};
