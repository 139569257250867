import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import { AlignContent, ScreenContent, StyledTitle, StyledTitleContainer, TableContent } from 'components/simulator-result/styled';
import { TableList } from 'components/simulator-result/table';
import SearchInput from 'features/admin/dashboard/components/search-input';
import { Status } from 'features/admin/dashboard/components/status';
import { ReactComponent as ChevronRight } from 'images/blue-chevron-right.svg';
import { RoutePath } from 'model/enums/route-path';
import { IMyContracts } from 'model/simulator-result';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';

interface DesktopProps {
  filterTable: IMyContracts[];
  setSearchByIdentification: (value: string) => void;
  page: string;
  setPage: (value: string) => void;
  totalPages: number;
  numberedPage: number;
  isLoading: boolean;
}

const Desktop: React.FC<DesktopProps> = ({
  filterTable,
  setSearchByIdentification,
  setPage,
  page,
  totalPages,
  numberedPage,
  isLoading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const headerData = [t('dashboard.solicitationNumber'), t('dashboard.contract'), t('admin.dashboard.tableHeaders.status'), ''];

  const tableData: { [key: string]: string | JSX.Element }[] = filterTable.map((contract, idx) => ({
    solicitationNumber: contract?.financing?.identification ?? '',
    contract: StringUtils.characterLimit(contract?.attachment?.fileName, 56),
    status: (
      <Status
        key={contract.id}
        text={contract.status !== null ? t(`enum.contractStatus.${contract.status}`) : ''}
        color={contract.status ?? ''}
      />
    ),
    access: (
      <BlueCustomButtonNoBackground
        onClick={() =>
          history.push(`${RoutePath.CONTRACTS}/${contract.id}`, {
            prevPath: RoutePath.CONTRACTS,
          })
        }
      >
        {t('global.button.access')}
        <ChevronRight />
      </BlueCustomButtonNoBackground>
    ),
  }));

  return (
    <ScreenContent>
      <AlignContent>
        <StyledTitleContainer>
          <StyledTitle>{t('admin.dashboard.contracts')}</StyledTitle>
          <SearchInput setSearch={setSearchByIdentification} />
        </StyledTitleContainer>
        <TableContent>
          <TableList
            headerData={headerData}
            tableData={tableData}
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            numberedPage={numberedPage}
            isLoading={isLoading}
          />
        </TableContent>
      </AlignContent>
    </ScreenContent>
  );
};

export default Desktop;
