export enum FormStepType {
  PERSONAL_DATA = 'PERSONAL_DATA',
  PERSONAL_DATA_TOP_ONE = 'PERSONAL_DATA_TOP_ONE',
  PERSONAL_DATA_MULTTIPLO = 'PERSONAL_DATA_MULTTIPLO',
  PERSONAL_DATA_ZM = 'PERSONAL_DATA_ZM',
  PERSONAL_DATA_MONJUA = 'PERSONAL_DATA_MONJUA',
  ADDRESS = 'ADDRESS',
  ADDRESS_TOP_ONE = 'ADDRESS_TOP_ONE',
  ADDRESS_MONJUA = 'ADDRESS_MONJUA',
  ADDRESS_ZM = 'ADDRESS_ZM',
  ADDRESS_MULTTIPLO = 'ADDRESS_MULTTIPLO',
  ADDRESS_EVOLVE = 'ADDRESS_EVOLVE',
  INCOME_EVOLVE = 'INCOME_EVOLVE',
  INCOME = 'INCOME',
  INCOME_TOP_ONE = 'INCOME_TOP_ONE',
  INCOME_MONJUA = 'INCOME_MONJUA',
  SELFIE = 'SELFIE',
  PHYSICAL_PERSON_DOCUMENTS = 'PHYSICAL_PERSON_DOCUMENTS',
  PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT = 'PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT',
  PASSWORD = 'PASSWORD',
  FRAUD_ANALYSIS_SELFIE = 'FRAUD_ANALYSIS_SELFIE',
  SCORE_ANALYSIS = 'SCORE_ANALYSIS',
  DOC_FRAUD_ANALYSIS = 'DOC_FRAUD_ANALYSIS',
  BANCK_ACCOUNT_DATA = 'BANCK_ACCOUNT_DATA',
  PHYSICAL_PERSON_DOCUMENTS_HCRED = 'PHYSICAL_PERSON_DOCUMENTS_HCRED',
  REFERENCES_MONJUA = 'REFERENCES_MONJUA',
  GENERAL_DATA = 'GENERAL_DATA',
  PARTNER_RELATIONS = 'PARTNER_RELATIONS',
  PATRIMONIAL_SITUATION = 'PATRIMONIAL_SITUATION',
  LEGAL_PERSON_DOCUMENTS = 'LEGAL_PERSON_DOCUMENTS',
}
