import Header from 'components/general-components/header';
import InactiveAccountContainer from 'components/landing-page/inactive-account';
import { HeaderVariants } from 'model/enums/header-variants';

interface InactiveAccountProps {}

const InactiveAccount: React.FC<InactiveAccountProps> = props => {
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <InactiveAccountContainer />
    </>
  );
};

export default InactiveAccount;
