import ButtonVariants from 'model/enums/buttonVariants';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import ReactLoading from 'react-loading';
import { PopupActions } from 'reactjs-popup/dist/types';
import { ButtonBox, CancelButton, ClosedPopUp, ConfirmButton, IconBox, StyledPopup, Text, Title } from './styles';

interface ConfirmActionProps {
  title: string;
  text: string;
  activateButton: JSX.Element;
  onConfirm?: () => void;
  isSubmit?: boolean;
  formId?: string;
  modalRef?: MutableRefObject<PopupActions>;
  type?: string;
  isLoading: number;
}

const ConfirmAction: React.FC<ConfirmActionProps> = ({
  title,
  text,
  activateButton,
  onConfirm,
  formId,
  isSubmit,
  modalRef,
  type,
  isLoading,
}) => {
  const { t } = useTranslation();

  const sentProcess = (formId?: string) => {
    switch (isLoading) {
      case 0:
        return (
          <ConfirmButton
            onClick={onConfirm}
            customStyle={{ variant: ButtonVariants.NO_BACKGROUND }}
            form={formId}
            type={isSubmit ? 'submit' : 'button'}
          >
            {t('global.button.confirm')}
          </ConfirmButton>
        );
      case 1:
        return (
          <ConfirmButton type="button" form={formId} customStyle={{ variant: ButtonVariants.NO_BACKGROUND }} disabled>
            <ReactLoading type="spinningBubbles" width="24px" height="24px" />
          </ConfirmButton>
        );
      case 2:
        return (
          <ConfirmButton type="button" customStyle={{ variant: ButtonVariants.NO_BACKGROUND }} form={formId} disabled>
            {t('global.button.sended')}
          </ConfirmButton>
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledPopup trigger={activateButton} modal closeOnDocumentClick ref={modalRef}>
      {close => (
        <>
          <ClosedPopUp onClick={close}>
            <IoMdClose />
          </ClosedPopUp>
          <IconBox>
            <FiAlertCircle />
          </IconBox>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <ButtonBox>
            <CancelButton onClick={close}>{t('global.button.cancel')}</CancelButton>
            {sentProcess(formId)}
          </ButtonBox>
        </>
      )}
    </StyledPopup>
  );
};

export default ConfirmAction;
