import { WithTranslation, withTranslation } from 'react-i18next';
import * as S from './styles';
import CustomButton from 'components/custom-button';
import { useTheme } from 'styled-components';

interface Props extends WithTranslation {
  handleCloseModal: () => void;
  handleCancelPayment: () => void;
}

const ContentCancelPaymentModal = ({ handleCloseModal, handleCancelPayment, t }: Props) => {
  const { color } = useTheme();

  return (
    <S.ModalContainer>
      {t('admin.newBalance.modal.cancelPayment')}
      <S.ButtonsContainer>
        <CustomButton
          style={{ justifyContent: 'center', color: `${color.rejected}`, borderColor: `${color.rejected}` }}
          height={48}
          width="160px"
          isInvertColor
          onClick={() => handleCloseModal()}
        >
          {t('global.button.no')}
        </CustomButton>

        <CustomButton
          style={{ justifyContent: 'center', backgroundColor: `${color.rejected}`, borderColor: `${color.rejected}` }}
          height={48}
          width="160px"
          onClick={() => handleCancelPayment()}
        >
          {t('global.button.yesCancel')}
        </CustomButton>
      </S.ButtonsContainer>
    </S.ModalContainer>
  );
};

export default withTranslation()(ContentCancelPaymentModal);
