import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { PopupContent } from 'components/simulator-data/aproved/style';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContainerTextArea, PopupText, PopupTitle } from './styles';
import { useState } from 'react';

interface Props extends WithTranslation {
  isShowModal: boolean;
  handleClick: (string) => void;
  handleClose: () => void;
}

const CanRetryModal = ({ isShowModal, handleClick, handleClose, t }: Props) => {
  const [message, setMessage] = useState<string>('');

  return (
    <CustomModalTwoButtons
      isShowModal={isShowModal}
      onCloseModal={() => handleClose()}
      large
      children={
        <PopupContent>
          <PopupTitle>{t('admin.signatures.allowNewTry')}</PopupTitle>
          <PopupText>{t('admin.signatures.addObservation')}</PopupText>
          <ContainerTextArea onChange={event => setMessage(event.target.value)} />
        </PopupContent>
      }
      firstButtonText={t('global.button.cancel')}
      secondButtonText={t('global.button.confirm')}
      handleClickSecondButton={() => handleClick(message)}
    />
  );
};

export default withTranslation()(CanRetryModal);
