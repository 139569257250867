import Header from 'components/general-components/header';
import { TwoFactorAuth } from 'model/auth-biometric';
import { GeneralStatus } from 'model/enums/general-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { TwoFactorStatus } from 'model/enums/two-factors-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { Status } from '../dashboard/components/status';
import { Content, Label, PageContainer, Text, TextContainer, TextField, Title, TitleHeader } from './styles';

export const AuthenticationScreen = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { id } = useParams<{ id: string }>();
  const [twoFactorAuth, setTwoFactorAuth] = useState<TwoFactorAuth | null>(null);

  useEffect(() => {
    getTwoFactorAuth();
  }, []);

  const getTwoFactorAuth = async () => {
    if (id) {
      const res = await financingService.getAuthenticateAndBiometry(Number(id));
      setTwoFactorAuth(res.twoFactorAuth[0]);
    }
  };

  const getAuthTextStatus = () => {
    if (twoFactorAuth?.status) {
      switch (twoFactorAuth?.status) {
        case TwoFactorStatus.REPLIED:
          return twoFactorAuth.verified
            ? t(`enum.authBiometryShortStatus.${twoFactorAuth.status}.approved`)
            : t(`enum.authBiometryShortStatus.${twoFactorAuth.status}.denied`);

        case TwoFactorStatus.SENT:
          return twoFactorAuth.verified
            ? t(`enum.authBiometryShortStatus.${TwoFactorStatus.REPLIED}.approved`)
            : t(`enum.authBiometryShortStatus.${twoFactorAuth.status}`);

        default:
          return t(`enum.authBiometryShortStatus.${twoFactorAuth.status}`);
      }
    }

    return '';
  };

  const getAuthShortStatus = () => {
    if (twoFactorAuth?.status) {
      switch (twoFactorAuth?.status) {
        case TwoFactorStatus.REPLIED:
          return twoFactorAuth.verified ? GeneralStatus.APPROVED : GeneralStatus.REJECTED;

        case TwoFactorStatus.SENT:
          return twoFactorAuth.verified ? GeneralStatus.APPROVED : GeneralStatus.VALIDATION;

        case TwoFactorStatus.EXPIRED:
          return GeneralStatus.REJECTED;
        default:
          return '';
      }
    }
  };

  const formatDate = (date: string) => {
    const formatedDate = new Date(date);

    return formatedDate.toLocaleDateString();
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.authenticationScreen.pageTitle')}</span>
        </Title>
      </TitleHeader>
      <Content>
        <TextContainer>
          <TextField>
            <Label>{t('admin.authenticationScreen.labels.sms')}</Label>
            <Text>{twoFactorAuth?.text ?? ''}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.authenticationScreen.labels.type')}</Label>
            <Text>{twoFactorAuth?.type ?? ''}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.authenticationScreen.labels.status')}</Label>
            <Status text={getAuthTextStatus()} color={getAuthShortStatus() as string} />
          </TextField>
          <TextField>
            <Label>{t('admin.authenticationScreen.labels.date')}</Label>
            <Text>{twoFactorAuth?.createdAt ? formatDate(twoFactorAuth.createdAt) : ''}</Text>
          </TextField>
        </TextContainer>
      </Content>
    </PageContainer>
  );
};
