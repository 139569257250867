import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import { ReactComponent as RedExclamationCircleSvg } from 'images/red-exclamation-circle.svg';
import BiometricsStatusAndClientAuthentication from 'model/biometrics-status-and-client-authentication';
import { HeaderVariants } from 'model/enums/header-variants';
import { Financing } from 'model/financing';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { ClientService } from 'services/client-service';
import financingService from 'services/financing-service';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';
import { StyledContainer, StyleTextModalContainer } from './styles';

const MyCredits = ({ t }: WithTranslation) => {
  const isMobile = useResponsiveMobile().isMobile;
  const loggedUserData = useSelector((state: IRootState) => state.authentication.account);

  const [myFinancing, setMyFinancing] = useState<Financing[]>();
  const [biometricsStatusAndClientAuthentication, setBiometricsStatusAndClientAuthentication] =
    useState<BiometricsStatusAndClientAuthentication>();
  const [isShowCreditLockModal, setIsShowCreditLockModal] = useState<boolean>(false);

  useEffect(() => {
    financingService.getMyFinancing().then(response => setMyFinancing(response.data.content));

    if (loggedUserData?.client?.id != null) {
      ClientService().getBiometricsStatusAndClientAuthentication(loggedUserData.client.id).then(setBiometricsStatusAndClientAuthentication);
    }
  }, []);

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />

      {isMobile ? (
        <Mobile
          myFinancing={myFinancing}
          biometricsStatusAndClientAuthentication={biometricsStatusAndClientAuthentication}
          setIsShowCreditLockModal={setIsShowCreditLockModal}
        />
      ) : (
        <Desktop
          myFinancing={myFinancing}
          biometricsStatusAndClientAuthentication={biometricsStatusAndClientAuthentication}
          setIsShowCreditLockModal={setIsShowCreditLockModal}
        />
      )}

      <CustomModalTwoButtons
        isShowModal={isShowCreditLockModal}
        onCloseModal={() => setIsShowCreditLockModal(false)}
        icon={<RedExclamationCircleSvg />}
        title={t('dashboard.youCannotAddNewFunding')}
        children={
          <StyleTextModalContainer>
            {t('dashboard.thisFunctionCurrentlyNotAvailable', {
              email: biometricsStatusAndClientAuthentication?.organization.contactEmail,
            })}
          </StyleTextModalContainer>
        }
        secondButtonText={t('global.button.ok')}
        handleClickSecondButton={() => setIsShowCreditLockModal(false)}
      />
    </StyledContainer>
  );
};

export default withTranslation()(MyCredits);
