import LoadingScreen from 'components/loading-screen/loading-screen';
import { Status } from 'features/admin/dashboard/components/status';
import { GeneralImages } from 'images/general-images';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { EmptyTitleBox, ImageBox, StyledIcon } from '../styled';
import { Button, ButtonsPage, Container, InputPage, Pagination } from '../table/styled';
import {
  StyleCardInnerContainer,
  StyledCardContainer,
  StyledCardsContainerMobile,
  StyledCardText,
  StyledCardTitle,
  StyledHeaderCardContainer,
} from './styled';

interface TableListProps {
  cardData: { [key: string]: string | number }[];
  page: string;
  setPage: (page: string) => void;
  totalPages: number;
  editMode?: boolean;
  numberedPage: number;
  isLoading?: boolean;
  redirect: string;
}

export const CardList = ({ cardData, totalPages, page, setPage, numberedPage, isLoading, redirect }: TableListProps) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const isEmpty = cardData.length === 0;

  //   pagination --
  const onHandleChangePage = (value: string) => {
    const newPage = StringUtils.removeNonNumbersFromMaskedValue(value);

    setPage(newPage);
  };

  const onHandleClickLeft = () => {
    const newPage = Number(page) - 1;

    if (newPage >= 1) {
      setPage(newPage.toString());
    }
  };

  const onHandleClickRight = () => {
    const newPage = Number(page) + 1;

    if (newPage <= totalPages) {
      setPage(newPage.toString());
    }
  };

  const handleBlur = () => {
    if (numberedPage < 1) {
      setPage('1');
    } else if (numberedPage > totalPages) {
      setPage(totalPages.toString());
    }
  };
  //   pagination --

  const history = useHistory();
  const theme = useTheme();
  return (
    <Container>
      <LoadingScreen isLoading={isLoading} height={'192px'}>
        {isEmpty ? (
          <EmptyTitleBox>
            <span className="title">{t('admin.dashboard.emptyList.title')}</span>
            <span className="message">{t('admin.dashboard.emptyList.message')}</span>
            <ImageBox>
              <img src={GeneralImages.noResults} />
            </ImageBox>
          </EmptyTitleBox>
        ) : (
          <StyledCardsContainerMobile>
            {cardData?.map((card, index) => (
              <StyledCardContainer
                key={`financing-${index}`}
                onClick={() =>
                  history.push(`${redirect}/${card.id}`, {
                    cardId: card?.id,
                    financingStatus: card?.status,
                    consultantComment: card?.consultantComment,
                    prevPath: redirect,
                  })
                }
              >
                <StyleCardInnerContainer>
                  <StyledHeaderCardContainer>
                    {/* idNumber */}
                    <StyledCardTitle>{card?.idNumber}</StyledCardTitle>

                    {/* status */}
                    <Status
                      text={card.status !== null ? t(`enum.contractStatus.${card.status}`) : ''}
                      color={card?.status.toString() ?? ''}
                    />
                  </StyledHeaderCardContainer>

                  <StyledCardText>{card.text}</StyledCardText>
                </StyleCardInnerContainer>
                <StyledIcon>
                  <FiChevronRight color={theme.color.primaryColor} size={24} />
                </StyledIcon>
              </StyledCardContainer>
            ))}
          </StyledCardsContainerMobile>
        )}
      </LoadingScreen>

      <Pagination>
        <InputPage>
          <span>{t('admin.dashboard.table.page')}</span>
          <input
            type="number"
            placeholder={page.toString() ?? '1'}
            min="0"
            value={page.toString()}
            onChange={e => onHandleChangePage(e.target.value)}
            onBlur={handleBlur}
          />
          <span>{t('admin.dashboard.table.totalPage', { total: totalPages })}</span>
        </InputPage>
        <ButtonsPage>
          <Button className="left" onClick={onHandleClickLeft}>
            <FiChevronLeft color={color.primaryColor} />
          </Button>
          <Button className="right" onClick={onHandleClickRight}>
            <FiChevronRight color={color.primaryColor} />
          </Button>
        </ButtonsPage>
      </Pagination>
    </Container>
  );
};
