import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer, Column, FormInputsContainer } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { EmploymentBond } from 'model/client';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { employmentBondOptions } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsIncomeTopOne = props => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();
  const { occupations, occupationsTotalPages, getOccupations } = useSelectLists();
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  return (
    <FormInputsContainer>
      <Column>
        <TextInput name="employmentBond" label={t('register.inputs.employmentBond')} isRequired hasCustomInput>
          <SearchSelectInput
            name="employmentBond"
            placeholder={t('register.placeholder.employmentBond')}
            options={employmentBondOptions}
            defaultOptions={employmentBondOptions}
            defaultValue={employmentBondOptions.find(res => res.value === initialClientData?.employmentBond)?.label}
            handleChange={event => props.setEmploymentBondValue(employmentBondOptions.find(res => res.label === event.name)?.value ?? '')}
          />
        </TextInput>
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="occupation"
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
            defaultValue={initialClientData?.physicalPerson?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <InputText
          isRequired
          name="admissionDate"
          label={t('register.inputs.admissionDate')}
          placeholder={t('register.placeholder.admissionDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.admissionDate
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.admissionDate.toString())
              : ''
          }
        />
        <InputText
          name="income"
          label={t('register.inputs.income')}
          placeholder={t('register.placeholder.income')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
          maxLength={14}
        />
      </Column>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          fullSize
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
