export enum AttachmentType {
  SOCIAL_CONTRACT = 'SOCIAL_CONTRACT',
  REVENUE_DECLARATION = 'REVENUE_DECLARATION',
  IDENTIFICATION_CARD = 'IDENTIFICATION_CARD',
  PROOF_INCOME = 'PROOF_INCOME',
  PROOF_RESIDENCE = 'PROOF_RESIDENCE',
  PROOF_PURCHASE = 'PROOF_PURCHASE',
  PROOF_PURCHASE_FILE = 'PROOF_PURCHASE_FILE',
  SELFIE = 'SELFIE',
  DOCUMENT = 'DOCUMENT',
  PROCEDURE_REFUND = 'PROCEDURE_REFUND',
  FACE_MATCH = 'FACE_MATCH',
}
