import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import Header from 'components/general-components/header';
import Simulator from 'components/landing-page/opening-screen/simulator';
import { ReactComponent as ChevronRightSvg } from 'images/blue-chevron-right.svg';
import { HeaderVariants } from 'model/enums/header-variants';
import { SelectListsProvider } from 'provider/select-list';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledContainer, StyledInnerContainer } from './styles';

const AdminAddNewFinancing = ({ t }: WithTranslation) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledInnerContainer>
        <BlueCustomButtonNoBackground onClick={() => history.goBack()} style={{ marginBottom: '20px' }}>
          <ChevronRightSvg style={{ transform: 'rotate(180deg)' }} />
          {t('global.button.backStep')}
        </BlueCustomButtonNoBackground>
        <SelectListsProvider>
          <Simulator isAdmin />
        </SelectListsProvider>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(AdminAddNewFinancing);
