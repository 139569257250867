import styled from 'styled-components';

export const content = styled.div``;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;
export const ProcessingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.color.white};
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black0};
`;
export const Description = styled.span`
  margin-top: 12px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray18};
  text-align: center;
`;

export const StyledImg = styled.img`
  margin: 16px 0px 16px 0px;
`;
