import FinancingStatusType from 'model/enums/financing-status-type';

const statusListCannotEditData = [
  FinancingStatusType.NEW,
  FinancingStatusType.ADJUSTED,
  FinancingStatusType.IN_ANALYSIS,
  FinancingStatusType.REJECTED,
  FinancingStatusType.VALIDATION,
];

export default statusListCannotEditData;
