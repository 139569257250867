import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/register-forms/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { genderType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPersonalDataMonjua = () => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();
  const { maritalStatus, getMaritalStatus } = useSelectLists();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const maritalStatusDV = maritalStatus.find(occ => occ.id === initialClientData?.physicalPerson?.maritalStatus?.id)?.displayValue;

  useEffect(() => {
    getMaritalStatus();
  }, []);

  return (
    <FormInputsContainer>
      <Row>
        <InputText
          isRequired
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.physicalPerson?.name}
        />
        <InputText
          isRequired
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString()) : ''
          }
        />
        <InputText
          isRequired
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
        <InputText
          isRequired
          name="cpf"
          disabled
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialClientData?.physicalPerson?.cpf}
        />
        <InputText
          isRequired
          name="rg"
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialClientData?.physicalPerson?.rg}
          maxLength={20}
        />
        <InputText
          isRequired
          name="rgIssuingBody"
          label={t('register.inputs.rgIssuingBody')}
          placeholder={t('register.placeholder.rgIssuingBody')}
          defaultValue={initialClientData?.physicalPerson?.rgIssuingBody}
          maxLength={20}
        />
        <InputText
          isRequired
          name="rgUfIssuingBody"
          label={t('register.inputs.rgUfIssuingBody')}
          placeholder={t('register.placeholder.rgUfIssuingBody')}
          defaultValue={initialClientData?.physicalPerson?.rgUfIssuingBody}
          maxLength={2}
        />
        <InputText
          isRequired
          name="rgDateOfIssuance"
          label={t('register.inputs.rgDateOfIssuance')}
          placeholder={t('register.placeholder.rgDateOfIssuance')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.rgDateOfIssuance
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.rgDateOfIssuance.toString())
              : ''
          }
        />
        <InputText
          isRequired
          name="phone"
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.phone}
        />
        <InputText
          isRequired
          name="motherName"
          label={t('register.inputs.motherName')}
          placeholder={t('register.placeholder.motherName')}
          defaultValue={initialClientData?.physicalPerson?.motherName}
        />
        <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="genderType"
            placeholder={t('register.placeholder.genderType')}
            options={genderType}
            defaultOptions={genderType}
            defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
          />
        </TextInput>
        <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
          <SearchSelectInput
            name="maritalStatus"
            placeholder={t('register.placeholder.maritalStatus')}
            options={maritalStatus.map(el => ({
              label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
              value: el.id.toString(),
            }))}
            defaultValue={maritalStatusDV && maritalStatusDV[0].toUpperCase() + maritalStatusDV.toLowerCase().substring(1)}
            handleChange={values => {
              setSelectedMaritalStatus(values.name);
            }}
            defaultOptions={maritalStatus.map(el => ({
              label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
              value: el.id.toString(),
            }))}
          />
        </TextInput>
        <InputText
          name="spouseName"
          label={t('register.inputs.spouseName')}
          placeholder={t('register.placeholder.spouseName')}
          defaultValue={initialClientData?.physicalPerson?.spouse?.name}
        />
        <InputText
          name="spouseBirth"
          label={t('register.inputs.spouseBirth')}
          placeholder={t('register.placeholder.spouseBirth')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.spouse?.birth
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.spouse.birth.toString())
              : ''
          }
        />
        <InputText
          name="spousePhone"
          label={t('register.inputs.spousePhone')}
          placeholder={t('register.placeholder.spousePhone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.physicalPerson?.spouse?.phone}
        />
      </Row>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
