import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    /* justify-content: center; */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: end;
`;

export const TabContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  padding: 16px 24px 24px 24px;
`;

export const Title = styled.div`
  > div.firstTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    > button {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  & > div {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;

    span.title {
      display: inline-block;
      font-weight: 700;
      margin-left: 16px;
    }

    > span.code {
      margin-left: 8px;
    }

    > span.ccb {
      margin-left: 45px;
    }
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;

  justify-content: space-between;
  margin-bottom: 16px;

  > div,
  label {
    flex: 1;
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: flex-start;

    > div,
    label {
      flex: 0;
    }
  }
`;

export const AttachmentTitle = styled.h2`
  width: 288px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AttachmentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 6px;
  height: 42px;
  background: #eff6ff;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 8px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  width: 500px;
  color: #333333;
  cursor: pointer;
`;
