import landingPageApi from 'api/landing-page-api';
import { AxiosResponse } from 'axios';
import { GetValidationType } from 'model/organization-types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { simulatorParametersError, simulatorParametersRequest, simulatorParametersSuccess } from './actions';
import { SystemParametersActionTypes } from './types';

function* handleSimulatorParameters(action: ReturnType<typeof simulatorParametersRequest>) {
  try {
    const result: AxiosResponse<GetValidationType[]> = yield call(landingPageApi.getValidation, action.payload);
    if (result.status !== 200 || result.data == null) {
      const errorMessage: string = result.status === 401 ? 'Cannot register this user' : 'An unknown error occured.';
      yield put(simulatorParametersError(errorMessage));
      return;
    } else {
      yield put(simulatorParametersSuccess(result.data));
      return;
    }
  } catch (err) {
    console.log('err', err);

    if (err instanceof Error && err.stack) {
      yield put(simulatorParametersError(err.stack));
    } else {
      yield put(simulatorParametersError('An unknown error occured.'));
    }
  }
}

function* watchSystemParametersRequest() {
  yield takeEvery(SystemParametersActionTypes.SYSTEM_PARAMETERS_REQUEST, handleSimulatorParameters);
}

function* systemParametersSaga() {
  yield all([fork(watchSystemParametersRequest)]);
}

export default systemParametersSaga;
