import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiInfo } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Content, InfoDTI, Label, PageContainer, Text, TextContainer, TextField, Title, TitleHeader } from './styles';

export const DTIScreen = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.dtiScreen.pageTitle')}</span>
        </Title>
        <InfoDTI>
          O Digital Trust Index (DTI) é uma Inteligência Artificial da ClearSale permite a geração de um dos scores de crédito mais
          eficientes e assertivos do mercado financeiro atual.
        </InfoDTI>
      </TitleHeader>

      <Content>
        <TextContainer>
          <TextField>
            <Label>Código da transação</Label>
            <Text>30</Text>
          </TextField>

          <TextField>
            <Label>Código do pedido</Label>
            <Text>1452291</Text>
          </TextField>

          <TextField>
            <Label>CPF consultado</Label>
            <Text>078.456.456-43</Text>
          </TextField>

          <TextField>
            <Label>Data de referência</Label>
            <Text>00/00/0000</Text>
          </TextField>

          <TextField>
            <Label>Data de transação</Label>
            <Text>00/00/0000</Text>
          </TextField>

          <TextField>
            <Label>Score geral</Label>
            <Text>402</Text>
          </TextField>

          <TextField>
            <Label>Presença digital</Label>
            <Text>Sim</Text>
          </TextField>

          <TextField>
            <Label>
              Potencial de consumo <FiInfo size={16} />
            </Label>
            <Text>G</Text>
          </TextField>

          <TextField>
            <Label>
              Qualidade e variedade de compra <FiInfo size={16} />
            </Label>
            <Text>511</Text>
          </TextField>

          <TextField>
            <Label>
              Formas de pagamento e frequência <FiInfo size={16} />
            </Label>
            <Text>404</Text>
          </TextField>

          <TextField>
            <Label>
              Perfil do cliente <FiInfo size={16} />
            </Label>
            <Text>5151 2139 3652 8806</Text>
          </TextField>

          <TextField>
            <Label>
              Dados do cliente <FiInfo size={16} onMouseOver={() => console.log('over')} />
            </Label>
            <Text>460</Text>
          </TextField>

          <TextField>
            <Label>
              Índice de CEP <FiInfo size={16} />
            </Label>
            <Text>369</Text>
          </TextField>

          <TextField>
            <Label>
              Scores dos CPFs <FiInfo size={16} />
            </Label>
            <Text>385</Text>
          </TextField>
        </TextContainer>
      </Content>
    </PageContainer>
  );
};
