import { AxiosResponse } from 'axios';
import { CrefizCreditEngine, CrefizCreditEngineId } from 'model/crefiz-credit-engine';
import { api } from './api';

const crefizApi = () => {
  const BASE_URL = '/crefiz/credit-engine';
  const createAnalysis = (financingId: number): Promise<AxiosResponse<CrefizCreditEngineId>> => {
    const url = `${BASE_URL}/financing/${financingId}`;
    return api.post<CrefizCreditEngineId>(url);
  };

  const getAnalysisInfo = (clientId: number): Promise<AxiosResponse<CrefizCreditEngine>> => {
    const url = `${BASE_URL}?id=${clientId}`;
    return api.get<CrefizCreditEngine>(url);
  };

  return {
    createAnalysis,
    getAnalysisInfo,
  };
};

export default crefizApi();
