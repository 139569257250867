import landingPageApi from 'api/landing-page-api';
import { AxiosResponse } from 'axios';
import { OrganizationData } from 'model/organization-types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getSubsidiariesError, getSubsidiariesSuccess } from './actions';
import { GetSubsidiariesActionTypes } from './types';
import { OrganizationSubsidiaries } from 'model/landing-page';

function* handleGetOrganizationSubsidiaries() {
  try {
    const result: AxiosResponse<OrganizationSubsidiaries[]> = yield call(landingPageApi.getOrganizationSubsidiaries);

    if (result.status !== 200 || result.data == null) {
      const errorMessage: string = result.status === 401 ? 'Not permitted' : 'An unknown error occured.';
      yield put(getSubsidiariesError(errorMessage));
      return;
    } else {
      yield put(getSubsidiariesSuccess(result.data));
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSubsidiariesError(err.stack));
    } else {
      yield put(getSubsidiariesError('An unknown error occured.'));
    }
  }
}

function* watchGetSubsidiariesRequestRequest() {
  yield takeEvery(GetSubsidiariesActionTypes.GET_SUBSIDIARIES_REQUEST, handleGetOrganizationSubsidiaries);
}

function* organizationSubsidiarySaga() {
  yield all([fork(watchGetSubsidiariesRequestRequest)]);
}

export default organizationSubsidiarySaga;
