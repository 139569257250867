import { LogoBox } from 'components/general-components/header/styles';
import AdminFiltersContext from 'context/admin-filters';
import { Logo } from 'images/custom-organization';
import { RoutePath } from 'model/enums/route-path';
import { useAdmin } from 'provider/admin-general';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import { setStorageFilters } from 'shared/util/filter-utils';
import { ReactComponent as ArrowIconSvg } from '../../images/open_arrow.svg';
import { useCustomSideMenu } from './hooks';
import { ButtonsContainer, Container, ContainerArrow, ContainerIcon, StyledArrow, TabButton } from './styles';

interface Props {
  containerHeight?: string;
}

export const CustomSideMenu: React.FC<Props> = ({ containerHeight }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { menuOpen, setMenuOpen, matchUrlSelectedTab, returnDropdonw } = useCustomSideMenu();
  const { resetFilters } = useContext(AdminFiltersContext);
  const { tabsMenu } = useAdmin();
  const { resetStatusFilters, resetCreatedDates } = useAdmin();
  const selectedTab = tabsMenu.find(tab => matchUrlSelectedTab(tab));

  return (
    <Container containerHeight={containerHeight} onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
      <ContainerArrow menuOpen={menuOpen}>
        {menuOpen ? (
          <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
            <Logo isWhite={true} isSecundaryLogo={false} />
          </LogoBox>
        ) : (
          <LogoBox
            onClick={() => {
              AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME);
              setMenuOpen(!menuOpen);
            }}
          >
            <Logo isSecundaryLogo={true} />
          </LogoBox>
        )}
        {menuOpen ? (
          <StyledArrow
            menuOpen={menuOpen}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <ContainerIcon>
              <ArrowIconSvg />
            </ContainerIcon>
          </StyledArrow>
        ) : (
          <></>
        )}
      </ContainerArrow>
      <ButtonsContainer
        onClick={() => {
          resetStatusFilters();
          resetCreatedDates();
          resetFilters();
        }}
      >
        {menuOpen
          ? tabsMenu.map((tab, idx) => (
              <TabButton
                menuOpen={menuOpen}
                selected={matchUrlSelectedTab(tab)}
                key={`${tab.label}-${idx}`}
                onClick={() => {
                  if (tab?.url) {
                    history.push(tab.url);
                  }
                  setStorageFilters({ statusFilters: [], page: 0, search: '' });
                  resetStatusFilters();
                  resetCreatedDates();
                  resetFilters();
                }}
              >
                {tab?.logo ?? ''}
                {menuOpen && t(tab?.label ?? '')}
              </TabButton>
            ))
          : selectedTab && (
              <TabButton
                menuOpen={menuOpen}
                selected={true}
                key={`${selectedTab.label}`}
                onClick={() => {
                  if (selectedTab?.url) {
                    history.push(selectedTab.url);
                    setMenuOpen(!menuOpen);
                  }
                  setStorageFilters({ statusFilters: [], page: 0, search: '' });
                  resetStatusFilters();
                  resetCreatedDates();
                  resetFilters();
                }}
              >
                {selectedTab?.logo ?? ''}
                {menuOpen && t(selectedTab?.label ?? '')}
              </TabButton>
            )}
      </ButtonsContainer>
    </Container>
  );
};
