import PreRegisterContext from 'context/pre-register';
import { Authority } from 'model/enums/authority';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface ApprovedProps {}

const Approved: React.FC<ApprovedProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;

  const { registrationKey, setRegistrationKey } = useContext(PreRegisterContext);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const unLoggedStatus = [
    RegistrationStatus.REJECTED,
    RegistrationStatus.NEW,
    RegistrationStatus.PRE_REJECTED,
    RegistrationStatus.VALIDATION,
  ];

  const whichRoute = unLoggedStatus.some((status: RegistrationStatus) => status.includes(account?.client?.registrationStatus ?? ''));
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  useEffect(() => {
    if (!authorities?.includes(Authority.ROLE_CLIENT)) {
      let getRegistrationKey = localStorage.getItem(LocalStorageKeys.REGISTRATION_KEY);
      if (getRegistrationKey) {
        const parse: string = JSON.parse(getRegistrationKey);
        setRegistrationKey(parse);
      }
    }
  }, []);

  return !isMobile ? (
    <Desktop registrationKey={registrationKey} whichRoute={whichRoute} />
  ) : (
    <Mobile registrationKey={registrationKey} whichRoute={whichRoute} />
  );
};

export default Approved;
