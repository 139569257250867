import { SVGProps } from 'react';
import theme from '../themes/default';

export const CheckBoxFill = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y={0.5} width={16} height={16} rx={2} fill={theme.color.primaryColor} />
    <path
      d="M5.664 7.975A1 1 0 1 0 4.336 9.47l1.328-1.495ZM7 10.5l-.664.747a1 1 0 0 0 1.371-.04L7 10.5Zm4.707-3.293a1 1 0 0 0-1.414-1.414l1.414 1.414ZM4.336 9.47l2 1.777 1.328-1.494-2-1.778L4.336 9.47Zm3.371 1.737 4-4-1.414-1.414-4 4 1.414 1.414Z"
      fill="#fff"
    />
  </svg>
);
