import { ReactComponent as CurrentStepSvg } from 'images/current-step.svg';
import { ReactComponent as StepCompletedSvg } from 'images/step-completed.svg';
import * as S from '../styles';

const useCustomStepIcon = () => {
  const checkIfYouAreInCurrentStep = (stepValue: number, currentStep: number) => stepValue === currentStep;

  const handleStepIcon = (stepCompleted: boolean, stepValue: number, currentStep: number): JSX.Element => {
    if (stepCompleted) {
      return <StepCompletedSvg />;
    }

    if (checkIfYouAreInCurrentStep(stepValue, currentStep)) {
      return <CurrentStepSvg />;
    }

    return <S.StepValue>{stepValue}</S.StepValue>;
  };

  return { checkIfYouAreInCurrentStep, handleStepIcon };
};

export default useCustomStepIcon;
