import i18n from 'config/i18n';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import StringUtils from 'shared/util/string-utils';

const useFormatPaymentTypeToShow = () => {
  const account = useSelector((state: IRootState) => state.authentication.account);

  const formatPaymentTypeToShow = (text: FinancingPaymentType) => {
    switch (text) {
      case FinancingPaymentType.ORGANIZATION:
        return i18n.t(`admin.customerAnalysis.paymentOptions.organization`, {
          type: StringUtils.replaceToCapitalize(account?.organization ?? ''),
        });
      case FinancingPaymentType.DEALER:
        return i18n.t(`admin.customerAnalysis.paymentOptions.dealer`, {
          type: StringUtils.replaceToCapitalize(account?.organization ?? ''),
        });

      default:
        return i18n.t('admin.customerAnalysis.paymentOptions.client');
    }
  };

  return { formatPaymentTypeToShow };
};

export default useFormatPaymentTypeToShow;
