import { AxiosResponse } from 'axios';
import { FinancyInternal, Internal } from 'model/internals';
import { PageableResponse } from 'model/pageable';
import { api } from './api';

export const internalsApi = () => {
  const baseUrl = '/internals';

  const getInternalById = (internalId: number): Promise<AxiosResponse<Internal>> => {
    return api.get<Internal>(`${baseUrl}/${internalId}`);
  };

  const getInternals = (
    name?: string,
    size?: number,
    page?: number,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<Internal>>> => {
    return api.get<PageableResponse<Internal>>(
      `${baseUrl}?size=${size ?? 11}&page=${page ?? 0}&name=${name ?? ''}${
        subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`
      }`
    );
  };

  const getFinancyInternalsById = (id): Promise<AxiosResponse<FinancyInternal>> => {
    return api.get<FinancyInternal>(`/financing/${id}/internals`);
  };

  const updateFinancyInternalById = (internalId: number, internal: FinancyInternal): Promise<AxiosResponse<FinancyInternal>> => {
    return api.patch<FinancyInternal>(`/financing/${internalId}/internals`, internal);
  };

  const createInternal = (internal: Internal): Promise<AxiosResponse<Internal>> => {
    return api.post<Internal>(`${baseUrl}`, internal);
  };

  const updateInternalById = (internalId: number, internal: Internal): Promise<AxiosResponse<Internal>> => {
    return api.patch<Internal>(`${baseUrl}/${internalId}`, internal);
  };

  const inactiveInternalById = (internalId: number): Promise<AxiosResponse<void>> => {
    return api.delete<void>(`${baseUrl}/${internalId}`);
  };

  return {
    getInternalById,
    getInternals,
    createInternal,
    updateInternalById,
    inactiveInternalById,
    getFinancyInternalsById,
    updateFinancyInternalById,
  };
};

export default internalsApi();
