import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Container, PageContainer, Title } from './styles';
import { CgCloseO } from 'react-icons/cg';
import { useTheme } from 'styled-components';

export const simulatorRejectModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();
  const { color } = useTheme();

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <CgCloseO size={75} color={color.rejected} />
        <Title>{t('admin.simulatorModal.reject')}</Title>
        <CustomButton onClick={() => history.push(`/admin/cadastros/${clientId}`)}>
          <span>{t('global.button.backStep')}</span>
        </CustomButton>
      </Container>
    </PageContainer>
  );
};
