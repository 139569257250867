export enum PaymentStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  DONE = 'Done',
  PENDING = 'PENDING',
}
