import contractQueueApi from 'api/contract-queue-api';
import { AxiosError, AxiosResponse } from 'axios';
import ContractQueueExists from 'model/enums/contract-queue-exists';
import HttpStatus from 'model/enums/http-status';

const contractQueueService = () => {
  const createContractQueue = async (financingId: number) => {
    try {
      const result: AxiosResponse<void> = await contractQueueApi.createContractQueue(financingId);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getContractQueueExists = async (financingId: number): Promise<ContractQueueExists> => {
    try {
      const result: AxiosResponse<ContractQueueExists> = await contractQueueApi.getContractQueueExists(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    createContractQueue,
    getContractQueueExists,
  };
};

export default contractQueueService();
