import { SVGProps } from 'react';
import theme from '../themes/default';

export const StrokeSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08958 0.410826C7.41502 0.736263 7.41502 1.2639 7.08958 1.58934L2.67884 6.00008L7.08958 10.4108C7.41502 10.7363 7.41502 11.2639 7.08958 11.5893C6.76414 11.9148 6.23651 11.9148 5.91107 11.5893L0.91107 6.58934C0.585633 6.2639 0.585633 5.73626 0.91107 5.41083L5.91107 0.410826C6.23651 0.0853888 6.76414 0.0853888 7.08958 0.410826Z"
      fill={theme.color.primaryColor}
    />
  </svg>
);
