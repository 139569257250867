import { AxiosResponse } from 'axios';
import { DTIResponse } from 'model/dti';
import { FaceMatchAndOCRAdminResponse } from 'model/face-match-and-ocr';
import { MeuCrediarioResponse, MultiCreditResponse } from 'model/multi-credit-response';
import { api } from './api';
import { Client } from 'model/client';

export const clientDataApi = () => {
  const baseUrl = '/clients';

  const getClientSummary = (clientId?: number): Promise<AxiosResponse<Client>> => {
    return api.get<Client>(`${baseUrl}/${clientId}/summary`);
  };

  const getFaceMatchAndOCRAdmin = (clientId?: number): Promise<AxiosResponse<FaceMatchAndOCRAdminResponse>> => {
    return api.get<FaceMatchAndOCRAdminResponse>(`${baseUrl}/${clientId}/document-analysis/admin`);
  };

  const analyzeClientRegistration = (newStatus: any, clientId: number): Promise<AxiosResponse<any>> => {
    return api.patch<any>(`${baseUrl}/${clientId}/analyze-registration`, newStatus);
  };

  const getClientMultiCredit = (clientId?: number): Promise<AxiosResponse<MultiCreditResponse>> => {
    return api.get<MultiCreditResponse>(`/financing/multicredito/client/${clientId}`);
  };

  const getClientDTI = (clientId?: number): Promise<AxiosResponse<DTIResponse>> => {
    return api.get<DTIResponse>(`/clients/${clientId}/dti`);
  };

  const getClientMeuCrediario = (clientId?: number): Promise<AxiosResponse<MeuCrediarioResponse>> => {
    return api.get<MeuCrediarioResponse>(`/meu-crediario/credit-engine/client/${clientId}`);
  };

  return {
    getClientSummary,
    analyzeClientRegistration,
    getFaceMatchAndOCRAdmin,
    getClientMultiCredit,
    getClientDTI,
    getClientMeuCrediario,
  };
};

export default clientDataApi();
