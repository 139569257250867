import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerDocument from 'components/container-document';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, IdentityContainer, Subtitle, Title } from './styles';

interface IIdentificationScreenProps {
  attachmentIdentification?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
  finalStep?: boolean;
}

export const IdentificationScreen: React.FC<IIdentificationScreenProps> = props => {
  const { t } = useTranslation();
  const { files, isLastForm } = useRegisterForm();
  const schema = yup.object({
    attachmentIdentification: yup.mixed(),
  });

  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.IDENTIFICATION_CARD)) {
      setRequiredError(false);
    }
  }, [files]);

  const handleSubmit = () => {
    setIsLoading(true);
    if (!props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD)) {
      props.setActualStep(props.actualStep + 1);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setRequiredError(true);
    }
  };

  return (
    <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
      <Container>
        <Title>{t('documents.identity.title')}</Title>
        <Subtitle>{t('documents.identity.subtitle')}</Subtitle>
        <IdentityContainer>
          <ContainerDocument
            requiredError={requiredError}
            titleDocument="documents.identification"
            editDocument
            nameInput="doc1"
            name="attachmentIdentification"
            type={AttachmentType.IDENTIFICATION_CARD}
            title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
            docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
          />
          <ContainerUploadedDocument editDocument attachmentType={AttachmentType.IDENTIFICATION_CARD} />
        </IdentityContainer>
        <ButtonsContainer>
          {props.actualStep !== 0 ? (
            <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
              <span>{t('global.button.backStep')}</span>
            </Button>
          ) : (
            <div />
          )}
          <EnterCustomButton
            type="submit"
            isLoading={isLoading}
            text={isLastForm() ? t('global.button.addPartner') : t('global.button.nextStep')}
          />
        </ButtonsContainer>
      </Container>
    </ReactHookFormProvider>
  );
};
