import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1350px;
  padding: 100px;
  margin: 0 auto;

  @media (max-width: 576px) {
    padding: 60px;
  }
`;

export const Title = styled.div`
  max-width: 760px;
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 100px auto;
  opacity: 0.9;

  @media (max-width: 576px) {
    font-size: 36px;
    margin: 0 auto 40px auto;
  }
`;

export const Subtitle = styled.div`
  font-size: 29px;
  font-weight: bold;
  margin-top: 90px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.color.gray18};

  @media (max-width: 576px) {
    font-size: 21px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.gray18};
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 35px;
  line-height: 1.4;

  &.italic {
    font-style: italic;
  }

  @media (max-width: 576px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;
