import { truncate } from 'lodash';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Delete from '../../images/close-black.svg';
import { Container, ContainerDataDocument } from './styles';

interface Props {
  editDocument?: boolean;
  attachmentType?: string;
}
export default function ContainerUploadedDocumentMobile({ editDocument, attachmentType }: Props) {
  const { t } = useTranslation();
  const { setFiles, files } = useRegisterForm();

  const handleRemoveFile = (fileName: string) => {
    const filesFiltred = files.filter(file => file.fileName !== fileName);
    setFiles(filesFiltred);
  };

  return (
    <Container>
      {files
        .filter(item => item.type === attachmentType)
        .map((file, key) => (
          <ContainerDataDocument key={key}>
            <a download={file.fileName} href={file.url} target="_blank">
              {file.fileName && truncate(file.fileName, { length: 25 })}
            </a>
            {editDocument && <img src={Delete} onClick={() => handleRemoveFile(file.fileName ?? '')} />}
          </ContainerDataDocument>
        ))}
    </Container>
  );
}
