import Header from 'components/general-components/header';
import MyPaymentsContainer from 'components/simulator-result/my-payments';
import { HeaderVariants } from 'model/enums/header-variants';

interface MyPaymentsProps {}

const MyPayments: React.FC<MyPaymentsProps> = props => {
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <MyPaymentsContainer />
    </>
  );
};

export default MyPayments;
