import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { Partner } from 'model/dashboard';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useTranslation } from 'react-i18next';
import { unMaskedCurrency } from 'shared/util/register-utils';
import * as yup from 'yup';
import { Container } from '../styles';
import { ContainerInputsPartnerIncome } from './inputs-partner-income';

interface IParterDataProps {
  onClickBack: () => void;
  onClickForward: (client?: Client) => void;
  onlyView?: boolean;
}

export const EditFormPartnerIncome: React.FC<IParterDataProps> = props => {
  const { initialPartnerData, setInitialPartnerData } = useRegisterForm();
  const { occupations } = useSelectLists();
  const { t } = useTranslation();

  const handleSubmit = (partner: Partner) => {
    setInitialPartnerData(partner);
    props.onClickForward();
  };

  const schema = yup.object({
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test(
        'test-occupation',
        t('global.errorMessage.invalidOccupation'),
        occupationName => occupations.find(occupation => occupation.name === occupationName) !== undefined
      ),
    income: yup
      .string()
      .min(3, t('global.errorMessage.invalidValue'))
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
  });

  return (
    <Container>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const partnerIncome = unMaskedCurrency(res.income) / 100;
          const occupationId = occupations.find(occupation => occupation.name === res.occupation)?.id;
          const newPartner = {
            ...initialPartnerData,
            occupation: {
              id: occupationId,
              name: res.occupation,
            },
            income: partnerIncome,
          } as Partner;
          handleSubmit(newPartner as Partner);
        }}
      >
        <ContainerInputsPartnerIncome onlyView={props.onlyView} visible onClickBack={props.onClickBack} />
      </ReactHookFormProvider>
    </Container>
  );
};
