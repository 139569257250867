import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { RoundCheckSolidSvg } from 'images/round-check-solid';
import CustomButton from 'components/custom-button';
import { SimulatorInsuranceProps } from '..';
import { useSendSimulation } from 'components/simulator-data/hooks/use-send-simulation';
import ReactLoading from 'react-loading';

export const Desktop = ({ benefits, installmentValue, isLoading }: SimulatorInsuranceProps) => {
  const { t } = useTranslation();
  const { renderButton } = useSendSimulation();
  return (
    <>
      <S.InsuranceInfoContainer>
        <S.InsuranceInstallmentContainer>
          <S.InsuranceTitle>{t('simulatorInsurance.title')}</S.InsuranceTitle>
          {isLoading ? (
            <ReactLoading type="spin" color="#000" height={12} width={12} />
          ) : (
            <S.InstallmentText>
              {t('simulatorInsurance.installment')}
              <b>{installmentValue}</b>
            </S.InstallmentText>
          )}
        </S.InsuranceInstallmentContainer>
        <S.BenefitListContainer>
          {benefits?.map((benefit, index) => (
            <S.BenefitContainer key={index}>
              <RoundCheckSolidSvg />
              <S.BenefitTitleDescription>
                <S.BenefitTitle>{benefit.title}</S.BenefitTitle>
                <S.BenefitDescription>{benefit.description}</S.BenefitDescription>
              </S.BenefitTitleDescription>
            </S.BenefitContainer>
          ))}
        </S.BenefitListContainer>
      </S.InsuranceInfoContainer>
      <S.ButtonsContainer>{renderButton()}</S.ButtonsContainer>
    </>
  );
};
