import CustomButton from 'components/custom-button';
import { ReactComponent as XSvg } from 'images/x.svg';

import CustomCalendar from '../calendar';

import { useTranslation } from 'react-i18next';
import { CalendarContainer, ClosingArea, FilterDateContainer, FilterDateFooter, ModalContainer, Overlay, TitleContainer } from './styles';

interface TableListFiltersProps {
  setInitialDate?: (value: string) => void;
  setEndDate?: (value: string) => void;
  onClickFilterDate: () => void;
  searchName?: string;
  closeModal: () => void;
  showDateFilter: boolean;
}

const TableListFiltersCalendar: React.FC<TableListFiltersProps> = ({
  showDateFilter,
  closeModal,
  setEndDate,
  setInitialDate,
  onClickFilterDate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Overlay />

      <FilterDateContainer>
        <ModalContainer expanded={showDateFilter}>
          <ClosingArea onClick={() => closeModal()}>
            <section>
              <XSvg />
            </section>
          </ClosingArea>
          <TitleContainer>
            <span>{t('global.button.selectDate')}</span>
          </TitleContainer>

          <CalendarContainer>
            <CustomCalendar setInitialDate={setInitialDate} setFinishDate={setEndDate} />
          </CalendarContainer>

          <FilterDateFooter>
            <CustomButton isInvertColor height={48} width="140px" onClick={() => closeModal()}>
              {t('global.button.cancel')}
            </CustomButton>
            <CustomButton
              height={48}
              width="140px"
              onClick={() => {
                onClickFilterDate();
                closeModal();
              }}
            >
              {t('global.button.apply')}
            </CustomButton>
          </FilterDateFooter>
        </ModalContainer>
      </FilterDateContainer>
    </>
  );
};

export default TableListFiltersCalendar;
