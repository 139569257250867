import simulatorResultApi from 'api/simulator-result';
import { AxiosResponse } from 'axios';
import { CreatedDates } from 'model/date';
import HttpStatus from 'model/enums/http-status';
import { IMyContractsRequest, IMyPaymentsRequest } from 'model/simulator-result';

export const simulatorResultService = () => {
  const getContracts = async (
    name?: string,
    size?: number,
    page?: number,
    contractStatus?: string[],
    createdDates?: CreatedDates
  ): Promise<IMyContractsRequest> => {
    try {
      const result: AxiosResponse<IMyContractsRequest> = await simulatorResultApi.getContracts(
        name,
        size,
        page,
        contractStatus,
        createdDates
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getInstallments = async (
    name?: string,
    size?: number,
    page?: number,
    paymentStatus?: string[],
    createdDates?: CreatedDates
  ): Promise<IMyPaymentsRequest> => {
    try {
      const result: AxiosResponse<IMyPaymentsRequest> = await simulatorResultApi.getInstallments(
        name,
        size,
        page,
        paymentStatus,
        createdDates
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return { getContracts, getInstallments };
};

export default simulatorResultService();
