import { useRegisterForm } from 'provider/register-form';
import { useState } from 'react';
import { InstructionsScreen } from './screens/instructions';
import { ResumeScreen } from './screens/resume';
import { RevenueDeclarationScreen } from './screens/revenue-declaration';
import { SocialContractScreen } from './screens/social-contract';

export const FormLegalPersonDocuments = () => {
  const [actualStep, setActualStep] = useState(0);
  const { files } = useRegisterForm();

  const renderSteps = () => {
    switch (actualStep) {
      case 0:
        return <InstructionsScreen actualStep={actualStep} setActualStep={setActualStep} />;
      case 1:
        return (
          <SocialContractScreen actualStep={actualStep} setActualStep={setActualStep} handleVerifyEmptyFields={handleVerifyEmptyFields} />
        );
      case 2:
        return (
          <RevenueDeclarationScreen
            actualStep={actualStep}
            setActualStep={setActualStep}
            handleVerifyEmptyFields={handleVerifyEmptyFields}
          />
        );
      case 3:
        return <ResumeScreen actualStep={actualStep} setActualStep={setActualStep} />;
      default:
        return <></>;
    }
  };

  const handleVerifyEmptyFields = (field: string) => {
    const hasFiles = files.find(file => file.type === field);
    if (hasFiles) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '31px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px', width: '100%' }}>{renderSteps()}</div>
    </div>
  );
};
