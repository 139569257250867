import Header from 'components/general-components/header';
import Attention from 'images/attention.svg';
import Sent from 'images/sent.svg';
import { UploadingSvg } from 'images/uploading';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledScreenContainer } from '../../style';
import { AttentionContainer, Button, ContainerDesktop, SubTitleDesktop, TitleDesktop } from '../styles';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <StyledScreenContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <ContainerDesktop>
        <div className="header">
          <img src={Sent} width="45" />
          <TitleDesktop>{t('registerSent.title')}</TitleDesktop>
        </div>
        <div className="img-container">
          <UploadingSvg />
        </div>
        <AttentionContainer>
          <img src={Attention} />
          <span>{t('registerSent.attentionMessage')}</span>
        </AttentionContainer>
        <SubTitleDesktop>{t('registerSent.subTitle')}</SubTitleDesktop>
        <Button onClick={() => history.push('/login')}>
          <span>{t('global.button.login')}</span>
        </Button>
      </ContainerDesktop>
    </StyledScreenContainer>
  );
};

export default Desktop;
