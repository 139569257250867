import StyledMobileFormContainer from 'components/styled-mobile-form-container';
import StyledMobileRegisterHeader from 'components/styled-mobile-register-header';
import StyledMobileSideMenu from 'components/styled-mobile-side-menu';
import { useRegisterForm } from 'provider/register-form';
import { StyledMobileScreenContainer } from '../style';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = props => {
  const { showMobileStepsModal } = useRegisterForm();
  return (
    <StyledMobileScreenContainer>
      {showMobileStepsModal ? (
        <StyledMobileSideMenu />
      ) : (
        <>
          <StyledMobileRegisterHeader />
          <StyledMobileFormContainer />
        </>
      )}
    </StyledMobileScreenContainer>
  );
};

export default Mobile;
