import Header from 'components/general-components/header';
import SimulatorFinancing from 'components/simulator-financing';
import { HeaderVariants } from 'model/enums/header-variants';
import { useParams } from 'react-router-dom';
import { Container, PageContainer } from './styles';

export const AddNewAnalysis = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <SimulatorFinancing clientId={Number(id)} />
      </Container>
    </PageContainer>
  );
};
