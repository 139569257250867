import { EditFormProvider } from 'provider/edit-form';
import { SelectListsProvider } from 'provider/select-list';
import Desktop from './layout/desktop';

interface IEditProfileProps {}

const EditProfileAdmin: React.FC<IEditProfileProps> = props => {
  return (
    <SelectListsProvider>
      <EditFormProvider>
        <Desktop />
      </EditFormProvider>
    </SelectListsProvider>
  );
};

export default EditProfileAdmin;
