import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Client } from 'model/client';
import { Partner } from 'model/dashboard';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container } from '../styles';
import { ContainerInputsPartnerData } from './inputs-partner-data';

interface IParterDataProps {
  onClickBack: () => void;
  onClickForward: (client?: Client) => void;
  onlyView?: boolean;
}

export const EditFormPartnerData: React.FC<IParterDataProps> = props => {
  const { initialPartnerData, setInitialPartnerData } = useRegisterForm();
  const { t } = useTranslation();

  const handleSubmit = (partner: Partner) => {
    setInitialPartnerData(partner);
    props.onClickForward();
  };

  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
  });

  return (
    <Container>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const partnerBirth = format(res.birth, 'yyyy-MM-dd');
          const newPartner = {
            ...initialPartnerData,
            phone: res.phone,
            email: res.email,
            name: res.name,
            cpf: res.cpf,
            rg: res.rg,
            birth: partnerBirth,
          };
          handleSubmit(newPartner as Partner);
        }}
      >
        <ContainerInputsPartnerData onlyView={props.onlyView} visible onClickBack={props.onClickBack} />
      </ReactHookFormProvider>
    </Container>
  );
};
