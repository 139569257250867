import InputText from 'components/styled-input';
import { Mask } from 'model/enums/mask-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { Container, InputBox } from './styles';

import { Client } from 'model/signatures';
import { StyledSection } from '../tabOCR/styles';

interface IProps {
  clientData: Client;
}

export const TabGeneralData = ({ clientData }: IProps) => {
  const { t } = useTranslation();

  const methods = useForm({ mode: 'onSubmit' });

  return (
    <Container>
      <FormProvider {...methods}>
        <InputBox>
          <StyledSection>
            <InputText
              disabled={true}
              name="name"
              label={t('register.inputs.name')}
              placeholder={t('register.placeholder.name')}
              defaultValue={clientData?.physicalPerson?.name}
            />
            <InputText
              disabled={true}
              name="birth"
              label={t('register.inputs.birthDate')}
              placeholder={t('register.placeholder.birthDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                clientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(clientData?.physicalPerson?.birth.toString()) : ''
              }
            />
            <InputText
              name="phone"
              disabled={true}
              label={t('register.inputs.phone')}
              placeholder={t('register.placeholder.phone')}
              mask={Mask.PHONE}
              maxLength={15}
              defaultValue={clientData?.phone}
            />
            <InputText
              name="cpf"
              disabled={true}
              label={t('register.inputs.cpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              maxLength={14}
              defaultValue={clientData?.physicalPerson?.cpf}
            />
            <InputText
              disabled={true}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={clientData?.email}
            />
          </StyledSection>
        </InputBox>
      </FormProvider>
    </Container>
  );
};
