import { AxiosResponse } from 'axios';
import { api } from './api';

const documentOcrApi = () => {
  const approveOcr = (ocrId: number): Promise<AxiosResponse<void>> => {
    return api.post(`/document-ocr/${ocrId}/approve`);
  };

  return {
    approveOcr,
  };
};

export default documentOcrApi();
