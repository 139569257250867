import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_ISO_FORMAT } from 'config/constants';
import i18n from 'config/i18n';
import { differenceInYears, format, parse } from 'date-fns';
import { isNil } from 'lodash';
import { Attachment } from 'model/attachment';
import { ContestationStatus } from 'model/credit-card';
import { ContractStatus } from 'model/enums/contract-status';
import FinancingStatusType from 'model/enums/financing-status-type';
import { OcrAttachmentType } from 'model/enums/ocr-type';
import { IOcrAttachments } from 'model/ocr';

export const convertCurrencyStringToNumber = (value: string): number | null => {
  let floatNumber = parseFloat(value);
  if (!isNaN(floatNumber)) {
    return floatNumber;
  }
  const valueWithoutMask = value.replace(/[^\d,.]/g, '').replace(/\./g, '');

  const valueWithDot = valueWithoutMask.replace(',', '.');

  floatNumber = parseFloat(valueWithDot);

  if (!isNaN(floatNumber)) {
    return floatNumber;
  } else {
    return null;
  }
};
