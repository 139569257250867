import { useEffect } from 'react';
import clientService from 'services/client-service';
import { useSelfieContext } from './provider';

export const SelfiePage = () => {
  const { checkMobileAndSelfieStep } = useSelfieContext();

  useEffect(() => {
    clientService.generateSelfieKey().then(response => {
      if (checkMobileAndSelfieStep) {
        checkMobileAndSelfieStep(response.selfieKey);
      }
    });
  }, []);

  return <></>;
};
