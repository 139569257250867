import DynamicDesktopEditForm from 'components/dynamic-desktop-edit-form';
import DynamicDesktopLegalPersonEditForm from 'components/dynamic-legal-person-desktop-edit-form';
import Header from 'components/general-components/header';
import Stroke from 'images/stroke.svg';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEditForm } from 'provider/edit-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContainerDesktop, StyledHeader, TitleDesktop } from '../styles';
import { partnerPath } from 'model/edit-paths';
import { legalPersonPath } from 'model/edit-paths';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { steps, actualStep, setIsAdmin, initialClientData, currentEditStep, setEditSteps, setCurrentEditStep } = useEditForm();

  const [isLegalPerson, setIsLegalPerson] = useState(false);

  useEffect(() => {
    if (initialClientData?.legalPerson?.id) {
      setIsLegalPerson(true);
    }
  }, [initialClientData]);

  useEffect(() => {
    setIsAdmin(true);
  }, []);

  const findTitle = () => {
    return steps[actualStep]?.systemStep?.step ?? '';
  };

  const onGoBack = () => {
    const isEditingPartner = partnerPath.some(step => step.step === currentEditStep?.step);
    if (isEditingPartner) {
      setEditSteps(legalPersonPath);
      setCurrentEditStep(legalPersonPath[0]);
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <ContainerDesktop>
        <div>
          <StyledHeader>
            <div className="title">
              <img src={Stroke} onClick={onGoBack} />
              <TitleDesktop>{t('profile.editData')}</TitleDesktop>
            </div>
          </StyledHeader>
          {isLegalPerson ? (
            <DynamicDesktopLegalPersonEditForm onlyView={false} />
          ) : (
            <DynamicDesktopEditForm type={findTitle()} onlyView={false} />
          )}
        </div>
      </ContainerDesktop>
    </>
  );
};

export default Desktop;
