import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OrganizationSubsidiary } from 'model/internals';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { getSubsidiariesRequest } from 'reducer/organization-subsidiaries/actions';

export const useOrganizationSubsidiaries = () => {
  const dispatch = useDispatch();
  const organizationSubsidiaries = useSelector((state: IRootState) => state.organizationSubsidiary.organizationSubsidiaries);
  const status = useSelector((state: IRootState) => state.organizationSubsidiary.GetSubsidiariesStatus);

  useEffect(() => {
    if (organizationSubsidiaries.length === 0 && status === HttpRequestStatus.NOOP) {
      dispatch(getSubsidiariesRequest());
    }
  }, [organizationSubsidiaries]);

  const findSubsidiaryByName = (name?: string): OrganizationSubsidiaries | undefined => {
    if (!name) return;
    return organizationSubsidiaries.find(subsidiary => subsidiary.name.toUpperCase() === name.toUpperCase());
  };

  return {
    organizationSubsidiaries,
    findSubsidiaryByName,
  };
};
