import ContainerUploadedDocument from 'components/container-uploaded-document';
import ContainerUploadedDocumentMobile from 'components/container-uploaded-document-mobile';
import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Button, ButtonsContainer } from 'components/register-forms-mobile/styles';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Container, IdentityContainer, Title } from './styles';

interface IResumeScreenProps {
  attachmentResume?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { onClickNext, files, isLastForm, isLoading } = useRegisterForm();
  const schema = yup.object({
    attachmentResume: yup.mixed(),
  });
  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ attachments: files } as Client)}>
      <Container>
        <Title style={{ margin: '0px 16px' }}>{t('documents.upload')}</Title>
        <IdentityContainer>
          <Title style={{ marginTop: '5px' }}>{t('documents.identity.title')}</Title>
          <ContainerUploadedDocumentMobile attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          <Title style={{ marginTop: '10px' }}>{t('documents.proofs.incomeProof.title')}</Title>
          <ContainerUploadedDocumentMobile attachmentType={AttachmentType.PROOF_INCOME} />
          <Title style={{ marginTop: '10px' }}>{t('documents.proofs.residenceProof.title')}</Title>
          <ContainerUploadedDocumentMobile attachmentType={AttachmentType.PROOF_RESIDENCE} />
        </IdentityContainer>
        <ButtonsContainer>
          {props.actualStep !== 0 ? (
            <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
              <span>{t('global.button.backStep')}</span>
            </Button>
          ) : (
            <div />
          )}
          <EnterCustomButton
            type="submit"
            isLoading={isLoading}
            text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
          />
        </ButtonsContainer>
      </Container>
    </ReactHookFormProvider>
  );
};
