import { Authority } from 'model/enums/authority';
import { FinancingSimpleSimulation } from 'model/financing';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import Desktop from './layout/desktop';
import { useLocation } from 'react-router-dom';

interface StateType {
  sendSimulator?: FinancingSimpleSimulation;
  id?: number;
}

export interface LocationType {
  state: StateType;
}

interface FoundedDataContainerProps {
  location?: LocationType;
}

const AdminFoundedDataContainer: React.FC<FoundedDataContainerProps> = () => {
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  const isAdmin = authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN);
  const location: LocationType = useLocation();
  const clientId = location?.state?.id;

  return <Desktop isAdmin={isAdmin} clientId={clientId ?? 0} />;
};

export default AdminFoundedDataContainer;
