import Stepper from 'components/stepper';
import { Logo } from 'images/custom-organization';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

interface IStyledDesktopSideMenuProps {}

const StyledDesktopSideMenu: React.FC<IStyledDesktopSideMenuProps> = props => {
  const { t } = useTranslation();
  return (
    <Container>
      <Stepper activeStep={0} />
    </Container>
  );
};

export default StyledDesktopSideMenu;
