import UseResponsiveContext from 'context/use-responsive-context';
import { Authority } from 'model/enums/authority';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface StateType {
  sendSimulator?: FinancingSimpleSimulation;
}

export interface LocationType {
  state: StateType;
}

interface FoundedDataContainerProps {
  location?: LocationType;
}

const FoundedDataContainer: React.FC<FoundedDataContainerProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);
  const history = useHistory();
  const location: LocationType = useLocation();

  const authorities = useSelector((state: IRootState) => state?.authentication?.account?.authorities);

  const sendSimulator = location?.state?.sendSimulator;

  useEffect(() => {
    if (!sendSimulator) {
      try {
        history.push(RoutePath.HOME);
      } catch (error) {
        console.error('Error redirecting to HOME:', error);
      }
    }
  }, [sendSimulator, history]);

  return !isMobile ? (
    <Desktop sendSimulator={sendSimulator} isAdmin={authorities?.includes(Authority.ROLE_ADMIN)} />
  ) : (
    <Mobile sendSimulator={sendSimulator} isAdmin={authorities?.includes(Authority.ROLE_ADMIN)} />
  );
};

export default FoundedDataContainer;
