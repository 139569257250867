import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBr from 'i18n/custom-organization/pt-br.json';
import { DEFAULT_LANGUAGE } from './constants';

const resources = {
  'pt-BR': {
    translation: ptBr,
  },
};

const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  debug: true,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
