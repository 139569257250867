import { CardList } from 'components/simulator-result/card-list';
import { InputContainer, ScreenContent, StyledIcon } from 'components/simulator-result/styled';
import UseResponsiveContext from 'context/use-responsive-context';
import { StyledTitle } from 'features/simulator-result/my-credits/styles';
import { RoutePath } from 'model/enums/route-path';
import { IMyContracts } from 'model/simulator-result';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';

interface MobileProps {
  filterTable: IMyContracts[];
  setSearchByIdentification: (value: string) => void;
  page: string;
  setPage: (value: string) => void;
  totalPages: number;
  numberedPage: number;
  isLoading: boolean;
  searchByIdentification: string;
}

const Mobile: React.FC<MobileProps> = ({
  filterTable,
  setSearchByIdentification,
  searchByIdentification,
  setPage,
  page,
  totalPages,
  numberedPage,
  isLoading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const { isMobile, isTablet, isMidTablet } = useContext(UseResponsiveContext);

  const charactersLimitPoints = () => {
    if (isMobile) return 14;

    if (isMidTablet) return 32;

    if (isTablet) return 68;
  };

  const cardData: { [key: string]: string | number }[] = filterTable.map((contract, idx) => ({
    idNumber: contract?.financing?.identification ?? '',
    text: StringUtils.characterLimit(contract?.attachment?.fileName, charactersLimitPoints()),
    status: contract.status ?? '',
    id: contract?.id ?? 0,
  }));

  return (
    <ScreenContent>
      <StyledTitle style={{ margin: '24px 0px 16px 0px' }}>{t('admin.dashboard.contracts')}</StyledTitle>
      <InputContainer>
        <StyledIcon>
          <BiSearch color={theme.color.primaryColor} size={24} />
        </StyledIcon>
        <input
          type="text"
          placeholder={t('admin.dashboard.search')}
          value={searchByIdentification}
          onChange={e => setSearchByIdentification(e.target.value)}
        />
      </InputContainer>
      <CardList
        cardData={cardData}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        numberedPage={numberedPage}
        isLoading={isLoading}
        redirect={RoutePath.CONTRACTS}
      />
    </ScreenContent>
  );
};

export default Mobile;
