import OrganizationParametersContext from 'context/organization';
import { useRedirect } from 'hooks/use-redirect/use-redirect';
import { FormProperties } from 'model/enums/form-properties';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { resetStatus } from 'reducer/system-parameters/actions';

export const useSystemParameters = () => {
  const { systemParameterStatus } = useSelector((state: IRootState) => state.systemParameters);
  const { systemParameters } = useContext(OrganizationParametersContext);
  const dispatch = useDispatch();

  const { redirect } = useRedirect();

  const isPhysicalPerson = systemParameters?.fieldType?.value === FormProperties.CPF.toUpperCase();

  useEffect(() => {
    if (systemParameterStatus === HttpRequestStatus.ERROR) {
      dispatch(resetStatus());
      redirect('/problem');
    }
  }, [systemParameterStatus]);

  return {
    systemParameters,
    isPhysicalPerson,
  };
};
