import { yupResolver } from '@hookform/resolvers/yup';
import EnterCustomButton from 'components/enter-custom-button';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { useDetailEvolveGuarantee } from 'hooks/use-detail-evolve-guarantee';
import { useRootDispatch } from 'hooks/use-dispatch/use-dispatch';
import useFormatPaymentTypeToShow from 'hooks/use-format-payment-type-to-show';
import { Client } from 'model/client';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Mask } from 'model/enums/mask-types';
import { BankAccountInFinancing, Contract as ContractModel, Financing, PaymentType } from 'model/financing';
import { IfirstDueDateInDays, SimulatorParameters } from 'model/landing-page';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiEdit2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import { createEvolveGuaranteeRequest } from 'reducer/evolve-guarantee/create-evolve-guarantee/actions';
import { detailEvolveGuaranteeRequest } from 'reducer/evolve-guarantee/detail-evolve-guarantee/actions';
import { updateEvolveGuaranteeRequest } from 'reducer/evolve-guarantee/update-evolve-guarantee/actions';
import clientService from 'services/client-service';
import financingService from 'services/financing-service';
import landingPageService from 'services/landing-page-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

import InputTextArea from 'components/styled-input-textarea';
import { truncate } from 'lodash';
import {
  BackGround,
  Contract,
  ContractButtonContainer,
  ContractContent,
  ContractData,
  ContractHeader,
  ContractInfoContainer,
  ContractSection,
  CreditInformationContainer,
  CreditInformationSection,
  CreditInformationTitle,
  FileNameBox,
  HighlightInfo,
  InfoBox,
  InputEditSection,
  InputSection,
  Labelinput,
  Payment,
  PaymentInfoContainer,
  PaymentSection,
  SaveEditButtons,
  SectionTitle,
  Sign,
  SignatoriesBox,
  SignatoryContent,
  TextAreaContainer,
  Unsigned,
} from './styles';

interface FinancingCode {
  id?: string;
  ccb?: string;
}

interface Schema {
  choosedValue: string;
  approvedValue: string;
  installmentValue: string;
  installmentsTotal: string;
  loanGracePeriod: string;
  financingTaxInitial: string;
  interestTaxReleased: string;
  financingTax: string;
  cet: string;
  masterRetentionValue: string;
  evolveGuarantee: string;
}

interface Props {
  contracts: ContractModel[];
}

const AnalysisDatails = ({ contracts }: Props) => {
  const [financing, setFinancing] = useState<Financing>();
  const [disabledToEdit, setDisabledToEdit] = useState(true);
  const { guarantee, status } = useDetailEvolveGuarantee();
  const [loanGracePeriodOptions, setLoanGracePeriodOptions] = useState<IfirstDueDateInDays[]>([]);
  const [pageName, setPageName] = useState('');
  const [chosenLoanGracePeriod, setChosenLoanGracePeriod] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [installmentRange, setInstallmentRange] = useState<{ min: number; max: number } | null>(null);
  const [hasError, setHasError] = useState(false);
  const [payments, setPayments] = useState<PaymentType | null>(null);
  const [simulatorOtherData, setSimulatorOtherData] = useState<SimulatorParameters>();
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInFinancing>();

  const [clientData, setClientData] = useState<Client>();
  const dispatch = useRootDispatch();
  const { color } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<{ prevPath: string }>();
  const { financingId } = useParams<{ financingId: string }>();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { formatPaymentTypeToShow } = useFormatPaymentTypeToShow();
  const [currentSchema, setCurrentSchema] = useState<Schema>();

  const schema = yup.object().shape({
    choosedValue: yup.string(),
    approvedValue: yup.string(),
    installmentValue: yup.string(),
    installmentsTotal: yup.string(),
    loanGracePeriod: yup.string(),
    financingTaxInitial: yup.string(),
    financingTax: yup.string(),
    cet: yup.string(),
  });

  const methods = useForm<Schema>({
    resolver: yupResolver(schema),
  });

  const isFinancingCanBeEdited =
    financing?.financingStatus === FinancingStatusType.APPROVED ||
    financing?.financingStatus === FinancingStatusType.PENDING_SIGN ||
    financing?.financingStatus === FinancingStatusType.PENDING_PAYMENT ||
    financing?.financingStatus === FinancingStatusType.FINISHED ||
    financing?.financingStatus === FinancingStatusType.CANCELLED;

  useEffect(() => {
    if (isFinancingCanBeEdited) {
      setDisabledToEdit(true);
    }
  }, []);

  useEffect(() => {}, [methods]);

  useEffect(() => {
    if (financingId) {
      getFinancingById();
      dispatch(detailEvolveGuaranteeRequest(Number(financingId)));
      getClientData(Number(financing?.client?.id));
    }
    setCurrentSchema(methods.getValues());
  }, [financingId, financing?.client?.id]);

  useEffect(() => {
    getBankAccount();
    getPaymentsInfo();
    getOrganizationInstallmentsRange();
    landingPageService.getSimulatorParameters().then(data => {
      setSimulatorOtherData(data);
    });
  }, []);

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setLoading(false);
        setHasError(false);
      }, 200);
    }
  }, [hasError]);

  const getPaymentsInfo = async () => {
    if (financingId) {
      const res = await financingService.getPaymentProgress(Number(financingId));
      setPayments(res);
    }
  };

  const getClientData = (clientId: number) => {
    clientService.getAllClientData(clientId).then(setClientData);
  };

  const getBankAccount = async () => {
    if (financingId) {
      const backAccount = await financingService.getFinancingBankAccount(Number(financingId));
      setBankAccountInfos(backAccount.data);
    }
  };

  const getOrganizationInstallmentsRange = async () => {
    const organization = await landingPageService.getSimulatorParameters();
    setInstallmentRange({ min: organization.minInstallment, max: organization.maxInstallment });
    Array.isArray(organization.firstDueDateInDays) && setLoanGracePeriodOptions(organization.firstDueDateInDays);
  };

  const getFinancingById = async () => {
    const financingRes = await financingService.getFinancingById(Number(financingId));
    setFinancing(financingRes);
  };
  const saveEdit = async (values: Schema) => {
    const interestTaxReleased = Number(values.interestTaxReleased.replace('% a.m', '').replace(',', '.').trim());
    const masterRetentionValue =
      values?.masterRetentionValue != null ? Number(values.masterRetentionValue?.replace('%', '').replace(',', '.').trim()) : null;
    const approvedValue = Number(StringUtils.removeMoneyFormat(values.approvedValue));
    const firstDueDateInDaysId = Number(chosenLoanGracePeriod);
    const approvedTax = interestTaxReleased > 0 ? interestTaxReleased : Number(values.financingTaxInitial.replace('% a.m', '').trim());
    const installmentsTotal = Number(values.installmentsTotal.replace('parcelas', '').trim());
    const evolveGuarantee = values.evolveGuarantee;
    if (installmentsTotal === 0) {
      methods.setValue('installmentsTotal', `${installmentsTotal} parcelas`);
    }

    if (approvedTax === 0) {
      setHasError(true);
      setLoading(true);
      return methods.setError('financingTaxInitial', { message: t('global.errorMessage.income') });
    }

    setLoading(true);
    if (financing?.id) {
      const payload: {
        id: number;
        approvedValue?: number;
        approvedTax: number;
        installmentsTotal?: number;
        firstDueDateInDaysId?: number;
        retentionPercentage?: number;
      } = {
        id: financing.id,
        approvedTax,
        ...(installmentsTotal !== 0 && { installmentsTotal }),
        ...(approvedValue !== 0 && { approvedValue }),
        ...(firstDueDateInDaysId && { firstDueDateInDaysId }),
        ...(masterRetentionValue !== null && { retentionPercentage: masterRetentionValue }),
      };
      await financingService.updateAdminFinancing(financing.id, payload);

      if (evolveGuarantee) {
        const createGuarantee = {
          id: financing.id,
          guarantee: evolveGuarantee,
        };

        const updateGuarantee = {
          id: guarantee?.id,
          guarantee: evolveGuarantee,
        };

        guarantee?.id ? dispatch(updateEvolveGuaranteeRequest(updateGuarantee)) : dispatch(createEvolveGuaranteeRequest(createGuarantee));
      }

      setLoading(false);
      getFinancingById();
      setDisabledToEdit(true);
    }
  };

  const handleDisabledToEdit = () => {
    if (!isFinancingCanBeEdited) {
      setDisabledToEdit(false);
    }
  };

  return (
    <BackGround>
      <FormProvider {...methods}>
        <CreditInformationContainer>
          <InfoBox>
            <CreditInformationSection>
              <HighlightInfo>
                <span>{t('admin.procedureScreen.clientName')}</span>
                <span>{truncate(clientData?.physicalPerson?.name, { length: 20 })}</span>
              </HighlightInfo>
              <InputSection>
                <InputText
                  name="choosedValue"
                  label={t('admin.customerAnalysis.tabDetails.choosedValue')}
                  disabled
                  defaultValue={StringUtils.moneyMaskPtbr(financing?.choosedValue ?? 0)}
                  width={100}
                />
              </InputSection>
              <InputSection onClick={() => handleDisabledToEdit()}>
                <Labelinput>
                  <section>
                    <span>{'Primeiro Vencimento'}</span>
                    <SearchSelectInput
                      name="loanGracePeriod"
                      placeholder={t('register.placeholder.ownCar')}
                      options={loanGracePeriodOptions
                        .sort((a, b) => a.days - b.days)
                        .map(item => ({
                          label: `${item.days} dias`,
                          value: item.id.toString(),
                        }))}
                      defaultOptions={loanGracePeriodOptions
                        .sort((a, b) => a.days - b.days)
                        .map(item => ({
                          label: `${item.days} dias`,
                          value: item.id.toString(),
                        }))}
                      defaultValue={t('admin.customerAnalysis.tabDetails.loanGracePeriodDays', {
                        days: financing?.firstDueDateInDays ? financing.firstDueDateInDays : '30',
                      })}
                      handleChange={values => {
                        setChosenLoanGracePeriod(values.value ?? '0');
                      }}
                      disabled={disabledToEdit}
                      onBlur={() => {
                        const value = methods.getValues('loanGracePeriod');
                        methods.setValue(
                          'loanGracePeriod',
                          t('admin.customerAnalysis.tabDetails.loanGracePeriodDays', {
                            days: financing?.firstDueDateInDays ? financing.firstDueDateInDays : '30',
                          })
                        );
                        setDisabledToEdit(true);
                      }}
                    />
                  </section>
                </Labelinput>
              </InputSection>
            </CreditInformationSection>

            <CreditInformationSection>
              <HighlightInfo>
                <span>{t('admin.procedureScreen.registerStatus')}</span>
                <span>{t(`enum.filterAllStatus.${clientData?.registrationStatus}`)}</span>
              </HighlightInfo>
              <InputSection>
                <InputText
                  name="cet"
                  label={t('admin.customerAnalysis.tabDetails.cet')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.monthlyTotalEffectiveCost ?? 0 })}
                  width={100}
                />
              </InputSection>
              <InputEditSection onClick={() => handleDisabledToEdit()}>
                <InputText
                  name="approvedValue"
                  label={t('admin.customerAnalysis.tabDetails.approvedValue')}
                  disabled={disabledToEdit}
                  defaultValue={StringUtils.moneyMaskPtbr(financing?.approvedValue ?? 0)}
                  mask={disabledToEdit ? Mask.DEFAULT : Mask.NUMBERS}
                  onBlur={() => {
                    const value = methods.getValues('approvedValue');
                    methods.setValue('approvedValue', StringUtils.moneyMaskPtbr(Number(value)));
                    setDisabledToEdit(false);
                  }}
                  onFocus={() => {
                    const value = methods.getValues('approvedValue');
                    methods.setValue('approvedValue', StringUtils.removeMoneyFormat(value));
                  }}
                  rightIcon={!isFinancingCanBeEdited ? <FiEdit2 size={18} color={color.primaryColor} /> : undefined}
                  activated={!disabledToEdit}
                  width={100}
                />
              </InputEditSection>
            </CreditInformationSection>

            <CreditInformationSection>
              <HighlightInfo>
                <span>{t('admin.procedureScreen.paymentFor')}</span>
                {bankAccountInfos && <span>{formatPaymentTypeToShow(bankAccountInfos.financingPaymentType)}</span>}
              </HighlightInfo>
              <InputSection>
                <InputText
                  name="financingTaxInitial"
                  label={t('admin.customerAnalysis.tabDetails.financingTaxInitial')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.financingTax ?? 0 })}
                  width={100}
                />
              </InputSection>
              <InputEditSection onClick={() => handleDisabledToEdit()}>
                <InputText
                  name="interestTaxReleased"
                  mask={disabledToEdit ? Mask.DEFAULT : Mask.PERCENTAGE}
                  label={t('admin.customerAnalysis.tabDetails.interestTaxReleased')}
                  disabled={disabledToEdit}
                  defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.approvedTax ?? 0 })}
                  onBlur={() => {
                    const value = methods.getValues('interestTaxReleased');
                    methods.setValue(
                      'interestTaxReleased',
                      t('admin.customerAnalysis.tabDetails.tax', {
                        tax: value !== '' ? value : financing?.approvedTax ?? 0,
                      })
                    );
                    setDisabledToEdit(true);
                  }}
                  onFocus={() => {
                    const value = methods.getValues('interestTaxReleased');
                    methods.setValue('interestTaxReleased', value.replace('% a.m', '').trim());
                  }}
                  rightIcon={!isFinancingCanBeEdited ? <FiEdit2 size={18} color={color.primaryColor} /> : undefined}
                  activated={!disabledToEdit}
                  width={100}
                />
              </InputEditSection>
            </CreditInformationSection>

            <CreditInformationSection>
              <HighlightInfo>
                <span>Conta</span>
                <span>
                  {bankAccountInfos &&
                  bankAccountInfos.bankAccount &&
                  bankAccountInfos.bankAccount.bankAccountNumber &&
                  bankAccountInfos.bankAccount.bankAccountDigit
                    ? `${bankAccountInfos.bankAccount.bankAccountNumber}-${bankAccountInfos.bankAccount.bankAccountDigit}`
                    : '...'}
                </span>
              </HighlightInfo>
              <InputSection>
                <InputText
                  name="financingTax"
                  label={t('admin.customerAnalysis.tabDetails.CCBFees')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.monthly_tax ?? financing?.monthlyTax ?? 0 })}
                  width={100}
                />
              </InputSection>

              <InputEditSection onClick={() => handleDisabledToEdit()}>
                <InputText
                  name="masterRetentionValue"
                  mask={disabledToEdit ? Mask.DEFAULT : Mask.PERCENTAGE}
                  label={t('admin.customerAnalysis.tabDetails.masterRetentionValue')}
                  disabled={disabledToEdit}
                  defaultValue={t('admin.customerAnalysis.tabDetails.percentageRetentionRate', {
                    retentionRate: financing?.retentionPercentage ?? 0,
                  })}
                  onBlur={() => {
                    const value = methods.getValues('masterRetentionValue');
                    methods.setValue(
                      'masterRetentionValue',
                      t('admin.customerAnalysis.tabDetails.percentageRetentionRate', {
                        retentionRate: value !== '' ? value : financing?.retentionPercentage ?? 0,
                      })
                    );
                    setDisabledToEdit(true);
                  }}
                  onFocus={() => {
                    const value = methods.getValues('masterRetentionValue');
                    methods.setValue('masterRetentionValue', value.replace('%', '').trim());
                  }}
                  rightIcon={!isFinancingCanBeEdited ? <FiEdit2 size={18} color={color.primaryColor} /> : undefined}
                  activated={!disabledToEdit}
                  width={100}
                />
              </InputEditSection>
            </CreditInformationSection>
          </InfoBox>
          <TextAreaContainer onClick={() => handleDisabledToEdit()}>
            <InputTextArea
              name="evolveGuarantee"
              mask={Mask.DEFAULT}
              label={t('admin.customerAnalysis.tabDetails.evolveGuarantee')}
              disabled={disabledToEdit}
              defaultValue={guarantee?.guarantee ?? ''}
              onBlur={() => {
                const value = methods.getValues('evolveGuarantee');
                methods.setValue('evolveGuarantee', value !== '' ? value : guarantee?.guarantee ?? '');
                setDisabledToEdit(true);
              }}
              onFocus={() => {
                const value = methods.getValues('evolveGuarantee');
                methods.setValue('evolveGuarantee', value !== '' ? value : '');
              }}
              rightIcon={!isFinancingCanBeEdited ? <FiEdit2 size={18} color={color.primaryColor} /> : undefined}
              activated={!disabledToEdit}
              width={955}
            />
          </TextAreaContainer>
        </CreditInformationContainer>

        {!isFinancingCanBeEdited && (
          <SaveEditButtons>
            <EnterCustomButton onClick={() => methods.reset(currentSchema)} isInvertColor isLoading={false} text="Cancelar" />
            <EnterCustomButton
              onClick={() => {
                methods.handleSubmit(saveEdit)();
              }}
              isLoading={false}
              text="Salvar alterações"
            />
          </SaveEditButtons>
        )}

        <Payment>
          <SectionTitle>
            <span>Pagamento</span>
          </SectionTitle>
          <PaymentInfoContainer>
            <PaymentSection>
              <InputSection>
                <InputText
                  name="totalValue"
                  label={t('admin.customerAnalysis.tabPayments.totalValue')}
                  disabled
                  defaultValue={StringUtils.moneyMaskPtbr(payments?.payment?.totalValue ?? 0)}
                  flexWidth
                />
              </InputSection>
              <InputSection>
                <InputText
                  name="fees"
                  label={t('admin.customerAnalysis.tabPayments.fees')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabPayments.valueFees', { fees: financing?.financingTax ?? 0 })}
                  flexWidth
                />
              </InputSection>
            </PaymentSection>

            <PaymentSection>
              <InputSection>
                <InputText
                  name="installmentValue"
                  label={t('admin.customerAnalysis.tabDetails.installmentValue')}
                  disabled
                  defaultValue={StringUtils.moneyMaskPtbr(financing?.installmentValue ?? 0)}
                  width={100}
                />
              </InputSection>
              <InputSection>
                <InputText
                  name="cet"
                  label={t('admin.customerAnalysis.tabPayments.cetMonth')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabPayments.valueCet', { cet: financing?.monthlyTotalEffectiveCost ?? 0 })}
                  flexWidth
                />
              </InputSection>
            </PaymentSection>

            <PaymentSection>
              <InputEditSection onClick={() => handleDisabledToEdit()}>
                <InputText
                  name="installmentsTotal"
                  label={t('admin.customerAnalysis.tabDetails.installmentsTotal')}
                  disabled={disabledToEdit}
                  defaultValue={t('admin.customerAnalysis.tabDetails.installment', { installment: financing?.installmentsTotal ?? 0 })}
                  mask={disabledToEdit ? Mask.DEFAULT : Mask.NUMBERS}
                  onBlur={() => {
                    const value = methods.getValues('installmentsTotal');
                    methods.setValue(
                      'installmentsTotal',
                      t('admin.customerAnalysis.tabDetails.installment', {
                        installment: value !== '' ? value : financing?.installmentsTotal ?? 0,
                      })
                    );
                    setDisabledToEdit(true);
                  }}
                  onFocus={() => {
                    const value = methods.getValues('installmentsTotal');
                    methods.setValue('installmentsTotal', value.replace('parcelas', '').trim());
                  }}
                  rightIcon={!isFinancingCanBeEdited ? <FiEdit2 size={18} color={color.primaryColor} /> : undefined}
                  activated={!disabledToEdit}
                />
              </InputEditSection>
              <InputSection>
                <InputText
                  name="cetYear"
                  label={t('admin.customerAnalysis.tabPayments.cetAnnual')}
                  disabled
                  defaultValue={t('admin.customerAnalysis.tabPayments.valueCetYear', { cetYear: financing?.annualTotalEffectiveCost ?? 0 })}
                  flexWidth
                />
              </InputSection>
            </PaymentSection>

            <PaymentSection>
              <InputSection>
                <InputText
                  name="nextDueDate"
                  label={t('admin.customerAnalysis.tabPayments.nextDueDate')}
                  disabled
                  defaultValue={StringUtils.dateFormatMask(payments?.payment?.nextDueDate ?? '')}
                  flexWidth
                />
              </InputSection>
              <InputSection>
                <CreditInformationTitle>
                  <span>{t('simulatorData.receiptPeriod')}</span>
                </CreditInformationTitle>
                <span>{t('simulatorData.businessDay', { count: simulatorOtherData?.receiptDeadLine })}</span>
              </InputSection>
            </PaymentSection>
          </PaymentInfoContainer>
        </Payment>

        {!isFinancingCanBeEdited && (
          <SaveEditButtons>
            <EnterCustomButton onClick={() => methods.reset(currentSchema)} isInvertColor isLoading={false} text="Cancelar" />
            <EnterCustomButton onClick={methods.handleSubmit(saveEdit)} isLoading={false} text="Salvar alterações" />
          </SaveEditButtons>
        )}

        {contracts.length > 0 && (
          <Contract isContainsSaveButton={!isFinancingCanBeEdited}>
            <SectionTitle>
              <span>{t('admin.customerAnalysis.tabContracts.headers.contract')}</span>
            </SectionTitle>
            <ContractInfoContainer>
              <ContractContent>
                <ContractHeader>
                  <ContractSection>
                    <CreditInformationTitle>
                      <span>{t('admin.customerAnalysis.tabContracts.headers.contract')}</span>
                    </CreditInformationTitle>
                  </ContractSection>
                  <ContractSection>
                    <CreditInformationTitle>
                      <span>{t('admin.customerAnalysis.tabContracts.headers.signatures')}</span>
                    </CreditInformationTitle>
                  </ContractSection>
                  <ContractSection>
                    <CreditInformationTitle>
                      <span>{t('admin.customerAnalysis.tabContracts.headers.status')}</span>
                    </CreditInformationTitle>
                  </ContractSection>
                </ContractHeader>

                {contracts?.map(contract => (
                  <ContractData key={contract.id}>
                    <FileNameBox>
                      <span>{contract.attachment.fileName}</span>
                    </FileNameBox>
                    <SignatoriesBox>
                      {contract?.contractSign?.contractSignatories?.map(signatory => (
                        <SignatoryContent key={signatory.id}>
                          {signatory.signed ? <FiCheck color={color.new} /> : <Unsigned />}
                          <Sign>{signatory?.signatory?.email}</Sign>
                        </SignatoryContent>
                      ))}
                    </SignatoriesBox>
                    <span>{t(`enum.filterAllStatus.${contract.status}`)}</span>
                  </ContractData>
                ))}
              </ContractContent>

              <ContractButtonContainer>
                <EnterCustomButton
                  onClick={() => history.push(`/admin/contratos/analise/${contracts[0].id}`, { prevPath: history.location.pathname })}
                  isLoading={false}
                  text="Acessar contrato"
                />
              </ContractButtonContainer>
            </ContractInfoContainer>
          </Contract>
        )}
      </FormProvider>
    </BackGround>
  );
};

export default AnalysisDatails;
