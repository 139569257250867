import { EvolveGuarantee } from 'model/evolve-guarantee';
import { action } from 'typesafe-actions';
import { DetailEvolveGuaranteeTypes } from './types';

export const detailEvolveGuaranteeRequest = (financingId: number) =>
  action(DetailEvolveGuaranteeTypes.DETAIL_EVOLVE_GUARANTEE_REQUEST, financingId);
export const detailEvolveGuaranteeSuccess = (guarantee: EvolveGuarantee) =>
  action(DetailEvolveGuaranteeTypes.DETAIL_EVOLVE_GUARANTEE_SUCCESS, guarantee);
export const detailEvolveGuaranteeError = (error: string) => action(DetailEvolveGuaranteeTypes.DETAIL_EVOLVE_GUARANTEE_ERROR, error);
export const detailEvolveGuaranteeResetState = () => action(DetailEvolveGuaranteeTypes.DETAIL_EVOLVE_GUARANTEE_RESET);
