import clientApi from 'api/client-api';
import { ContractAcceptOptions } from 'model/enums/contract-accept';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Desktop } from './layout/desktop';
import { Mobile } from './layout/mobile';

export interface AuthorizationProps {
  isChecked: boolean;
  handleCheck: (value: { value: string; check: boolean }) => void;
  onHandleClick: () => void;
  isLoading: boolean;
}

const AuthorizationScreen = () => {
  const isMobile = useResponsiveMobile().isTablet;
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (account?.client?.id) {
      clientApi.getTerms(account?.client?.id).then(res => {
        if (res.data.contractAccountAccept === ContractAcceptOptions.ACCEPT) {
          return history.push('/selfie');
        }
      });
    }
  }, []);

  const handleCheck = (value: { value: string; check: boolean }) => {
    setIsChecked(value.check);
  };

  const onHandleClick = () => {
    setIsLoading(true);

    if (isChecked && account?.client?.id) {
      clientApi
        .acceptTerms(account?.client?.id)
        .then(res => {
          if (account?.client?.id) {
            clientApi.getTerms(account?.client?.id).then(response => {
              if (response.data.contractAccountAccept === ContractAcceptOptions.ACCEPT) {
                return history.push('/selfie');
              }
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return !isMobile ? (
    <Desktop handleCheck={handleCheck} onHandleClick={onHandleClick} isChecked={isChecked} isLoading={isLoading} />
  ) : (
    <Mobile handleCheck={handleCheck} onHandleClick={onHandleClick} isChecked={isChecked} isLoading={isLoading} />
  );
};

export default AuthorizationScreen;
