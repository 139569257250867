import { getMonth, getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CalendarContainer,
  DayOfWeek,
  IncreaseButton,
  InputContainer,
  MonthList,
  Page,
  SelectContainer,
  Separator,
  YearList,
} from './styles';

export enum HeaderItem {
  LEFT_BUTTON = 'LEFT_BUTTON',
  MONTH_YEAR = 'MONTH_YEAR',
  RIGHT_BUTTON = 'RIGHT_BUTTON',
}

interface CustomCalendarProps {
  setInitialDate?: (value: string) => void;
  setFinishDate?: (value: string) => void;
  resetCalendar?: boolean;
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({ setInitialDate, setFinishDate, resetCalendar }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startDateInput, setStartDateInput] = useState<string>('');
  const [endDateInput, setEndDateInput] = useState<string>('');
  const [isMonthListVisible, setMonthListVisible] = useState(false);
  const [isYearListVisible, setYearListVisible] = useState(false);

  const currentYear = getYear(new Date());
  const years = Array.from({ length: currentYear - 1990 + 1 }, (_, i) => 1990 + i);
  const weekDaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    if (resetCalendar) {
      setStartDate(undefined);
      setEndDate(undefined);
      setStartDateInput('');
      setEndDateInput('');
    }
  }, [resetCalendar]);

  useEffect(() => {
    setInitialDate?.(startDate?.toISOString().split('T')[0] ?? '');
    setFinishDate?.(endDate?.toISOString().split('T')[0] ?? startDate?.toISOString().split('T')[0] ?? '');
  }, [startDate, endDate]);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start ?? undefined);
    setEndDate(end ?? undefined);
    setStartDateInput(start ? start.toISOString().split('T')[0] : '');
    setEndDateInput(end ? end.toISOString().split('T')[0] : '');
  };

  const handleStartDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateInput(e.target.value);
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      setStartDate(date);
    } else {
      setStartDate(undefined);
    }
  };

  const handleEndDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateInput(e.target.value);
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      setEndDate(date);
    } else {
      setEndDate(undefined);
    }
  };

  return (
    <Page>
      <div>
        <InputContainer>
          <section>
            <label htmlFor="startDateInput">Inicial</label>
            <input type="date" className="date-input" value={startDateInput} onChange={handleStartDateInput} />
          </section>

          <section>
            <label htmlFor="endDateInput">Final</label>
            <input type="date" className="date-input" value={endDateInput} onChange={handleEndDateInput} />
          </section>
        </InputContainer>

        <Separator />

        <CalendarContainer>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            renderCustomHeader={({ date, changeMonth, changeYear, decreaseMonth, increaseMonth }) => (
              <>
                <SelectContainer>
                  <section>
                    <IncreaseButton onClick={decreaseMonth}>&lt;</IncreaseButton>
                    <div onClick={() => setMonthListVisible(!isMonthListVisible)} style={{ cursor: 'pointer' }}>
                      {months[getMonth(date)]}
                    </div>
                    <IncreaseButton onClick={increaseMonth}>&gt;</IncreaseButton>
                  </section>

                  {isMonthListVisible && (
                    <MonthList>
                      {months.map((month, index) => (
                        <div
                          key={month}
                          onClick={() => {
                            changeMonth(index);
                            setMonthListVisible(false);
                          }}
                        >
                          {month}
                        </div>
                      ))}
                    </MonthList>
                  )}

                  <section>
                    <IncreaseButton onClick={() => changeYear(getYear(date) - 1)}>&lt;</IncreaseButton>
                    <div onClick={() => setYearListVisible(!isYearListVisible)} style={{ cursor: 'pointer' }}>
                      {getYear(date)}
                    </div>
                    <IncreaseButton onClick={() => changeYear(getYear(date) + 1)}>&gt;</IncreaseButton>
                  </section>

                  {isYearListVisible && (
                    <YearList>
                      {years.map(year => (
                        <div
                          key={year}
                          onClick={() => {
                            changeYear(year);
                            setYearListVisible(false);
                          }}
                        >
                          {year}
                        </div>
                      ))}
                    </YearList>
                  )}
                </SelectContainer>

                <DayOfWeek>
                  {weekDaysShort.map((day, index) => (
                    <div key={index}>{day}</div>
                  ))}
                </DayOfWeek>
              </>
            )}
          />
        </CalendarContainer>
        <Separator />
      </div>
    </Page>
  );
};

export default CustomCalendar;
