import CustomButton from 'components/custom-button';
import i18n from 'config/i18n';
import { ReactComponent as DataAdjustmentsSvg } from 'images/data-adjustments.svg';
import { ReactComponent as FinancingAnalysisSvg } from 'images/financing-analysis.svg';
import { ReactComponent as FundingRejectedSvg } from 'images/funding-rejected.svg';
import { isEmpty } from 'lodash';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Dispatch, SetStateAction } from 'react';
import financingService from 'services/financing-service';
import { CommentContainer } from '../../registration-analysis/styles';
import { PaintSvg } from '../styles';

interface Props {
  financingId: number;
  financingStatus: FinancingStatusType;
  isLoadingSendSettingsButton: boolean;
  consultantComment?: string;
  setIsLoadingSendSettingsButton: Dispatch<SetStateAction<boolean>>;
  backCreditsList: () => void;
}

const financingAnalysisData = ({
  financingId,
  financingStatus,
  consultantComment,
  isLoadingSendSettingsButton,
  setIsLoadingSendSettingsButton,
  backCreditsList,
}: Props) => {
  const changeFunancingStatusToAdjusted = () => {
    setIsLoadingSendSettingsButton(true);

    financingService.financingStatusToAdjusted(financingId).then(() => {
      backCreditsList();
      setIsLoadingSendSettingsButton(false);
    });
  };

  if (financingStatus === FinancingStatusType.ADJUST) {
    return {
      title: i18n.t('dashboard.dataAdjustment'),
      subtitle: i18n.t('dashboard.someTweaking'),
      text: '',
      children: (
        <PaintSvg className="dataAdjustmentsSvg">
          <DataAdjustmentsSvg />
        </PaintSvg>
      ),
      footer: (
        <>
          {!isEmpty(consultantComment) && (
            <CommentContainer style={{ marginTop: '10px', width: '100%' }}>{consultantComment}</CommentContainer>
          )}
          <CustomButton
            style={{ margin: '25px auto 0 auto', justifyContent: 'center' }}
            height={48}
            width="165px"
            onClick={() => changeFunancingStatusToAdjusted()}
            loading={isLoadingSendSettingsButton}
          >
            {i18n.t('global.button.submitSettings')}
          </CustomButton>
        </>
      ),
    };
  }

  if (financingStatus === FinancingStatusType.REJECTED) {
    return {
      title: i18n.t('dashboard.rejectedCredit'),
      subtitle: i18n.t('dashboard.unableApproveCredit'),
      children: (
        <PaintSvg className="fundingRejectedSvg">
          <FundingRejectedSvg />
        </PaintSvg>
      ),
      footer: !isEmpty(consultantComment) ? <CommentContainer>{consultantComment}</CommentContainer> : <></>,
    };
  }

  if (
    financingStatus === FinancingStatusType.APPROVED ||
    financingStatus === FinancingStatusType.IN_PROGRESS ||
    financingStatus === FinancingStatusType.PENDING_PAYMENT ||
    financingStatus === FinancingStatusType.PENDING_SIGN
  ) {
    return {
      title: i18n.t('dashboard.completedCreditAnalysis'),
      subtitle: i18n.t('dashboard.yourCreditHasBeenApproved'),
      children: (
        <PaintSvg className="financingAnalysisSvg">
          <FinancingAnalysisSvg />
        </PaintSvg>
      ),
    };
  }

  return {
    title: i18n.t('dashboard.creditAnalysis'),
    subtitle: i18n.t('dashboard.waitCreditAnalysis'),
    text: i18n.t('dashboard.soonHaveResultOfCreditAnalysisProcess'),
    children: (
      <PaintSvg className="financingAnalysisSvg">
        <FinancingAnalysisSvg />
      </PaintSvg>
    ),
  };
};

export default financingAnalysisData;
