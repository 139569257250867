export enum FormProperties {
  NAME = 'name',
  CORPORATE_NAME = 'corporateName',
  PHONE = 'phone',
  EMAIL = 'email',
  CPF = 'cpf',
  CNPJ = 'cnpj',
  BIRTH_DATE = 'birth',
  ZIP_CODE = 'zipCode',
  INCOME = 'income',
  EMPLOYMENT_BOND = 'employmentBond',
  GENDER = 'gender',
  MARITAL_STATUS = 'maritalStatus',
  ORGANIZATION_SUBSIDIARIES = 'organizationSubsidiary',
  STREET = 'street',
  DISTRICT = 'district',
  ADDRESS_NUMBER = 'addressNumber',
  MAIN_ACTIVITY = 'mainActivity',
  REVENUE = 'revenue',
  OCCUPATION = 'occupation',
}
export enum EmploymentBondSend {
  'Assalariado' = 'ASSALARIADO',
  'Autônomo' = 'AUTONOMO',
  'Aposentado' = 'APOSENTADO',
  'Rural' = 'RURAL',
  'Pensionista' = 'PENSIONISTA',
  'Empresário' = 'EMPRESARIO',
  'Funcionario Público' = 'FUNCIONARIO_PUBLICO',
  'Profissional Liberal' = 'PROFISSIONAL_LIBERAL',
  'Militar' = 'MILITAR',
  'Outras' = 'OUTRAS',
}
export enum GenderList {
  'Homem' = 'MALE',
  'Mulher' = 'FEMALE',
  'Masculino' = 'MALE',
  'Feminino' = 'FEMALE',
}

export enum MaritalStatusList {
  'Solteiro' = 'SINGLE',
  'Casado' = 'MARRIED',
  'Divorciado' = 'DIVORCED',
  'Viuvo' = 'WIDOW',
  'Separado' = 'SEPARETED',
  'Outro' = 'OTHER ',
}

export enum EmploymentBond {
  ASSALARIADO,
  AUTONOMO,
  APOSENTADO,
  RURAL,
  PENSIONISTA,
  EMPRESARIO,
  FUNCIONARIO_PUBLICO,
  PROFISSIONAL_LIBERAL,
  MILITAR,
  OUTRAS,
}
