import SystemStepCategory from './enums/system-step-category';
import TypeSubStepCategory from './enums/type-substep-category';

export interface EditStep {
  stepOrder: number;
  step: SystemStepCategory | TypeSubStepCategory;
}

export const legalPersonPath: EditStep[] = [
  {
    stepOrder: 1,
    step: SystemStepCategory.GENERAL_DATA,
  },
  {
    stepOrder: 2,
    step: SystemStepCategory.ADDRESS,
  },
  {
    stepOrder: 3,
    step: SystemStepCategory.MY_COMPANY,
  },
  {
    stepOrder: 4,
    step: SystemStepCategory.LEGAL_PERSON_DOCUMENTS,
  },
];

export const partnerPath: EditStep[] = [
  {
    stepOrder: 1,
    step: TypeSubStepCategory.PARTNER_GENERAL_DATA,
  },
  {
    stepOrder: 2,
    step: TypeSubStepCategory.PARTNER_ADDRESS,
  },
  {
    stepOrder: 3,
    step: TypeSubStepCategory.PARTNER_DOCUMENTS,
  },
];
