enum StepType {
  REGISTRATION = 'REGISTRATION',
  AFTER_REGISTER = 'AFTER_REGISTER',
  CCB = 'CCB',
  ORGANIZATION = 'ORGANIZATION',
  PROCEDURE = 'PROCEDURE',
  CREDIT_ANTICIPATION = 'CREDIT_ANTICIPATION',
  SIGNATURE = 'SIGNATURE',
}

export default StepType;
