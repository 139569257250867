import * as S from './styles';
import { ReactComponent as InputRadioNotSelectedSvg } from 'images/gray-input-radio-not-selected.svg';
import { ReactComponent as InputRadioCheckedSvg } from 'images/input-radio-checked.svg';

interface Props {
  title: string;
  text: string;
  checked: boolean;
  handleOnClick: () => void;
}

const CustomInputRadio = ({ title, text, checked, handleOnClick }: Props) => {
  return (
    <S.Container onClick={() => handleOnClick()}>
      <S.ContainerInput>
        <S.ContainerInput>
          <S.Input name="radio" type="radio" value={title} checked={checked} readOnly />
          {checked ? (
            <S.PaintSvg>
              <InputRadioCheckedSvg />
            </S.PaintSvg>
          ) : (
            <InputRadioNotSelectedSvg />
          )}
        </S.ContainerInput>
      </S.ContainerInput>
      <div>
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
      </div>
    </S.Container>
  );
};

export default CustomInputRadio;
