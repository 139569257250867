export const goTo = (sectionId?: string) => {
  if (sectionId) {
    const form = document.getElementById(sectionId);
    return form?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
};
