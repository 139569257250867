import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface IRegisterSentProps {}

const RegisterSent: React.FC<IRegisterSentProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default RegisterSent;
