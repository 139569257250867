import { Authentication } from 'model/authentication';
import { User } from 'model/user';
import { UserParameters } from 'model/user-parameters';
import { action } from 'typesafe-actions';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const loginAdminRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_ADMIN_REQUEST, authenticate);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginError = (message: string) => action(AuthenticationActionTypes.LOGIN_ERROR, message);
export const loginValidate = (message: string) => action(AuthenticationActionTypes.LOGIN_VALIDATE, message);
export const getSessionRequest = () => action(AuthenticationActionTypes.GET_SESSION_REQUEST);
export const getSessionSuccess = (user: User) => action(AuthenticationActionTypes.GET_SESSION_SUCCESS, user);
export const getSessionError = (message: string) => action(AuthenticationActionTypes.GET_SESSION_ERROR, message);
export const getAccountParametersRequest = () => action(AuthenticationActionTypes.GET_ACCOUNT_PARAMETERS_REQUEST);
export const getAccountParametersSuccess = (userParameters: UserParameters) =>
  action(AuthenticationActionTypes.GET_ACCOUNT_PARAMETERS_SUCCESS, userParameters);
export const getAccountParametersError = (message: string) => action(AuthenticationActionTypes.GET_ACCOUNT_PARAMETERS_ERROR, message);

export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);
