import { Container, Content, PageContainer } from '../dashboard/styles';
import { CustomSideMenu } from 'components/custom-side-menu';
import { MyAccountList } from '../dashboard/screens/my-account';
import { useParams } from 'react-router-dom';

const MyAccount: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <PageContainer>
      <Container>
        <CustomSideMenu />
        <Content>
          <MyAccountList id={id} />
        </Content>
      </Container>
    </PageContainer>
  );
};

export default MyAccount;
