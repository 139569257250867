import { AxiosResponse } from 'axios';
import { FinancyInternal, Internal } from 'model/internals';
import { PageableResponse } from 'model/pageable';
import { api } from './api';
import { Insurance } from 'model/insurance';

export const insuranceApi = () => {
  const baseUrl = '/insurance';

  const getInsuranceByFinancing = (financingId: number): Promise<AxiosResponse<Insurance>> => {
    return api.get<Insurance>(`${baseUrl}/financing/${financingId}`);
  };

  const cancelInsurance = (financingId: number): Promise<AxiosResponse<void>> => {
    return api.post<void>(`${baseUrl}/cancel-ticket/financing/${financingId}`);
  };

  return {
    getInsuranceByFinancing,
    cancelInsurance,
  };
};

export default insuranceApi();
