export enum Mask {
  CURRENCY = 'CURRENCY',
  PHONE = 'PHONE',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  CEP = 'CEP',
  LANDLINE = 'LANDLINE',
  PERCENTAGE = 'PERCENTAGE',
  NUMBERS = 'NUMBERS',
  RG = 'RG',
  BIRTHDAY = 'BIRTHDAY',
  MONTH_YEAR = 'MONTH_YEAR',
  DEFAULT = 'DEFAULT',
  MONEY = 'MONEY',
  FULLNAME = 'FULLNAME',
}
