import Header from 'components/general-components/header';
import Translate from 'i18n/translate';
import { Authority } from 'model/enums/authority';
import { HeaderVariants } from 'model/enums/header-variants';
import { FinancingSimpleSimulation } from 'model/financing';
import Simulator from '../../simulator';
import { SimulatorContainer, StyledText, StyledTitle, StyledWarning } from '../style';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationTypes } from 'model/enums/organization';
import { Trans } from 'react-i18next';
import * as S from '../style';
import { SimulatorSideBar } from 'components/simulator-data/simulator-side-bar';
import { useAuthorities } from 'hooks/use-authorities';
import { useSendSimulation } from '../../hooks/use-send-simulation';
import { ReactComponent as WhatsIconRed } from '../../../../images/general-images/WhatsappRed.svg';

interface DesktopProps {
  whichRoute: boolean;
}

const Desktop: React.FC<DesktopProps> = ({ whichRoute }) => {
  const { hasAuthorities } = useAuthorities();
  const { hasInsurance, renderButton } = useSendSimulation();

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <S.SimulatorContainer>
        <S.StyledTitle>
          {whichRoute ? (
            <Translate contentKey={hasAuthorities([Authority.ROLE_ADMIN]) ? 'admin.simulatorDataTitle' : 'simulatorData.title'} />
          ) : (
            <Translate contentKey="landingPage.openingScreen.simulatorSlider.newCreditApproved" />
          )}
        </S.StyledTitle>
        {ORGANIZATION_NAME === OrganizationTypes.zmEmprestimos && (
          <StyledWarning>
            <a href="https://api.whatsapp.com/send?phone=61996826925" target={'_blank'}>
              <Trans i18nKey="simulatorData.zmWarning" values={{ via: 'Whatsapp' }} />
              <WhatsIconRed />
            </a>
          </StyledWarning>
        )}
        <S.StyledText>
          <Translate contentKey="simulatorData.text" />
        </S.StyledText>

        <S.SimulatorInsuranceContainer>
          <S.SimulatorButtonContainer>
            <Simulator />
            {!hasInsurance && renderButton()}
          </S.SimulatorButtonContainer>
          <SimulatorSideBar hasInsurance={hasInsurance} />
        </S.SimulatorInsuranceContainer>
      </S.SimulatorContainer>
    </>
  );
};

export default Desktop;
