import { UserParameters } from 'model/user-parameters';
import { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { getAccountParametersRequest, getSessionRequest } from 'reducer/authentication/actions';
import useResponsiveMobile from 'shared/util/use-responsive-hook';

import AuthUtils from 'shared/util/auth-utils';

interface SelfieProviderProps {
  accountParameters?: UserParameters;
  checkMobileAndSelfieStep: (key: string) => void;
  updateUserParameters: () => void;
  updateAccount: () => void;
}

const SelfieContext = createContext<SelfieProviderProps>({} as SelfieProviderProps);

export const SelfieProvider = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!accountParameters && AuthUtils.isAuthenticated()) {
      dispatch(getAccountParametersRequest());
    }
  }, []);

  const updateUserParameters = () => {
    if (AuthUtils.isAuthenticated()) {
      dispatch(getAccountParametersRequest());
    }
  };

  const updateAccount = () => {
    if (AuthUtils.isAuthenticated()) {
      dispatch(getSessionRequest());
    }
  };

  const checkMobileAndSelfieStep = (key: string) => {
    if (accountParameters != null) {
      checkMobileAndRedirect(key);
      checkSelfieStep();
    }
  };

  const checkMobileAndRedirect = (key: string) => {
    if (isMobile || isTablet) {
      if (!window.location.href.includes('intrucoes')) {
        history.replace('/selfie/instrucoes/' + key);
      }
    } else {
      if (!window.location.href.includes('qrcode')) {
        history.replace('/selfie/qrcode/' + key);
      }
    }
  };

  const checkSelfieStep = () => {
    if (AuthUtils.isAdmin()) return;

    if (accountParameters != null && !accountParameters?.hasSelfieStep) {
      return history.push('/login');
    }
  };

  return (
    <SelfieContext.Provider value={{ accountParameters, checkMobileAndSelfieStep, updateUserParameters, updateAccount }}>
      {children}
    </SelfieContext.Provider>
  );
};

export const useSelfieContext = () => useContext(SelfieContext);
