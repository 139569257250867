import { CustomSideMenu } from 'components/custom-side-menu';
import DashboardHeader from 'features/admin/dashboard/components/dashboard-header';
import { useTranslation } from 'react-i18next';
import { Container, Content, HeaderContainer, PageContainer } from './styles';

interface Props {
  children: React.ReactNode;
  title: string;
  containerHeight?: string;
}

const SideMenuAndHeader: React.FC<Props> = ({ children, title, containerHeight }) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Container containerHeight={containerHeight}>
        <CustomSideMenu containerHeight={containerHeight} />
        <HeaderContainer>
          <DashboardHeader title={title} currentScreenPath="" />
          <Content>{children}</Content>
        </HeaderContainer>
      </Container>
    </PageContainer>
  );
};

export default SideMenuAndHeader;
