import { HttpRequestStatus } from 'model/enums/http-request-status';
import { LandingPageType } from 'model/landing-page';

export type ApiResponse = Record<string, any>;

export enum LandingPageTemplateActionTypes {
  LANDING_PAGE_TEMPLATE_REQUEST = '@@landing-page-template/LANDING_PAGE_TEMPLATE_REQUEST',
  LANDING_PAGE_TEMPLATE_SUCCESS = '@@landing-page-template/LANDING_PAGE_TEMPLATE_SUCCESS',
  LANDING_PAGE_TEMPLATE_ERROR = '@@landing-page-template/LANDING_PAGE_TEMPLATE_ERROR',
}

export interface LandingPageTemplateState {
  readonly LandingPageTemplateStatus: HttpRequestStatus;
  readonly error?: string;
  readonly landingPageTemplate?: LandingPageType;
}
