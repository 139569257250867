import { EditFormProvider } from 'provider/edit-form';
import { SelectListsProvider } from 'provider/select-list';
import { CreateBranchScreen } from './createBranch';

interface IEditProfileProps {}

const CreateBranch: React.FC<IEditProfileProps> = props => {
  return (
    <SelectListsProvider>
      <EditFormProvider>
        <CreateBranchScreen />
      </EditFormProvider>
    </SelectListsProvider>
  );
};

export default CreateBranch;
