import organizationApi from 'api/organization-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { Organization } from 'model/organization';

const organizationService = () => {
  const getCurrentOrganization = async (): Promise<Organization> => {
    try {
      const result: AxiosResponse<Organization> = await organizationApi.getCurrentOrganization();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getCurrentOrganization,
  };
};

export default organizationService();
