import { ReactComponent as CloseIconSvg } from 'images/close.svg';
import { ReactComponent as DeleteIconSvg } from 'images/delete.svg';
import { ReactComponent as FilterIconSvg } from 'images/filter.svg';

import { useTranslation } from 'react-i18next';
import {
  ActiveFilter,
  ActiveFiltersContainer,
  ClearFiltersContainer,
  CloseIconContainer,
  FilterButton,
  FilterButtonContainer,
} from './styles';

interface TableListFiltersProps {
  setInitialDate?: (value: string) => void;
  setEndDate?: (value: string) => void;
  initialDate?: string;
  endDate?: string;
  searchName?: string;
  setSearchName?: (value: string) => void;
  setFilterStatus?: (value: string) => void;
  filterStatus?: string;
  resetFilters?: () => void;
  showNameFilter: boolean;
  setShowNameFilter: (value: boolean) => void;
  showDateFilter: boolean;
  setShowDateFilter: (value: boolean) => void;
  showStatusFilter: boolean;
  setShowStatusFilter: (value: boolean) => void;
}

const TableListFilterBar: React.FC<TableListFiltersProps> = ({
  searchName,
  setSearchName,
  setInitialDate,
  setFilterStatus,
  initialDate,
  filterStatus,
  resetFilters,
  showNameFilter,
  setShowNameFilter,
  showDateFilter,
  setShowDateFilter,
  showStatusFilter,
  setShowStatusFilter,
  setEndDate,
  endDate,
}) => {
  const { t } = useTranslation();

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

  const showResetFilter = () => {
    return (searchName && searchName?.length > 0) || (initialDate && initialDate.length > 0) || (filterStatus && filterStatus.length > 0);
  };

  const periodDescription = () => {
    if (initialDate === endDate) {
      return formatDate(initialDate);
    }

    return `${formatDate(initialDate)} - ${formatDate(endDate)}`;
  };

  return (
    <>
      <FilterButtonContainer>
        <FilterButton onClick={() => setShowNameFilter(!showNameFilter)}>
          <FilterIconSvg />
          <span>{t('global.button.name')}</span>
        </FilterButton>

        <FilterButton onClick={() => setShowDateFilter(!showDateFilter)}>
          <FilterIconSvg />
          <span>{t('global.button.date')}</span>
        </FilterButton>

        <FilterButton onClick={() => setShowStatusFilter(!showStatusFilter)}>
          <FilterIconSvg />
          <span>{t('global.button.status')}</span>
        </FilterButton>
      </FilterButtonContainer>

      <ActiveFiltersContainer>
        {searchName && searchName?.length > 0 && (
          <ActiveFilter onClick={() => (setSearchName ? setSearchName('') : '')}>
            <span>
              {`${t('global.button.name')}:`} {`${searchName}`}
            </span>
            <CloseIconContainer>
              <CloseIconSvg />
            </CloseIconContainer>
          </ActiveFilter>
        )}

        {initialDate && initialDate.length > 0 && (
          <ActiveFilter
            onClick={() => {
              setInitialDate ? setInitialDate('') : '';
              setEndDate ? setEndDate('') : '';
            }}
          >
            <span>
              {`${t('global.button.date')}:`} {periodDescription()}
            </span>
            <CloseIconContainer>
              <CloseIconSvg />
            </CloseIconContainer>
          </ActiveFilter>
        )}

        {filterStatus && filterStatus.length > 0 && (
          <ActiveFilter onClick={() => (setFilterStatus ? setFilterStatus('') : '')}>
            <span>
              {`${t('global.button.status')}:`} {t(`enum.financingStatus.${filterStatus}`)}
            </span>
            <CloseIconContainer>
              <CloseIconSvg />
            </CloseIconContainer>
          </ActiveFilter>
        )}

        {showResetFilter() && (
          <ClearFiltersContainer onClick={() => (resetFilters ? resetFilters() : '')}>
            <DeleteIconSvg />
          </ClearFiltersContainer>
        )}
      </ActiveFiltersContainer>
    </>
  );
};

export default TableListFilterBar;
