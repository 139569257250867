import { CSSProperties } from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { Container } from './styles';

interface PageContainerProps {
  customStyle?: CSSProperties;
}

export const PageLoading = ({ customStyle }: PageContainerProps) => {
  const { color } = useTheme();
  return (
    <Container style={customStyle}>
      <ReactLoading type="spinningBubbles" color={color.primaryColor} />
    </Container>
  );
};
