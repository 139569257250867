import { HeaderVariants } from 'model/enums/header-variants';
import { PageContainer, TitleHeader, Title, Content, Separator } from './styles';
import Header from 'components/general-components/header';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Fragment, useEffect, useState } from 'react';
import { ClieloStatus, ContestationStatus, FinancingCreditCards } from 'model/credit-card';
import clientService from 'services/client-service';
import CardContestionForm from './card-contestion-form';
import creditCardService from 'services/credit-card-service';
import { TabHeaderInnerContainer, TabTitleHeader } from 'features/admin/customerAnalysis/styles';
import { Status } from 'features/admin/dashboard/components/status';
import StringUtils from 'shared/util/string-utils';
import FinancingPaymentType from 'model/enums/financingPaymentType';

const CreditCardScreen = ({ t }: WithTranslation) => {
  const { color } = useTheme();
  const location = useLocation<{ prevPath: string }>();
  const { financeId, clientId } = useParams<{ financeId: string; clientId: string }>();
  const history = useHistory();

  const [financingCreditCards, setFinancingCreditCards] = useState<FinancingCreditCards[]>([]);
  const [cieloStatus, setCieloStatus] = useState<ClieloStatus>();
  const [contestationStatus, setContestationStatus] = useState<ContestationStatus>();

  useEffect(() => {
    getCreditCardFromFinancing();
    getContestationStatus();
  }, []);

  const getCreditCardFromFinancing = async () => {
    if (financeId != null) {
      await clientService.getCreditCardFromFinancing(Number(financeId)).then(response => {
        const creditCardsSortedById = response.financingCreditCards?.sort((a, b) => {
          if (a?.creditCard?.id != null && b.creditCard?.id != null) {
            return a.creditCard.id - b.creditCard.id;
          }

          return 0;
        });

        setFinancingCreditCards(creditCardsSortedById ?? []);
        setCieloStatus(response?.cielo);
      });
    }
  };

  const getContestationStatus = () => {
    creditCardService.getContestationStatus(Number(clientId)).then(contestation => {
      const currentContestation = contestation.filter(value => value?.financing?.id === Number(Number(financeId)))[0];
      setContestationStatus(currentContestation);
    });
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.creditCardScreen.pageTitle')}</span>
        </Title>
      </TitleHeader>

      <Content>
        {contestationStatus != null && (
          <TabHeaderInnerContainer style={{ marginTop: '5px', marginBottom: '20px' }}>
            <TabTitleHeader>{t('admin.procedureScreen.contestationStatus')}</TabTitleHeader>
            <Status text={StringUtils.contestationStatusText(contestationStatus) ?? ''} color={FinancingPaymentType.ORGANIZATION} />
          </TabHeaderInnerContainer>
        )}
        {financingCreditCards?.map((value, index) => (
          <Fragment key={`financing-credit-card-${index}`}>
            {index !== 0 && <Separator />}
            <CardContestionForm
              financingCreditCard={value}
              cardIndex={index}
              handleClickConfirmationModal={() => getCreditCardFromFinancing()}
              cieloStatus={cieloStatus}
            />
          </Fragment>
        ))}
      </Content>
    </PageContainer>
  );
};

export default withTranslation()(CreditCardScreen);
