import { Button, Container, FormInputsContainer } from 'components/register-forms/styles';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import { referencesType } from 'shared/util/select-utils';

interface ContainerReferencesProps {
  setShowAddReferences: (value: boolean) => void;
}
export const ContainerReferences = (props: ContainerReferencesProps) => {
  const { references, isFirstForm, onClickBack, isLastForm, isLoading } = useEditForm();
  const { t } = useTranslation();

  return (
    <FormInputsContainer>
      <div style={{ width: '100%', maxHeight: '360px', overflow: 'auto' }}>
        {references.map((ref, key) => (
          <Container key={key}>
            <div>
              <span className="title">{t('register.inputs.name')}</span>
              <span className="description">{ref.name}</span>
            </div>
            <div>
              <span className="title">{t('register.inputs.referenceType')}</span>
              <span className="description">{referencesType.find(res => res.value === ref.type)?.label}</span>
            </div>
            <div>
              <span className="title">{t('register.inputs.phone')}</span>
              <span className="description">{ref.phone}</span>
            </div>
            <div>
              <span className="title">{t('register.inputs.homePhone')}</span>
              <span className="description">{ref.homePhone}</span>
            </div>
            <div>
              <span className="title">{t('register.inputs.workPhone')}</span>
              <span className="description">{ref.workPhone}</span>
            </div>
          </Container>
        ))}
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '15px', justifyContent: 'center' }}>
        <Button onClick={() => props.setShowAddReferences(true)}>
          <span>+ {t('global.button.add')}</span>
        </Button>
      </div>
    </FormInputsContainer>
  );
};
