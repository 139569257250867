import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  margin: 0px 0px 10px 0px;
  gap: 10px;
  width: 100%;
  border: 1.5px solid #e5e7e7;
  border-radius: 8px;
  background-color: #fff;
  .firstSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #333;
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #7f7f7f;
  }
`;

export const Eye = styled.img`
  fill: red;
  color: red;
`;
