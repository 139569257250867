import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { PopupContent, PopupTitle } from 'components/simulator-data/aproved/style';
import { ReactComponent as ConfirmedGreenSvg } from 'images/confirmed-green.svg';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  text: string;
  isShowModal: boolean;
  handleClick: () => void;
  handleClose: () => void;
}

const ConfirmationModal = ({ text, isShowModal, handleClick, handleClose, t }: Props) => {
  return (
    <CustomModalTwoButtons
      isShowModal={isShowModal}
      onCloseModal={() => handleClose()}
      children={
        <PopupContent>
          <ConfirmedGreenSvg />
          <PopupTitle>{text}</PopupTitle>
        </PopupContent>
      }
      secondButtonText={t('global.button.ok')}
      handleClickSecondButton={() => handleClick()}
    />
  );
};

export default withTranslation()(ConfirmationModal);
