import { StorageFilters } from 'model/storageFilters';

const STATUS_FILTERS = 'statusFilters';
const PAGE = 'page';
const SEARCH = 'search';

export const getStorageFilter = () => {
  const filters = localStorage.getItem(STATUS_FILTERS) !== '' ? localStorage.getItem(STATUS_FILTERS)?.split(' ') : [];

  const storageFilters: StorageFilters = {
    statusFilters: filters,
    page: Number(localStorage.getItem(PAGE)) ?? 0,
    search: localStorage.getItem(SEARCH) ?? '',
  };

  return storageFilters;
};

export const setStorageFilters = ({ statusFilters, page, search }: StorageFilters) => {
  localStorage.setItem(STATUS_FILTERS, statusFilters?.join(' ') ?? localStorage.getItem(STATUS_FILTERS) ?? '');
  localStorage.setItem(PAGE, page?.toString() ?? localStorage.getItem(PAGE) ?? '');
  localStorage.setItem(SEARCH, search ?? localStorage.getItem(SEARCH) ?? '');
};
