export enum validationEnum {
  formType = 'formType',
  fieldType = 'fieldType',
}

export enum FlowMap {
  DEFAULT = 'DEFAULT',
  EVOLVE = 'EVOLVE',
  TOP_ONE = 'TOP_ONE',
  MONJUA = 'MONJUA',
}

export enum OrganizationTypes {
  azure = 'azure',
  creditFlow = 'credit-flow',
  evolve = 'evolve',
  esparta = 'esparta',
  giroPf = 'giropf',
  giroPj = 'giropj',
  hcred = 'hcred',
  monjua = 'monjua',
  multtiplo = 'multtiplo',
  zmEmprestimos = 'zm-emprestimos',
}
