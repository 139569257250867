import CircularProgress from '@mui/material/CircularProgress';
import Header from 'components/general-components/header';
import { ProcessingText, SimulatorContainer, StyledText, StyledTitle } from 'components/simulator-data/confirm-simulation/style';
import { ProcessingImg } from 'images/processing-screen';
import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { StyledImg } from '../style';
import { TextRotator } from 'components/text-rotator';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <SimulatorContainer>
        <StyledTitle>{t('simulatorFLow.processingScreen.almostThere')}</StyledTitle>
        <StyledText>{t('simulatorFLow.processingScreen.fewMinutes')}</StyledText>
        <CircularProgress style={{ margin: '48px 0px 0px 0px', color: theme.color.primaryColor }} />
        <ProcessingText>
          <TextRotator
            texts={[
              t('simulatorFLow.processingScreen.processingData'),
              t('simulatorFLow.processingScreen.takingTooLong'),
              t('simulatorFLow.processingScreen.stillWaiting'),
            ]}
            delay={15}
          />
        </ProcessingText>
      </SimulatorContainer>
    </>
  );
};

export default Desktop;
