import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Address } from 'model/address';
import { Client, EmploymentBond } from 'model/client';
import { Income } from 'model/income';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { unMaskedCurrency } from 'shared/util/register-utils';
import { employmentBondOptions, ownCar, ownMotorbike, scholarity } from 'shared/util/select-utils';
import { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer, ImageContainer } from '../styles';
import { ContainerInputsIncomeMonjua } from './inputs-income';

interface StepRefProps {
  setError: (name: string, error: string) => void;
}

export const FormIncomeMonjua = () => {
  const { onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const { occupations, getOccupations } = useSelectLists();
  const { initialClientData } = useRegisterForm();

  const formRef = useRef<StepRefProps>(null);

  const schema = yup.object({
    ownMotorbike: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return ownMotorbike.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    scholarity: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return scholarity.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    ownCar: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return ownCar.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    companyTimeInYears: yup.string().required(t('global.errorMessage.required')),
    company: yup.string().required(t('global.errorMessage.required')),
    dependents: yup.string().required(t('global.errorMessage.required')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
  });

  useEffect(() => {
    getOccupations({ size: 7 });
  }, []);

  return (
    <FormContainer>
      <ReactHookFormProvider
        hasError={false}
        schema={schema}
        onSubmit={res => {
          const newIncome = unMaskedCurrency(res.income) / 100;
          const occupation = occupations.find(occ => occ.name === res.occupation);
          const scholarityOption = scholarity.find(occ => occ.label === res.scholarity)?.value;
          const ownCarOption = ownCar.find(occ => occ.label === res.ownCar)?.value;
          const ownMotorbikeOption = ownMotorbike.find(occ => occ.label === res.ownMotorbike)?.value;

          if (occupation == null) {
            formRef.current?.setError('occupation', t('global.errorMessage.required'));
            return;
          }

          const newPhysicalPerson = {
            physicalPerson: {
              ...initialClientData?.physicalPerson,
              company: res.company,
              income: newIncome,
              companyTimeInYears: Number(res.companyTimeInYears),
              dependents: Number(res.dependents),
              scholarity: scholarityOption,
              ownCar: ownCarOption,
              ownMotorbike: ownMotorbikeOption,
              occupation,
            },
          };

          onClickNext(newPhysicalPerson as Client);
        }}
      >
        <ContainerInputsIncomeMonjua ref={formRef} />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
