import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SystemParametersType } from 'model/organization-types';

export type ApiResponse = Record<string, any>;

export enum SystemParametersActionTypes {
  SYSTEM_PARAMETERS_REQUEST = '@@system-parameters/SYSTEM_PARAMETERS_REQUEST',
  SYSTEM_PARAMETERS_SUCCESS = '@@system-parameters/SYSTEM_PARAMETERS_SUCCESS',
  SYSTEM_PARAMETERS_ERROR = '@@system-parameters/SYSTEM_PARAMETERS_ERROR',
  RESET_STATUS = '@@system-parameters/RESET_STATUS',
}

export interface SystemParametersState {
  readonly systemParameterStatus: HttpRequestStatus;
  readonly error?: string;
  readonly systemParameters?: SystemParametersType;
}
