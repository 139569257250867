import styled from 'styled-components';

interface Props {
  AddBankAccountButton?: boolean;
}
export const BackGround = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  margin: 0 auto;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 1004px;
  heigth: 63px;
  display: flex;
  margin-bottom: 24px;
`;

export const BasicAnalysisInformation = styled.div`
  width: 410px;
  heigth: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
`;

export const BackSection = styled.div`
  width: 65px;
  heigth: 19px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

export const IdentificationSection = styled.div`
  width: 450px;
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div<Props>`
  width: 500px;
  heigth: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;

  button:first-of-type {
    border-left: 8px solid ${({ theme }) => theme.color.primaryColor};
    width: 200px;
  }

  button {
    border: none;
    max-width: 240px;
    width: ${({ AddBankAccountButton }) => (AddBankAccountButton ? '240px' : '200px')};
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  width: 100%;
  width: 100%;
  height: 106px;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-between;
`;

export const StatusContainer = styled.div`
  margin-left: 8px;
`;

export const CreditInformationContainer = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  width: 1004px;
  max-height: 476px;
  min-height: 287px;
  padding: 24px 25px 24px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
`;

export const CreditInformationSection = styled.div`
  width: 220px;
  height: 215px;
`;

export const HighlightInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 64px;
  padding: 9px 16px 10px 24px;
  align-items: center;
  border-radius: 4px;
  background: var(--Other-a, #f2f8ff);
`;

export const CreditInformationTitle = styled.span`
  color: var(--Cool-gray-50, #878d96);
  font-size: 16px;
  font-weight: 600;
`;

export const InputEditSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span {
    color: var(--Cool-gray-50, #878d96);
    font-size: 16px;
    font-weight: 600;
  }

  label {
    width: 220px;
    height: 62px;
    border-radius: 8px;
    border: 1px solid var(--Cool-gray-30, #c1c7cd);
    padding: 8px 28px 8px 8px;
  }

  div {
    min-width: 220px;
    height: 24px;
    border: none;
    margin: 0;
    padding: 0;

    input {
      width: 168px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
    }

    section {
      width: 28px;
      height: 28px;
      background-color: #e5eefe;
      border-radius: 9999px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span {
    color: var(--Cool-gray-50, #878d96);
    font-size: 16px;
    font-weight: 600;
  }

  div {
    min-width: 220px;
    border: none;
    margin: 0;
    padding: 0;

    input {
      width: 220px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  width: 1004px;
  height: 158px;
  padding-left: 24px;
  align-items: flex-start;
  gap: 45px;

  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
`;

export const PaymentSection = styled.div`
  width: 200px;
  height: 110px;

  div {

    input {
      width: 148px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
    }
`;

export const Payment = styled.div`
  width: 1004px;
  height: 202px;
  margin: 0 auto;
  margin-top: 34px;
`;

export const SectionTitle = styled.div`
  width: 1004px;
  margin-bottom: 20px;

  span {
    color: var(--Cool-gray-100, #121619);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Contract = styled.div`
  width: 1004px;
  margin: 0 auto;
  margin-top: 32px;
`;

export const ContractInfoContainer = styled.div`
  width: 1004px;
  height: 93px;
  display: flex;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding-left: 24px;
`;

export const ContractSection = styled.div`
  width: 221px;
  height: 45px;
  display: flex;
  flex-direction: column;
`;

export const ContractButtonContainer = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;

  button {
    border: none;
    width: 200px;
  }
`;

export const CancelButtonContainer = styled.div`
  width: 1004px;
  height: 48px;
  margin: 0 auto;
  margin-top: 32px;

  button {
    background-color: ${({ theme }) => theme.color.rejected};

    border: none;
    width: 200px;
  }
`;

export const SaveEditButtons = styled.div`
  width: 1004px;
  height: 104px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin: 0 auto;
  padding-bottom: 24px;
  padding-left: 24px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Labelinput = styled.div`
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #656666;
    margin-bottom: 5px;
  }

  span + span {
    margin-left: 0.125rem;
    color: #f0654a;
  }

  .bNyJYp input {
    margin: 0;
    width: 200px;
    height: 62px;
  }

  .bNyJYp {
    height: 62px;
  }
`;

export const Identification = styled.span`
  color: var(--Cool-gray-100, #121619);
  font-size: 20px;
  font-weight: 500;
`;

export const PageName = styled.span`
  color: var(--Cool-gray-60, #697077);
  font-size: 20px;
  font-weight: 500;
`;
