import { EvolveGuaranteeToSimple, EvolveGuaranteeUpdateRequest } from 'model/evolve-guarantee';
import { action } from 'typesafe-actions';
import { UpdateEvolveGuaranteeTypes } from './types';

export const updateEvolveGuaranteeRequest = (request: EvolveGuaranteeUpdateRequest) =>
  action(UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_REQUEST, request);
export const updateEvolveGuaranteeSuccess = (guarantee: EvolveGuaranteeToSimple) =>
  action(UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_SUCCESS, guarantee);
export const updateEvolveGuaranteeError = (error: string) => action(UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_ERROR, error);
export const updateEvolveGuaranteeResetState = () => action(UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_RESET);
