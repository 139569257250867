import iconDefault from './iconDefault.svg';
import moneyIcon from './moneyIcon.svg';
import sliderIcon from './sliderIcon.svg';

import alertCircle from './alertCircle.svg';
import calendar from './calendar.svg';
import whiteCalendar from './white-calendar.svg';
import whiteCreditCard from './white-credit-card.svg';
import creditCard from './creditCard.svg';

import minus from './minus.svg';
import plus from './plus.svg';

import trash from './trash.svg';
import pencil from './pencil.svg';

import close from './close.svg';
import creditCheck from './creditCheck.svg';
import menuIcon from './menu-icon.svg';
import noResults from './no-results.svg';
import background1 from '../custom-organization/background1.png';
import background1Mobile from '../custom-organization/background1mobile.png';
import { ReactComponent as accessArrow } from './access-arrow.svg';
import React from 'react';

export const GeneralImages = {
  iconDefault,
  sliderIcon,
  moneyIcon,
  alertCircle,
  calendar,
  creditCard,
  minus,
  plus,
  close,
  creditCheck,
  noResults,
  menuIcon,
  accessArrow,
  background1,
  background1Mobile,
  trash,
  pencil,
  whiteCalendar,
  whiteCreditCard,
};

interface DropdownArrowProps {
  isOpen?: boolean;
  isDark?: boolean;
}

// seta dropdown
export const DropdownArrow: React.FC<DropdownArrowProps> = ({ isOpen, isDark }) => (
  //
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', cursor: 'pointer' }}
  >
    <path
      d="M2.14545 0.715152C1.75049 0.320185 1.11012 0.320184 0.715151 0.715152C0.320184 1.11012 0.320185 1.75049 0.715151 2.14545L7.29289 8.7232C7.68342 9.11372 8.31658 9.11372 8.70711 8.7232L15.2848 2.14545C15.6798 1.75049 15.6798 1.11012 15.2848 0.715152C14.8899 0.320184 14.2495 0.320184 13.8545 0.715152L8 6.5697L2.14545 0.715152Z"
      fill="#525252"
    />
  </svg>
);

interface XIconProps {
  isWrong?: boolean;
  onClick?: () => void;
}

export const XIcon: React.FC<XIconProps> = ({ isWrong, onClick }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: isWrong ? '' : 'pointer' }}
  >
    <path
      d="M6.01044 4.675L10.0177 0.667722C10.3865 0.298949 10.9844 0.29895 11.3532 0.667723C11.7219 1.0365 11.7219 1.63439 11.3532 2.00317L7.34589 6.01045L11.3532 10.0177C11.7219 10.3865 11.7219 10.9844 11.3532 11.3532C10.9844 11.7219 10.3865 11.7219 10.0177 11.3532L6.01044 7.34589L2.00317 11.3532C1.63439 11.7219 1.03649 11.7219 0.667722 11.3532C0.298949 10.9844 0.298949 10.3865 0.667722 10.0177L4.675 6.01045L0.667722 2.00317C0.298949 1.63439 0.298949 1.0365 0.667722 0.667722C1.03649 0.29895 1.63439 0.298949 2.00317 0.667722L6.01044 4.675Z"
      fill={isWrong ? '#FE3C4C' : '#455561'}
    />
  </svg>
);
