import { Logo } from 'images/custom-organization';
import CustomLogo from 'images/custom-organization/logo.png';
import { HamburguerSvg } from 'images/hamburguer';
import { useRegisterForm } from 'provider/register-form';
import { Button, Container } from './styles';

interface RegisterMobileHeaderProps {}

const StyledMobileRegisterHeader: React.FC<RegisterMobileHeaderProps> = props => {
  const { showMobileStepsModal, setShowMobileStepsModal } = useRegisterForm();

  return (
    <Container>
      <Logo isWhite={false} />
      <Button onClick={() => setShowMobileStepsModal(!showMobileStepsModal)}>
        <HamburguerSvg />
      </Button>
    </Container>
  );
};

export default StyledMobileRegisterHeader;
