export enum RegistrationStatus {
  PRE_REJECTED = 'PRE_REJECTED',
  NEW = 'NEW',
  VALIDATION = 'VALIDATION',
  ADJUSTED = 'ADJUSTED',
  ADJUST = 'ADJUST',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SIGN = 'PENDING_SIGN',
}
