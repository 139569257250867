import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/register-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPartnerIncomeProps {
  onClickBack: () => void;
  onlyView?: boolean;
  visible?: boolean;
}

export const ContainerInputsPartnerIncome: React.FC<IPartnerIncomeProps> = props => {
  const { t } = useTranslation();
  const { initialPartnerData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();
  const { getOccupations, occupations, occupationsTotalPages } = useSelectLists();
  const [occupationsPage, setOccupationsPage] = useState(0);
  const [occupationName, setOccupationName] = useState('');

  useEffect(() => {
    getOccupations({ name: occupationName, page: occupationsPage, size: 7 }, true);
  }, [occupationsPage]);

  return (
    <FormInputsContainer>
      <Row>
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="occupation"
            placeholder={t('register.inputs.occupation')}
            defaultValue={initialPartnerData?.occupation?.name}
            options={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
            defaultOptions={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
            handleChange={values => {
              setOccupationName(values?.name);
              setOccupationsPage(0);
              getOccupations({ name: values?.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (occupationsPage < occupationsTotalPages - 1) {
                setOccupationsPage(occupationsPage + 1);
              }
            }}
          />
        </TextInput>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="income"
          label={t('register.inputs.partnerIncome')}
          placeholder={t('register.placeholder.partnerIncome')}
          mask={Mask.CURRENCY}
          defaultValue={initialPartnerData?.income ? initialPartnerData?.income.toFixed(2) : ''}
          maxLength={14}
        />
      </Row>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.addPartner') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
