import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/register-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import InputText from 'components/styled-input';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';

interface IPartnerDataProps {
  onClickBack: () => void;
  onlyView?: boolean;
  visible?: boolean;
}

export const ContainerInputsPartnerData: React.FC<IPartnerDataProps> = props => {
  const { t } = useTranslation();
  const { initialPartnerData, isLastForm, isLoading } = useRegisterForm();

  return (
    <FormInputsContainer>
      <Row>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialPartnerData?.name}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={initialPartnerData?.birth ? StringUtils.dateFormatMask(initialPartnerData?.birth.toString()) : ''}
        />
      </Row>
      <Row>
        <InputText
          isRequired
          name="phone"
          disabled={props.onlyView}
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialPartnerData?.phone}
        />
        <InputText
          isRequired
          name="cpf"
          disabled={props.onlyView}
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialPartnerData?.cpf}
        />
      </Row>
      <Row>
        <InputText
          isRequired
          name="rg"
          disabled={props.onlyView}
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialPartnerData?.rg}
          maxLength={9}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialPartnerData?.email}
        />
      </Row>
      <ButtonsContainer>
        <Button type="button" onClick={props.onClickBack}>
          <span>{t('global.button.backStep')}</span>
        </Button>
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.addPartner') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
