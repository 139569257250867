import insuranceApi from 'api/insurance-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { Insurance } from 'model/insurance';

const insuranceService = () => {
  const getInsuranceByFinancing = async (financingId: number): Promise<Insurance> => {
    try {
      const result: AxiosResponse<Insurance> = await insuranceApi.getInsuranceByFinancing(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelInsurance = async (financingId: number): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await insuranceApi.cancelInsurance(financingId);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve();
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getInsuranceByFinancing,
    cancelInsurance,
  };
};

export default insuranceService();
