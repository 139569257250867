import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import Header from 'components/general-components/header';
import SimulatorFinancing from 'components/simulator-financing';
import { ReactComponent as ChevronRightSvg } from 'images/blue-chevron-right.svg';
import { HeaderVariants } from 'model/enums/header-variants';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledContainer, StyledInnerContainer } from './styles';

const AddNewFinancing = ({ t }: WithTranslation) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledInnerContainer>
        <BlueCustomButtonNoBackground onClick={() => history.push('/creditos')} style={{ marginBottom: '20px' }}>
          <ChevronRightSvg style={{ transform: 'rotate(180deg)' }} />
          {t('global.button.backStep')}
        </BlueCustomButtonNoBackground>
        <SimulatorFinancing />
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(AddNewFinancing);
