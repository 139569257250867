import { AxiosResponse } from 'axios';
import ContractQueueExists from 'model/enums/contract-queue-exists';
import { api } from './api';

const contractQueueApi = () => {
  const createContractQueue = (financingId: number): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/contract-queues/financing/${financingId}`);
  };

  const getContractQueueExists = (financingId: number): Promise<AxiosResponse<ContractQueueExists>> => {
    return api.get<ContractQueueExists>(`/contract-queues/financing/${financingId}/exists`);
  };

  return {
    createContractQueue,
    getContractQueueExists,
  };
};

export default contractQueueApi();
