import Header from 'components/general-components/header';
import MyContractsContainer from 'components/simulator-result/my-contracts';
import { HeaderVariants } from 'model/enums/header-variants';

interface MyContractsProps {}

const MyContracts: React.FC<MyContractsProps> = props => {
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <MyContractsContainer />
    </>
  );
};

export default MyContracts;
