import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { VaduCreditEngine, VaduCreditEngineLog } from 'model/vadu-credit-engine';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import vaduService from 'services/vadu-service';
import { useTheme } from 'styled-components';
import { GenericFieldProps } from '../clientData/components/RegisterDataDisplay';
import { CampDisplay } from '../clientData/components/RegisterDataDisplay/campDisplay';
import * as S from './styles';

const VaduScreen = () => {
  const methods = useForm({});
  const { color } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams<{ clientId: string }>();

  const [vaduCreditEngine, setVaduCreditEngine] = useState<VaduCreditEngine>({});

  useEffect(() => {
    if (params.clientId) {
      vaduService
        .getAnalysisInfo(Number(params.clientId))
        .then(vadu => {
          setVaduCreditEngine(vadu);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [params.clientId]);

  const renderAnalysisGroup = (analysis: VaduCreditEngineLog) => {
    return (
      <S.GroupContainer>
        <S.GroupTitle>{analysis.ruleDescription}</S.GroupTitle>
        <CampDisplay camps={getCamps(analysis)} />
      </S.GroupContainer>
    );
  };

  const getCamps = (analysis: VaduCreditEngineLog): GenericFieldProps[] => {
    return [
      {
        name: t('vaduScreen.ruleCondition'),
        content: analysis.ruleCondition,
        isFullLineField: true,
      },
      {
        name: t('vaduScreen.error'),
        content: analysis.error,
      },
      {
        name: t('vaduScreen.alert'),
        content: analysis.alert,
      },
      {
        name: t('vaduScreen.block'),
        content: analysis.block,
      },
    ];
  };

  return (
    <S.Container>
      <Header variant={HeaderVariants.SECONDARY} />
      <S.TitleHeader>
        <S.Title>
          <div className="firstTitle">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              <FiChevronLeft color={color.primaryColor} />
            </button>
            <span className="title">{t('vaduScreen.title')}</span>
          </div>
        </S.Title>
      </S.TitleHeader>

      <S.TabContainer>
        <FormProvider {...methods}>
          {vaduCreditEngine.logs?.map(analysis => {
            return renderAnalysisGroup(analysis);
          })}
        </FormProvider>
      </S.TabContainer>
    </S.Container>
  );
};

export default VaduScreen;
