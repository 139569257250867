import { ReactSelectOption, ReactSelectSearch, ReactSelectSearchProps } from 'components/react-select-search';
import { useEffect, useState } from 'react';
import _ from 'lodash';

export interface AsyncReactSelectSearchProps extends ReactSelectSearchProps {
  name: string;
  initialPageNumber?: number;
  pageSize?: number;
  search: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => Promise<void>;
}

export function mapObjectArrayToReactSelectOption<T>(obj: T[], fields: { label: keyof T; value: keyof T }): ReactSelectOption[] {
  return obj.map(o => mapObjectToReactSelectOption(o, fields));
}

export function mapObjectToReactSelectOption<T>(obj: T, fields: { label: keyof T; value: keyof T }): ReactSelectOption {
  return {
    value: String(obj[fields.value]),
    label: String(obj[fields.label]),
  };
}

export const AsyncReactSelectSearch = ({
  placeholder,
  onMenuScrollToBottom,
  onChange,
  initialPageNumber,
  options,
  search,
  pageSize,
  name,
}: AsyncReactSelectSearchProps) => {
  const [page, setPage] = useState<number>(initialPageNumber ?? 0);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    loadOptions({ size: pageSize, page, name: searchText }, true);
  }, [page]);

  useEffect(() => {
    if (searchText.length > 2) {
      setPage(0);
      loadOptions({ size: pageSize, name: searchText });
    }
  }, [searchText]);

  const loadOptions = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    await search(param, scroll);
  };

  const onScrollToBottom = () => {
    setPage(page + 1);
    if (onMenuScrollToBottom) {
      onMenuScrollToBottom();
    }
  };

  const onSearchChange = (text: string) => {
    setSearchText(text);
  };

  const debounceOnSearchChange = _.debounce(onSearchChange, 500);

  const clearAll = () => {
    setSearchText('');
    setPage(0);
  };

  return (
    <ReactSelectSearch
      name={name}
      placeholder={placeholder}
      onMenuScrollToBottom={onScrollToBottom}
      options={options}
      onChange={onChange}
      onMenuClose={clearAll}
      onInputChange={debounceOnSearchChange}
    />
  );
};
