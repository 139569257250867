import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import AuthUtils from 'shared/util/auth-utils';

interface IPageNotFoundProps extends WithTranslation, RouteComponentProps {
  // TODO
}

class PageNotFound extends React.Component<IPageNotFoundProps> {
  $defaultPath: string;
  constructor(props) {
    super(props);
    this.$defaultPath = 'pageNotFound';
  }

  redirectToMainPath = () => {
    this.props.history.replace(AuthUtils.getMainPath());
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Row className={'base-full-height'}>
          <Col md={12} className={'base-centered-column'}>
            <div className={'base-access-denied-wrapper'}>
              <Alert color={'danger'}>
                <span>{t(`${this.$defaultPath}.title`)}</span>
              </Alert>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(PageNotFound));
