import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components/macro';

export const Page = styled.div`
  width: auto;
  height: auto;
`;

export const OptionContainer = styled.option`
  width: 300px;
  height: 100%;
  background: red;
`;

export const SelectContainer = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 16px;


  select {
    border: none;
    background: none;
  }

  section {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
  }
  }
`;

export const MonthList = styled.div`
  width: 100%;
  height: 375px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  z-index: 10;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  div {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 16px;

    color: var(--Cool-gray-100, #121619);
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

export const YearList = styled.div`
  width: 100%;
  height: 375px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  z-index: 10;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  div {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    padding: 16px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

export const CalendarContainer = styled.div`
  width: 340px;
  height: 350px;
  max-height: 350px;

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 9999px;
    background: var(--Other-c, #e5eefe);
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 999px;
    background: var(--Other-i, #8089ab);
    color: ${props => props.theme.color.white} !important;
  }

  .react-datepicker__day-names {
    white-space: nowrap;
    margin-bottom: -8px;
    display: none;
    width: 0%;
    height: 0%;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    width: 40px;
    height: 40px;
    color: var(--Cool-gray-100, #121619);
    font-size: 14px;
    font-weight: 600;

    &:hover {
      border-radius: 9999px;
    }
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 6px;

    color: var(--Cool-gray-100, #121619);
    font-size: 14px;
    font-weight: 600;
  }

  .react-datepicker {
    // font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
    border: none;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${({ theme }) => theme.color.white};
    border-bottom: none;
    border: none;
    padding: 0px;
    position: relative;
  }
`;

export const InputContainer = styled.div`
  width: 340px;
  height: 75px;
  display: flex;
  gap: 24px;
  justify-content: center;

  label {
    width: 148px;
    text-align: left;
    margin-bottom: 8px;

    color: var(--Cool-gray-100, #121619);
    font-size: 16px;
    font-weight: 600;
  }

  section {
    width: 148px;
    height: 75px;

    input {
      padding-left: 16px;
      width: 148px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid var(--Cool-gray-50, #878d96);
      background: var(--White, #fff);
    }
    .date-input {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: none;
      background-color: white;
      border: 1px solid #ccc;
      padding: 0.5rem;
      border-radius: 4px;
    }

    .date-input::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;

export const Separator = styled.div`
  width: 340px;
  height: 1px;
  background: #dde1e6;
  margin: 16px 0;
`;

export const IncreaseButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 600;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const DayOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  width: 317px;
  margin: 0 auto;
  margin-bottom: 0px;

  div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
  }
`;
