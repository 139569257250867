import { HttpRequestStatus } from 'model/enums/http-request-status';
import { User } from 'model/user';
import { UserParameters } from 'model/user-parameters';
export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
  LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
  LOGIN_ADMIN_REQUEST = '@@authentication/LOGIN_ADMIN_REQUEST',
  LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
  LOGIN_VALIDATE = '@@authentication/LOGIN_VALIDATE',
  GET_SESSION_REQUEST = '@@authentication/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@authentication/GET_SESSION_SUCCESS',
  GET_SESSION_ERROR = '@@authentication/GET_SESSION_ERROR',
  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',
  GET_ACCOUNT_PARAMETERS_REQUEST = '@@authentication/GET_ACCOUNT_PARAMETERS_REQUEST',
  GET_ACCOUNT_PARAMETERS_SUCCESS = '@@authentication/GET_ACCOUNT_PARAMETERS_SUCCESS',
  GET_ACCOUNT_PARAMETERS_ERROR = '@@authentication/GET_ACCOUNT_PARAMETERS_ERROR',
}

export interface AuthenticationState {
  readonly loginStatus: HttpRequestStatus;
  readonly getSessionStatus: HttpRequestStatus;
  readonly isAuthenticated: boolean;
  readonly account?: User;
  readonly accountParametersStatus: HttpRequestStatus;
  readonly accountParameters?: UserParameters;
  readonly error?: string;
}
