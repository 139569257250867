import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { referencesType } from 'shared/util/select-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerReferences } from './container-references';
import { ContainerInputsReferences } from './inputs-references';

export const FormReferencesMonjua = props => {
  const { onClickNext, initialClientData, references, setReferences } = useEditForm();
  const { t } = useTranslation();
  const { getResidenceType, residenceType } = useSelectLists();
  const [showAddReferences, setShowAddReferences] = useState(references.length === 0 ? true : false);

  useEffect(() => {
    getResidenceType();
  }, []);

  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    phone: yup.string().required(t('global.errorMessage.required')),
    referenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return referencesType.map(item => item.label).includes(value);
        } else {
          return false;
        }
      }),
    homePhone: yup.string(),
    workPhone: yup.string(),
  });

  return (
    <div style={{ display: props.visible && 'none' }}>
      {showAddReferences && !props.onlyView ? (
        <FormContainer>
          <ReactHookFormProvider
            schema={schema}
            onSubmit={res => {
              const newRes = { ...res, type: referencesType.find(ref => ref.label === res.referenceType)?.value };
              setReferences([...references, newRes]);
              setShowAddReferences(false);
            }}
          >
            <ContainerInputsReferences visible={props.visible} />
          </ReactHookFormProvider>
        </FormContainer>
      ) : (
        <FormContainer>
          <ContainerReferences setShowAddReferences={setShowAddReferences} />
        </FormContainer>
      )}
    </div>
  );
};
