import { AxiosResponse } from 'axios';
import { api } from './api';
import { ContestationStatus, CreditCardContest } from 'model/credit-card';

const creditCardApi = () => {
  const contest = (contestData: CreditCardContest): Promise<AxiosResponse<CreditCardContest>> => {
    return api.patch<CreditCardContest>('/financing-credit-cards/contest', contestData);
  };

  const getContestationStatus = (clientId: number): Promise<AxiosResponse<ContestationStatus[]>> => {
    return api.get<ContestationStatus[]>(`/financing-credit-cards/contestations-to-solve-admin/client/${clientId}`);
  };

  return {
    contest,
    getContestationStatus,
  };
};

export default creditCardApi();
