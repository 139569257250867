import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mailingDestinationType, referencesType } from 'shared/util/select-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerReferences } from './container-references';
import { ContainerInputsReferences } from './inputs-references';

export const FormReferencesMonjua = () => {
  const { onClickNext, initialClientData, references, setReferences } = useRegisterForm();
  const { t } = useTranslation();
  const { getResidenceType, residenceType } = useSelectLists();
  const [showAddReferences, setShowAddReferences] = useState(references.length === 0 ? true : false);

  useEffect(() => {
    getResidenceType();
  }, []);

  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    phone: yup.string().required(t('global.errorMessage.required')),
    referenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return referencesType.map(item => item.label).includes(value);
        } else {
          return false;
        }
      }),
    homePhone: yup.string(),
    workPhone: yup.string(),
  });

  const handleSubmit = () => {
    if (onClickNext) {
      const newRes = {
        physicalPerson: {
          ...initialClientData?.physicalPerson,
          references,
        },
      };

      onClickNext(newRes as Client);
    }
  };

  return showAddReferences ? (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const newRes = { ...res, type: referencesType.find(ref => ref.label === res.referenceType)?.value };
          setReferences([...references, newRes]);
          setShowAddReferences(false);
        }}
      >
        <ContainerInputsReferences />
      </ReactHookFormProvider>
    </FormContainer>
  ) : (
    <FormContainer>
      <ContainerReferences setShowAddReferences={setShowAddReferences} handleSubmit={handleSubmit} />
    </FormContainer>
  );
};
