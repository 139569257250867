import SliderIcon from 'components/slider/sliderIcon';
import { SliderBar, SliderBox } from './style';

export interface Mark {
  value: number;
  label?: React.ReactNode;
}
interface SliderProps {
  color?: string;
  min?: number;
  max?: number;
  value?: number;
  handleChange: (event: React.SyntheticEvent | Event, newValue: number | number[]) => void;
  disabled?: boolean;
  marks?: boolean | Mark[];
}

const Slider: React.FC<SliderProps> = ({ color, value, handleChange, min, max, disabled, marks }) => {
  return (
    <SliderBox>
      <SliderBar
        components={{ Thumb: SliderIcon }}
        min={min}
        max={max}
        step={marks ? null : 1}
        defaultValue={value}
        value={value}
        onChangeCommitted={handleChange}
        onChange={handleChange}
        disabled={disabled}
        marks={marks}
      />
    </SliderBox>
  );
};

export default Slider;
