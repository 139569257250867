import { createContext } from 'react';
import useResponsiveMobile from 'shared/util/use-responsive-hook';

interface IUseResponsiveContextProvider {
  children: JSX.Element;
}

interface IPropsUseResponsiveContext {
  isMobile: boolean;
  isTablet: boolean;
  isMidTablet: boolean;
}
const DEFAULT_VALUE = {
  isMobile: false,
  isTablet: false,
  isMidTablet: false,
};

const UseResponsiveContext = createContext<IPropsUseResponsiveContext>(DEFAULT_VALUE);

const UseResponsiveContextProvider: React.FC<IUseResponsiveContextProvider> = ({ children }) => {
  const { isMobile, isTablet, isMidTablet } = useResponsiveMobile();

  return <UseResponsiveContext.Provider value={{ isMobile, isTablet, isMidTablet }}>{children}</UseResponsiveContext.Provider>;
};

export { UseResponsiveContextProvider };
export default UseResponsiveContext;
