import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components/macro';

interface Props {
  emptyList?: boolean;
}

export const NewStyledButton = styled(BlueCustomButtonNoBackground)`
  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};
  border-radius: 24px;
  width: 190px;
  display: flex;
  justify-content: center;
  transition: 200ms;

  svg path {
    fill: transparent;
    transition: 200ms;
  }

  &:hover {
    color: #ffff;
    border: 1px solid transparent;
    background: ${props => props.theme.color.primaryColorLight};
    transition: 200ms;

    svg {
      margin-top: -4px;
      transition: 200ms;
    }

    svg path {
      fill: white;
      transition: 200ms;
    }
  }
`;

export const CampsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
`;

export const StepCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 5px;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;

  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #7f7f7f;
  cursor: pointer;
  user-select: none;

  ${(props: { selected: boolean; theme: any }) =>
    props.selected && {
      color: props.theme.color.primaryColor,
      background: '#ffffff',
      fontWeight: '700',
      border: '1px solid #e5e7e7',
      boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
      transition: '500ms',
    }}
`;

export const SelectedStep = styled.div``;

export const AddAnalistButton = styled.button`
  border: none;
  background: transparent;
  height: 24px;
  margin-left: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: ${(props: { theme: any }) => props.theme.color.primaryColor};
  flex: none;
  order: 1;
  flex-grow: 0;
  gap: 10px;
  display: flex;
  align-items: center;
  transition: 200ms;
`;

export const AnalisisStatus = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  min-width: 120px;
  padding: 6px;
  border-radius: 4px;
  background-color: ${(props: { styling: any }) => props.styling};
`;

export const Alignor = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 48px;
  background-color: #e5e7e7;
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  height: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #999999;
  width: 150px;

  & > span:nth-child(2) {
    font-weight: 500;
    color: #333333;
  }
`;

export const ShadowBox = styled.div`
  background-color: rgba(250, 250, 250, 0.9);
  box-shadow: -10px 0px 10px rgba(250, 250, 250, 1);
  width: 1vw;
  height: 126px;
  border-top-left-radius: 10px;
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 190px;
  height: 48px;
  background: ${props => (props.value ? '#ffff' : props.disabled ? props.theme.color.primaryColorLight : props.theme.color.primaryColor)};
  color: ${props => (!props.value ? '#ffff' : props.theme.color.primaryColor)};
  border: ${props => `1px solid ${props.theme.color.primaryColor}`};
  border-radius: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  transition: 200ms;
`;

export const StyledArrow = styled(IoIosArrowBack)`
  color: ${({ theme }) => theme.color.primaryColor};
  font-size: 28px;
  cursor: pointer;
`;

export const TitleArea = styled.h1`
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Satoshi';
  line-height: 150%;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  transition: 200ms;
  margin-bottom: 35px;

  &:hover {
    margin-left: -6px;
    transition: 200ms;
  }
`;

export const FirstAreaContainer = styled.div`
  width: 1004px;
  min-height: 128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;

  button {
    min-height: 48px;
    border-radius: 8px;
  }
`;

export const SecondAreaContainer = styled.div`
  width: 1004px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const ThirdAreaContainer = styled.div`
  width: 1004px;
  min-height: 140px;
  display: flex;
  gap: 5px;
  padding-right: 24px;
  overflow-x: auto;
  overflow-y: hidden;

  align-items: center;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props: { theme: any }) => props.theme.color.primaryColor};
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100%;
  overflow: auto;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddButton = styled.button<Props>`
  display: flex;
  flex-direction: ${({ emptyList }) => (emptyList ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  height: 120px;
  border: 1.5px dashed ${({ theme }) => theme.color.primaryColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ emptyList }) => (emptyList ? '20px' : '30px')};

  width: ${({ emptyList }) => (emptyList ? '100%' : '56px')};

  > span {
    padding-left: ${({ emptyList }) => (emptyList ? '13px' : '0')};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};
    text-align: center;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1004px;
  align-items: center;
`;

export const StyledStatusContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 600px;
`;

export const StyledAutocompleteContainer = styled.div`
  margin: 54px 0;
`;
