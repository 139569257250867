import styled from 'styled-components/macro';

interface Props {
  noHeader?: boolean;
  blank?: boolean;
  internal?: boolean;
  fullLength?: boolean;
  paddingLeft?: string;
  centerLine?: boolean;
}

interface PropsColumn {
  maxWidth?: string;
  paddingLeft?: string;
}

export const Container = styled.div`
  margin: 0 auto;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
`;

export const PaginationContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 1004px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  cursor: pointer;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const Table = styled.div<Props>`
  overflow-x: auto;
  overflow-y: auto;
  background-color: ${({ theme, internal }) => (internal ? 'transparent' : theme.color.white)};
  border: 1px solid ${({ theme, internal }) => (internal ? 'transparent' : theme.color.borderGray)};
  border-radius: 8px;
  padding: 16px 0px;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 1004px;
  max-height: 450px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TableContainer = styled.div`
  width: 1004px;
  max-height: 450px;
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

export const Column = styled.div<PropsColumn>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
`;

export const Header = styled.span<Props>`
  min-height: 24px;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, noHeader }) => (noHeader ? 'transparent' : theme.color.black0)};
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
  cursor: pointer;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.gray20};
  margin: 9px 0px;
`;

export const Row = styled.div<Props>`
  cursor: pointer;
  margin: ${({ centerLine }) => (centerLine ? '0 auto' : '0px')};

  & > span {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${({ theme, blank }) => (blank ? 'transparent' : theme.color.gray19)};

    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${({ fullLength }) => !fullLength && 200}px;
    padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};

    @media only screen and (max-width: 1145px) {
      max-width: ${({ fullLength }) => !fullLength && 100}px;
    }

    @media only screen and (max-width: 1550px) {
      max-width: ${({ fullLength }) => !fullLength && 150}px;
    }
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-contente: center;
  align-items: center;
`;

export const InputPage = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray22};
    margin-right: 16px;
  }

  input + span {
    margin-left: 16px;
  }

  input {
    max-width: 40px;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.gray23};
    border-radius: 6px;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black0};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.gray22};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ButtonsPage = styled.div`
  button.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  button.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;

  :hover {
    border-color: ${({ theme }) => theme.color.primaryColorDarker};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;
