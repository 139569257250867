import { CircularProgress } from '@mui/material';
import { ReactComponent as ViewContratSvg } from 'images/view-contract.svg';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { PaintSvg, ProcessingLodingText, StlyedProcessingContainer, StyledProcessingText, StyledProcessingTitle } from './styles';

interface Props extends WithTranslation {
  showSummaryLoading?: boolean;
}

const LoadingDashboard = ({ showSummaryLoading, t }: Props) => {
  const theme = useTheme();

  return (
    <StlyedProcessingContainer>
      {!showSummaryLoading && (
        <>
          <StyledProcessingTitle>{t('simulatorFLow.processingScreen.almostThere')}</StyledProcessingTitle>
          <StyledProcessingText>{t('simulatorFLow.processingScreen.fewMinutes')}</StyledProcessingText>
        </>
      )}
      <PaintSvg className="viewContratSvg">
        <ViewContratSvg />
      </PaintSvg>
      <div>
        <CircularProgress style={{ margin: '48px 0px 0px 0px', display: 'block', color: theme.color.primaryColor }} />
      </div>
      <ProcessingLodingText>{t('simulatorFLow.processingScreen.processingData')}</ProcessingLodingText>
    </StlyedProcessingContainer>
  );
};

export default withTranslation()(LoadingDashboard);
