import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const GeneralFooter = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <span>{t('generalFooter.description')}</span>
    </Container>
  );
};

export default GeneralFooter;
