import { AxiosResponse } from 'axios';
import { Guarantor, GuarantorsInContract } from 'model/guarantor';
import { PageableResponse } from 'model/pageable';
import { api } from './api';

const guarantorApi = () => {
  const getAllGuarantors = (): Promise<AxiosResponse<PageableResponse<Guarantor>>> => {
    return api.get<PageableResponse<Guarantor>>('/guarantors');
  };

  const getAllGuarantorsInFinancing = (idFinancing: number): Promise<AxiosResponse<GuarantorsInContract>> => {
    return api.get<GuarantorsInContract>(`/financing/${idFinancing}/guarantors`);
  };

  return {
    getAllGuarantors,
    getAllGuarantorsInFinancing,
  };
};

export default guarantorApi();
