import { MobilePhotoComponent } from 'components/mobile-photo-component';
import UseResponsiveContext from 'context/use-responsive-context';
import { Attachment } from 'model/attachment';
import { AttachmentType } from 'model/enums/attachment-types';
import { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clientService from 'services/client-service';

export const SelfieTakePhoto = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const key = useParams<{ key: string }>().key;
  const id = useParams<{ id: string }>().id;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const { isMobile } = useContext(UseResponsiveContext);

  const handleSubmitPhoto = (photo: Attachment) => {
    setIsLoading(true);
    if (key) {
      if (isAdmin) {
        clientService
          .sendSelfieAdmin(photo, key)
          .then(() => {
            setIsLoading(false);
            history.replace(`/selfie/${id}/enviado`);
          })
          .catch(err => {
            setIsLoading(false);
            console.log('error', err);
          });
      } else {
        clientService
          .sendSelfie(photo, key)
          .then(() => {
            setIsLoading(false);
            history.replace('/selfie/enviado');
          })
          .catch(err => {
            setIsLoading(false);
            console.log('error', err);
          });
      }
    }
  };
  return (
    <>
      <MobilePhotoComponent isLoading={isLoading} type={AttachmentType.SELFIE} onSubmit={handleSubmitPhoto} />
    </>
  );
};

export default SelfieTakePhoto;
