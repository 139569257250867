import { ORGANIZATION_NAME } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _ from 'lodash';
import { ProcedureDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { OrganizationName } from 'model/enums/organization-name';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { getStorageFilter } from 'shared/util/filter-utils';
import StringUtils from 'shared/util/string-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import { TitleHeader } from '../../components/title-header';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { FinancingStatus } from 'model/enums/financing-status';

const filterValues = [
  FinancingStatus.NEW,
  FinancingStatus.IN_PROGRESS,
  FinancingStatus.VALIDATION,
  FinancingStatus.PENDING_PAYMENT,
  FinancingStatus.PENDING_SIGN,
  FinancingStatus.ADJUST,
  FinancingStatus.ADJUSTED,
  FinancingStatus.APPROVED,
  FinancingStatus.FINISHED,
  FinancingStatus.REJECTED,
  FinancingStatus.CANCELLED,
];

export const ProcedureList = () => {
  const [procedures, setProcedures] = useState<ProcedureDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const [orderBy, setOrderBy] = useState<boolean>(false);

  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;

  const { filters } = useContext(AdminFiltersContext);

  useEffect(() => {
    verifyStorageAndGetProcedures();
  }, [searchName, page, orderBy, searchSubsidiaryName, filters]);

  const verifyStorageAndGetProcedures = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    if (isHcred) {
      getDashboardProcedure({
        name: searchName,
        page,
        procedureStatus: filters?.statusFilters,
        createdDates: filters?.dateRange,
      });
    } else {
      getDashboardProcedure({
        name: searchName,
        page,
        procedureStatus: defaultFilters,
        createdDates,
        subsidiaryName: searchSubsidiaryName,
      });
    }
  };

  const getDashboardProcedure = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    procedureStatus?: string[];
    createdDates?: CreatedDates;
    subsidiaryName?: string;
  }) => {
    const res = await DashboardService().getDashboardProcedures(
      props?.name,
      props?.size,
      props?.page,
      props?.procedureStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName
    );

    setProcedures(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: t('admin.dashboard.tableHeaders.name'),
        values: procedures.map(
          procedure => procedure?.client?.legalPerson?.responsibleName ?? procedure?.client.physicalPerson?.name ?? ''
        ),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.procedure'),
        values: procedures.map(procedure => procedure.medicalProcedure),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.procedureValue'),
        values: procedures.map(procedure => StringUtils.moneyMaskPtbr(procedure.choosedValue)),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.lastModifiedDate'),
        values: procedures.map(procedure => formatDateToLocalFormat(procedure.lastModifiedDate)),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.createdDate'),
        values: procedures.map(procedure => formatDateToLocalFormat(procedure.createdDate)),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: procedures.map(procedure => (
          <div style={{ display: 'flex', alignItems: 'center', height: '24px' }}>
            <Status key={procedure.id} text={t(`enum.financingStatus.${procedure.financingStatus}`)} color={procedure.financingStatus} />
          </div>
        )),
        ids: procedures.map(procedure => ({ first: procedure.id })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/procedimentos/analise/${ids.first}`);
          }
        },
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardProcedure({ name: searchName, procedureStatus: statusFilters, createdDates });
  };

  const cleanAppliedFilters = () => {
    getDashboardProcedure({ name: searchName });
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.procedure')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        filterValues={filterValues}
        setFilters={setNewFilter}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <TableList tableValues={setTableValues()} page={page} totalPages={totalPages} setNewPage={setNewPage} />
    </>
  );
};
