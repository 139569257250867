import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { RegistrationStatus } from 'model/enums/registration-status';
import { Financing } from 'model/financing';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import financingService from 'services/financing-service';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';
import { StyledContainer } from './styles';

const MyProcedures = () => {
  const isMobile = useResponsiveMobile().isTablet;

  const [myProcedures, setMyProcedures] = useState<Financing[]>();

  const account = useSelector((state: IRootState) => state.authentication.account);
  const history = useHistory();

  useEffect(() => {
    if (
      account?.client?.registrationStatus === RegistrationStatus.VALIDATION ||
      account?.client?.registrationStatus === RegistrationStatus.NEW
    ) {
      return history.push('/analise-cadastro/status/validacao');
    }
    financingService.getMyFinancing().then(response => setMyProcedures(response.data.content));
  }, []);

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      {isMobile ? <Mobile myProcedures={myProcedures} /> : <Desktop myProcedures={myProcedures} />}
    </StyledContainer>
  );
};

export default MyProcedures;
