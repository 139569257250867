import { EvolveGuaranteeCreateRequest, EvolveGuaranteeToSimple } from 'model/evolve-guarantee';
import { action } from 'typesafe-actions';
import { CreateEvolveGuaranteeTypes } from './types';

export const createEvolveGuaranteeRequest = (request: EvolveGuaranteeCreateRequest) =>
  action(CreateEvolveGuaranteeTypes.CREATE_EVOLVE_GUARANTEE_REQUEST, request);
export const createEvolveGuaranteeSuccess = (id: EvolveGuaranteeToSimple) =>
  action(CreateEvolveGuaranteeTypes.CREATE_EVOLVE_GUARANTEE_SUCCESS, id);
export const createEvolveGuaranteeError = (error: string) => action(CreateEvolveGuaranteeTypes.CREATE_EVOLVE_GUARANTEE_ERROR, error);
export const createEvolveGuaranteeResetState = () => action(CreateEvolveGuaranteeTypes.CREATE_EVOLVE_GUARANTEE_RESET);
