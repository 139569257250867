import { withTranslation, WithTranslation } from 'react-i18next';
import { DesiredValue, DesiredValueButtons, DesiredValueContainer, LoanGracePeriodContainer, SectionTitle } from './styles';
import { SimulatorImages } from 'images/simulator';
import { unMaskedCurrency } from 'shared/util/register-utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import StringUtils from 'shared/util/string-utils';
import { IfirstDueDateInDays } from 'model/landing-page';
import TypesPlusMinus from 'model/enums/types-plus-minus';
import * as S from './styles';
import Slider, { Mark } from 'components/slider';

interface Props extends WithTranslation {
  firstDueDateInDays: IfirstDueDateInDays[];
  setChosenFirstDueDateInDaysId: Dispatch<SetStateAction<number | undefined>>;
}

const LoanGracePeriodComponent = ({ firstDueDateInDays, setChosenFirstDueDateInDaysId, t }: Props) => {
  const [desiredValue, setDesiredValue] = useState<string>('');
  const [minValue, setMinValue] = useState<number>();
  const [maxValue, setMaxValue] = useState<number>();

  useEffect(() => {
    setDesiredValue(firstDueDateInDays.find(option => option.isDefault)?.days.toString() ?? firstDueDateInDays[0]?.days.toString());
    setMaxValue(firstDueDateInDays[firstDueDateInDays.length - 1].days);
    setMinValue(firstDueDateInDays[0]?.days);
  }, []);

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setDesiredValue(newValue.toString());
    }
  };
  useEffect(() => {
    setChosenFirstDueDateInDaysId(firstDueDateInDays.find(option => option.days.toString() === desiredValue)?.id);
  }, [desiredValue]);

  const convertDueDateInSliderMarks = (): Mark[] => {
    return firstDueDateInDays.map(it => {
      return {
        value: it.days,
      };
    });
  };

  return (
    <>
      <S.SliderTitleContainer>
        <S.SliderTitle>{t('simulatorFLow.simulator.loanGracePeriod')}</S.SliderTitle>
        <S.SliderValue>{t('simulatorFLow.simulator.days', { day: desiredValue })}</S.SliderValue>
      </S.SliderTitleContainer>
      <Slider
        marks={convertDueDateInSliderMarks()}
        min={minValue}
        max={maxValue}
        value={Number(desiredValue)}
        handleChange={handleChange}
      />
    </>
  );
};

export default withTranslation()(LoanGracePeriodComponent);
