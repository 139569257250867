import Header from 'components/general-components/header';
import { StyledInnerContainer, StyledQRCodeContainer } from './styles';
import { StyledCardShowContractContainer } from './styles';
import { useTranslation } from 'react-i18next';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import CustomComponentModal from 'components/custom-conmponent-modal';
import ContractContext from 'context/contract';
import CardShowContract from 'components/card-show-contract';
import contractService from 'services/contract-service';
import QRCode from 'qrcode.react';

const GoToMobile = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveMobile();
  const history = useHistory();
  const urlParams = useParams();
  const signatureKey = urlParams['signatureKey'];
  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const { setContractResponse, setDocumentPresignUrl, documentPresignUrl, setSignatureKey, setSignatoryDocument } =
    useContext(ContractContext);

  useEffect(() => {
    if (isMobile || isTablet) {
      return history.push(`/assinar/${signatureKey}`);
    }

    if (signatureKey != null) {
      contractService
        .getContract(`${signatureKey}`)
        .then(response => {
          if (response) {
            setContractResponse(response);
            setSignatoryDocument(response.contractSignatory?.signatory?.documentNumber);
            setSignatureKey(response.contractSignatory?.signatureKey);
            setDocumentPresignUrl(response.contractSignatory?.contractSign?.contract.attachment.presignedUrl);
          }
        })
        .catch(() => history.push('/'));
    }
  }, [isMobile, isTablet]);

  const url = window.location.href.replace('qr-code', `assinar`);

  return (
    <>
      <Header variant="SECONDARY" />
      <StyledInnerContainer>
        <StyledQRCodeContainer>
          <h1>{t('goToMobile.title')}</h1>
          <p>{t('goToMobile.subtitle')}</p>
          <QRCode style={{ margin: '30px auto', width: '186px', height: '186px' }} renderAs="svg" value={url} />
        </StyledQRCodeContainer>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>
    </>
  );
};

export default GoToMobile;
