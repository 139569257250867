import { useTranslation } from 'react-i18next';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Desktop } from './desktop';
import { Mobile } from './mobile';
import { useSimulatorContext } from 'context/simulator';
import StringUtils from 'shared/util/string-utils';
import { SimulatorLoadingStage, useConfirmSimulationContext } from 'context/confirm-simulation';

interface InsuranceBenefit {
  title: string;
  description: string;
}

export interface SimulatorInsuranceProps {
  benefits?: InsuranceBenefit[];
  installmentValue?: string;
  isLoading?: boolean;
}

export const SimulatorInsurance = ({ installmentValue }: SimulatorInsuranceProps) => {
  const { t } = useTranslation();

  const { simulatorInsuranceInfo } = useSimulatorContext();
  const { loadingStage } = useConfirmSimulationContext();

  const isMobile = useResponsiveMobile().isTablet;

  const benefits: InsuranceBenefit[] = [
    {
      title: t('simulatorInsurance.benefits.benefit1'),
      description: t('simulatorInsurance.benefits.benefit1Description'),
    },
    {
      title: t('simulatorInsurance.benefits.benefit2'),
      description: t('simulatorInsurance.benefits.benefit2Description'),
    },
    {
      title: t('simulatorInsurance.benefits.benefit3'),
      description: t('simulatorInsurance.benefits.benefit3Description'),
    },
  ];

  return (
    <>
      {isMobile ? (
        <Mobile
          isLoading={loadingStage === SimulatorLoadingStage.SIMULATING_INSURANCE}
          installmentValue={StringUtils.currencyMask(simulatorInsuranceInfo?.simulate?.firstInstallmentValue?.toFixed(2))}
          benefits={benefits}
        />
      ) : (
        <Desktop
          isLoading={loadingStage === SimulatorLoadingStage.SIMULATING_INSURANCE}
          installmentValue={StringUtils.currencyMask(simulatorInsuranceInfo?.simulate?.firstInstallmentValue?.toFixed(2))}
          benefits={benefits}
        />
      )}
    </>
  );
};
