import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import { ReactComponent as GreenIconDropdown } from 'images/green-icon-dropdown.svg';
import { ReactComponent as IconDropdown } from 'images/icon-dropdown.svg';
import { ReactComponent as Trash } from 'images/trash.svg';
import { Guarantor } from 'model/guarantor';
import { Dispatch, SetStateAction, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import guarantorService from 'services/guarantor-service';
import { StyledLastSelected, StyledModalContainer, StyledModalData, StyledModalInnerContainer } from '../styles';

interface Props extends WithTranslation {
  isShowListDataModal: boolean;
  listGuarantorsToAddFinancing: Guarantor[];
  lastGuarantorsSelected?: Guarantor;
  setIsShowListDataModal: Dispatch<SetStateAction<boolean>>;
  setLastGuarantorsSelected: Dispatch<SetStateAction<Guarantor | undefined>>;
  setListGuarantorsToAddFinancing: Dispatch<SetStateAction<Guarantor[]>>;
}

const GuarantorSelection = ({
  isShowListDataModal,
  lastGuarantorsSelected,
  listGuarantorsToAddFinancing,
  setIsShowListDataModal,
  setLastGuarantorsSelected,
  setListGuarantorsToAddFinancing,
  t,
}: Props) => {
  const [allGuarantors, setAllGuarantors] = useState<Guarantor[]>([]);

  guarantorService.getAllGuarantors().then(response => {
    setAllGuarantors(response.data.content);
  });

  return (
    <StyledModalContainer>
      <StyledModalInnerContainer onClick={() => setIsShowListDataModal(!isShowListDataModal)}>
        <span>{t('dashboard.selectGuarantor')}</span>
        <IconDropdown />

        {isShowListDataModal && (
          <StyledModalData>
            {allGuarantors.map(guarantor => (
              <li
                key={`guarantor-list-${guarantor.name}`}
                onClick={() => {
                  setLastGuarantorsSelected(guarantor);

                  if (!listGuarantorsToAddFinancing.find(item => item.id === guarantor.id)) {
                    setListGuarantorsToAddFinancing([...listGuarantorsToAddFinancing, guarantor]);
                  }
                }}
              >
                {guarantor.name}
                {listGuarantorsToAddFinancing.find(item => item.id === guarantor.id) && <GreenIconDropdown />}
              </li>
            ))}
          </StyledModalData>
        )}
      </StyledModalInnerContainer>
      <BlueCustomButtonNoBackground>{t('global.button.registerNewGuarantor')}</BlueCustomButtonNoBackground>
      {lastGuarantorsSelected != null && (
        <StyledLastSelected>
          {lastGuarantorsSelected.name}
          <Trash
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const filteredList = listGuarantorsToAddFinancing.filter(item => item.id !== lastGuarantorsSelected.id);
              setListGuarantorsToAddFinancing(filteredList);
              setLastGuarantorsSelected(undefined);
            }}
          />
        </StyledLastSelected>
      )}
    </StyledModalContainer>
  );
};

export default withTranslation()(GuarantorSelection);
