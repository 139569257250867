import styled from 'styled-components';

export const InsuranceInfoContainer = styled.div`
  padding: 12px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const InsuranceInstallmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

export const InsuranceTitle = styled.span`
  font-size: 15px;
  font-weight: 700;
`;

export const InstallmentText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray21};

  b {
    color: ${({ theme }) => theme.color.primaryColor};
    font-weight: 700;
  }
`;

export const InstallmentValue = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const BenefitListContainer = styled.div``;

export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
`;

export const BenefitIconContainer = styled.div`
  min-width: 24px;
`;

export const BenefitTitleDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  max-width: 320px;
`;

export const BenefitTitle = styled.span`
  color: ${({ theme }) => theme.color.gray21};
  font-weight: 500;
  font-size: 12px;
`;

export const BenefitDescription = styled.span`
  color: ${({ theme }) => theme.color.gray21};
  font-weight: 400;
  font-size: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InstallmentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
