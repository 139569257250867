import registerApi from 'api/register-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

export const RegisterService = () => {
  const resendValidationEmail = async (documentNumber: string): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await registerApi.resendValidationEmail(documentNumber);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    resendValidationEmail,
  };
};

export default RegisterService();
