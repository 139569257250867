import { StyledTable, StyledTableRow, StyledTableRowNoData } from './styles';
import { ReactComponent as DollarSignSvg } from 'images/dollar-sign.svg';

interface IProps {
  headerData: string[];
  tableData: { [key: string]: string | JSX.Element }[];
  textNoData?: string;
}

const WhiteCustomTable = ({ headerData, tableData, textNoData }: IProps) => {
  return (
    <StyledTable>
      {tableData.length > 0 ? (
        <>
          <thead>
            <StyledTableRow>
              {headerData.map((data, index) => (
                <th key={`header-data-${index}`}>{data}</th>
              ))}
            </StyledTableRow>
          </thead>

          <tbody>
            {tableData.map((data, rowIndex) => (
              <StyledTableRow key={`row-data-${rowIndex}`} className={`${rowIndex + 1 === tableData.length ? 'removeBorder' : ''}`}>
                {Object.values(data).map((value, dataIndex) => (
                  <td key={`table-data-${dataIndex}`}>{value}</td>
                ))}
              </StyledTableRow>
            ))}
          </tbody>
        </>
      ) : (
        <tbody>
          <StyledTableRowNoData>
            <td style={{ padding: '20px' }}>
              <DollarSignSvg />
            </td>
          </StyledTableRowNoData>
          <StyledTableRowNoData>
            <td style={{ paddingBottom: '20px' }}>{textNoData}</td>
          </StyledTableRowNoData>
        </tbody>
      )}
    </StyledTable>
  );
};

export default WhiteCustomTable;
