import * as S from './styles';
import { ReactComponent as NavigationArrow } from 'images/navigation-arrow.svg';

interface Props {
  title: string;
  handleClick: () => void;
}

const PreviousPage = ({ title, handleClick }: Props) => {
  return (
    <S.Container onClick={() => handleClick()}>
      <NavigationArrow />
      {title}
    </S.Container>
  );
};

export default PreviousPage;
