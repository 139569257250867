import SystemStepCategory from './enums/system-step-category';
import TypeSubStepCategory from './enums/type-substep-category';
import { SubStep } from './organization-system-steps';

export const partnerPath: SubStep[] = [
  {
    parentStep: SystemStepCategory.PARTNER_DATA,
    stepOrder: 1,
    name: TypeSubStepCategory.PARTNER_GENERAL_DATA,
  },
  {
    parentStep: SystemStepCategory.PARTNER_DATA,
    stepOrder: 2,
    name: TypeSubStepCategory.PARTNER_ADDRESS,
  },
  {
    parentStep: SystemStepCategory.PARTNER_DATA,
    stepOrder: 3,
    name: TypeSubStepCategory.PARTNER_INCOME,
  },
  {
    parentStep: SystemStepCategory.PARTNER_DATA,
    stepOrder: 4,
    name: TypeSubStepCategory.PARTNER_DOCUMENTS,
  },
];
