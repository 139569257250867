import { PaymentStatus } from 'model/enums/payment-status';
import { PaymentData } from 'model/financing';
import { PaymentFormProperties } from 'model/procedurePayment';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupActions } from 'reactjs-popup/dist/types';
import StringUtils from 'shared/util/string-utils';
import ConfirmAction from '../confirm-action';
import AdminInput, { AdminInputType } from '../input';
import { BoxData, ButtonPayment, FormConfirmPayment } from './style';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Authority } from 'model/enums/authority';

interface ConfirmPaymentProps {
  paymentData?: PaymentData;
  setPaymentData?: (value) => void;
  onConfirm: () => void;
  reference: MutableRefObject<PopupActions>;
  isLoading: number;
  buttonPayment: string;
}

const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({ paymentData, setPaymentData, onConfirm, reference, isLoading, buttonPayment }) => {
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const { t } = useTranslation();

  return (
    <FormConfirmPayment>
      <BoxData>
        <AdminInput
          name={PaymentFormProperties.TOTAL_VALUE}
          required={true}
          htmlFor={PaymentFormProperties.TOTAL_VALUE}
          label={t('admin.customerAnalysis.tabPayments.totalValue')}
          formData={paymentData}
          setFormData={setPaymentData}
          maxLength={14}
          mask={StringUtils.currencyPtBrMask}
          type={AdminInputType.VIEW}
        />
        <AdminInput
          name={PaymentFormProperties.AMOUNT_PAID}
          required={true}
          htmlFor={PaymentFormProperties.AMOUNT_PAID}
          label={t('admin.customerAnalysis.tabPayments.paidValue')}
          formData={paymentData}
          setFormData={setPaymentData}
          maxLength={14}
          mask={StringUtils.currencyPtBrMask}
          type={AdminInputType.VIEW}
        />
        <AdminInput
          name={PaymentFormProperties.PAYMENT_STATUS}
          htmlFor={PaymentFormProperties.PAYMENT_STATUS}
          label={t('admin.procedureScreen.payments.paymentStatus')}
          status={paymentData?.paymentStatus ?? PaymentStatus.PENDING}
          statusColor={paymentData?.paymentStatus ?? PaymentStatus.PENDING}
          type={AdminInputType.STATUS}
        />
      </BoxData>
      {paymentData?.paymentStatus !== PaymentStatus.PAID &&
        (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) && (
          <ConfirmAction
            isLoading={isLoading}
            title={t('proceduresDashboard.confirmPayment')}
            text={t('proceduresDashboard.clickToConfirmDelete')}
            onConfirm={onConfirm}
            modalRef={reference}
            activateButton={<ButtonPayment>{buttonPayment}</ButtonPayment>}
          />
        )}
    </FormConfirmPayment>
  );
};

export default ConfirmPayment;
