import { ReactElement } from 'react';
import { ModalType } from '../layout/desktop';
import MyDataModal from './my-data-modal';

interface VerifyModalProps {
  text?: string;
  icon?: ReactElement;
  modalType?: string;
  setCloseOnDocumentClick: (value: boolean) => void;
  closeMenu: () => void;
}

const VerifyModal: React.FC<VerifyModalProps> = ({ text, icon, modalType, setCloseOnDocumentClick, closeMenu }) => {
  const renderContent = () => {
    switch (modalType) {
      case ModalType.MY_DATA:
        return <MyDataModal text={text} icon={icon} setCloseOnDocumentClick={setCloseOnDocumentClick} closeMenu={closeMenu} />;
      default:
        return <></>;
    }
  };

  return renderContent();
};

export default VerifyModal;
