import { HttpRequestStatus } from 'model/enums/http-request-status';
import { EvolveGuaranteeToSimple } from 'model/evolve-guarantee';

export type ApiResponse = Record<string, any>;

export enum CreateEvolveGuaranteeTypes {
  CREATE_EVOLVE_GUARANTEE_REQUEST = '@@evolve-guarantee-create/CREATE_EVOLVE_GUARANTEE_REQUEST',
  CREATE_EVOLVE_GUARANTEE_SUCCESS = '@@evolve-guarantee-create/CREATE_EVOLVE_GUARANTEE_SUCCESS',
  CREATE_EVOLVE_GUARANTEE_ERROR = '@@evolve-guarantee-create/CREATE_EVOLVE_GUARANTEE_ERROR',
  CREATE_EVOLVE_GUARANTEE_RESET = '@@evolve-guarantee-create/CREATE_EVOLVE_GUARANTEE_RESET',
}

export interface CreateEvolveGuaranteeState {
  readonly guarantee?: EvolveGuaranteeToSimple;
  readonly status: HttpRequestStatus;
  readonly error?: string;
}
