import { AxiosResponse } from 'axios';
import { api } from './api';
import {
  EvolveGuarantee,
  EvolveGuaranteeCreateRequest,
  EvolveGuaranteeToSimple,
  EvolveGuaranteeUpdateRequest,
} from 'model/evolve-guarantee';

export const evolveGuaranteeApi = () => {
  const baseUrl = '/evolve';

  const getEvolveGuarantee = (financingId: number): Promise<AxiosResponse<EvolveGuarantee>> => {
    return api.get<EvolveGuarantee>(`${baseUrl}/financing/${financingId}/guarantees`);
  };

  const createEvolveGuarantee = (request: EvolveGuaranteeCreateRequest): Promise<AxiosResponse<EvolveGuaranteeToSimple>> => {
    return api.post<EvolveGuaranteeToSimple>(`${baseUrl}/financing/${request.id}/guarantee`, request);
  };

  const updateEvolveGuarantee = (request: EvolveGuaranteeUpdateRequest): Promise<AxiosResponse<EvolveGuaranteeToSimple>> => {
    return api.patch<EvolveGuaranteeToSimple>(`${baseUrl}/guarantee/${request.id}`, request);
  };

  return {
    getEvolveGuarantee,
    createEvolveGuarantee,
    updateEvolveGuarantee,
  };
};

export default evolveGuaranteeApi();
