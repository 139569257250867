import DynamicDesktopEditForm from 'components/dynamic-desktop-edit-form';
import DynamicDesktopLegalPersonEditForm from 'components/dynamic-legal-person-desktop-edit-form';
import Header from 'components/general-components/header';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import { ContainerDesktop, TitleDesktop } from '../styles';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();
  const { steps, actualStep } = useEditForm();
  const { isPhysicalPerson } = useSystemParameters();

  const findTitle = () => {
    return steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
  };
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <ContainerDesktop>
        <div>
          <TitleDesktop>{t('registrationAdjustment.title')}</TitleDesktop>
          {isPhysicalPerson ? <DynamicDesktopEditForm type={findTitle()} /> : <DynamicDesktopLegalPersonEditForm onlyView={false} />}
        </div>
      </ContainerDesktop>
    </>
  );
};

export default Desktop;
