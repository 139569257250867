import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { Container } from './styles';

interface LoadingProps {
  invertColor?: boolean;
}

export const Loading = ({ invertColor }: LoadingProps) => {
  const { color } = useTheme();
  return (
    <Container>
      <ReactLoading type="balls" color={invertColor ? color.white : color.primaryColor} />
    </Container>
  );
};
