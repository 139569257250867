import { PhotoViewContainer, PhotoViewImage } from './styles';

interface PhotoViewProps {
  dataUri: string;
}

export const PhotoViewComponent = ({ dataUri }: PhotoViewProps) => {
  return (
    <PhotoViewContainer>
      <PhotoViewImage src={dataUri} />
    </PhotoViewContainer>
  );
};
