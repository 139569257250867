import styled from 'styled-components';

export const SimulatorSideBarContainer = styled.div`
  width: 600px;
  height: 488px;
  background: #ffffff;
  border: 1px solid #fafafa;
  border-radius: 10px;
  margin: 40px 0px 0px;
  margin-left: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1120px) {
    max-width: 600px;
    width: 100%;
    height: auto;
    padding: 16px;
    margin: 16px 0px 0px 0px;
  }
`;
