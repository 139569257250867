import { ScreenLoading } from './styled';

interface ILoadingScreenProps {
  isLoading?: boolean;
  children?: JSX.Element | JSX.Element[] | any;
  width?: string;
  minWidth?: string;
  height?: string;
  minHeight?: string;
}

import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ isLoading, children, width, minWidth, height, minHeight }) => {
  const theme = useTheme();

  return isLoading ? (
    <ScreenLoading customStyle={{ width: width, minWidth: minWidth, height: height, minHeight: minHeight }}>
      <ReactLoading type="spinningBubbles" color={theme.color.primaryColor} height={25} width={25} />
    </ScreenLoading>
  ) : (
    <>{children}</>
  );
};

export default LoadingScreen;
