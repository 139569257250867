import { SimulatorInsurance } from './simulator-insurance';
import * as S from './styles';

interface SimulatorSideBarProps {
  hasInsurance?: boolean;
}

export const SimulatorSideBar = ({ hasInsurance }: SimulatorSideBarProps) => {
  const hideBar = !hasInsurance;

  if (hideBar) {
    return <></>;
  }

  return <S.SimulatorSideBarContainer>{hasInsurance && <SimulatorInsurance />}</S.SimulatorSideBarContainer>;
};
