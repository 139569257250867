import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import { AlignContent, ScreenContent, StyledTitle, StyledTitleContainer, TableContent } from 'components/simulator-result/styled';
import { TableList } from 'components/simulator-result/table';
import { Status } from 'features/admin/dashboard/components/status';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';

import SearchInput from 'features/admin/dashboard/components/search-input';
import { ReactComponent as ChevronRight } from 'images/blue-chevron-right.svg';
import { RoutePath } from 'model/enums/route-path';
import { IMyPayments } from 'model/simulator-result';

export interface IPayments {}

interface DesktopProps {
  filterTable: IMyPayments[];
  setSearchByIdentification: (value: string) => void;
  page: string;
  setPage: (value: string) => void;
  totalPages: number;
  numberedPage: number;
  isLoading: boolean;
}

const Desktop: React.FC<DesktopProps> = ({
  filterTable,
  setSearchByIdentification,
  setPage,
  page,
  totalPages,
  numberedPage,
  isLoading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const headerData = [
    t('myFinancing.identificationNumber'),
    t('myFinancing.requestDate'),
    t('myFinancing.value'),
    t('myFinancing.installments'),
    t('myFinancing.status'),
    '',
  ];

  const tableData: { [key: string]: string | JSX.Element }[] = filterTable.map((payment, idx) => ({
    identificationNumber: payment.identification ?? '',
    createdDate: formatDateToLocalFormat(payment.createdDate) ?? '',
    value: `R$ ${StringUtils.maskAmount(payment?.choosedValue)}`,
    installments: `${payment.installmentsTotal}x R$${StringUtils.maskAmount(payment.installmentValue)}`,
    status:
      payment?.financingStatus != null ? (
        <Status
          key={payment.id}
          text={payment.payment?.status !== null ? t(`enum.contractStatus.${payment.payment?.status}`) : ''}
          color={payment.payment?.status ?? ''}
        />
      ) : (
        <></>
      ),
    access: (
      <BlueCustomButtonNoBackground
        onClick={() =>
          history.push(`${RoutePath.PAYMENTS}/${payment.id}`, {
            financingId: payment.id,
            financingStatus: payment.payment?.status,
          })
        }
      >
        {t('global.button.access')}
        <ChevronRight />
      </BlueCustomButtonNoBackground>
    ),
  }));

  return (
    <ScreenContent>
      <AlignContent>
        <StyledTitleContainer>
          <StyledTitle>{t('admin.dashboard.payments')}</StyledTitle>
          <SearchInput setSearch={setSearchByIdentification} />
        </StyledTitleContainer>
        <TableContent>
          <TableList
            headerData={headerData}
            tableData={tableData}
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            numberedPage={numberedPage}
            isLoading={isLoading}
          />
        </TableContent>
      </AlignContent>
    </ScreenContent>
  );
};

export default Desktop;
