import { ContainerInputsAddress } from 'components/edit-forms/address/inputs-address';
import { ContainerInputsGeneralData } from 'components/edit-forms/general-data/inputs-general-data';
import { EditFormLegalDocuments } from 'components/edit-forms/legal-documents';
import { ContainerInputsMyCompany } from 'components/edit-forms/my-company/inputs-my-company';
import { ContainerInputsPartnerAddress } from 'components/edit-forms/partner-address/inputs-partner-address';
import { EditFormPartnerDocuments } from 'components/edit-forms/partner-documents';
import { ContainerInputsPartnerGeneralData } from 'components/edit-forms/partner-general-data/inputs-partner-general-data';
import { Partner } from 'model/dashboard';
import { EditStep, partnerPath } from 'model/edit-paths';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeSubStepCategory from 'model/enums/type-substep-category';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { useEditForm } from 'provider/edit-form';
import { useEffect, useMemo, useState } from 'react';

export interface StepEditForm {
  step: OrganizationsSystemSteps;
  form: JSX.Element;
}

interface EditStepsProps {
  onlyView: boolean;
  visible?: string;
  steps: EditStep[];
  currentStep: EditStep | null;
}

export const EditLegalFormSteps = ({ steps, currentStep, onlyView }: EditStepsProps): JSX.Element => {
  const { getClientData } = useEditForm();
  useEffect(() => {
    getClientData();
  }, []);

  const elements: JSX.Element[] = useMemo(() => {
    return steps.map((step: EditStep) => {
      switch (step?.step) {
        case SystemStepCategory.GENERAL_DATA:
          return <ContainerInputsGeneralData onlyView={onlyView} visible={currentStep?.step === SystemStepCategory.GENERAL_DATA} />;
        case SystemStepCategory.ADDRESS:
          return <ContainerInputsAddress onlyView={onlyView} visible={currentStep?.step === SystemStepCategory.ADDRESS} />;
        case SystemStepCategory.MY_COMPANY:
          return <ContainerInputsMyCompany onlyView={onlyView} visible={currentStep?.step === SystemStepCategory.MY_COMPANY} />;
        case SystemStepCategory.LEGAL_PERSON_DOCUMENTS:
          return <EditFormLegalDocuments onlyView={onlyView} visible={currentStep?.step === SystemStepCategory.LEGAL_PERSON_DOCUMENTS} />;
        case TypeSubStepCategory.PARTNER_GENERAL_DATA:
          return (
            <ContainerInputsPartnerGeneralData
              onlyView={onlyView}
              visible={currentStep?.step === TypeSubStepCategory.PARTNER_GENERAL_DATA}
            />
          );
        case TypeSubStepCategory.PARTNER_ADDRESS:
          return <ContainerInputsPartnerAddress onlyView={onlyView} visible={currentStep?.step === TypeSubStepCategory.PARTNER_ADDRESS} />;
        case TypeSubStepCategory.PARTNER_DOCUMENTS:
          return <EditFormPartnerDocuments onlyView={onlyView} visible={currentStep?.step === TypeSubStepCategory.PARTNER_DOCUMENTS} />;
        default:
          return <></>;
      }
    });
  }, [steps, currentStep]);

  return <>{elements}</>;
};
