import { CustomRadioOption, DropDownFilter, FilterItem, InputContainer, SectionTitle } from './styles';
import { BiSearch } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import { RadioFillIcon } from 'images/radio-fill';
import { ReactComponent as RadioEmptyIcon } from 'images/radio-empty.svg';

interface SearchInputProps {
  setSearch?: (value: string) => void;
  setSearchOption: (value: string) => void;
  searchOption: string;
  title?: string;
}

export const SearchInputOptions = ({ searchOption, setSearchOption, setSearch, title }: SearchInputProps) => {
  const [value, setValue] = useState<string>('');
  const { color } = useTheme();
  const { t } = useTranslation();

  const handleSetValue = (text: string) => {
    setValue(text);
    if (setSearch) {
      setSearch(text);
    }
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => setIsOpen(false);
  const ref: MutableRefObject<PopupActions | null> = useRef(null);

  return (
    <>
      <Popup
        ref={ref}
        open={isOpen}
        closeOnDocumentClick
        onClose={closeModal}
        trigger={
          <InputContainer>
            <BiSearch color={color.primaryColor} size={16} />
            <input
              type="text"
              placeholder={!title ? t('admin.dashboard.search') : title}
              value={value}
              onChange={e => handleSetValue(e.target.value)}
            />
          </InputContainer>
        }
        position="bottom center"
        on="click"
      >
        <DropDownFilter>
          <SectionTitle className="status">{t('admin.signatures.searchFor')}</SectionTitle>
          <CustomRadioOption
            onClick={() => {
              if (setSearchOption) {
                setSearchOption('name');
              }
            }}
          >
            {searchOption === 'name' ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
            <span>{t('admin.dashboard.tableHeaders.name')}</span>
          </CustomRadioOption>
          <CustomRadioOption
            onClick={() => {
              if (setSearchOption) {
                setSearchOption('cpf');
              }
            }}
          >
            {searchOption === 'cpf' ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
            <span>{t('enums.campNames.cpf')}</span>
          </CustomRadioOption>
        </DropDownFilter>
      </Popup>
    </>
  );
};
