import registerApi from 'api/register-api';
import { Client } from 'model/client';
import { RegistrationStatus } from 'model/enums/registration-status';
import clientService from 'services/client-service';
import ClientService from 'services/client-service';

export interface ProcessingClientData {
  registrationStatus?: RegistrationStatus;
  client?: Client;
  clientRegistration?: Client;
}

export const useClientData = () => {
  const getClientData = async (registrationKey: string): Promise<ProcessingClientData | undefined> => {
    const clientRegistrationResponse = await clientService.getClientRegistration(registrationKey);

    const clientCpf = clientRegistrationResponse?.physicalPerson?.cpf;
    const clientCnpj = clientRegistrationResponse?.legalPerson?.cnpj;

    const clientDocument = clientCpf ?? clientCnpj;

    if (clientDocument) {
      const clientBasicInfoResponse = await ClientService.getBasicInfo(clientDocument);
      const isEmpty = Object.keys(clientBasicInfoResponse).length === 0;

      if (isEmpty) return Promise.resolve(undefined);

      return {
        registrationStatus: clientBasicInfoResponse.registrationStatus,
        client: clientBasicInfoResponse,
        clientRegistration: clientRegistrationResponse,
      };
    }

    return Promise.resolve(undefined);
  };

  return { getClientData };
};
