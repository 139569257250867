import ContainerUploadedDocument from 'components/container-uploaded-document';
import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Button, ButtonsContainer } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Container, DocumentContainer, IdentityContainer, Title } from './styles';
import ContainerDocument from 'components/container-document';
import { useEffect, useState } from 'react';
import StringUtils from 'shared/util/string-utils';

interface IResumeScreenProps {
  attachmentResume?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { onClickNext, files, isLastForm, isLoading } = useRegisterForm();
  const schema = yup.object({
    attachment: yup.mixed(),
    attachmentSocialContract: yup.mixed(),
    attachmentRevenue: yup.mixed(),
  });

  const [requiredError, setRequiredError] = useState<string[]>([]);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.REVENUE_DECLARATION)) {
      setRequiredError([]);
    }
  }, [files]);

  const handleSubmit = () => {
    if (
      !props.handleVerifyEmptyFields(AttachmentType.REVENUE_DECLARATION) ||
      !props.handleVerifyEmptyFields(AttachmentType.SOCIAL_CONTRACT)
    ) {
      onClickNext({ attachments: files } as Client);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.REVENUE_DECLARATION)) {
      setRequiredError(prevState => [...prevState, AttachmentType.REVENUE_DECLARATION]);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.SOCIAL_CONTRACT)) {
      setRequiredError(prevState => [...prevState, AttachmentType.SOCIAL_CONTRACT]);
    }
  };
  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ attachments: files } as Client)}>
      <Container>
        <DocumentContainer>
          <Title>{t('documents.socialContract.title')}</Title>
          <IdentityContainer>
            <ContainerDocument
              requiredError={requiredError.includes(AttachmentType.SOCIAL_CONTRACT)}
              titleDocument="documents.socialContract"
              editDocument
              nameInput="doc1"
              name="attachmentSocialContract"
              type={AttachmentType.SOCIAL_CONTRACT}
              title={files && files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT))}
            />
            <ContainerUploadedDocument editDocument attachmentType={AttachmentType.SOCIAL_CONTRACT} />
          </IdentityContainer>
          <DocumentContainer>
            <Title>{t('documents.revenueDeclaration.title')}</Title>
            <IdentityContainer>
              <ContainerDocument
                requiredError={requiredError.includes(AttachmentType.REVENUE_DECLARATION)}
                titleDocument="documents.revenueDeclaration"
                editDocument
                nameInput="doc2"
                name="attachmentRevenue"
                type={AttachmentType.REVENUE_DECLARATION}
                title={files && files.find(att => att.type === AttachmentType.REVENUE_DECLARATION)?.fileName}
                docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.REVENUE_DECLARATION))}
              />
              <ContainerUploadedDocument editDocument attachmentType={AttachmentType.REVENUE_DECLARATION} />
            </IdentityContainer>
          </DocumentContainer>
        </DocumentContainer>
      </Container>
      <ButtonsContainer>
        {props.actualStep !== 0 ? (
          <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </ReactHookFormProvider>
  );
};
