import Header from 'components/general-components/header';
import FoundedDataContainer from 'components/landing-page/found-data';
import { HeaderVariants } from 'model/enums/header-variants';

interface FoundedDataProps {}

const FoundedData: React.FC<FoundedDataProps> = props => {
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <FoundedDataContainer />
    </>
  );
};

export default FoundedData;
