import CustomButton from 'components/custom-button';
import { ReactComponent as XSvg } from 'images/x.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClosingArea,
  FilterNameButtonContainer,
  FilterNameContainer,
  InputContainer,
  Labelcontainer,
  ModalFilterName,
  SeparatorInput,
  SerachResponseContainer,
  SerachResponseContent,
} from './styles';

interface TableListFiltersProps {
  values: string[];
  searchName?: string;
  setSearchName?: (value: string) => void;
  setIsFilterName?: (value: boolean) => void;
  onClick?: () => void;
  onCloseModal: () => void;
  showNameFilter: boolean;
  setShowNameFilter: (value: boolean) => void;
}

const TableListFilterName: React.FC<TableListFiltersProps> = ({
  onCloseModal,
  searchName,
  setSearchName,
  values,
  setIsFilterName,
  onClick,
  showNameFilter,
  setShowNameFilter,
}) => {
  const [isShowResult, setIsShowResult] = useState<boolean>(values.length > 0);
  const [currentName, setCurrentName] = useState('');
  const { t } = useTranslation();

  return (
    <FilterNameContainer>
      <ModalFilterName expanded={showNameFilter}>
        <ClosingArea onClick={() => setShowNameFilter(false)}>
          <div>
            <XSvg />
          </div>
        </ClosingArea>
        <section>
          <Labelcontainer>
            <span>{'Nome'}</span>
          </Labelcontainer>
          <InputContainer>
            <input
              value={searchName}
              onChange={e => {
                setSearchName ? setSearchName(e.target.value) : '';
                e.target.value.length > 0 ? setIsShowResult(true) : setIsShowResult(false);

                if (setIsFilterName) {
                  e.target.value.length > 0 ? setIsFilterName(true) : setIsFilterName(false);
                }
              }}
              name="reason"
              placeholder={'Procure pelo nome'}
            />
          </InputContainer>

          {isShowResult ? (
            <SerachResponseContainer>
              {values.map((value, index) => (
                <>
                  <SerachResponseContent
                    onClick={() => {
                      setCurrentName(value);
                      setIsShowResult(!isShowResult);
                      setIsFilterName ? setIsFilterName(false) : '';
                    }}
                  >
                    <span key={index}>{value}</span>
                  </SerachResponseContent>
                  <SeparatorInput />
                </>
              ))}
            </SerachResponseContainer>
          ) : (
            <> </>
          )}

          <FilterNameButtonContainer>
            <CustomButton isInvertColor height={48} width="200px" onClick={() => onCloseModal()}>
              {t('global.button.cancel')}
            </CustomButton>
            <CustomButton
              height={48}
              width="200px"
              onClick={() => {
                setSearchName ? setSearchName(currentName) : '';
                setSearchName ? setSearchName(searchName ? searchName : '') : '';
                onClick ? onClick() : '';
                onCloseModal();
              }}
            >
              {t('global.button.apply')}
            </CustomButton>
          </FilterNameButtonContainer>
        </section>
      </ModalFilterName>
    </FilterNameContainer>
  );
};

export default TableListFilterName;
