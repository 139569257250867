import LoadingScreen from 'components/loading-screen/loading-screen';
import { StyledTable, StyledTableRow } from 'components/white-custom-table/styles';
import { GeneralImages } from 'images/general-images';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { EmptyTitleBox, ImageBox } from '../styled';
import { Button, ButtonsPage, Container, InputPage, Pagination } from './styled';

interface TableListProps {
  headerData: string[];
  tableData: { [key: string]: string | JSX.Element }[];

  page: string;
  setPage: (page: string) => void;
  totalPages: number;
  editMode?: boolean;
  numberedPage: number;
  isLoading?: boolean;
}

export const TableList = ({ headerData, tableData, totalPages, page, setPage, numberedPage, isLoading }: TableListProps) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const isEmpty = tableData.length === 0;

  //   pagination --
  const onHandleChangePage = (value: string) => {
    const newPage = StringUtils.removeNonNumbersFromMaskedValue(value);

    setPage(newPage);
  };

  const onHandleClickLeft = () => {
    const newPage = Number(page) - 1;

    if (newPage >= 1) {
      setPage(newPage.toString());
    }
  };

  const onHandleClickRight = () => {
    const newPage = Number(page) + 1;

    if (newPage <= totalPages) {
      setPage(newPage.toString());
    }
  };

  const handleBlur = () => {
    if (numberedPage < 1) {
      setPage('1');
    } else if (numberedPage > totalPages) {
      setPage(totalPages.toString());
    }
  };
  //   pagination --

  return (
    <Container>
      <LoadingScreen isLoading={isLoading} height={'384px'}>
        {isEmpty ? (
          <EmptyTitleBox>
            <span className="title">{t('myFinancing.noResults')}</span>
            <ImageBox>
              <img src={GeneralImages.noResults} />
            </ImageBox>
          </EmptyTitleBox>
        ) : (
          <StyledTable>
            <thead>
              <StyledTableRow>
                {headerData.map((data, index) => (
                  <th key={`header-data-${index}`}>{data}</th>
                ))}
              </StyledTableRow>
            </thead>
            <tbody>
              {tableData.map((data, rowIndex) => (
                <StyledTableRow key={`row-data-${rowIndex}`} className={`${rowIndex + 1 === tableData.length ? 'removeBorder' : ''}`}>
                  {Object.values(data).map((value, dataIndex) => (
                    <td key={`table-data-${dataIndex}`}>{value}</td>
                  ))}
                </StyledTableRow>
              ))}
            </tbody>
          </StyledTable>
        )}
      </LoadingScreen>

      {!isEmpty && (
        <Pagination>
          <InputPage>
            <span>{t('admin.dashboard.table.page')}</span>
            <input
              type="number"
              placeholder={page.toString() ?? '1'}
              min="0"
              value={page.toString()}
              onChange={e => onHandleChangePage(e.target.value)}
              onBlur={handleBlur}
            />
            <span>{t('admin.dashboard.table.totalPage', { total: totalPages })}</span>
          </InputPage>
          <ButtonsPage>
            <Button className="left" onClick={onHandleClickLeft}>
              <FiChevronLeft color={color.primaryColor} />
            </Button>
            <Button className="right" onClick={onHandleClickRight}>
              <FiChevronRight color={color.primaryColor} />
            </Button>
          </ButtonsPage>
        </Pagination>
      )}
    </Container>
  );
};
