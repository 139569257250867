import creditCardApi from 'api/credit-card-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ContestationStatus, CreditCardContest } from 'model/credit-card';
import HttpStatus from 'model/enums/http-status';

const creditCardService = () => {
  const getContestationStatus = async (clientId: number): Promise<ContestationStatus[]> => {
    try {
      const res: AxiosResponse<ContestationStatus[]> = await creditCardApi.getContestationStatus(clientId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const contest = async (contestData: CreditCardContest): Promise<CreditCardContest> => {
    try {
      const res: AxiosResponse<CreditCardContest> = await creditCardApi.contest(contestData);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getContestationStatus,
    contest,
  };
};

export default creditCardService();
