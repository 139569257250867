import { useEffect } from 'react';
import Liveness from './Livenessv5.9.js';
interface IProps {
  sucessFunction: () => void;
  setBase64?: (string) => void;
  apiPath?: string;
}

export const LivenessComponent = ({ sucessFunction, setBase64, apiPath }: IProps) => {
  useEffect(() => {
    async function start() {
      const config = {
        width: 720, // largura de exibição da câmera
        isDebug: false,
        token: '',
        faceapiPath: 'https://cdn.jsdelivr.net/gh/nextcodebr/liveness-sdk-web-sample/libs/', // caminho para a faceapi e os modelos
        livenessUrlBase: apiPath, // endpoint da api liveness
        livenessConfirmEndpoint: '/create-liveness', // id +
        isShowPreview: false, // exibir um preview da foto que será enviada
        errorCallback: error, // metodo de callback em caso de erro (status !== 200)
        successCallback: success, // metodo de callback em caso de sucesso (status: 200 com isAlive = true ou false)
        brightnessControl: 118, // padrão 108 - controla a tolerancia do brilho para submeter a selfie (quanto menor o valor, maior a tolerancia e possibilidade de isAlive=false)
        luminanceControl: 25, // padrão 23 - controla a tolerancia da luminância para submeter a selfie (quanto menor o valor, maior a tolerancia e possibilidade de isAlive=false)
        ellipseStrokeStyle: '#D02780', // padrão '#D02780' - cor da elipse que encaixa o rosto - pode ser o nome da cor ou hexadecimal
        activatedEllipseStrokeStyle: '#46E3C3', // padrão '#46E3C3' - cor da elipse ao detectar o rosto - pode ser o nome da cor ou hexadecimal
        boxMessageBackgroundColor: '#D02780', // padrão '#D02780' - cor de fundo da caixa de mensagem - pode ser o nome da cor ou hexadecimal
        boxMessageTextColor: '#f3f3f5', // padrão '#f3f3f5' - cor a fonte da caixa de mensagem - pode ser o nome da cor ou hexadecimal
        configEyesBoxHeight: 100, // padrão 100 - setar a altura da caixa dos olhos em pixels (soma ou subtrai da altura padrão)
      };

      const videoWrapper = document.getElementById('video-wrapper');
      (window as any).liveness = new Liveness(videoWrapper, config);
      (window as any).liveness.start();
    }

    function success(response) {
      if (setBase64) {
        setBase64(response.base64);
      }
      sucessFunction();
    }

    function error(error) {
      console.log('error', error);
      if (setBase64) {
        setBase64(error.base64);
      }
    }

    start();

    return () => {
      (window as any).liveness.stop();
    };
  }, []);

  return <div id="video-wrapper"></div>;
};
