export enum UploadedFileType {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PNG = 'image/png',
  DNG = 'image/DNG',
  HEIF = 'image/heif',
  HEIC = 'image/heic',
  HEICs = 'image/heics',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
}
