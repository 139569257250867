import { AxiosResponse } from 'axios';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import { AdminStepType } from 'model/org-step-type';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { api } from './api';
import { buildQueryContainsString, buildQueryString } from 'shared/util/query-utils';

const organizationsSystemStepsApi = () => {
  const getSteps = (
    stepType?: StepType | StepType[],
    stepMoment?: StepMoment | StepMoment[]
  ): Promise<AxiosResponse<OrganizationsSystemSteps[]>> => {
    const queryParams = buildQueryContainsString({ stepType, stepMoment });
    return api.get<OrganizationsSystemSteps[]>(`/organization-system-steps/organization${queryParams}`);
  };

  const getStepAdmin = (): Promise<AxiosResponse<AdminStepType[]>> => {
    return api.get<AdminStepType[]>('/organization-steps-type/organization/admin');
  };

  return {
    getSteps,
    getStepAdmin,
  };
};

export default organizationsSystemStepsApi();
