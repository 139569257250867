import { SelectListsProvider } from 'provider/select-list';
import { Desktop } from './layout';
import { EditFormProvider } from 'provider/edit-form';

interface IEditProfileProps {}

const BranchsScreen: React.FC<IEditProfileProps> = props => {
  return (
    <SelectListsProvider>
      <EditFormProvider>
        <Desktop />
      </EditFormProvider>
    </SelectListsProvider>
  );
};

export default BranchsScreen;
