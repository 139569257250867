import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import * as S from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';

const ScreenTermsOfUse = ({ t }: WithTranslation) => {
  return (
    <>
      <Header variant={HeaderVariants.TERTIARY} />
      <S.Container>
        <S.Title>{t('screenTermsOfUse.title')}</S.Title>
        <S.Text className="italic">{t('screenTermsOfUse.updatedOn')}</S.Text>
        <S.Text>{t('screenTermsOfUse.termsOfUse')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.definitions.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.definitions.definitionsText')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.creditFlow')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.client')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.account')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.document')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.platform')}</S.Text>
        <S.Text>{t('screenTermsOfUse.definitions.user')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.acceptanceTerms.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.acceptanceTerms.terms.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.acceptanceTerms.terms.clauseTwo')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.conditionsAccessPlatform.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseFour')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseFive')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseSix')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseSeven')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseEight')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseNine')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseNineParagraphOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseNineParagraphTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseNineParagraphThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseTen')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseEleven')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseElevenParagraphOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseElevenParagraphTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseElevenParagraphThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.conditionsAccessPlatform.clauseElevenParagraphFour')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.payment.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.payment.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.payment.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.payment.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.payment.clauseFour')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.intellectualProperty.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseTwoParagraphOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseTwoParagraphTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseTwoParagraphThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.intellectualProperty.clauseFour')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.guarantees.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseFour')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseFive')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseSix')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseSeven')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseEight')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNine')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNineParagraphOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNineParagraphTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNineParagraphThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNineParagraphFour')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseNineParagraphFive')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseTen')}</S.Text>
        <S.Text>{t('screenTermsOfUse.guarantees.clauseEleven')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.rescission.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.rescission.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.rescission.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.rescission.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.rescission.clauseFour')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.controversy.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.controversy.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.controversy.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.controversy.clauseThree')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.generalProvisions.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.generalProvisions.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.generalProvisions.clauseTwo')}</S.Text>
        <S.Text>{t('screenTermsOfUse.generalProvisions.clauseThree')}</S.Text>
        <S.Text>{t('screenTermsOfUse.generalProvisions.clauseFour')}</S.Text>
        <S.Text>{t('screenTermsOfUse.generalProvisions.clauseFive')}</S.Text>

        <S.Subtitle>{t('screenTermsOfUse.applicableLaw.title')}</S.Subtitle>
        <S.Text>{t('screenTermsOfUse.applicableLaw.clauseOne')}</S.Text>
        <S.Text>{t('screenTermsOfUse.applicableLaw.clauseTwo')}</S.Text>

        <S.Text style={{ fontWeight: 'bold' }}>{t('screenTermsOfUse.footer')}</S.Text>
      </S.Container>
    </>
  );
};

export default withTranslation()(ScreenTermsOfUse);
