import styled from 'styled-components/macro';

interface Props {
  large?: boolean;
  minHeight?: boolean;
}

export const StyledContainer = styled.div<Props>`
  width: ${({ large }) => (large ? '630px' : 'max-content')};
  max-height: 85%;
  min-height: 420px;
  min-height: ${({ minHeight }) => (minHeight ? '220px' : '420px')};
  position: relative;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  padding: ${({ large }) => (large ? '24' : '40')}px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  @media (max-width: 575.98px) {
    overflow-y: auto;
    width: 80%;
  }
`;

export const StyledXIconContainer = styled.div`
  text-align: right;
`;

export const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin: 15px 0 16px 0;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
`;

export const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 16px;

  button {
    border-radius: 8px;
  }
`;

export const IconContainer = styled.div`
  margin-top: 20px;
`;
