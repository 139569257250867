import { CircularProgress } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ProcessingLodingText, StlyedProcessingContainer } from './styles';

interface Props extends WithTranslation {
  text?: string;
  hideProgressMargin?: boolean;
}

const LoadingJustProcessing = ({ text, hideProgressMargin, t }: Props) => {
  const theme = useTheme();

  return (
    <StlyedProcessingContainer>
      <div>
        <CircularProgress
          style={{ margin: `${hideProgressMargin ? '' : '48px 0px 0px 0px'}`, display: 'block', color: theme.color.primaryColor }}
        />
      </div>
      {text != null && <ProcessingLodingText>{text}</ProcessingLodingText>}
    </StlyedProcessingContainer>
  );
};

export default withTranslation()(LoadingJustProcessing);
