export enum GeneralStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  VALIDATION = 'VALIDATION',
  ADJUST = 'ADJUST',
  ADJUSTED = 'ADJUSTED',
  APPROVED = 'APPROVED',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  PRE_REJECTED = 'PRE_REJECTED',
  UPDATED = 'UPDATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  PAID = 'PAID',
  WAITING = 'Waiting',
  DONE = 'Done',
  ERROR = 'Error',
  WAITING_FOR_PAYMENT_CONFIRMATION = 'WAITING_FOR_PAYMENT_CONFIRMATION',
  IN_PROGRESS_ANALYSIS = 'IN_PROGRESS_ANALYSISIS',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SIGN = 'PENDING_SIGN',
}
