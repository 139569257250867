import Header from 'components/general-components/header';
import OrganizationWithoutLimitContainer from 'components/landing-page/organization-without-limit';
import { HeaderVariants } from 'model/enums/header-variants';

interface OrganizationWithoutLimitProps {}

const OrganizationWithoutLimit: React.FC<OrganizationWithoutLimitProps> = props => {
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <OrganizationWithoutLimitContainer />
    </>
  );
};

export default OrganizationWithoutLimit;
