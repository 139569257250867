import React, { useState } from 'react';
import EyeSvg from 'images/eye.svg';
import { Button, Eye } from './styles';
import { Partner } from 'model/dashboard';
import { useTranslation } from 'react-i18next';

export interface PartnerViewButtonProps {
  partner: Partner;
  index: number;
  onClick: () => void;
}

const PartnerViewButton = ({ partner, index, onClick }: PartnerViewButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>
      <div className="firstSection">
        <span className="title">{`${t('register.inputs.partner')} ${index + 1}`}</span>
        <span className="subtitle">{partner.name}</span>
      </div>
      {isHovered && <Eye src={EyeSvg} onClick={() => null} />}
    </Button>
  );
};

export default PartnerViewButton;
