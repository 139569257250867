import styled from 'styled-components/macro';

export const MainContent = styled.div`
  margin-top: 48px;
  with: max-content;
  display: flex;
  justify-content: center;
`;

export const ContentFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: 65%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ContentCalendarInputsContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;

  button {
    min-width: 158px;
    width: 158px;
    height: 48px;
    border-radius: 8px;
  }
`;

export const Mainn = styled.div`
  width: 484px;
  height: 647px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--White, #fff);
  padding: 32px 72px 32px 72px;
  margin: 0 auto;
  margin-top: 64px;
`;

export const TitleContainer = styled.div`
  width: 303px;
  display: flex;
  margin-bottom: 10px;

  span {
    color: var(--Cool-gray-100, #121619);
    font-size: 24px;
    font-weight: 500;
  }
`;
