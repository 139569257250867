import clientDataApi from 'api/clientData-api';
import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { DTIResponse } from 'model/dti';
import HttpStatus from 'model/enums/http-status';
import { FaceMatchAndOCRAdminResponse } from 'model/face-match-and-ocr';
import { MeuCrediarioResponse, MultiCreditResponse } from 'model/multi-credit-response';

export const ClientDataService = () => {
  const getClientSummary = async (clientId?: number): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientDataApi.getClientSummary(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getClientMultiCredit = async (clientId?: number): Promise<MultiCreditResponse> => {
    try {
      const result: AxiosResponse<MultiCreditResponse> = await clientDataApi.getClientMultiCredit(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getFaceMatchAndOCRAdmin = async (clientId?: number): Promise<FaceMatchAndOCRAdminResponse> => {
    try {
      const result: AxiosResponse<FaceMatchAndOCRAdminResponse> = await clientDataApi.getFaceMatchAndOCRAdmin(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const analyzeClientRegistration = async (newStatus: any, clientId: number): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await clientDataApi.analyzeClientRegistration(newStatus, clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientDTI = async (clientId?: number): Promise<DTIResponse> => {
    try {
      const result: AxiosResponse<DTIResponse> = await clientDataApi.getClientDTI(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientMeuCrediario = async (clientId?: number): Promise<MeuCrediarioResponse> => {
    try {
      const result: AxiosResponse<MeuCrediarioResponse> = await clientDataApi.getClientMeuCrediario(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getClientSummary,
    analyzeClientRegistration,
    getFaceMatchAndOCRAdmin,
    getClientMultiCredit,
    getClientDTI,
    getClientMeuCrediario,
  };
};

export default ClientDataService();
