import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container } from './styles.';
import LoadingDocument from 'components/loading-document';
import useWindowSize from 'hooks/use-window-size';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ContractPdfProps {
  url: string;
}

enum ContractViewScale {
  SMALL = 0.6,
  LARGE = 1.6,
}

const sizeInPixelsWideScreen = 768;

export const ContractPdf = ({ url }: ContractPdfProps) => {
  const [width] = useWindowSize();

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pages, setPages] = useState<number[]>([]);
  const [scale, setScale] = useState<number>(ContractViewScale.SMALL);

  const onDocumentLoadSuccess = (pages: number) => {
    setNumPages(pages);
  };

  const getPages = () => {
    if (numPages) {
      const pageArr: number[] = [];

      for (let i = 1; i <= numPages; i++) {
        pageArr.push(i);
      }

      setPages(pageArr);
    }
  };

  useEffect(() => {
    if (width > sizeInPixelsWideScreen) {
      setScale(ContractViewScale.LARGE);
    }
  }, [width]);

  useEffect(() => {
    getPages();
  }, [numPages]);

  return (
    <Container>
      <Document file={url} onLoadSuccess={pdf => onDocumentLoadSuccess(pdf.numPages)} loading={<LoadingDocument />} noData="">
        {pages.map(page => (
          <Page key={page} pageNumber={page} scale={scale} loading="" renderAnnotationLayer={false} renderTextLayer={false} />
        ))}
      </Document>
    </Container>
  );
};
