import * as S from './styles';
import { ReactComponent as XIconSvg } from 'images/gray-x.svg';

interface Props {
  text: string;
  onClick: () => void;
}

const FilterTag = ({ text, onClick }: Props) => {
  return (
    <S.Container>
      {text}
      <XIconSvg onClick={() => onClick()} style={{ cursor: 'pointer' }} />
    </S.Container>
  );
};

export default FilterTag;
