import { AxiosResponse } from 'axios';
import { ClientLP } from 'model/client';
import { CreateFinancingResponse } from 'model/create-financing-response';
import FilterParameters from 'model/filter-parameters';
import { FinancingSimpleSimulation } from 'model/financing';
import {
  CreditEngineData,
  OrganizationRules,
  OrganizationSubsidiaries,
  ResponseMaritalStatus,
  ResponsePreSimulator,
  ResponseResidence,
  CreditEngineMonjuaResponse,
  SendPreSimulator,
  SimulatorParameters,
  SimulatorFormData,
} from 'model/landing-page';
import { GetValidationType, OrganizationData } from 'model/organization-types';
import { buildUrl } from 'shared/util/api-utils';
import { api } from './api';

export const landingPageApi = () => {
  const getData = (): Promise<AxiosResponse<OrganizationData>> => {
    return api.get<OrganizationData>('/organization-landing-pages/organization');
  };
  const getSimulatorParameters = (): Promise<AxiosResponse<SimulatorParameters>> => {
    return api.get<SimulatorParameters>('/simulator-parameters/organization');
  };
  const getValidation = (type: string): Promise<AxiosResponse<GetValidationType[]>> => {
    return api.get<GetValidationType[]>(`/system-parameters/organization?parameter=${type}`);
  };
  const postPhysicalPerson = (data: ClientLP): Promise<AxiosResponse<SimulatorFormData>> => {
    return api.post<SimulatorFormData>(`/clients/physical-person/landing-page`, data);
  };
  const postLegalPerson = (data: ClientLP): Promise<AxiosResponse<SimulatorFormData>> => {
    return api.post<SimulatorFormData>(`/clients/legal-person/landing-page`, data);
  };
  const postPhysicalPersonAdmin = (data: ClientLP): Promise<AxiosResponse<SimulatorFormData>> => {
    return api.post<SimulatorFormData>(`/clients/physical-person/simple/admin`, data);
  };
  const postLegalPersonAdmin = (data: ClientLP): Promise<AxiosResponse<SimulatorFormData>> => {
    return api.post<SimulatorFormData>(`/clients/legal-person/simple/admin`, data);
  };
  const postSimulator = (data: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/simple`, data, {
      timeout: 180 * 1000,
    });
  };
  const postSimulationDraft = (data: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/draft`, data);
  };
  const checkClientMonthlyLimit = (id?: number): Promise<AxiosResponse<boolean>> => {
    return api.get<boolean>(`/organizations/client/${id}/check-monthly-limit`);
  };
  const getOrganizationRules = (id?: number): Promise<AxiosResponse<OrganizationRules>> => {
    return api.get<OrganizationRules>(`/organizations/${id}/limit`);
  };

  const getOrganizationSubsidiaries = (filterParameters?: FilterParameters): Promise<AxiosResponse<OrganizationSubsidiaries[]>> => {
    const url = buildUrl('/organization-subsidiaries/by-organization', filterParameters ?? []);
    return api.get<OrganizationSubsidiaries[]>(url);
  };

  const postOrganizationSubsidiaries = (id: number, clientId: number): Promise<AxiosResponse> => {
    return api.post(`/organization-subsidiaries/${id}/bind-with-client/${clientId}`);
  };
  const getMaritalStatus = (): Promise<AxiosResponse<ResponseMaritalStatus>> => {
    return api.get<ResponseMaritalStatus>(`/marital-status`);
  };
  const getResidenceType = (): Promise<AxiosResponse<ResponseResidence>> => {
    return api.get<ResponseResidence>(`/residence-type`);
  };

  const creditEngineMonjua = (data: CreditEngineData, id: number): Promise<AxiosResponse<CreditEngineMonjuaResponse>> => {
    return api.post<CreditEngineMonjuaResponse>(`/meu-crediario/credit-engine/client/${id}`, data);
  };
  const postSimulatorMonjua = (data?: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/simple/by-organization`, data, {
      timeout: 180 * 1000,
    });
  };
  const postSimulatorMonjuaDraft = (data?: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/by-organization/draft`, data);
  };

  const postSimulatorZmEmprestimos = (data?: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/simple/wscred`, data);
  };

  const postSimulatorZmEmprestimosDraft = (data?: FinancingSimpleSimulation): Promise<AxiosResponse<CreateFinancingResponse>> => {
    return api.post<CreateFinancingResponse>(`/financing/wscred/draft`, data);
  };

  const preSimulation = (data?: SendPreSimulator): Promise<AxiosResponse<ResponsePreSimulator>> => {
    return api.post<ResponsePreSimulator>(`/giro/simulate`, data);
  };

  return {
    getData,
    getSimulatorParameters,
    getValidation,
    postPhysicalPerson,
    postLegalPerson,
    postSimulator,
    postSimulationDraft,
    checkClientMonthlyLimit,
    getOrganizationRules,
    getOrganizationSubsidiaries,
    postOrganizationSubsidiaries,
    getResidenceType,
    getMaritalStatus,
    creditEngineMonjua,
    postSimulatorMonjua,
    postSimulatorMonjuaDraft,
    postSimulatorZmEmprestimos,
    postSimulatorZmEmprestimosDraft,
    postLegalPersonAdmin,
    postPhysicalPersonAdmin,
    preSimulation,
  };
};

export default landingPageApi();
