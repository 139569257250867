import UseResponsiveContext from 'context/use-responsive-context';
import { FinancingSimpleSimulation } from 'model/financing';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface StateType {
  sendSimulator?: FinancingSimpleSimulation;
}

export interface LocationType {
  state: StateType;
}

interface InactiveAccountContainerProps {
  location?: LocationType;
}

const InactiveAccountContainer: React.FC<InactiveAccountContainerProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default InactiveAccountContainer;
