import ButtonArrow from 'components/button-arrow';
import { StyledContainer, StyledTitle } from './styles';
import { ReactComponent as FrameSvg } from '../../images/frame-large.svg';
import { useTranslation } from 'react-i18next';

export interface CardShowContract {
  onClick?: () => void;
}

const CardShowContract = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <div>
        <StyledTitle>{t('letsSign.contract')}</StyledTitle>
        <div>{t('letsSign.readBeforeAssign')}</div>
        <ButtonArrow text={t('letsSign.openDocument')} white onClick={onClick} />
      </div>
      <div>
        <FrameSvg style={{ width: '100%' }} />
      </div>
    </StyledContainer>
  );
};

export default CardShowContract;
