import styled from 'styled-components';

interface Props {
  erroCancelling?: boolean;
  containsContract?: boolean;
}

export const MainContent = styled.div`
  margin: 0 auto;
`;

export const PageContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Menu = styled.div`
  position: fixed;
  display: flex;
  z-index: 2;
`;
export const Header = styled.div`
  width: 100vw;
  height: 104px;
  position: fixed;
  margin: 0 auto;
  display: flex;
  z-index: 1;
`;

export const BackGround = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const Page = styled.div`
  display: flex;
`;

export const CancelButtonContainer = styled.div<Props>`
  width: 1004px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: ${({ containsContract }) => (containsContract ? '0px' : '85px')};

  button {
    background-color: ${({ theme }) => theme.color.rejected};

    border: none;
    width: 200px;
  }
`;

export const InputContent = styled.div<Props>`
  width: 480px;
  height: 482px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color.white};

  > label + label {
    margin-top: 35px;
  }

  input {
    width: 397px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid var(--Cool-gray-50, #878d96);
    border: ${props => (props.erroCancelling ? '1px solid var(--Red-70, #A2191F)' : '1px solid var(--Cool-gray-50, #878d96)')};
    padding-left: 16px;

    ::placeholder {
      color: ${props => (props.erroCancelling ? 'var(--Red-70, #A2191F)' : 'var(--Cool-gray-50, #878d96)')};
    }
  }
`;

export const FormContainer = styled.div`
  height: 96px;

  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 306px;
    min-height: 53px;
  }

  label + label {
    margin-top: 16px;
  }
  span:nth-of-type(2) {
    color: red; /* Exemplo de estilo */
  }
`;

export const ModalContainer = styled.div`
  ${(props: { expanded: boolean }) => null}
  width: 480px;
  height: 482px;
  display: flex;
  pointer-events: ${(props: { expanded: boolean }) => (props.expanded ? '' : 'none')};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${(props: { expanded: boolean }) => (props.expanded ? '1' : '0')};
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: 500ms;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 20px;
  padding-bottom: 40px;
  border-radius: 8px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

export const ClosingArea = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
`;

export const ClosingIconContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  border-radius: 9999px;
  background: var(--Cool-gray-20, #dde1e6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelAnalysisContent = styled.div`
  width: 398px;
  height: 274px;
`;

export const CancelAnalysisTitle = styled.span`
  color: var(--Cool-gray-100, #121619);
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 32px;
`;

export const CancelAnalysisDescription = styled.span`
  color: var(--Cool-gray-100, #121619);
  font-size: 16px;
  font-weight: 400;
`;

export const CancelAnalysisDescriptionContainer = styled.div`
  width: 397px;
`;
export const InputContainer = styled.div`
  width: 397px;
  margin-top: 28px;

  span {
    color: var(--Cool-gray-90, #21272a);
    font-size: 16px;
    font-weight: 600;
  }
`;

export const CancelAnalysisButons = styled.div`
  width: 397px;
  display: flex;
  color: var(--Cool-gray-100, #121619);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  gap: 24px;
  margin-top: 32px;

  button {
    min-width: 188px;
    width: 188px;
  }
`;

export const CancelAnalysisWarning = styled.div`
  width: 397px;
  display: flex;
  margin-top: 28px;
  margin-bottom: 29px;
  gap: 8px;

  span {
    color: var(--Red-70, #a2191f);
    font-size: 16px;
    font-weight: 400;
  }

  section {
    width: 28px;
    height: 28px;
    border-radius: 9999px;
    background: var(--Red-30, #ffb3b8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Separator = styled.div`
  width: 100%;
  min-height: 1px;
  background: #dde1e6;
`;

export const HistoryActionModal = styled.div`
  ${(props: { expanded: boolean }) => null}
  width: 480px;
  height: 482px;
  display: flex;
  pointer-events: ${(props: { expanded: boolean }) => (props.expanded ? '' : 'none')};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${(props: { expanded: boolean }) => (props.expanded ? '1' : '0')};
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: 500ms;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 20px;
  padding-bottom: 40px;
  border-radius: 8px;
`;

export const NotificationBAr = styled.div`
  width: 1004px;
  height: 48px;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  algin-items: center;
  margin-top: 24px;

  span {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    color: var(--Cool-gray-100, #121619);
    font-size: 14px;
    font-weight: 400;
  }

  section {
    width: 3px;
    height: 48px;
    background: #4262d7;
  }

  div {
    display: flex;
    align-items: center;
    width: 20px;
    height: 48px;
    margin-left: 16px;
    margin-right: 16px;
  }

  strong {
    margin-right: 4px;
  }
`;

export const DetailsContainer = styled.div`
  margin-bottom: 32px;
`;
