import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import PreRegisterContext from 'context/pre-register';
import { HeaderVariants } from 'model/enums/header-variants';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/route-path';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Container, PageContainer, Title } from './styles';

interface LocationState {
  state: {
    isNewCredit: boolean;
  };
}

export const SimulatorApprovedModal = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location: LocationState = useLocation();

  const { registrationKey, setRegistrationKey } = useContext(PreRegisterContext);

  useEffect(() => {
    let getRegistrationKey = localStorage.getItem(LocalStorageKeys.REGISTRATION_KEY);
    if (getRegistrationKey) {
      const parse: string = JSON.parse(getRegistrationKey);
      setRegistrationKey(parse);
    }
  }, []);

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <FiCheckCircle size={60} color={color.green} />
        <Title>{t('admin.simulatorModal.title')}</Title>
        <CustomButton
          onClick={() =>
            location?.state?.isNewCredit
              ? history.replace(`/admin/cadastros/${clientId}`)
              : history.replace(`${RoutePath.REGISTER}${registrationKey}`)
          }
        >
          <span>{t('global.button.ok')}</span>
        </CustomButton>
      </Container>
    </PageContainer>
  );
};
