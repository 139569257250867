export const buildQueryContainsString = (params: Record<string, unknown | unknown[]>): string => {
  const modifiedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.map(item => `contains(${item})`)];
      } else if (value !== undefined) {
        return [key, `contains(${value})`];
      }
      return [key, value];
    })
  );

  return buildQueryString(modifiedParams);
};

export const buildQueryString = (params: Record<string, unknown | unknown[]>): string => {
  const queryParams = Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => `${key}=${item}`).join('&');
      } else if (value !== undefined) {
        return `${key}=${value}`;
      }
      return null;
    })
    .filter(queryPart => queryPart !== null)
    .join('&');

  return queryParams ? `?${queryParams}` : '';
};
