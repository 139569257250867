import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer, Column, FormInputsContainer } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { genderType, nationalityType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPersonalDataTopOne = () => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();
  const { maritalStatus, getMaritalStatus } = useSelectLists();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');

  useEffect(() => {
    getMaritalStatus();
  }, []);

  return (
    <FormInputsContainer>
      <Column>
        <InputText
          isRequired
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.physicalPerson?.name}
        />
        <InputText
          isRequired
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString()) : ''
          }
        />
        <InputText
          isRequired
          name="phone"
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.phone}
        />
        <InputText
          isRequired
          name="cpf"
          disabled
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialClientData?.physicalPerson?.cpf}
        />
        <InputText
          isRequired
          name="rg"
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialClientData?.physicalPerson?.rg}
          maxLength={20}
        />
        <InputText
          isRequired
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
        <InputText
          isRequired
          name="motherName"
          label={t('register.inputs.motherName')}
          placeholder={t('register.placeholder.motherName')}
          defaultValue={initialClientData?.physicalPerson?.motherName}
        />
        <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="genderType"
            placeholder={t('register.placeholder.genderType')}
            options={genderType}
            defaultOptions={genderType}
            defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
          />
        </TextInput>
        <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
          <SearchSelectInput
            name="maritalStatus"
            placeholder={t('register.placeholder.maritalStatus')}
            options={maritalStatus.map(occ => ({ label: occ.displayValue ?? '', value: occ.id.toString() }))}
            defaultValue={maritalStatus.find(occ => occ.id === initialClientData?.physicalPerson?.maritalStatus?.id)?.displayValue}
            handleChange={values => {
              setSelectedMaritalStatus(values.name);
            }}
            defaultOptions={maritalStatus.map(occ => ({ label: occ?.displayValue ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <TextInput name="nationalityType" label={t('register.inputs.nationalityType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="nationalityType"
            placeholder={t('register.placeholder.nationalityType')}
            options={nationalityType}
            defaultOptions={nationalityType}
            defaultValue={nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label}
          />
        </TextInput>
      </Column>

      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          fullSize
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
