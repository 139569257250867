import organizationsSystemStepsApi from 'api/organization-system-steps-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import { AdminStepType } from 'model/org-step-type';
import OrganizationsSystemSteps from 'model/organization-system-steps';

const organizationsSystemStepsService = () => {
  const getSteps = async (
    stepType?: StepType | StepType[],
    stepMoment?: StepMoment | StepMoment[]
  ): Promise<OrganizationsSystemSteps[]> => {
    try {
      const result: AxiosResponse<OrganizationsSystemSteps[]> = await organizationsSystemStepsApi.getSteps(stepType, stepMoment);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllRegistrationSteps = async (): Promise<AxiosResponse<OrganizationsSystemSteps[]>> => {
    try {
      const result: AxiosResponse<OrganizationsSystemSteps[]> = await organizationsSystemStepsApi.getSteps([
        StepType.REGISTRATION,
        StepType.AFTER_REGISTER,
      ]);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getStepAdmin = async (): Promise<AdminStepType[]> => {
    try {
      const result: AxiosResponse<AdminStepType[]> = await organizationsSystemStepsApi.getStepAdmin();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getSteps,
    getAllRegistrationSteps,
    getStepAdmin,
  };
};

export default organizationsSystemStepsService();
