import { EditFormProvider } from 'provider/edit-form';
import { SelectListsProvider } from 'provider/select-list';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface IProfileProps {}

const Profile: React.FC<IProfileProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;

  return (
    <SelectListsProvider>
      <EditFormProvider>
        <Desktop />
      </EditFormProvider>
    </SelectListsProvider>
  );
};

export default Profile;
