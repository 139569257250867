import ProfileIcon from 'features/admin/dashboard/components/profile-icon';
import { ReactComponent as EditIconSvg } from 'images/edit-pen.svg';
import { RoutePath } from 'model/enums/route-path';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import AuthUtils from 'shared/util/auth-utils';
import {
  DropdownContainer,
  LoggedUserBox,
  LogoutContainer,
  Separator,
  SvgBackground,
  SvgContainer,
  UserInformationContainer,
} from './style';

export interface ListType {
  icon?: ReactElement;
  text?: string;
  link?: string;
  modalType?: string;
}

export interface UserDropdownProps {
  position?: PopupPosition;
  menuOpen?: boolean;
  fullName?: string;
  email?: string;
}

const AdminDropdown: React.FC<UserDropdownProps> = ({ menuOpen = true, position, email, fullName }) => {
  const history = useHistory();
  const [closeOnDocumentClick, setCloseOnDocumentClick] = useState(true);
  const account = AuthUtils.getAccount();
  const { t } = useTranslation();

  return (
    <>
      <Popup
        trigger={
          <LoggedUserBox>
            <ProfileIcon />
          </LoggedUserBox>
        }
        position={position ? position : 'bottom center'}
        closeOnDocumentClick={closeOnDocumentClick}
        on="click"
        arrow={false}
      >
        <DropdownContainer>
          <UserInformationContainer onClick={() => history.push(RoutePath.ADMIN_PROFILE)}>
            <SvgContainer>
              <p>{`${account?.firstName}`}</p>
              <SvgBackground>
                <EditIconSvg />
              </SvgBackground>
            </SvgContainer>
            <span>{`${account?.email}`}</span>
          </UserInformationContainer>
          <Separator />
          <LogoutContainer onClick={() => history.push(RoutePath.LOGOUT)}>
            <span>{t('global.button.logout')}</span>
          </LogoutContainer>
        </DropdownContainer>
      </Popup>
    </>
  );
};

export default AdminDropdown;
