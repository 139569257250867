import i18n from 'config/i18n';
import { GenderType } from 'model/enums/gender-type';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';
import { MailingDestinationType } from 'model/enums/mailing-destination-type';
import { MaritalStatus } from 'model/enums/marital-status';
import { NationalityType } from 'model/enums/nationality-type';
import { ResidenceType } from 'model/enums/residence-type';

export const maritalStatus = [
  { label: i18n.t('enum.maritalStatus.single'), value: MaritalStatus.SINGLE },
  { label: i18n.t('enum.maritalStatus.married'), value: MaritalStatus.MARRIED },
  { label: i18n.t('enum.maritalStatus.divorced'), value: MaritalStatus.DIVORCED },
  { label: i18n.t('enum.maritalStatus.widow'), value: MaritalStatus.WIDOW },
  { label: i18n.t('enum.maritalStatus.separated'), value: MaritalStatus.SEPARETED },
  { label: i18n.t('enum.maritalStatus.other'), value: MaritalStatus.OTHER },
];

export const nationalityType = [
  { label: i18n.t('enum.nationalityType.brazilian'), value: NationalityType.BRAZILIAN },
  { label: i18n.t('enum.nationalityType.foreign'), value: NationalityType.FOREIGN },
];

export const genderType = [
  { label: i18n.t('enum.genderType.male'), value: GenderType.MALE },
  { label: i18n.t('enum.genderType.female'), value: GenderType.FEMALE },
];

export const identityDocumentTypes = [
  { label: i18n.t('enum.identityDocumentTypes.rg'), value: IdentityDocumentTypes.RG },
  { label: i18n.t('enum.identityDocumentTypes.cnh'), value: IdentityDocumentTypes.CNH },
  { label: i18n.t('enum.identityDocumentTypes.ctps'), value: IdentityDocumentTypes.CTPS },
];

export const residenceType = [
  { label: i18n.t('enum.residenceType.own'), value: ResidenceType.OWN },
  { label: i18n.t('enum.residenceType.rent'), value: ResidenceType.RENT },
  { label: i18n.t('enum.residenceType.relatives'), value: ResidenceType.RELATIVES },
];

export const mailingDestinationType = [
  { label: i18n.t('enum.mailingDestinationType.residence'), value: MailingDestinationType.RESIDENCE },
  { label: i18n.t('enum.mailingDestinationType.work'), value: MailingDestinationType.WORK },
];

export const employmentBondOptions = [
  { label: 'Assalariado', value: 'ASSALARIADO' },
  { label: 'Autônomo', value: 'AUTONOMO' },
  { label: 'Aposentado', value: 'APOSENTADO' },
  { label: 'Rural', value: 'RURAL' },
  { label: 'Pensionista', value: 'PENSIONISTA' },
  { label: 'Empresário', value: 'EMPRESARIO' },
  { label: 'Funcionário Público', value: 'FUNCIONARIO_PUBLICO' },
  { label: 'Profissional Liberal', value: 'PROFISSIONAL_LIBERAL' },
  { label: 'Militar', value: 'MILITAR' },
  { label: 'Outras', value: 'OUTRAS' },
];

export const scholarity = [
  { label: 'Fundamental Incompleto', value: 'ELEMENTARY_SCHOOL_INCOMPLETE' },
  { label: 'Fundamental Completo', value: 'ELEMENTARY_SCHOOL_COMPLETE' },
  { label: 'Ensino Médio Incompleto', value: 'HIGH_SCHOOL_INCOMPLETE' },
  { label: 'Ensino Médio Completo', value: 'HIGH_SCHOOL_COMPLETE' },
  { label: 'Superior Incompleto', value: 'BACHELOR_DEGREE_INCOMPLETE' },
  { label: 'Superior Completo', value: 'BACHELOR_DEGREE_COMPLETE' },
  { label: 'Pós Graduação Incompleta', value: 'GRADUATE_SCHOOL_INCOMPLETE' },
  { label: 'Pós Graduação Completa', value: 'GRADUATE_SCHOOL_COMPLETE' },
  { label: 'Mestrado Incompleto', value: 'MASTER_DEGREE_INCOMPLETE' },
  { label: 'Mestrado Completo', value: 'MASTER_DEGREE_COMPLETE' },
  { label: 'Doutorado Incompleto', value: 'DOCTORATE_INCOMPLETE' },
  { label: 'Doutorado Completo', value: 'DOCTORATE_COMPLETE' },
];

export const ownCar = [
  { label: 'Quitado', value: 'PAID' },
  { label: 'Financiado', value: 'FINANCED' },
  { label: 'Não', value: 'NO' },
];

export const ownMotorbike = [
  { label: 'Quitado', value: 'PAID' },
  { label: 'Financiado', value: 'FINANCED' },
  { label: 'Não', value: 'NO' },
];

export const referencesType = [
  { label: 'Amigo(a)', value: 'FRIEND' },
  { label: 'Vizinho(a)', value: 'NEIGHBOR' },
  { label: 'Namorado(a)', value: 'PARTNER' },
  { label: 'Conjuge', value: 'SPOUSE' },
  { label: 'Cunhado(a)', value: 'BROTHER_IN_LAW' },
  { label: 'Genro', value: 'SON_IN_LAW' },
  { label: 'Filho', value: 'CHILD' },
  { label: 'Irmão(ã)', value: 'SIBLING' },
  { label: 'Mãe', value: 'MOTHER' },
  { label: 'Pai', value: 'FATHER' },
  { label: 'Padrasto', value: 'STEPFATHER' },
  { label: 'Madrasta', value: 'STEPMOTHER' },
  { label: 'Avô(á)', value: 'GRANDPARENT' },
  { label: 'Primo', value: 'COUSIN' },
  { label: 'Sobrinho', value: 'NEPHEW' },
  { label: 'Sogro(a)', value: 'PARENT_IN_LAW' },
  { label: 'Tio(a)', value: 'UNCLE' },
  { label: 'Outro', value: 'OTHER' },
];
