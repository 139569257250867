import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider, { FieldsError } from 'components/ReactHookFormProvider';
import InputText from 'components/styled-input';
import Stroke from 'images/stroke.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ILogin } from 'model/login';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import { loginRequest } from 'reducer/authentication/actions';
import registerService from 'services/register-service';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import {
  Link,
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinHeader,
  ThinHeaderStroke,
  ThinInputContainer,
  ThinSubTitle,
  ThinTitle,
} from 'styled-components/thin-styles';
import * as yup from 'yup';
import { Resend } from '../styles';
interface IAuthenticateProps extends RouteComponentProps {
  documentValue: string;
}

const Authenticate: React.FC<IAuthenticateProps> = props => {
  const { state } = useLocation<IAuthenticateProps>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  // tslint:disable-next-line:no-shadowed-variable
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);
  const [hasError, setHasError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = yup.object({
    password: yup.string().required(t('global.errorMessage.required')),
  });

  const fieldsError: FieldsError[] = [
    {
      name: 'password',
      errorMessage:
        loginStatus === HttpRequestStatus.VALIDATE ? t('global.errorMessage.resendValidationEmail') : t('global.errorMessage.login'),
    },
  ];

  useEffect(() => {
    if (isAuthenticated && account?.id != null && accountParameters != null) {
      AuthUtils.redirectToPath(account?.client?.registrationStatus ?? '', history, accountParameters);
    }

    handleSetError();
  }, [account, loginStatus, accountParameters]);

  const handleSetError = () => {
    if (loginStatus === HttpRequestStatus.ERROR || loginStatus === HttpRequestStatus.VALIDATE) {
      setHasError(true);
      setIsLoading(false);
    } else {
      setHasError(false);
    }
  };

  const onHandleSubmit = (data: ILogin) => {
    setIsLoading(true);
    handleSetError();
    const newData = {
      username: StringUtils.removeNonNumbersFromMaskedValue(state.documentValue),
      ...data,
    };
    dispatch(loginRequest(newData));
  };

  const handleResendEmail = () => {
    if (!sentEmail) {
      registerService.resendValidationEmail(StringUtils.removeNonNumbersFromMaskedValue(state.documentValue)).then(res => {
        setSentEmail(true);
      });
    }
  };

  return (
    <ThinBackground>
      <ThinHeader onClick={() => history.goBack()}>
        <ThinHeaderStroke src={Stroke} />
        <span>{t('global.button.backStep')}</span>
      </ThinHeader>
      <ThinContainer>
        <ThinTitle>{t('login.authenticate.title')}</ThinTitle>
        <ThinSubTitle>{t('login.authenticate.subtitle')}</ThinSubTitle>
      </ThinContainer>
      <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} hasError={hasError} fieldsWithError={fieldsError}>
        <ThinInputContainer>
          <InputText
            width={300}
            inputType="password"
            name="password"
            label={t('login.label.password')}
            placeholder={t('login.placeholder.password')}
          />
          <div style={{ marginBottom: '10px' }}>
            {loginStatus !== HttpRequestStatus.VALIDATE ? (
              <Link to={'/login/redefinir-senha'}> {t('login.form.forgotPassword')}</Link>
            ) : (
              <Resend onClick={handleResendEmail}>
                {!sentEmail ? t('login.form.resendValidationEmail') : t('login.form.resentValidationEmail')}
              </Resend>
            )}
          </div>
        </ThinInputContainer>
        <ThinButtonContainer>
          <EnterCustomButton type={'submit'} width={300} text={t('global.button.enter')} disabled={false} isLoading={isLoading} />
        </ThinButtonContainer>
      </ReactHookFormProvider>
    </ThinBackground>
  );
};

export default Authenticate;
