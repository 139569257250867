import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledLabel } from './styles';

interface Props {
  label: string;
  name: string;
  isChecked?: boolean;
}

const Checkbox = ({ label, name, isChecked }: Props) => {
  const { register } = useFormContext();

  const [isSelected, setIsSelected] = useState<boolean>(isChecked ?? false);

  return (
    <>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <input {...register(name)} type="checkbox" name={name} id={name} checked={isSelected} onClick={() => setIsSelected(!isSelected)} />
    </>
  );
};

export default Checkbox;
