import { SVGProps } from 'react';
import theme from '../themes/default';

export const Tool = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7982 2.52334C13.3957 2.47786 12.9865 2.49104 12.5834 2.56448C11.7501 2.7163 10.9829 3.11847 10.384 3.71738C9.7851 4.31629 9.38294 5.08351 9.23111 5.91678C9.07929 6.75005 9.18502 7.60981 9.53421 8.38146C9.67721 8.69747 9.60951 9.06901 9.36425 9.31428L3.60591 15.0726C3.43067 15.2479 3.33222 15.4855 3.33222 15.7334C3.33222 15.9812 3.43067 16.2189 3.60591 16.3941C3.78115 16.5693 4.01883 16.6678 4.26666 16.6678C4.51448 16.6678 4.75216 16.5693 4.9274 16.3941L10.6857 10.6358C10.931 10.3905 11.3025 10.3228 11.6186 10.4658C12.3902 10.815 13.25 10.9207 14.0832 10.7689C14.9165 10.6171 15.6837 10.2149 16.2826 9.616C16.8815 9.01709 17.2837 8.24988 17.4355 7.4166C17.509 7.01356 17.5222 6.60432 17.4767 6.20186L15.3334 8.34517C15.0218 8.65055 14.6029 8.82157 14.1667 8.82157C13.7304 8.82157 13.3115 8.65052 13 8.34514L12.994 8.33931L11.6548 7.00005C11.3495 6.68851 11.1784 6.26961 11.1784 5.83336C11.1784 5.3971 11.3495 4.97824 11.6549 4.66669L11.6607 4.66074L13.7982 2.52334ZM12.2847 0.924807C13.4512 0.712253 14.6549 0.860273 15.7352 1.34914C15.9845 1.46192 16.1625 1.68985 16.2115 1.95898C16.2605 2.22811 16.1744 2.50417 15.9809 2.69761L12.8452 5.83336L14.1667 7.15485L17.3024 4.0191C17.4958 3.82566 17.7719 3.73949 18.041 3.78852C18.3102 3.83756 18.5381 4.01556 18.6509 4.2648C19.1397 5.34512 19.2878 6.54877 19.0752 7.71536C18.8627 8.88194 18.2996 9.95604 17.4611 10.7945C16.6227 11.633 15.5486 12.196 14.382 12.4086C13.4091 12.5858 12.4105 12.5123 11.479 12.1995L6.10591 17.5726C5.61811 18.0604 4.95651 18.3345 4.26666 18.3345C3.5768 18.3345 2.9152 18.0604 2.4274 17.5726C1.9396 17.0848 1.66556 16.4232 1.66556 15.7334C1.66556 15.0435 1.9396 14.3819 2.4274 13.8941L7.80053 8.52097C7.48769 7.58956 7.41418 6.5909 7.59144 5.61803C7.80399 4.45145 8.36703 3.37735 9.2055 2.53887C10.044 1.70039 11.1181 1.13736 12.2847 0.924807Z"
      fill={theme.color.primaryColor}
    />
  </svg>
);
