import DynamicDesktopEditForm from 'components/dynamic-desktop-edit-form';
import Header from 'components/general-components/header';
import Stroke from 'images/stroke.svg';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContainerDesktop, StyledHeader, TitleDesktop } from '../styles';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onClickNext, steps, actualStep } = useEditForm();

  const findTitle = () => {
    return steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
  };
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <ContainerDesktop>
        <div>
          <StyledHeader>
            <div className="title">
              <img src={Stroke} onClick={() => history.goBack()} />
              <TitleDesktop>{t('profile.editData')}</TitleDesktop>
            </div>
          </StyledHeader>
          <DynamicDesktopEditForm type={findTitle()} onlyView={false} />
        </div>
      </ContainerDesktop>
    </>
  );
};

export default Desktop;
