import internalsApi from 'api/internals-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { FinancyInternal, Internal } from 'model/internals';
import { PageableResponse } from 'model/pageable';

export const InternalsService = () => {
  const getInternalById = async (internalId: number): Promise<Internal> => {
    try {
      const result: AxiosResponse<Internal> = await internalsApi.getInternalById(internalId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getFinancyInternalsById = async (id: number): Promise<FinancyInternal> => {
    try {
      const result: AxiosResponse<FinancyInternal> = await internalsApi.getFinancyInternalsById(id);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const updateFinancyInternalById = async (internalId: number, internal: FinancyInternal): Promise<FinancyInternal> => {
    try {
      const result: AxiosResponse<FinancyInternal> = await internalsApi.updateFinancyInternalById(internalId, internal);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getInternalsList = async (
    name?: string,
    size?: number,
    page?: number,
    subsidiaryName?: string
  ): Promise<PageableResponse<Internal>> => {
    try {
      const result: AxiosResponse<PageableResponse<Internal>> = await internalsApi.getInternals(name, size, page, subsidiaryName);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const createInternal = async (internal: Internal): Promise<Internal> => {
    try {
      const result: AxiosResponse<Internal> = await internalsApi.createInternal(internal);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const updateInternal = async (internalId: number, internal: Internal): Promise<Internal> => {
    try {
      const result: AxiosResponse<Internal> = await internalsApi.updateInternalById(internalId, internal);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const inactiveInternal = async (internalId: number): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await internalsApi.inactiveInternalById(internalId);

      if (result.status === HttpStatus.NO_CONTENT) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    getInternalById,
    getInternalsList,
    createInternal,
    updateInternal,
    inactiveInternal,
    getFinancyInternalsById,
    updateFinancyInternalById,
  };
};

export default InternalsService();
