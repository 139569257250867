import { IconContainer, StyledContainer, StyledInnerContainer, StyledTextContainer, StyledTitle } from './styles';
import { ReactComponent as RoundCheck } from '../../images/round-check.svg';

interface IProps {
  icon: JSX.Element;
  title: string;
  text: string;
  isDone?: boolean;
}

const InstructionCard = ({ icon, title, text, isDone }: IProps) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <IconContainer>{icon}</IconContainer>

        <StyledTextContainer>
          <StyledTitle>{title}</StyledTitle>
          {text}
        </StyledTextContainer>
      </StyledInnerContainer>
      {isDone && <RoundCheck />}
    </StyledContainer>
  );
};

export default InstructionCard;
