import SystemStepCategory from 'model/enums/system-step-category';
import TypeSubStepCategory from 'model/enums/type-substep-category';

export const editLegalPersonFormFields = {
  [SystemStepCategory.GENERAL_DATA]: ['responsibleName', 'formationDate', 'cnpj', 'corporateName', 'mainActivity', 'email'],
  [SystemStepCategory.ADDRESS]: ['zipcode', 'street', 'number', 'complement', 'district', 'state', 'city'],
  [SystemStepCategory.MY_COMPANY]: ['baseDate', 'netWorth', 'revenue'],
  [SystemStepCategory.LEGAL_PERSON_DOCUMENTS]: [],
};

export const editPartnerFormFields = {
  [TypeSubStepCategory.PARTNER_GENERAL_DATA]: ['name', 'birth', 'phone', 'cpf', 'rg', 'email', 'occupation', 'income'],
  [TypeSubStepCategory.PARTNER_ADDRESS]: ['zipcode', 'street', 'number', 'complement', 'district', 'state', 'city'],
  [TypeSubStepCategory.PARTNER_DOCUMENTS]: [],
};
