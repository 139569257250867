import evolveGuaranteeApi from 'api/evolve-guarantee-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { EvolveGuaranteeToSimple } from 'model/evolve-guarantee';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { updateEvolveGuaranteeError, updateEvolveGuaranteeRequest, updateEvolveGuaranteeSuccess } from './actions';
import { UpdateEvolveGuaranteeTypes } from './types';

function* handleUpdateEvolveGuarantee(action: ReturnType<typeof updateEvolveGuaranteeRequest>) {
  try {
    const result: AxiosResponse<EvolveGuaranteeToSimple> = yield call(evolveGuaranteeApi.updateEvolveGuarantee, action.payload);
    if (result.status !== HttpStatus.OK) {
      const errorMessage: string = result.status === 401 ? 'Not permitted' : 'An unknown error occured.';
      yield put(updateEvolveGuaranteeError(errorMessage));
      return;
    }
    yield put(updateEvolveGuaranteeSuccess(result.data));
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(updateEvolveGuaranteeError(error.stack));
    } else {
      yield put(updateEvolveGuaranteeError('An unknown error occured.'));
    }
  }
}

function* watchUpdateEvolveGuarantee() {
  yield takeLeading(UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_REQUEST, handleUpdateEvolveGuarantee);
}

function* updateEvolveGuaranteeSaga() {
  yield all([fork(watchUpdateEvolveGuarantee)]);
}

export default updateEvolveGuaranteeSaga;
