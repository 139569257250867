import { OutOfTimeSVG } from 'images/simulator';
import { useTranslation } from 'react-i18next';
import { BoxSvg, SimulatorContainer, SimulatorText, SimulatorTitle } from '../style';

interface OutOfTimeProps {
  showHours: string;
}

const OutOfTime: React.FC<OutOfTimeProps> = ({ showHours }) => {
  const { t } = useTranslation();

  return (
    <SimulatorContainer>
      <SimulatorTitle>{t('register.outOfTime.title')}</SimulatorTitle>
      <SimulatorText>
        {t('register.outOfTime.text1')} {showHours}. {t('register.outOfTime.text2')}
      </SimulatorText>
      <BoxSvg>
        <OutOfTimeSVG />
      </BoxSvg>
    </SimulatorContainer>
  );
};

export default OutOfTime;
