import { Attachment } from 'model/attachment';
import { useRegisterForm } from 'provider/register-form';
import { useState } from 'react';
import { InstructionsScreen } from './screens/instructions';
import { ResumeScreen } from './screens/resume';
interface Props {
  attachmentIdentification?: Attachment;
  attachmentIncome?: Attachment;
  attachmentAddress?: Attachment;
  isGuarantorRegister?: boolean;
  isOnlyIdentificationDocument?: boolean;
}

export const FormDocuments = ({
  attachmentIdentification,
  attachmentIncome,
  attachmentAddress,
  isGuarantorRegister,
  isOnlyIdentificationDocument = false,
}: Props) => {
  const [actualStep, setActualStep] = useState(0);
  const { files } = useRegisterForm();

  const renderSteps = () => {
    switch (actualStep) {
      case 0:
        return <InstructionsScreen actualStep={actualStep} setActualStep={setActualStep} />;
      case 1:
        return (
          <ResumeScreen
            actualStep={actualStep}
            setActualStep={setActualStep}
            handleVerifyEmptyFields={handleVerifyEmptyFields}
            isOnlyIdentificationDocument={isOnlyIdentificationDocument}
          />
        );
      default:
        return <></>;
    }
  };

  const handleVerifyEmptyFields = (field: string) => {
    const hasFiles = files.find(file => file.type === field);
    if (hasFiles) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '31px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px', width: '100%' }}>{renderSteps()}</div>
    </div>
  );
};
