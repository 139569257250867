import styled from 'styled-components';

interface ICustomStyle {
  width?: string;
  minWidth?: string;
  height?: string;
  minHeight?: string;
}

interface IProps {
  customStyle: ICustomStyle;
}

export const ScreenLoading = styled.div<IProps>`
  width: ${({ customStyle }) => customStyle?.width ?? '100%'};
  min-width: ${({ customStyle }) => customStyle?.minWidth ?? 'auto'};
  height: ${({ customStyle }) => customStyle?.height ?? '100%'};
  min-height: ${({ customStyle }) => customStyle?.minHeight ?? 'auto'};

  display: flex;
  align-items: center;
  justify-content: center;
`;
