import { AxiosResponse } from 'axios';
import { Organization } from 'model/organization';
import { api } from './api';

const organizationApi = () => {
  const getCurrentOrganization = (): Promise<AxiosResponse<Organization>> => {
    return api.get<Organization>(`/organizations/current`);
  };

  return {
    getCurrentOrganization,
  };
};

export default organizationApi();
