import LoginModal from 'components/login-modal';
import { Logo } from 'images/custom-organization';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import AuthUtils from 'shared/util/auth-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Background, Container, TextContainer, Title } from './styles';

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  // tslint:disable-next-line:no-shadowed-variable
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  // tslint:disable-next-line:no-shadowed-variable
  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);
  const isMobile = useResponsiveMobile().isTablet;

  const [documentValue, setDocumentValue] = useState<string>('');

  useEffect(() => {
    if (isAuthenticated && account?.id != null && accountParameters != null) {
      AuthUtils.redirectToPath(account?.client?.registrationStatus ?? '', history, accountParameters);
    }
  }, [account, loginStatus, accountParameters]);

  return (
    <Background>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Logo isWhite />
        </div>
        <TextContainer>
          <Title>{t('login.title')}</Title>
        </TextContainer>
      </Container>
      <LoginModal isMobile={isMobile} documentValue={documentValue} setDocumentValue={setDocumentValue} />
    </Background>
  );
};

export default Login;
