import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { FormContainer } from '../styles';
import { ContainerInputsIncomeEvolve } from './inputs-income';
import { useFormIncomeEvolve } from './hooks';

export const FormIncomeEvolve = () => {
  const { schema, handleSubmit } = useFormIncomeEvolve();

  return (
    <FormContainer>
      <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
        <ContainerInputsIncomeEvolve />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
