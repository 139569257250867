import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider, { FieldsError } from 'components/ReactHookFormProvider';
import InputText from 'components/styled-input';
import { NO_LANDING_PAGE } from 'config/constants';
import Stroke from 'images/stroke.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ILogin } from 'model/login';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import { loginAdminRequest } from 'reducer/authentication/actions';
import AuthUtils from 'shared/util/auth-utils';
import {
  ForgotPasseordContainer,
  ImageContainer,
  InputContainer,
  Link,
  LogoContainer,
  SideImageContainer,
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinHeader,
  ThinHeaderStroke,
  ThinInputContainer,
  ThinTitle,
} from 'styled-components/thin-styles';
import * as yup from 'yup';
import logo from '../../../../images/login/logo.svg';
import sideImage from '../../../../images/login/side-image.png';

interface IAuthenticateProps extends RouteComponentProps<{}> {
  documentValue: string;
}

const AdminAuthenticate: React.FC<IAuthenticateProps> = props => {
  const { state } = useLocation<IAuthenticateProps>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  // tslint:disable-next-line:no-shadowed-variable
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const schema = yup.object({
    username: yup.string().email(t('global.errorMessage.verifyEmail')).required(t('global.errorMessage.required')),
    password: yup.string().required(t('global.errorMessage.required')),
  });

  const fieldsError: FieldsError[] = [{ name: 'password', errorMessage: t('global.errorMessage.login') }];

  useEffect(() => {
    if (isAuthenticated && account?.id != null) {
      redirectToPath();
    }

    handleSetError();
  }, [account, loginStatus]);

  const redirectToPath = () => {
    const redirectPath = AuthUtils.getMainPath();
    props.history.replace(redirectPath);
  };

  const handleSetError = () => {
    if (loginStatus === HttpRequestStatus.ERROR) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const onHandleSubmit = (data: ILogin) => {
    handleSetError();
    dispatch(loginAdminRequest(data));
  };

  return (
    <ThinBackground>
      {NO_LANDING_PAGE ? (
        <ThinHeader onClick={() => history.push('/admin/login')}>
          <ThinHeaderStroke src={Stroke} />
          <span>{t('global.button.backStep')}</span>
        </ThinHeader>
      ) : (
        <> </>
      )}

      <InputContainer>
        <LogoContainer>
          <ImageContainer src={logo} />
        </LogoContainer>

        <ThinContainer>
          <ThinTitle>{t('login.authenticate.title')}</ThinTitle>
        </ThinContainer>

        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} hasError={hasError} fieldsWithError={fieldsError}>
          <ThinInputContainer>
            <InputText label={t('login.label.email')} inputType="text" name="username" placeholder={t('login.placeholder.email')} />
            <InputText
              label={t('login.label.password')}
              inputType="password"
              name="password"
              placeholder={t('login.placeholder.password')}
            />
            <ForgotPasseordContainer>
              <Link to={isAdmin ? '/admin/redefinir-senha' : '/login/redefinir-senha'}> {t('login.form.forgotPassword')}</Link>
            </ForgotPasseordContainer>
          </ThinInputContainer>
          <ThinButtonContainer>
            <EnterCustomButton type={'submit'} width={420} text={t('global.button.enter')} disabled={false} isLoading={false} />
          </ThinButtonContainer>
        </ReactHookFormProvider>
      </InputContainer>

      <SideImageContainer>
        <ImageContainer src={sideImage} />
      </SideImageContainer>
    </ThinBackground>
  );
};

export default AdminAuthenticate;
