import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px 16px;
`;
export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    margin-left: 12px;
  }
`;
export const ContractDetailsContainer = styled.div`
  width: 100%;
  height: 78px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px 16px;
  margin: 15px 0px 0px;
  justify-content: space-between;

  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #4c4d4d;
  }
`;
export const ContractDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  }
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 81px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
`;
export const SignatureDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  > .type {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  }

  > .name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #4c4d4d;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
  position: fixed;
  bottom: 0;
`;

export const DownloadButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 140px;
  height: 45px;
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;
  background-color: transparent;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 140px;
  height: 45px;
  background: ${({ theme }) => theme.color.primaryColor};
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
  }
`;
