import CustomButton from 'components/custom-button';
import { useSendDataToSimulator } from 'components/simulator-data/hooks/use-send-data-to-simulator';
import { SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import { SimulatorLoadingStage, useConfirmSimulationContext } from 'context/confirm-simulation';
import { useAuthorities } from 'hooks/use-authorities';
import { useInsurance } from 'hooks/use-insurance';
import useSimulatorStorage from 'hooks/use-simulator-storage';
import Attention from 'images/attention.svg';
import { debounce } from 'lodash';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AttentionContainer, StyledButton } from '../../confirm-simulation/style';

export const useSendSimulation = () => {
  const { limitMonthError, setLoadingStage, loadingStage, newSimulation, hasSimulationValueChanged, resetLoadingState } =
    useConfirmSimulationContext();
  const { getClientSimulatorInfoFromStorage } = useSimulatorStorage();
  const { sendDataToSimulator } = useSendDataToSimulator();
  const { isDashboardAdmin } = useAuthorities();
  const { t } = useTranslation();
  const { hasInsurance } = useInsurance();

  const { clientId } = useParams<{ clientId: string }>();

  const location = useLocation<{ isNewCredit?: boolean }>();
  const isNewCredit = location.state?.isNewCredit ?? false;

  const history = useHistory();

  const sendSimulationWithInsuranceOrWithout = async (data?: FinancingSimpleSimulation) => {
    const simulationToSend = data ?? newSimulation;

    if (!hasInsurance) {
      await sendNewSimulation(simulationToSend);
    } else {
      await sendNewSimulation(simulationToSend, false);
      await sendNewSimulation(simulationToSend, true);
    }
  };

  const sendNewSimulation = async (data: FinancingSimpleSimulation, withInsurance: boolean = false) => {
    setLoadingSimulating(withInsurance);

    try {
      await handleSendDataToSimulator(data, withInsurance);
    } catch (error) {
      console.error(error);
      redirectToError();
    } finally {
      resetLoadingState();
    }
  };

  const submitSimulation = async (withInsurance: boolean = false) => {
    setLoadingSubmitting(withInsurance);

    const simulatorInfo = getClientSimulatorInfoFromStorage(clientId) as FinancingSimpleSimulation;
    try {
      await handleSendDataToSimulator(simulatorInfo, withInsurance, true);
      redirectToFinishing();
    } catch (error) {
      console.error(error);
      redirectToError();
      resetLoadingState();
    }
  };

  const setLoadingSimulating = (withInsurance?: boolean) => {
    withInsurance ? setLoadingStage(SimulatorLoadingStage.SIMULATING_INSURANCE) : setLoadingStage(SimulatorLoadingStage.SIMULATING);
  };

  const setLoadingSubmitting = (withInsurance?: boolean) => {
    withInsurance
      ? setLoadingStage(SimulatorLoadingStage.SUBMITTING_SIMULATION_INSURANCE)
      : setLoadingStage(SimulatorLoadingStage.SUBMITTING_SIMULATION);
  };

  const debounceSubmitSimulation = debounce(submitSimulation, 500);
  const debounceSendSimulationWithInsuranceOrWithout = debounce(sendSimulationWithInsuranceOrWithout, 500);

  const handleSendDataToSimulator = async (
    simulationToSend: FinancingSimpleSimulation,
    withInsurance?: boolean,
    isSubmit: boolean = false
  ) => {
    await sendDataToSimulator(simulationToSend, !isSubmit ? isNewCredit : false, isDashboardAdmin, withInsurance);
  };

  const redirectToError = () => {
    setTimeout(() => {
      resetLoadingState();
      history.replace(RoutePath.ERROR);
    }, 500);
  };

  const redirectToFinishing = () => {
    setTimeout(() => {
      resetLoadingState();
      history.replace(isDashboardAdmin ? `${RoutePath.SIMULATOR_FINISHING_ADMIN}/${clientId}` : RoutePath.SIMULATOR_FINISHING, {
        isNewCredit: isNewCredit,
      } as SimulatorState);
    }, 500);
  };

  const renderButton = (): JSX.Element | undefined => {
    if (hasSimulationValueChanged) {
      return renderSimulateButton(() => debounceSendSimulationWithInsuranceOrWithout(), 'global.button.simulate');
    } else {
      if (limitMonthError) {
        return renderLimitErrorButton();
      }
      if (!hasInsurance) {
        return renderSimulateButton(() => debounceSubmitSimulation(false), 'global.button.sendRequest');
      } else {
        return renderInsuranceButtons();
      }
    }
  };

  const renderSimulateButton = (onClick: () => void, i18n: string, isDisabled?: boolean) => {
    if (loadingStage !== SimulatorLoadingStage.NONE) {
      return (
        <StyledButton>
          <ReactLoading type="spinningBubbles" width="24px" height="24px" />
        </StyledButton>
      );
    } else {
      return (
        <StyledButton onClick={onClick} disabled={isDisabled ? isDisabled : false}>
          {t(`${i18n}`)}
        </StyledButton>
      );
    }
  };

  const renderInsuranceButtons = () => {
    return (
      <>
        <CustomButton
          disabled={loadingStage !== SimulatorLoadingStage.NONE}
          loading={loadingStage === SimulatorLoadingStage.SUBMITTING_SIMULATION_INSURANCE}
          onClick={() => debounceSubmitSimulation(true)}
          justifyContent="center"
          margin="12px 0px"
          width="310px"
        >
          {t('simulatorInsurance.button.continueWithInsurance')}
        </CustomButton>
        <CustomButton
          disabled={loadingStage !== SimulatorLoadingStage.NONE}
          loading={loadingStage === SimulatorLoadingStage.SUBMITTING_SIMULATION}
          onClick={() => debounceSubmitSimulation(false)}
          justifyContent="center"
          isInvertColor
          width="310px"
        >
          {t('simulatorInsurance.button.continueWithoutInsurance')}
        </CustomButton>
      </>
    );
  };

  const renderLimitErrorButton = () => {
    return (
      <>
        <AttentionContainer>
          <img src={Attention} />
          <span>{t('simulatorData.monthLimitExceded')}</span>
        </AttentionContainer>
        {renderSimulateButton(sendSimulationWithInsuranceOrWithout, 'global.button.changeValue', true)}
      </>
    );
  };

  return {
    renderButton,
    sendSimulationWithInsuranceOrWithout,
    hasInsurance,
  };
};
