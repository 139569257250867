import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { format, isDate, parse } from 'date-fns';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useTranslation } from 'react-i18next';
import { validateCNPJ } from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsGeneralData } from './inputs-general-data';
import useResponsiveMobile from 'shared/util/use-responsive-hook';

export const FormGeneralData = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { mainActivities } = useSelectLists();
  const { t } = useTranslation();
  const isMobile = useResponsiveMobile().isTablet;

  const schema = yup.object({
    responsibleName: yup.string().required(t('global.errorMessage.required')),
    formationDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    cnpj: yup
      .string()
      .min(15, t('global.errorMessage.invalidCnpj'))
      .test('test-cnpj', t('global.errorMessage.invalidCnpj'), cnpj => validateCNPJ(cnpj!))
      .required(t('global.errorMessage.required')),
    corporateName: yup.string().required(t('global.errorMessage.required')),
    mainActivity: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test(
        'test-mainActivity',
        t('global.errorMessage.invalidMainActivity'),
        activityName => mainActivities.find(activity => activity.name === activityName) !== undefined
      ),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const formationDate = format(res.formationDate, 'yyyy-MM-dd');
          const mainActivityId = mainActivities.find(activity => (activity.name = res.mainActivity))?.id;
          const newRes = {
            email: res.email,
            legalPerson: {
              ...initialClientData?.legalPerson,
              responsibleName: res.responsibleName,
              formationDate,
              cnpj: res.cnpj,
              corporateName: res.corporateName,
              mainActivity: {
                id: mainActivityId,
                name: res.mainActivity,
              },
            },
          };
          onClickNext(newRes as Client);
        }}
      >
        <ContainerInputsGeneralData mobile={isMobile} />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
