import guarantorApi from 'api/guarantor-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { Guarantor, GuarantorsInContract } from 'model/guarantor';
import { PageableResponse } from 'model/pageable';

const guarantorService = () => {
  const getAllGuarantors = async (): Promise<AxiosResponse<PageableResponse<Guarantor>>> => {
    try {
      const result: AxiosResponse<PageableResponse<Guarantor>> = await guarantorApi.getAllGuarantors();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllGuarantorsInFinancing = async (idFinancing: number): Promise<AxiosResponse<GuarantorsInContract>> => {
    try {
      const result: AxiosResponse<GuarantorsInContract> = await guarantorApi.getAllGuarantorsInFinancing(idFinancing);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getAllGuarantors,
    getAllGuarantorsInFinancing,
  };
};

export default guarantorService();
