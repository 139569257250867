import { AxiosResponse } from 'axios';
import { Bank, BankAccount } from 'model/bank-account';
import { PageableResponse } from 'model/pageable';
import { api } from './api';

const bankApi = () => {
  const createBankAccount = (account: BankAccount): Promise<AxiosResponse<BankAccount[]>> => {
    return api.post<BankAccount[]>('/bank-account', account);
  };

  const createBankAccountByAdmin = (clientId: number, account: BankAccount): Promise<AxiosResponse<BankAccount[]>> => {
    return api.post<BankAccount[]>(`/bank-account/client/${clientId}/admin`, account);
  };

  const getBankAccount = (): Promise<AxiosResponse<BankAccount[]>> => {
    return api.get<BankAccount[]>('/bank-account');
  };

  const getAllBankNames = (page: number, name: string, byParam?: string): Promise<AxiosResponse<PageableResponse<Bank>>> => {
    return api.get<PageableResponse<Bank>>(`/banks?page=${page}&filter=${name}${byParam ? `&byParam=${byParam}` : ''}`);
  };

  const updateBankDetails = (account: BankAccount): Promise<AxiosResponse<BankAccount>> => {
    return api.patch<BankAccount>(`/bank-account/${account.id}`, account);
  };

  const linkBankAccountWithFinancingByAdmin = (
    financingId: number,
    bankAccountId: number,
    clientId: number
  ): Promise<AxiosResponse<BankAccount>> => {
    return api.patch<BankAccount>(`/financing/${financingId}/bank-accounts/${bankAccountId}/client/${clientId}`);
  };

  const deleteBankAccount = (id: number): Promise<AxiosResponse> => {
    return api.delete(`/bank-account/${id}`);
  };

  return {
    createBankAccount,
    createBankAccountByAdmin,
    getBankAccount,
    getAllBankNames,
    updateBankDetails,
    linkBankAccountWithFinancingByAdmin,
    deleteBankAccount,
  };
};

export default bankApi();
