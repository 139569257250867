import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { PopupContent } from 'components/simulator-data/aproved/style';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContainerTextArea, PopupText, PopupTitle } from './styles';
import { useState } from 'react';

interface Props extends WithTranslation {
  isShowModal: boolean;
  message: string;
  handleClose: () => void;
}

const MessageModal = ({ message, isShowModal, handleClose, t }: Props) => {
  return (
    <CustomModalTwoButtons
      isShowModal={isShowModal}
      onCloseModal={() => handleClose()}
      large
      children={
        <PopupContent>
          <PopupTitle>{t('admin.signatures.messageToCLient')}</PopupTitle>
          <ContainerTextArea disabled defaultValue={message} />
        </PopupContent>
      }
    />
  );
};

export default withTranslation()(MessageModal);
