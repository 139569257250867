import DynamicDesktopEditForm from 'components/dynamic-desktop-edit-form';
import Header from 'components/general-components/header';
import { EditSvg } from 'images/edit';
import { StrokeSvg } from 'images/stroke';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, EditButton, StyledHeader, Title } from '../styles';
import SystemStepCategory from 'model/enums/system-step-category';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onClickNext, steps, actualStep } = useEditForm();

  const findTitle = () => {
    if (actualStep + 1 === steps.length && steps[actualStep]?.systemStep?.step === SystemStepCategory.SELFIE) {
      return steps[actualStep]?.systemStep?.step ?? '';
    }
    return steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
  };
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <div>
          <StyledHeader>
            <div className="title">
              <div onClick={() => history.goBack()}>
                <StrokeSvg />
              </div>
              <Title>{t('profile.title')}</Title>
            </div>
            <EditButton onClick={() => history.push('/perfil/editar')}>
              <EditSvg />
              <span>{t('global.button.editData')}</span>
            </EditButton>
          </StyledHeader>
          <DynamicDesktopEditForm type={findTitle()} onlyView />
        </div>
      </Container>
    </>
  );
};

export default Desktop;
