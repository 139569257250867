import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { Camera } from 'images/camera';
import { SettingsSvg } from 'images/settings';
import { Tool } from 'images/tool';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import AuthUtils from 'shared/util/auth-utils';
import {
  AdjustButtonContainer,
  CommentContainer,
  ConfirmButtonContainer,
  Dividider,
  StyledContainer,
  StyledInnerContainer,
  StyledMainTitle,
  StyledSvgContainer,
  StyledText,
} from './styles';
import { ORGANIZATION_TYPE } from 'config/constants';
import { OrganizationType } from 'model/enums/organization-type';

const RegistryAdjustments = ({ t }: WithTranslation) => {
  const [comments, setComments] = useState<string>();
  const history = useHistory();
  // tslint:disable-next-line:no-shadowed-variable
  const account = useSelector((state: IRootState) => state.authentication.account);
  // tslint:disable-next-line:no-shadowed-variable
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  // tslint:disable-next-line:no-shadowed-variable
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const accountParameters = useSelector((state: IRootState) => state.authentication.accountParameters);
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  useEffect(() => {
    if (isAuthenticated && account?.id != null && accountParameters != null) {
      AuthUtils.redirectToPath(account?.client?.registrationStatus ?? '', history, accountParameters);
    }
  }, [account, loginStatus, accountParameters]);
  useEffect(() => {
    clientService.getClientData().then(data => {
      setComments(data.internalComment ?? '');
    });
  }, []);

  const redirectToAdjustment = () => {
    return history.push('/cadastro/ajustes');
  };

  const redirectToSelfie = () => {
    return history.push('/selfie');
  };

  const onConfirm = () => {
    clientService.finishUpdate().then(() => {
      history.go(0);
    });
  };

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledContainer>
        <StyledInnerContainer>
          <StyledMainTitle>{t('registrationAnalysis.needTweaking')}</StyledMainTitle>
          <StyledSvgContainer>
            <SettingsSvg />
          </StyledSvgContainer>
          <StyledText>{t('registrationAnalysis.errorsInRegistration')}</StyledText>
          {comments && (
            <CommentContainer>
              <span>{comments}</span>
            </CommentContainer>
          )}
          <AdjustButtonContainer>
            <CustomButton
              onClick={() => redirectToAdjustment()}
              style={{ marginTop: '36px', justifyContent: 'center' }}
              isInvertColor
              height={48}
              width="100%"
              icon={<Tool />}
            >
              {t('global.button.fixRegister')}
            </CustomButton>
            {isPhysicalPerson && (
              <>
                <Dividider />
                <CustomButton
                  onClick={() => redirectToSelfie()}
                  style={{ marginTop: '36px', justifyContent: 'center' }}
                  isInvertColor
                  height={48}
                  width="100%"
                  icon={<Camera />}
                >
                  {t('global.button.newSelfie')}
                </CustomButton>
              </>
            )}
          </AdjustButtonContainer>
          <ConfirmButtonContainer>
            <CustomButton
              onClick={() => onConfirm()}
              style={{ marginTop: '36px', justifyContent: 'center' }}
              height={48}
              width={'200px'}
              max-width="200px"
            >
              {t('global.button.confirm')}
            </CustomButton>
          </ConfirmButtonContainer>
        </StyledInnerContainer>
      </StyledContainer>
    </>
  );
};

export default withTranslation()(RegistryAdjustments);
