import styled from 'styled-components';

export const Container = styled.span`
  width: 100%;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  margin: 0 auto;
  overflow: hidden;

  span {
    color: var(--Cool-gray-50, #878d96);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }
`;
