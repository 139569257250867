import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute, { adminAccessLevels, clientAccessLevel } from 'shared/routes/private-routes';
import { SelfiePage } from '..';
import SelfieIntructions from '../mobile/selfie-instructions';
import { SelfieSuccess } from '../mobile/selfie-success';
import SelfieTakePhoto from '../mobile/take-photo';
import { SelfieProvider } from '../provider';
import SelfieQrCode from '../qrCode';

export const SelfieRoute = ({ match }) => {
  return (
    <SelfieProvider>
      <Switch>
        <PrivateRoute exact path={`${match.url}`} component={SelfiePage} />
        <PrivateRoute accessLevel={clientAccessLevel} path={`${match.url}/qrcode/:key`} component={SelfieQrCode} />
        <Route path={`${match.url}/:id/admin/qrcode/:key`} component={SelfieQrCode} />
        <Route path={`${match.url}/instrucoes/:key`} component={SelfieIntructions} />
        <Route path={`${match.url}/:id/admin/instrucoes/:key`} component={SelfieIntructions} />
        <Route path={`${match.url}/foto/:key`} component={SelfieTakePhoto} />
        <Route path={`${match.url}/admin/:id/foto/:key`} component={SelfieTakePhoto} />
        <Route path={`${match.url}/enviado`} component={SelfieSuccess} />
        <Route path={`${match.url}/:id/enviado`} component={SelfieSuccess} />
      </Switch>
    </SelfieProvider>
  );
};
