import { SVGProps } from 'react';
import theme from '../themes/default';

export const Camera = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.80662 2.53759C6.96118 2.30575 7.22137 2.1665 7.5 2.1665H12.5C12.7786 2.1665 13.0388 2.30575 13.1934 2.53759L14.6127 4.6665H17.5C18.163 4.6665 18.7989 4.9299 19.2678 5.39874C19.7366 5.86758 20 6.50346 20 7.1665V16.3332C20 16.9962 19.7366 17.6321 19.2678 18.1009C18.7989 18.5698 18.163 18.8332 17.5 18.8332H2.5C1.83696 18.8332 1.20107 18.5698 0.732233 18.1009C0.263392 17.6321 0 16.9962 0 16.3332V7.1665C0 6.50346 0.263392 5.86758 0.732233 5.39874C1.20107 4.9299 1.83696 4.6665 2.5 4.6665H5.38735L6.80662 2.53759ZM7.94599 3.83317L6.52671 5.96209C6.37215 6.19392 6.11196 6.33317 5.83333 6.33317H2.5C2.27899 6.33317 2.06702 6.42097 1.91074 6.57725C1.75446 6.73353 1.66667 6.94549 1.66667 7.1665V16.3332C1.66667 16.5542 1.75446 16.7661 1.91074 16.9224C2.06702 17.0787 2.27899 17.1665 2.5 17.1665H17.5C17.721 17.1665 17.933 17.0787 18.0893 16.9224C18.2455 16.7661 18.3333 16.5542 18.3333 16.3332V7.1665C18.3333 6.94549 18.2455 6.73353 18.0893 6.57725C17.933 6.42097 17.721 6.33317 17.5 6.33317H14.1667C13.888 6.33317 13.6278 6.19392 13.4733 5.96209L12.054 3.83317H7.94599Z"
      fill={theme.color.primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.83317C8.61929 8.83317 7.5 9.95246 7.5 11.3332C7.5 12.7139 8.61929 13.8332 10 13.8332C11.3807 13.8332 12.5 12.7139 12.5 11.3332C12.5 9.95246 11.3807 8.83317 10 8.83317ZM5.83333 11.3332C5.83333 9.03198 7.69881 7.1665 10 7.1665C12.3012 7.1665 14.1667 9.03198 14.1667 11.3332C14.1667 13.6344 12.3012 15.4998 10 15.4998C7.69881 15.4998 5.83333 13.6344 5.83333 11.3332Z"
      fill={theme.color.primaryColor}
    />
  </svg>
);
