import { useHistory } from 'react-router-dom';

export const useRedirect = () => {
  const history = useHistory();
  const redirect = (nextRoute: string, params?: unknown) => {
    setTimeout(() => {
      history.push(nextRoute, params);
    }, 1000);
  };

  const replace = (nextRoute: string, params?: unknown) => {
    setTimeout(() => {
      history.replace(nextRoute, params);
    }, 1000);
  };

  return { redirect, replace };
};
