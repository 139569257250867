import registerApi from 'api/register-api';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { City, State } from 'model/address';
import { Occupation } from 'model/client';
import { MainActivity } from 'model/dashboard';
import { MaritalStatus, Residence } from 'model/landing-page';
import { ReactNode, createContext, useContext, useState } from 'react';
import landingPageService from 'services/landing-page-service';

interface SelectListsData {
  states: State[];
  cities: City[];
  occupations: Occupation[];
  maritalStatus: MaritalStatus[];
  residenceType: Residence[];
  mainActivities: MainActivity[];
  stateID: number | null;
  statesTotalPages: number;
  citiesTotalPages: number;
  occupationsTotalPages: number;
  mainActivitiesTotalPages: number;
  languagesTotalPages: number;
  handleSetStateID: (stateID: number) => void;
  getStates: (param?: { size?: number; page?: number; name?: string; ac?: string }, scroll?: boolean) => void;
  getCities: (stateID: number, param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getOccupations: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => Promise<void>;
  getMaritalStatus: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getResidenceType: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getMainActivities: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => Promise<void>;
}

interface SelectListsProps {
  children: ReactNode;
}

const SelectListsContext = createContext<SelectListsData>({} as SelectListsData);

export const SelectListsProvider = ({ children }: SelectListsProps) => {
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus[]>([]);
  const [residenceType, setResidenceType] = useState<Residence[]>([]);
  const [mainActivities, setMainActivities] = useState<MainActivity[]>([]);
  const [stateID, setStateID] = useState<number | null>(null);
  const [statesTotalPages, setStatesTotalPages] = useState(0);
  const [citiesTotalPages, setCitiesTotalPages] = useState(0);
  const [languagesTotalPages, setLanguagesTotalPages] = useState(0);
  const [maritalStatusTotalPages, setMaritalStatusTotalPages] = useState(0);
  const [occupationsTotalPages, setOccupationsTotalPages] = useState(0);
  const { systemParameters } = useSystemParameters();
  const formType = systemParameters?.formType?.value;
  const [mainActivitiesTotalPages, setMainActivitiesTotalPages] = useState(0);
  const [mainActivitiesCurrentPage, setMainActivitiesCurrentPage] = useState(0);

  const handleSetStateID = (id: number) => {
    if (id != null) {
      setStateID(id);
    }
  };

  const getStates = async (param?: { size?: number; page?: number; name?: string; ac?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getStates(param?.size, param?.page, param?.name, param?.ac);
      setStatesTotalPages(res.data.totalPages);
      if (scroll) {
        setStates(states.concat(res.data.content));
      } else {
        setStates(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async (id: number, param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getCities(id, param?.size, param?.page, param?.name);
      setCitiesTotalPages(res.data.totalPages);
      if (scroll) {
        setCities(cities.concat(res.data.content));
      } else {
        setCities(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOccupations = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getOccupations(param?.size, param?.page, param?.name);
      setOccupationsTotalPages(res.data.totalPages);
      if (scroll) {
        setOccupations(occupations.concat(res.data.content));
      } else {
        setOccupations(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMaritalStatus = async () => {
    try {
      const res = await landingPageService.getMaritalStatus();
      setMaritalStatus(res.content);
    } catch (error) {
      console.error(error);
    }
  };

  const getResidenceType = async () => {
    try {
      const res = await landingPageService.getResidenceType();
      setResidenceType(res.content);
    } catch (error) {
      console.error(error);
    }
  };

  const getMainActivities = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getMainActivities(param?.size, param?.page, param?.name);
      setMainActivitiesTotalPages(res.data.totalPages);
      setMainActivitiesCurrentPage(res.data.number);
      if (scroll) {
        setMainActivities(mainActivities.concat(res.data.content));
      } else {
        setMainActivities(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SelectListsContext.Provider
      value={{
        states,
        cities,
        occupations,
        maritalStatus,
        residenceType,
        mainActivities,
        stateID,
        statesTotalPages,
        citiesTotalPages,
        languagesTotalPages,
        occupationsTotalPages,
        mainActivitiesTotalPages,
        handleSetStateID,
        getStates,
        getCities,
        getOccupations,
        getMaritalStatus,
        getResidenceType,
        getMainActivities,
      }}
    >
      {children}
    </SelectListsContext.Provider>
  );
};

export const useSelectLists = () => useContext(SelectListsContext);
