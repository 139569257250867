import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { Partner } from 'model/dashboard';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Container, IdentityContainer, Title } from './styles';

interface IResumeScreenProps {
  attachmentResume?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  onClickForward: (partner: Partner) => void;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { initialPartnerData, setInitialPartnerData, setInitialClientData, onClickNext, files, isLastForm, isLoading } = useRegisterForm();
  const schema = yup.object({
    attachmentResume: yup.mixed(),
  });

  const onSubmit = () => {
    const newPartner = {
      ...initialPartnerData,
      attachments: files,
    } as Partner;
    setInitialPartnerData(newPartner);
    props.onClickForward(newPartner);
  };

  return (
    <ReactHookFormProvider schema={schema} onSubmit={onSubmit}>
      <Container>
        <Title>{t('documents.upload')}</Title>
        <IdentityContainer>
          <Title>{t('documents.identity.title')}</Title>
          <ContainerUploadedDocument attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          <Title>{t('documents.proofs.incomeProof.title')}</Title>
          <ContainerUploadedDocument attachmentType={AttachmentType.PROOF_INCOME} />
          <Title>{t('documents.proofs.residenceProof.title')}</Title>
          <ContainerUploadedDocument attachmentType={AttachmentType.PROOF_RESIDENCE} />
        </IdentityContainer>
        <ButtonsContainer>
          {props.actualStep !== 0 ? (
            <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
              <span>{t('global.button.backStep')}</span>
            </Button>
          ) : (
            <div />
          )}
          <EnterCustomButton
            type="submit"
            isLoading={isLoading}
            text={isLastForm() ? t('global.button.addPartner') : t('global.button.nextStep')}
          />
        </ButtonsContainer>
      </Container>
    </ReactHookFormProvider>
  );
};
