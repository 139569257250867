import { Address, AddressLP } from './address';
import { Attachment } from './attachment';
import { BankAccount } from './bank-account';
import { CreditCard } from './credit-card';
import { CreditScore } from './credit-score';
import { LegalPerson } from './dashboard';
import { EmploymentBondSend } from './enums/form-properties';
import { MonjuaVerifyIdentityStatus } from './enums/monjua-verify-identity-status';
import { RegistrationStatus } from './enums/registration-status';
import { Financing } from './financing';
import { Internal, OrganizationSubsidiary } from './internals';
import { LegalPersonLP } from './legal-person';
import { PhysicalPerson, PhysicalPersonLP } from './physical-person';
import { User } from './user';

export interface ClientLP {
  name?: string;
  phone?: string;
  email?: string;
  registrationKey?: string;
  employmentBond?: EmploymentBond;
  user?: User;
  address?: AddressLP;
  physicalPerson?: PhysicalPersonLP;
  legalPerson?: LegalPersonLP;
  id?: number;
  genderType?: string;
  birth?: string;
  zipCode?: string;
  organizationSubsidiary?: {
    id: number;
  };
}

export interface FinancesType {
  id?: number;
  choosedValue?: number;
  installmentsTotal?: number;
  client?: {
    id?: number;
  };
}

export interface Client {
  id?: number;
  phone?: string;
  homePhone?: string;
  email?: string;
  user?: User;
  address?: Address;
  physicalPerson?: PhysicalPerson;
  employmentBond?: EmploymentBond;
  legalPerson?: LegalPerson;
  attachments?: Attachment[];
  registrationKey?: string;
  finances?: Financing[];
  createdDate?: string;
  internalComment?: string;
  registrationStatus?: RegistrationStatus;
  isActive?: boolean;
  selfieKey?: string;
  bankAccounts?: BankAccount[];
  name?: string;
  creditScore?: CreditScore;
  internals?: Internal[];
  lastModifiedDate?: string;
  approvedDate?: string;
  partnerCode?: string;
  isToVerifyIdentity?: MonjuaVerifyIdentityStatus;
  ocrProcessingTries?: number;
  faceMatchedProcessingTries?: number;
  organizationSubsidiary?: OrganizationSubsidiary;
  creditCards?: CreditCard[];
}

export interface MandatoryClient {
  phone: string;
  email: string;
  address: {
    street: string;
    zipcode: string;
    district: string;
    city: {
      id: number;
      name: string;
    };
    number?: string;
    complement?: string;
    uf?: string;
  };
  physicalPerson: {
    name: string;
    cpf: string;
    income?: number;
    birth?: string;
    genderType?: string;
    maritalStatus?: {
      id: number;
    };
    occupation?: {
      id: number;
    };
  };
  employmentBond?: string;
}

export interface Occupation {
  id: number;
  name?: string;
}

export enum EmploymentBond {
  ASSALARIADO = 'ASSALARIADO',
  AUTONOMO = 'AUTONOMO',
  APOSENTADO = 'APOSENTADO',
  RURAL = 'RURAL',
  PENSIONISTA = 'PENSIONISTA',
  EMPRESARIO = 'EMPRESARIO',
  FUNCIONARIO_PUBLICO = 'FUNCIONARIO_PUBLICO',
  PROFISSIONAL_LIBERAL = 'PROFISSIONAL_LIBERAL',
  MILITAR = 'MILITAR',
  OUTRAS = 'OUTRAS',
}
